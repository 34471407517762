import * as moment from "moment";
import { isEqual } from "lodash-es";
import { Locale } from "src/State/Parameters/Locale";
import { I18N_LOCALES } from "src/Constants/I18nLocales";
import { IDateConverter } from "src/Services/Utils/DateConverter";

export interface IFormatterService {
    formatNumber(value: number, options?: Intl.NumberFormatOptions): string;
    formatDate(value: Date | Graviton.Common.DateTime, format?: string): string;
}

type NumberFormatCache = {
    readonly options: Intl.NumberFormatOptions | undefined;
    readonly numberFormat: Intl.NumberFormat;
};

export class FormatterService implements IFormatterService {
    private cache: NumberFormatCache | null = null;

    public static $inject: string[] = [
        "evjLocale",
    ];
    public constructor(
        private locale: Locale,
        private dateConverter: IDateConverter,
    ) {
    }

    public formatNumber(value: number, options?: Intl.NumberFormatOptions): string {
        if (!this.cache || !isEqual(options, this.cache.options)) {
            this.cache = {
                options: options,
                numberFormat: new Intl.NumberFormat(I18N_LOCALES[this.locale], options),
            };
        }

        return this.cache.numberFormat.format(value);
    }

    public formatDate(value: Date | Graviton.Common.DateTime, format: string = "DD.MM.YYYY"): string {
        const date = value instanceof Date ? value : this.dateConverter.convertToApp(value);
        return moment(date).locale(this.locale).format(format);
    }
}
