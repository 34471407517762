import * as React from "react";
import { EvojaIcon } from "@evoja-web/uikit";
import { classList } from "src/Utils/classList";

type Props = {
    readonly sort?: "asc" | "desc";
};
export function SortIndicator({ sort }: Props): JSX.Element {
    const className = classList("m03-sort-indicator", {
        [`m03-sort-indicator--${sort}`]: !!sort,
    });

    return (
        <span className={className}>
            <span className="m03-sort-indicator__arrow">
                <EvojaIcon icon="triangle-up"/>
            </span>
        </span>
    );
}
