import { get, set } from "lodash-es";
import { FormErrors } from "redux-form";
import { Constraint, Validator } from "@xiag/validator";
import { ChecklistForm } from "src/State/Form/ChecklistForm";
import { FieldError, FieldErrors } from "src/State/FieldError";

const CHECKLIST_FORM_VALIDATOR = new Validator(new Constraint.All([
    new Constraint.Required(),
    new Constraint.Type("object"),

    new Constraint.Field("title", new Constraint.All([
        new Constraint.Required(),
        new Constraint.Type("string"),
    ])),
    new Constraint.Field("text", new Constraint.All([
        new Constraint.Required(),
        new Constraint.Type("string"),
    ])),
]));

export function checklistFormValidator(
    formData: ChecklistForm,
): FormErrors<ChecklistForm, FieldErrors> {
    return CHECKLIST_FORM_VALIDATOR
        .validate(formData)
        .reduce((result, { path = "", code, data }) => {
            const value: unknown = get(formData, path);
            const errors: FieldErrors = get(result, path, []);
            const error: FieldError = {
                value: value,
                path: path,
                code: code,
                context: data,
            };

            return set(result, path, [...errors, error]);
        }, {});
}
