import * as React from "react";
import { invalidDisplayClass } from "src/Utils/invalidDisplayClass";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceDisplayClassType } from "src/State/Balance/BalanceType";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedGravitonDate } from "src/Components/Common/FormattedGravitonDate";
import { isNotEmpty } from "src/Utils/isNotEmpty";

type Props = {
    readonly balanceObject: BalanceObject;
};

export function ObjectSubTitle({ balanceObject }: Props): JSX.Element | null {
    switch (balanceObject.displayClassType) {
        case BalanceDisplayClassType.ACCOUNT_NORMAL:
        case BalanceDisplayClassType.ACCOUNT_DEPOT:
        case BalanceDisplayClassType.ACCOUNT_EXCHANGE:
            return renderSubtitleChunks(
                (
                    <TranslatedMessage value={
                        balanceObject.account.accountCurrency.currency.textShort
                            ? balanceObject.account.accountCurrency.currency.textShort
                            : balanceObject.account.accountCurrency.currency.text
                    }/>
                ),
                balanceObject.account.rubric,
                balanceObject.account.numberFormatted,
            );

        case BalanceDisplayClassType.ACCOUNT_METAL:
            return renderSubtitleChunks(
                (
                    <TranslatedMessage value={
                        balanceObject.account.accountCurrency.currency.textShort
                            ? balanceObject.account.accountCurrency.currency.textShort
                            : balanceObject.account.accountCurrency.currency.text
                    }/>
                ),
            );

        case BalanceDisplayClassType.ACCOUNT_DEPOSIT:
            return renderSubtitleChunks(
                balanceObject.account.balanceAt
                    ? <FormattedGravitonDate value={balanceObject.account.balanceAt}/>
                    : null,
            );

        case BalanceDisplayClassType.CUSTOMER_BALANCE:
        case BalanceDisplayClassType.NEW_ENGAGEMENT:
        case BalanceDisplayClassType.FINANCING_DEBT:
            return renderSubtitleChunks(
                balanceObject.customerBalance.rubric,
                balanceObject.customerBalance.bank
                    ? <TranslatedMessage value={balanceObject.customerBalance.bank.text}/>
                    : null,
            );

        case BalanceDisplayClassType.NEW_CONTRACT:
            return renderSubtitleChunks(
                balanceObject.customerBalance.rubric,
                balanceObject.customerBalance.bank
                    ? <TranslatedMessage value={balanceObject.customerBalance.bank.text}/>
                    : null,
                balanceObject.customerBalance.endDate
                    ? <FormattedGravitonDate value={balanceObject.customerBalance.endDate}/>
                    : null,
            );

        case BalanceDisplayClassType.FINANCING:
            return null;

        default:
            return invalidDisplayClass(balanceObject, { balanceObject });
    }
}

function renderSubtitleChunks(...chunks: React.ReactNode[]): JSX.Element {
    return (
        <span className="m03-object-subtitle">
            {chunks.filter(isNotEmpty).map(renderSubtitleChunk)}
        </span>
    );
}
function renderSubtitleChunk(chunk: React.ReactNode, index: number): JSX.Element {
    return <span key={index} className="m03-object-subtitle__item">{chunk}</span>;
}
