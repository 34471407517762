import { Provider } from "@xiag/container";
import { UTILS_PROVIDER } from "src/Services/Utils/UtilsProvider";
import { ENVIRONMENT_PROVIDER } from "src/Services/Environment/EnvironmentProvider";
import { HOST_COMMUNICATION_PROVIDER } from "src/Services/HostCommunication/HostCommunicationProvider";
import { IOS_COMMUNICATION_PROVIDER } from "src/Services/IosCommunication/IosCommunicationProvider";
import { REFERENCE_PROVIDER } from "src/Services/Reference/ReferenceProvider";

const GLOBAL_OVERRIDES_PROVIDER = window.$evjGlobalOverridesProvider || new Provider([]);
export const SERVICE_STATELESS_PROVIDER = new Provider([
    UTILS_PROVIDER,
    ENVIRONMENT_PROVIDER,
    HOST_COMMUNICATION_PROVIDER,
    IOS_COMMUNICATION_PROVIDER,
    REFERENCE_PROVIDER,
    GLOBAL_OVERRIDES_PROVIDER,
]);
