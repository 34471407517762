import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import {
    AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS,
    AvailabilityChangeWishedAmountRequest,
} from "src/Actions/AvailabilityActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* availabilityChangeWishedAmountSaga(
    { data: { amount, object } }: AvailabilityChangeWishedAmountRequest,
): SagaIterator {
    yield put(AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS.success({
        ...object,
        wishedAmount: {
            ...object.wishedAmount,
            wishedAmount: amount.greaterThan(object.amount)
                ? object.amount
                : amount,
        },
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
