import { AxiosInstance } from "axios";
import { HttpError } from "src/Errors/HttpError";
import { IFileEndpoint } from "src/Services/ApiClient/FileEndpoint";
import { IApiUrlFactory } from "src/Services/ApiClient/ApiUrlFactory";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { ErrorResponse } from "src/Services/ApiClient/Response";

export class FileEndpointFactory {
    public static $inject: string[] = [
        "$http",
        "evjApiUrlFactory",
    ];
    public constructor(
        private $http: AxiosInstance,
        private urlFactory: IApiUrlFactory,
    ) {
    }

    public createEndpoint(endpoint: EndpointName): IFileEndpoint {
        return {
            upload: (data: ArrayBuffer, mime: string) => this.$http
                .post<void>(
                    this.urlFactory.createListUrl(endpoint),
                    data,
                    {headers: {"Content-Type": mime}, transformRequest: []},
                )
                .catch((error: ErrorResponse) => this.processError(error)),
            download: (id: string) => this.$http
                .get<ArrayBuffer>(
                    this.urlFactory.createItemUrl(endpoint, id),
                    {responseType: "arraybuffer", headers: {Accept: "*/*"}, transformResponse: []},
                )
                .catch((error: ErrorResponse) => this.processError(error)),
        };
    }

    private processError(error: ErrorResponse): Promise<never> {
        return Promise.reject(new HttpError(error));
    }
}
