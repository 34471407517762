import ExtReference = Graviton.Common.ExtReference;
import * as URI from "urijs";

export interface IExtRefComparator {
    compareRefs<T>(a: ExtReference<T>, b: ExtReference<T>): boolean;
}

export class ExtRefComparator implements IExtRefComparator {
    public static $inject: string[] = [];
    public compareRefs<T>(a: ExtReference<T>, b: ExtReference<T>): boolean {
        return URI(a).pathname() === URI(b).pathname();
    }
}
