import * as React from "react";
import { FormattedNumber as BaseFormattedNumber } from "react-intl";

export function FormattedNumber(props: BaseFormattedNumber.Props): JSX.Element {
    const {
        minimumFractionDigits = 2,
        maximumFractionDigits = 2,
        // tslint:disable-next-line:trailing-comma
        ...restProps
    } = props;

    return (
        <BaseFormattedNumber {...restProps}
                             minimumFractionDigits={minimumFractionDigits}
                             maximumFractionDigits={maximumFractionDigits}/>
    );
}
