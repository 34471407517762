import { assertDefined } from "src/Utils/assertionHelpers";
import { ILogger } from "src/Modules/Logger/Logger";
import { IGetEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { NativeError } from "src/Errors/NativeError";
import { RuntimeError } from "src/Errors/RuntimeError";
import { ParametersState } from "src/State/ParametersState";
import { ConsultationData } from "src/State/ConsultationState";
import { IDateConverter } from "src/Services/Utils/DateConverter";
import { IBalanceDataMapper } from "src/Services/BalanceData/BalanceDataMapper";
import { IChecklistDataMapper } from "src/Services/ChecklistData/ChecklistDataMapper";
import { IBalanceInvestmentSyncService } from "src/Services/BalanceData/BalanceInvestmentSyncService";

export type IConsultationService = () => Promise<ConsultationData>;

ConsultationServiceFactory.$inject = [
    "$logger",
    "evjParameters",
    "evjApiEndpointConsultation",
    "evjDateConverter",
    "evjBalanceDataMapper",
    "evjChecklistDataMapper",
    "evjBalanceInvestmentSyncService",
];
export function ConsultationServiceFactory(
    $logger: ILogger,
    parameters: ParametersState,
    apiEndpointConsultation: IGetEndpoint<Graviton.Consultation.Consultation>,
    dateConverter: IDateConverter,
    balanceDataMapper: IBalanceDataMapper,
    checklistDataMapper: IChecklistDataMapper,
    balanceInvestmentSyncService: IBalanceInvestmentSyncService,
): IConsultationService {
    return async () => {
        try {
            $logger.info(`Fetching consultation "${parameters.consultationId}"...`);
            const { data: consultation } = await apiEndpointConsultation.get(parameters.consultationId, [
                "customer",
                "creationDate",
                "dossierData.balance",
                "dossierData.checklist",
                "dossierData.investment.consultation.accounts",
            ]);
            $logger.info(`Fetching consultation "${parameters.consultationId}"... done`, { consultation });

            const customer = assertDefined(
                consultation.customer,
                `No "customer" data`,
                { consultation },
            );
            const originalBalance = assertDefined(
                consultation.dossierData.balance,
                `No "dossierData.balance" data`,
                { consultation },
            );
            const originalChecklist = assertDefined(
                consultation.dossierData.checklist,
                `No "dossierData.checklist" data`,
                { consultation },
            );

            const investmentAccounts = consultation.dossierData.investment &&
                consultation.dossierData.investment.consultation
                ? consultation.dossierData.investment.consultation.accounts
                : undefined;
            const syncedBalance = balanceInvestmentSyncService.syncInvestmentToBalanceAccounts(
                originalBalance,
                investmentAccounts,
            );

            return {
                customer: customer,
                originalBalance: syncedBalance,
                balance: balanceDataMapper.mapBalance(syncedBalance),
                checklist: checklistDataMapper.mapChecklist(originalChecklist),
                creationDate: dateConverter.convertToApp(consultation.creationDate),
                investmentAccounts: investmentAccounts,
            };
        } catch (error) {
            const wrappedError = NativeError.wrapError(error);
            $logger.error(`Error while loading consultation "${parameters.consultationId}"`, wrappedError);
            throw new RuntimeError(`Could not fetch consultation data`, { parameters }, wrappedError);
        }
    };
}
