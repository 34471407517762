import { EventChannel, SagaIterator } from "redux-saga";
import { call, put, select, take } from "redux-saga/effects";
import { ILogger } from "src/Modules/Logger/Logger";
import { hostMessageChannel } from "src/Sagas/HostCommunication/HostMessageChannel";
import { selectService } from "src/Modules/Bind/selectService";
import { hostRequest } from "src/Actions/HostCommandActions";

export function* hostRequestSaga(): SagaIterator {
    const $logger: ILogger = yield select(selectService, "$logger");
    const $window: EventTarget = yield select(selectService, "$window");
    const channel: EventChannel<string> = yield call(hostMessageChannel, $window);

    try {
        while (true) {
            const request: string = yield take(channel);
            const action = hostRequest(request);

            yield call({
                fn: $logger.info,
                context: $logger,
            }, "Dispatching host communication action...", {action});

            yield put(action);

            yield call({
                fn: $logger.info,
                context: $logger,
            }, "Dispatching host communication action... done", {action});
        }
    } finally {
        yield call(channel.close);
    }
}
