import {
    CHECKLIST_DEFAULT_MODULE,
    CHECKLIST_DEFAULT_USE_CASE,
    CHECKLIST_DEFAULT_SECTION,
} from "src/Constants/ChecklistDefaultData";
import { ChecklistData } from "src/State/Checklist/ChecklistData";
import { ChecklistItem } from "src/State/Checklist/ChecklistItem";
import { ChecklistModule } from "src/State/Checklist/ChecklistModule";
import { ChecklistUseCase } from "src/State/Checklist/ChecklistUseCase";
import { ChecklistSection } from "src/State/Checklist/ChecklistSection";

export function checklistCreateItem(
    checklistData: ChecklistData,
    checklistItem: ChecklistItem,
): ChecklistData {
    const targetModule = checklistData.modules.find((module) => module.key === CHECKLIST_DEFAULT_MODULE.key);
    if (!targetModule) {
        return createModule(checklistItem, checklistData);
    }

    const targetUseCase = targetModule.useCase;
    if (!targetUseCase) {
        return createUseCase(checklistItem, checklistData, targetModule);
    }

    const targetSection = targetUseCase.sections.find((section) => section.id === CHECKLIST_DEFAULT_SECTION.id);
    if (!targetSection) {
        return createSection(checklistItem, checklistData, targetModule, targetUseCase);
    }

    return createItem(checklistItem, checklistData, targetModule, targetUseCase, targetSection);
}

function createModule(
    item: ChecklistItem,
    checklist: ChecklistData,
): ChecklistData {
    return {
        ...checklist,
        modules: [
            ...checklist.modules,
            {
                ...CHECKLIST_DEFAULT_MODULE,
                useCases: [],
                useCase: {
                    ...CHECKLIST_DEFAULT_USE_CASE,
                    sections: [
                        {
                            ...CHECKLIST_DEFAULT_SECTION,
                            items: [item],
                        },
                    ],
                },
            },
        ],
    };
}

function createUseCase(
    item: ChecklistItem,
    checklist: ChecklistData,
    targetModule: ChecklistModule,
): ChecklistData {
    return {
        ...checklist,
        modules: checklist.modules.map((module) => {
            if (module !== targetModule) {
                return module;
            }

            return {
                ...targetModule,
                useCases: [],
                useCase: {
                    ...CHECKLIST_DEFAULT_USE_CASE,
                    sections: [
                        {
                            ...CHECKLIST_DEFAULT_SECTION,
                            items: [item],
                        },
                    ],
                },
            };
        }),
    };
}

function createSection(
    item: ChecklistItem,
    checklist: ChecklistData,
    targetModule: ChecklistModule,
    targetUseCase: ChecklistUseCase,
): ChecklistData {
    return {
        ...checklist,
        modules: checklist.modules.map((module) => {
            if (module !== targetModule) {
                return module;
            }

            return {
                ...targetModule,
                useCases: [],
                useCase: {
                    ...targetUseCase,
                    sections: [
                        ...targetUseCase.sections,
                        {
                            ...CHECKLIST_DEFAULT_SECTION,
                            items: [item],
                        },
                    ],
                },
            };
        }),
    };
}

function createItem(
    item: ChecklistItem,
    checklist: ChecklistData,
    targetModule: ChecklistModule,
    targetUseCase: ChecklistUseCase,
    targetSection: ChecklistSection,
): ChecklistData {
    return  {
        ...checklist,
        modules: checklist.modules.map((module) => {
            if (module !== targetModule) {
                return module;
            }

            return {
                ...targetModule,
                useCases: [],
                useCase: {
                    ...targetUseCase,
                    sections: targetUseCase.sections.map((section) => {
                        if (section !== targetSection) {
                            return section;
                        }

                        return {
                            ...targetSection,
                            items: [
                                ...targetSection.items,
                                item,
                            ],
                        };
                    }),
                },
            };
        }),
    };
}
