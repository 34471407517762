import { get } from "lodash-es";
import {
    BalanceAccountPositionDetail,
    BalanceAccountPositionData,
} from "src/State/Balance/BalanceAccountPosition";
import { Locale } from "src/State/Parameters/Locale";

export interface IBalanceAccountPositionDetailsMapper {
    mapAdditionalDetails(
        position: BalanceAccountPositionData,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceAccountPositionDetail[];
}

export class BalanceAccountPositionDetailsMapper implements IBalanceAccountPositionDetailsMapper {
    public static $inject: string[] = [
        "evjLocale",
    ];

    public constructor(
        private locale: Locale,
    ) {
    }

    public mapAdditionalDetails(
        position: BalanceAccountPositionData,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceAccountPositionDetail[] {
        return balance.parameterData.depotAccountAdditionalDetails
            .map((it) => ({
                title: it.name[this.locale] || "",
                value: this.getByPath(position, it.keypath),
            }))
            .filter((it) => it.title && it.value);
    }

    private getByPath(position: BalanceAccountPositionData, path: string): string {
        const value = get(position, path);
        if (typeof value === "string") {
            return value;
        }

        const localized = get(value, this.locale);
        if (typeof localized === "string") {
            return localized;
        }

        return "";
    }
}
