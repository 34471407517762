import { isBalanceObjectAccountAsset } from "src/Services/Utils/balanceObjectGuard";
import { BalanceAvailabilityCategoryType } from "src/State/Balance/BalanceAvailabilityCategory";
import { isDefined } from "src/Utils/isDefined";
import { BalanceData } from "src/State/Balance/BalanceData";
import { BalanceObject } from "src/State/Balance/BalanceObject";

export interface IBalanceAvailabilityMapper {
    mapBalanceAvailability(
        balance: BalanceData,
    ): Graviton.Consultation.Balance.Consultation.Availability;
}

export class BalanceAvailabilityMapper implements IBalanceAvailabilityMapper {
    public mapBalanceAvailability(
        balance: BalanceData,
    ): Graviton.Consultation.Balance.Consultation.Availability {
        return {
            selectiveAvailableAmounts: this.mapSelectiveAvailableAmounts(balance.objects),
            definedAvailableAmounts: this.mapDefinedAvailableAmounts(balance.objects),
            wishedAmounts: this.mapWishedAmounts(balance.objects),
        };
    }

    private mapSelectiveAvailableAmounts(
        balanceObjects: ReadonlyArray<BalanceObject>,
    ): Graviton.Consultation.Balance.Consultation.SelectiveAvailableAmount[] {
        return balanceObjects.map((account) => {
            if (!isBalanceObjectAccountAsset(account)) {
                return undefined;
            }
            if (account.availabilityCategory.type !== BalanceAvailabilityCategoryType.SELECTIVE) {
                return undefined;
            }

            return {
                accountId: account.id,
                isSelected: account.availableAmount.included,
            };
        }).filter(isDefined);
    }

    private mapDefinedAvailableAmounts(
        balanceObjects: ReadonlyArray<BalanceObject>,
    ): Graviton.Consultation.Balance.Consultation.DefinedAvailableAmount[] {
        return balanceObjects.map((account) => {
            if (!isBalanceObjectAccountAsset(account)) {
                return undefined;
            }
            if (account.availabilityCategory.type !== BalanceAvailabilityCategoryType.DEFINED) {
                return undefined;
            }

            return {
                accountId: account.id,
                availableAmount: account.availableAmount.value
                    ? account.availableAmount.value.toString()
                    : undefined,
            };
        }).filter(isDefined);
    }

    private mapWishedAmounts(
        balanceObjects: ReadonlyArray<BalanceObject>,
    ): Graviton.Consultation.Balance.Consultation.WishedAmount[] {
        return balanceObjects.map((account) => {
            if (!isBalanceObjectAccountAsset(account)) {
                return undefined;
            }
            if (
                account.availabilityCategory.type !== BalanceAvailabilityCategoryType.DIRECT &&
                account.availabilityCategory.type !== BalanceAvailabilityCategoryType.SELECTIVE
            ) {
                return undefined;
            }

            return {
                accountId: account.id,
                wishedAmount: account.wishedAmount.wishedAmount.toString(),
                nkkPercent: account.wishedAmount.nkkPercent.toString(),
            };
        }).filter(isDefined);
    }
}
