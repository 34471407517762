import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};

export function DetailGroupDataBalanceDetails({ balanceObject }: Props): JSX.Element {
    return (
        <DetailGroupTable>
            <BalanceDetailsNoLimits balanceObject={balanceObject}/>

            <DetailGroupTableHead>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_title_balanceDetails"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            <BalanceDetailsDisponibleBalance balanceObject={balanceObject}/>
            <BalanceDetailsCreditLimit balanceObject={balanceObject}/>
            <BalanceDetailsPledged balanceObject={balanceObject}/>
            <BalanceDetailsWithdrawalAvailableAmount balanceObject={balanceObject}/>
        </DetailGroupTable>
    );
}

function BalanceDetailsNoLimits({ balanceObject }: Props): JSX.Element | null {
    if (balanceObject.account.withdrawalAmount !== undefined) {
        return null;
    }

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_balanceDetails_noLimits"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount/>
        </DetailGroupTableRow>
    );
}

function BalanceDetailsDisponibleBalance({ balanceObject }: Props): JSX.Element | null {
    const disponibleBalance = balanceObject.account.reportingCurrency.disponibleBalance
        ? new Decimal(balanceObject.account.reportingCurrency.disponibleBalance)
        : ZERO_DECIMAL;

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_balanceDetails_disponibleBalance"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(disponibleBalance.toNumber())}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}


function BalanceDetailsCreditLimit({ balanceObject }: Props): JSX.Element | null {
    if (
        balanceObject.account.creditLimit === undefined ||
        balanceObject.account.creditLimit === 0.00
    ) {
        return null;
    }

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_balanceDetails_creditLimit"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(balanceObject.account.creditLimit)}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function BalanceDetailsPledged({ balanceObject }: Props): JSX.Element | null {
    const pledgeSum = getPlegdeSum(balanceObject);
    if (pledgeSum.equals(0.00)) {
        return null;
    }

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_balanceDetails_pledged"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(pledgeSum.toNumber())}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function BalanceDetailsWithdrawalAvailableAmount({ balanceObject }: Props): JSX.Element | null {
    const pledgeSum = getPlegdeSum(balanceObject);
    const hasWithdrawalAmount = (
        balanceObject.account.withdrawalAmount !== undefined &&
        balanceObject.account.withdrawalAmount >= 0.00
    );
    const hasCreditLimit = balanceObject.account.creditLimit !== undefined;
    const hasPledges = !pledgeSum.equals(0.00);

    const isVisible = hasWithdrawalAmount || hasCreditLimit || hasPledges;
    if (!isVisible) {
        return null;
    }

    const { account: { reportingCurrency: { disponibleBalance = 0 }, creditLimit = 0 } } = balanceObject;
    const withdrawalAvailableAmountCalculated = ZERO_DECIMAL
        .add(disponibleBalance)
        .add(creditLimit)
        .sub(pledgeSum);

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_balanceDetails_withdrawalAvailableAmount"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(withdrawalAvailableAmountCalculated.toNumber())}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function renderAmountValue(amount: number | undefined): JSX.Element | null {
    return amount !== undefined
        ? <FormattedNumber value={amount}/>
        : null;
}

function getPlegdeSum(balanceObject: BalanceObjectAccount): Decimal {
    const { account: { pledge = [] } } = balanceObject;
    return pledge.reduce((result, { amount = 0 }) => result.add(amount), ZERO_DECIMAL);
}
