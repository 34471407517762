import { assertNotNull } from "src/Utils/assertionHelpers";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { BalanceCreationClassAssetType } from "src/State/Balance/BalanceCreationClassAsset";
import { BalanceCreationClassLiabilityType } from "src/State/Balance/BalanceCreationClassLiability";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";

export function isOtherForm({ creationClass }: CustomerBalanceForm): boolean {
    return creationClass.type === BalanceCreationClassLiabilityType.OTHER;
}

export function isFinancingForm({ creationClass }: CustomerBalanceForm): boolean {
    return creationClass.type === BalanceCreationClassLiabilityType.PROPERTY;
}

export function isContractForm({ creationClass }: CustomerBalanceForm): boolean {
    return creationClass.type === BalanceCreationClassAssetType.CONTRACT;
}

export function updateCustomerBalance(
    balanceObject: BalanceObjectCustomerBalance,
    formData: CustomerBalanceForm,
): BalanceObjectCustomerBalance {
    return {
        ...balanceObject,
        amount: assertNotNull(
            formData.amount,
            `Invalid "CustomerBalanceForm.amount" value`,
            { formData },
        ),
        customerBalance: {
            ...balanceObject.customerBalance,
            bank: assertNotNull(
                formData.bank,
                `Invalid "CustomerBalanceForm.bank" value`,
                { formData },
            ),

            title: formData.title,
            rubric: formData.rubric,

            endDate: formData.endDate
                ? formData.endDate
                : undefined,
            currentMarketPrice: formData.currentMarketPrice
                ? formData.currentMarketPrice
                : undefined,
        },
    };
}
