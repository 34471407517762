import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { Locale } from "src/State/Parameters/Locale";

export interface IInvestmentToBalanceAccountMapper {
    createBalanceAccountFromInvestment(
        investmentAccount: Readonly<Graviton.Consultation.Investment.Consultation.Account>,
        categoryMapping: Readonly<BalanceCategoryMapping>,
        bankList: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance;

    updateBalanceAccountFromInvestment(
        balanceAccount: Graviton.Consultation.Balance.Consultation.CustomerBalance,
        investmentAccount: Readonly<Graviton.Consultation.Investment.Consultation.Account>,
        bankList: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance;
}

export class InvestmentToBalanceAccountMapper implements IInvestmentToBalanceAccountMapper {
    public static $inject: string[] = [
        "evjLocale",
        "evjExtRefGenerator",
    ];

    public constructor(
        private locale: Locale,
        private extRefGenerator: IExtRefGenerator,
    ) {
    }

    public createBalanceAccountFromInvestment(
        investmentAccount: Readonly<Graviton.Consultation.Investment.Consultation.Account>,
        categoryMapping: Readonly<BalanceCategoryMapping>,
        bankList: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance {
        const amount = investmentAccount.customAmount || investmentAccount.amount;
        return {
            id: investmentAccount.id,
            isShared: true,
            rubric: investmentAccount.rubric || "",
            title: this.mapTitle(investmentAccount),
            creationClass: categoryMapping.creationClass,
            displayClass: categoryMapping.displayClass,
            outputClass: categoryMapping.outputClass,
            balanceSubType: investmentAccount.balanceSubType
                ? { ...investmentAccount.balanceSubType, textShort: investmentAccount.balanceSubType.text }
                : undefined,
            category: {
                $ref: this.extRefGenerator.createRef(EndpointName.BALANCE_CATEGORY, categoryMapping.category.id),
            },
            isAtThirdParty: investmentAccount.isAtThirdParty,
            amount: amount,
            currentMarketPrice: investmentAccount.currentMarketPrice || amount,
            endDate: this.mapEndDate(investmentAccount),
            bank: investmentAccount.bankId
                ? bankList.find((bank) => bank.id === investmentAccount.bankId)
                : undefined,
        };
    }

    public updateBalanceAccountFromInvestment(
        balanceAccount: Graviton.Consultation.Balance.Consultation.CustomerBalance,
        investmentAccount: Readonly<Graviton.Consultation.Investment.Consultation.Account>,
        bankList: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance {
        const amount = investmentAccount.customAmount || investmentAccount.amount;
        return {
            ...balanceAccount,

            rubric: investmentAccount.rubric || "",
            title: this.mapTitle(investmentAccount),

            amount: amount,
            currentMarketPrice: investmentAccount.currentMarketPrice || amount,
            endDate: this.mapEndDate(investmentAccount),

            isAtThirdParty: investmentAccount.isAtThirdParty,
            bank: investmentAccount.bankId
                ? bankList.find((bank) => bank.id === investmentAccount.bankId)
                : undefined,
        };
    }

    private mapTitle(account: Graviton.Consultation.Investment.Consultation.Account): string {
        if (account.title) {
            return account.title;
        }

        return account.balanceSubType
            ? account.balanceSubType.text[this.locale] || ""
            : "";
    }

    private mapEndDate(
        account: Graviton.Consultation.Investment.Consultation.Account,
    ): Graviton.Common.DateTime | undefined {
        if (account.customEndDate) {
            return account.customEndDate;
        }

        if (account.contract && account.contract.endDate) {
            return account.contract.endDate;
        }

        return undefined;
    }
}
