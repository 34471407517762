import * as React from "react";
import { createStructuredSelector } from "reselect";
import { Decimal } from "decimal.js-light";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { Translatable } from "src/State/Translatable";
import { ApplicationState } from "src/State/ApplicationState";
import { BalanceObject, BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { ChecklistForm } from "src/State/Form/ChecklistForm";
import { Availability } from "src/Components/Availability/Availability";
import { uiModalClose, uiModalOpen } from "src/Actions/UiActions";
import {
    DOCUMENT_OPEN_PROVISION_INFO_ACTIONS,
    DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS,
} from "src/Actions/DocumentActions";
import { CHECKLIST_CREATE_ACTIONS } from "src/Actions/ChecklistActions";
import {
    AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS,
    AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS,
    AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS,
    AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS,
} from "src/Actions/AvailabilityActions";
import { getIsModalOpen } from "src/Selectors/getIsModalOpen";
import { getBankName } from "src/Selectors/Configuration/getBankName";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";
import { FileConfigurationItem } from "src/State/Configuration/FileConfiguration";
import { getProvisionInfoFiles } from "src/Selectors/Configuration/getProvisionInfoFiles";
import { getWithdrawalConditionFiles } from "src/Selectors/Configuration/getWithdrawalConditionFiles";
import { getAssetCategoriesAvailability } from "src/Selectors/Balance/getAssetCategoriesAvailability";

type OwnProps = {};
type StateProps = {
    readonly bankName: Translatable;
    readonly objects: ReadonlyArray<BalanceObject>;
    readonly categories: ReadonlyArray<BalanceCategory>;
    readonly isChecklistModalOpen: boolean;
    readonly provisionInfoFiles: ReadonlyArray<FileConfigurationItem>;
    readonly withdrawalConditionFiles: ReadonlyArray<FileConfigurationItem>;
};
type DispatchProps = {
    readonly openProvisionInfo: (fileId: string) => void;
    readonly openWithdrawalCondition: (fileId: string) => void;
    readonly changeNkkPercent: (object: BalanceObjectAccountAsset, nkkPercent: Decimal) => void;
    readonly changeWishedAmount: (object: BalanceObjectAccountAsset, amount: Decimal) => void;
    readonly changeAvailableAmount: (object: BalanceObjectAccountAsset, amount: Decimal | null) => void;
    readonly toggleAvailableAmount: (object: BalanceObjectAccountAsset, included: boolean) => void;
    readonly openChecklistModal: () => void;
    readonly closeChecklistModal: () => void;
    readonly createChecklistEntry: (formData: ChecklistForm) => void;
};

export type AvailabilityProps =
    & OwnProps
    & StateProps
    & DispatchProps;

const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    bankName: getBankName,
    objects: getBalanceObjects,
    categories: getAssetCategoriesAvailability,
    provisionInfoFiles: getProvisionInfoFiles,
    withdrawalConditionFiles: getWithdrawalConditionFiles,
    isChecklistModalOpen: (state) => getIsModalOpen(state, "checklist-form"),
});
const mapDispatchToProps: DispatchProps = {
    openProvisionInfo: (fileId) =>
        DOCUMENT_OPEN_PROVISION_INFO_ACTIONS.request(undefined, fileId),
    openWithdrawalCondition: (fileId) =>
        DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS.request(undefined, fileId),
    changeNkkPercent: (object, nkkPercent) =>
        AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS.request({ object, nkkPercent }),
    changeWishedAmount: (object, amount) =>
        AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS.request({ object, amount }),
    changeAvailableAmount: (object, amount) =>
        AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS.request({ object, amount }),
    toggleAvailableAmount: (object, included) =>
        AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS.request({ object, included }),

    openChecklistModal: () => uiModalOpen("checklist-form"),
    closeChecklistModal: () => uiModalClose("checklist-form"),
    createChecklistEntry: (formData) => CHECKLIST_CREATE_ACTIONS.request(formData),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Availability, mapStateToProps, mapDispatchToProps)
export class AvailabilityPage extends React.Component {}
