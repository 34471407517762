export const UI_MODAL_OPEN = "UI_MODAL_OPEN";
export const UI_MODAL_CLOSE = "UI_MODAL_CLOSE";

export const UI_POPOVER_OPEN = "UI_POPOVER_OPEN";
export const UI_POPOVER_CLOSE = "UI_POPOVER_CLOSE";

export type UiModalAction = UiModalOpen | UiModalClose;
export interface UiModalOpen {
    readonly type: typeof UI_MODAL_OPEN;
    readonly modalId: string;
}
export interface UiModalClose {
    readonly type: typeof UI_MODAL_CLOSE;
    readonly modalId: string;
}

export type UiPopoverAction = UiPopoverOpen | UiPopoverClose;
export interface UiPopoverOpen {
    readonly type: typeof UI_POPOVER_OPEN;
    readonly popoverId: string;
}
export interface UiPopoverClose {
    readonly type: typeof UI_POPOVER_CLOSE;
    readonly popoverId: string;
}

export type UiAction =
    | UiPopoverAction
    | UiModalAction;

export function uiModalOpen(modalId: string): UiModalOpen {
    return {
        type: UI_MODAL_OPEN,
        modalId: modalId,
    };
}
export function uiModalClose(modalId: string): UiModalClose {
    return {
        type: UI_MODAL_CLOSE,
        modalId: modalId,
    };
}

export function uiPopoverOpen(popoverId: string): UiPopoverOpen {
    return {
        type: UI_POPOVER_OPEN,
        popoverId: popoverId,
    };
}
export function uiPopoverClose(popoverId: string): UiPopoverClose {
    return {
        type: UI_POPOVER_CLOSE,
        popoverId: popoverId,
    };
}
