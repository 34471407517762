import { RouterState } from "connected-react-router";
import { ParametersState } from "src/State/ParametersState";
import { ApplicationState } from "src/State/ApplicationState";

StateFactory.$inject = [
    "evjDefaultStateRouter",
    "evjDefaultStateParameters",
];
export function StateFactory(
    routerState: RouterState,
    parametersState: ParametersState,
): Partial<ApplicationState> {
    return {
        router: routerState,
        parameters: parametersState,
        configuration: undefined,
        consultation: {
            data: undefined,
            error: undefined,
            loading: false,
        },
        ui: {
            openModals: [],
            openPopovers: [],
        },
    };
}
