import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { EnvironmentContext, EnvironmentContextData } from "src/Context/EnvironmentContext";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};

export function DetailGroupDataWithdrawalLimits({ balanceObject }: Props): JSX.Element {
    return (
        <DetailGroupTable>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_title_withdrawalLimits"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            <WithdrawalAmount balanceObject={balanceObject}/>
            <WithdrawalCancellation balanceObject={balanceObject}/>
            <UsedAmount balanceObject={balanceObject}/>
            <WithdrawalAvailableAmount balanceObject={balanceObject}/>
        </DetailGroupTable>
    );
}

function WithdrawalAmount({ balanceObject }: Props): JSX.Element | null {
    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_withdrawalLimits_withdrawalAmount"
                                  values={{
                                      period: balanceObject.account.withdrawalPeriod
                                          ? <TranslatedMessage value={balanceObject.account.withdrawalPeriod}/>
                                          : null,
                                      periodDesc: balanceObject.account.withdrawalPeriodDesc
                                          ? <TranslatedMessage value={balanceObject.account.withdrawalPeriodDesc}/>
                                          : null,
                                  }}/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(balanceObject.account.withdrawalAmount)}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function WithdrawalCancellation({ balanceObject }: Props): JSX.Element | null {
    if (balanceObject.account.withdrawalCancellation === undefined) {
        return null;
    }

    const context = React.useContext(EnvironmentContext);
    const withdrawalCancellation = balanceObject.account.withdrawalCancellation
        .filter((item) => isWithdrawalCancellationCountable(item, context))
        .reduce((result, { amount = 0 }) => result + amount, 0);
    if (withdrawalCancellation < 0.01) {
        return null;
    }

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_withdrawalLimits_withdrawalCancellation"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(withdrawalCancellation)}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function UsedAmount({ balanceObject }: Props): JSX.Element | null {
    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_withdrawalLimits_usedAmount"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(balanceObject.account.usedAmount)}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function WithdrawalAvailableAmount({ balanceObject }: Props): JSX.Element | null {
    const { account: { withdrawalAmount = 0, withdrawalCancellation = [], usedAmount = 0 } } = balanceObject;

    const withdrawalAvailableAmountCalculated = withdrawalCancellation
        .reduce((result, { amount = 0 }) => result.add(amount), ZERO_DECIMAL)
        .add(withdrawalAmount)
        .sub(usedAmount);

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_withdrawalLimits_withdrawalAvailableAmount"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(withdrawalAvailableAmountCalculated.toNumber())}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function renderAmountValue(amount: number | undefined): JSX.Element | null {
    return amount !== undefined
        ? <FormattedNumber value={amount}/>
        : null;
}

function isWithdrawalCancellationCountable(
    cancellation: Readonly<Graviton.Account.AccountWithdrawalCancellation>,
    environmentContext: EnvironmentContextData,
): boolean {
    if (!cancellation.from) {
        return false;
    }

    const from = environmentContext.parseGravitonDate(cancellation.from);
    return moment(from).isSameOrBefore(environmentContext.dateNow, "day");
}
