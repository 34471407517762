import { Decimal } from "decimal.js-light";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { BalanceAvailabilityCategory } from "src/State/Balance/BalanceAvailabilityCategory";
import { BalanceObjectAccountWishedAmount } from "src/State/Balance/BalanceObjectAccountWishedAmount";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";

export interface IBalanceAccountWishedAmountMapper {
    mapBalanceAccountWishedAmount(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceAvailabilityCategory,
    ): BalanceObjectAccountWishedAmount;
}

export class BalanceAccountWishedAmountMapper implements IBalanceAccountWishedAmountMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjCoreConfiguration",
    ];
    public constructor(
        private coreConfiguration: CoreConfiguration,
    ) {
    }

    public mapBalanceAccountWishedAmount(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceAvailabilityCategory,
    ): BalanceObjectAccountWishedAmount {
        if (category.hasPositionBasedAvailability) {
            return {
                wishedAmount: this.getWishedAmount(account, balance),
                withdrawalAmount: this.getWithdrawalAmount(account),
                nkkPercent: this.getNkkPercent(account, balance),
            };
        } else {
            return {
                wishedAmount: ZERO_DECIMAL,
                withdrawalAmount: ZERO_DECIMAL,
                nkkPercent: ZERO_DECIMAL,
            };
        }
    }

    private getWishedAmount(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Decimal {
        const storedValue = balance.consultation.availability.wishedAmounts.find((it) => it.accountId === account.id);
        return storedValue
            ? new Decimal(storedValue.wishedAmount)
            : ZERO_DECIMAL;
    }

    private getWithdrawalAmount(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
    ): Decimal {
        return account.withdrawalAvailableAmount
            ? new Decimal(account.withdrawalAvailableAmount)
            : ZERO_DECIMAL;
    }

    private getNkkPercent(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Decimal {
        const storedValue = balance.consultation.availability.wishedAmounts.find((it) => it.accountId === account.id);
        if (storedValue) {
            return new Decimal(storedValue.nkkPercent);
        }

        return this.coreConfiguration.nkkPercent;
    }
}
