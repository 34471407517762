import { AxiosInstance } from "axios";
import { HttpError } from "src/Errors/HttpError";
import { ErrorResponse } from "src/Services/ApiClient/Response";
import { IApiUrlFactory } from "src/Services/ApiClient/ApiUrlFactory";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";
import { IInfoEndpoint } from "src/Services/ApiClient/InfoEndpoint";

export class InfoEndpointFactory {
    public static $inject: string[] = [
        "$http",
        "evjApiUrlFactory",
    ];
    public constructor(
        private $http: AxiosInstance,
        private urlFactory: IApiUrlFactory,
    ) {
    }

    public createEndpoint<T extends EndpointName>(endpoint: T): IInfoEndpoint<EndpointEntity[T]> {
        type Data = EndpointEntity[T];
        return {
            info: () => this.$http
                .get<Data>(this.urlFactory.createInfoUrl(endpoint))
                .catch((error: ErrorResponse) => Promise.reject(new HttpError(error))),
        };
    }
}
