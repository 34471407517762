import * as React from "react";
import { Location } from "history";
import { createStructuredSelector } from "reselect";
import { getLocation, replace } from "connected-react-router";
import { EvojaModal } from "@evoja-web/uikit";
import { bind } from "lodash-decorators/bind";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { ApplicationState } from "src/State/ApplicationState";
import { DepotPage } from "src/Pages/DepotPage";
import { PreserveContent } from "src/Components/Common/PreserveContent";

type OwnProps = {};
type StateProps = {
    readonly location: Readonly<Location>;
};
type DispatchProps = {
    readonly replaceLocation: (location: Readonly<Location>) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class DepotModalConnected extends React.Component<AllProps> {
    public render(): JSX.Element {
        const objectId = this.getObjectId();
        return (
            <EvojaModal isOpen={objectId !== null}
                        onExit={this.handleClose}
                        className="m03-depot-modal">
                <PreserveContent>
                    {objectId ? <DepotPage objectId={objectId}/> : null}
                </PreserveContent>
            </EvojaModal>
        );
    }

    private getObjectId(): string | null {
        const { location } = this.props;
        return location.pathname === "/"
            ? new URLSearchParams(location.search).get("depot-id")
            : null;
    }

    @bind()
    private handleClose(): void {
        const { location, replaceLocation } = this.props;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("depot-id");
        replaceLocation({ ...location, search: searchParams.toString() });
    }
}

const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    location: getLocation,
});
const mapDispatchToProps: DispatchProps = {
    replaceLocation: replace,
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(DepotModalConnected, mapStateToProps, mapDispatchToProps)
export class DepotModal extends React.Component<OwnProps> {}
