import "url-search-params-polyfill";
import "@evoja-web/uikit/dist/evoja-uikit.css";
import "./index.less";

import * as React from "react";
import { render } from "react-dom";
import { Store } from "redux";
import { SagaMiddleware } from "redux-saga";
import { History } from "history";
import { AppContainer } from "react-hot-loader";
import { Container, Provider } from "@xiag/container";

import { Root } from "src/root";
import { ErrorMessage } from "src/Components/ErrorMessage";
import { ErrorWrapper } from "src/Components/ErrorWrapper";
import { DefaultLayout } from "src/Components/DefaultLayout";
import { NativeError } from "src/Errors/NativeError";
import { ApplicationState } from "src/State/ApplicationState";
import { BOOSTRAP_PROVIDER } from "src/Bootstrap/BootstrapProvider";
import { EnvironmentContextData } from "src/Context/EnvironmentContext";
import { applicationSaga } from "src/Sagas/ApplicationSaga";

try {
    const globalBootstrapProvider = window.$evjGlobalBootstrapProvider || new Provider([]);
    const applicationProvider = new Provider([globalBootstrapProvider, BOOSTRAP_PROVIDER]);
    const applicationContainer = new Container(applicationProvider);

    const history = applicationContainer.get<History>("$history");
    const store = applicationContainer.get<Store<ApplicationState>>("evjApplicationStore");
    const context = applicationContainer.get<EnvironmentContextData>("evjEnvironmentContext");

    const sagaMiddleware = applicationContainer.get<SagaMiddleware<{}>>("evjMiddlewareSaga");
    sagaMiddleware.run(applicationSaga);

    const { theme } = store.getState().parameters;
    document.body.classList.add(`evoja-theme--${theme}`);

    const { maxWidth, maxHeight } = store.getState().parameters;
    document.body.style.setProperty("--evoja-max-width", maxWidth === null ? "100vw" : `${maxWidth}px`);
    document.body.style.setProperty("--evoja-max-height", maxHeight === null ? "100vh" : `${maxHeight}px`);

    // tslint:disable-next-line
    const renderApp = (RootElement: typeof Root) => render((
        <AppContainer>
            <RootElement store={store}
                         history={history}
                         context={context}/>
        </AppContainer>
    ), document.getElementById("root"));

    renderApp(Root);

    // Hot Module Replacement API
    if (module.hot) {
        module.hot.accept("./root", () => {
            // tslint:disable-next-line
            const NextRoot = require("./root").Root;
            renderApp(NextRoot);
        });
    }
} catch (error) {
    render((
        <DefaultLayout>
            <ErrorWrapper>
                <ErrorMessage error={NativeError.wrapError(error)}
                              errorInfo={undefined}/>
            </ErrorWrapper>
        </DefaultLayout>
    ), document.getElementById("root"));
}
