import { createSelector } from "reselect";
import { getBalanceOriginal } from "src/Selectors/Balance/getBalanceOriginal";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";
import { sortByAll } from "src/Utils/sortByAll";

export const getPortfolios = createSelector([
    getBalanceOriginal,
], (
    { stockData: { portfolios = [] } },
): BalancePortfolio[] => [...portfolios].sort(sortByAll<BalancePortfolio>((it) => it.alphabetChar)));
