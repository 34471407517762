import { ConsultationState } from "src/State/ConsultationState";
import { CONSULTATION_ACTIONS, ConsultationActions } from "src/Actions/ConsultationActions";

export function consultationReducer(
    state: ConsultationState,
    action: ConsultationActions,
): ConsultationState {
    switch (action.type) {
        case CONSULTATION_ACTIONS.PENDING:
            return {...state, loading: true, data: undefined, error: undefined};

        case CONSULTATION_ACTIONS.SUCCESS:
            return {...state, loading: false, data: action.data, error: undefined};

        case CONSULTATION_ACTIONS.FAILURE:
            return {...state, loading: false, data: undefined, error: action.data};

        default:
            return state;
    }
}
