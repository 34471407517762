import { Provider } from "@xiag/container";

import { TranslationService } from "src/Services/TranslationService";
import { FormatterService } from "src/Services/FormatterService";

import { API_CLIENT_PROVIDER } from "src/Services/ApiClient/ApiClientProvider";
import { STATE_ACCESS_PROVIDER } from "src/Services/StateAccess/StateAccessProvider";
import { CONFIGURATION_DATA_PROVIDER } from "src/Services/ConfigurationData/ConfigurationDataProvider";
import { BALANCE_DATA_PROVIDER } from "src/Services/BalanceData/BalanceDataProvider";
import { CHECKLIST_DATA_PROVIDER } from "src/Services/ChecklistData/ChecklistDataProvider";
import { SYNCHRONIZATION_DATA_PROVIDER } from "src/Services/SynchronizationData/SynchronizationDataProvider";
import { OUTPUT_JSON_PROVIDER } from "src/Services/Output/OutputJsonProvider";
import { ConfigurationServiceFactory } from "src/Services/ConfigurationService";
import { ConsultationServiceFactory } from "src/Services/ConsultationService";
import { SynchronizationServiceFactory } from "src/Services/SynchronizationService";

const GLOBAL_OVERRIDES_PROVIDER = window.$evjGlobalOverridesProvider || new Provider([]);
export const SERVICE_STATEFUL_PROVIDER = new Provider([
    API_CLIENT_PROVIDER,
    STATE_ACCESS_PROVIDER,
    CONFIGURATION_DATA_PROVIDER,
    BALANCE_DATA_PROVIDER,
    CHECKLIST_DATA_PROVIDER,
    SYNCHRONIZATION_DATA_PROVIDER,
    OUTPUT_JSON_PROVIDER,
    GLOBAL_OVERRIDES_PROVIDER,
])
    .factory(
        "evjConfigurationService",
        ConfigurationServiceFactory,
        ConfigurationServiceFactory.$inject || [],
    )
    .factory(
        "evjConsultationService",
        ConsultationServiceFactory,
        ConsultationServiceFactory.$inject || [],
    )
    .factory(
        "evjSynchronizationService",
        SynchronizationServiceFactory,
        SynchronizationServiceFactory.$inject || [],
    )

    .service(
        "evjTranslationService",
        TranslationService,
        TranslationService.$inject || [],
    )
    .service(
        "evjFormatterService",
        FormatterService,
        FormatterService.$inject || [],
    );
