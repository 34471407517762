import { ConsultationData } from "src/State/ConsultationState";
import { assertDefined } from "src/Utils/assertionHelpers";

export interface IInvestmentAccountsPatchMapper {
    mapInvestmentAccounts(
        consultationData: ConsultationData,
        balanceConsultation: Readonly<Graviton.Consultation.Balance.ConsultationData>,
    ): Graviton.Consultation.Investment.Consultation.Account[];
}

export class InvestmentAccountsPatchMapper implements IInvestmentAccountsPatchMapper {
    public mapInvestmentAccounts(
        consultationData: ConsultationData,
        balanceConsultation: Readonly<Graviton.Consultation.Balance.ConsultationData>,
    ): Graviton.Consultation.Investment.Consultation.Account[] {
        const investmentAccounts = assertDefined(
            consultationData.investmentAccounts,
            `Module "investment" does not exist`,
            { consultationData, balanceConsultation },
        );

        return [
            ...investmentAccounts.filter((account) => !this.wasOriginallySharedAccount(account, consultationData)),
            ...balanceConsultation.sharedAccounts,
        ];
    }

    private wasOriginallySharedAccount(
        investmentAccount: Graviton.Consultation.Investment.Consultation.Account,
        { originalBalance: { consultation: { sharedAccounts } } }: ConsultationData,
    ): boolean {
        return sharedAccounts.some((balanceAccount) => balanceAccount.id === investmentAccount.id);
    }
}
