import { BalanceCategoryType } from "src/State/Balance/BalanceCategory";

export type BalanceCategoryLink = {
    readonly id: string;
    readonly type: BalanceCategoryType;
};
export const BALANCE_CATEGORY_LINKS: ReadonlyArray<BalanceCategoryLink> = [
    {
        id: "balanceCategoryType-1",
        type: BalanceCategoryType.ASSETS,
    },
    {
        id: "balanceCategoryType-2",
        type: BalanceCategoryType.LIABILITIES,
    },
];
