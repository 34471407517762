import { addLocaleData } from "react-intl";
import * as enLocale from "react-intl/locale-data/en";
import * as deLocale from "react-intl/locale-data/de";
import * as frLocale from "react-intl/locale-data/fr";

LocaleConfiguration.$inject = [] as const;
export function LocaleConfiguration(): void {
    addLocaleData(enLocale);
    addLocaleData(deLocale);
    addLocaleData(frLocale);
}
