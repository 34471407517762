type SortMapper<T> = (item: T) => any;
type SortFunction<T> = (a: T, b: T) => number;

export function sortByAll<T>(mapper: SortMapper<T>, ...rest: SortMapper<T>[]): SortFunction<T> {
    return (a: T, b: T) => {
        const aa = mapper(a);
        const bb = mapper(b);

        if (aa !== bb) {
            return aa < bb ? -1 : 1;
        } else if (!rest.length) {
            return 0;
        } else {
            return sortByAll(rest[0], ...rest.slice(1))(a, b);
        }
    };
}
