import * as React from "react";
import { bind } from "lodash-decorators/bind";
import { ExpandContent } from "src/Components/Common/ExpandContent";

type Props = {
    readonly title: React.ReactNode;
    readonly amount: React.ReactNode;
};
type State = {
    readonly expanded: boolean;
};

export class HomeCategory extends React.PureComponent<Props, State> {
    public state: State = {
        expanded: false,
    };

    public render(): JSX.Element {
        const { title, amount, children } = this.props;
        const { expanded } = this.state;

        return (
            <div className="m03-home-category">
                <div className="m03-home-category__header" onClick={this.toggleExpanded}>
                    <div className="m03-home-category__header-title">{title}</div>
                    <div className="m03-home-category__header-amount">{amount}</div>
                </div>
                <ExpandContent expanded={expanded}>
                    <div className="m03-home-category__body">{children}</div>
                </ExpandContent>
            </div>
        );
    }

    @bind()
    private toggleExpanded(): void {
        this.setState((prev) => ({ expanded: !prev.expanded }));
    }
}
