import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Decimal } from "decimal.js-light";
import { Omit } from "mapped-types-utils";
import { EvojaPlacement } from "@evoja-web/uikit";
import { isFieldInvalid } from "src/Utils/isFieldInvalid";
import { CalculatorDropdown } from "src/Components/Common/CalculatorDropdown";

const nullFocusEvent = null as any as React.FocusEvent<any>;

type CalculatorProps = {
    readonly name: string;
    readonly placement: EvojaPlacement;
    readonly className?: string;
    readonly disabled?: boolean;

    readonly formatValue?: (value: Decimal | null) => React.ReactNode;
    readonly formatInput?: (value: string) => React.ReactNode;

    readonly hasReset?: boolean;
    readonly hasPoint?: boolean;
    readonly hasEnter?: boolean;
    readonly hasMinus?: boolean;
};

function CalculatorComponent(props: WrappedFieldProps & CalculatorProps): JSX.Element {
    const { input, meta, disabled = false } = props;
    const { formatValue, formatInput, hasEnter, hasReset, hasMinus, hasPoint, placement } = props;

    const invalid = isFieldInvalid(meta, { disabled });
    const value = ensureValueType(input.value);

    const onOpen = () => input.onFocus(nullFocusEvent);
    const onClose = () => input.onBlur(undefined);
    const onChange = (decimal: Decimal | null) => {
        input.onChange(decimal);
        input.onBlur(undefined);
    };

    return (
        <CalculatorDropdown id={`field-${meta.form}-${input.name}`}
                            placement={placement}
                            onOpenPopover={onOpen}
                            onClosePopover={onClose}
                            disabled={disabled}
                            invalid={invalid}
                            name={input.name}
                            value={value}
                            onChange={onChange}
                            formatValue={formatValue}
                            formatInput={formatInput}
                            hasEnter={hasEnter}
                            hasMinus={hasMinus}
                            hasPoint={hasPoint}
                            hasReset={hasReset}/>
    );
}

function ensureValueType(value: unknown): Decimal | null {
    return value instanceof Decimal ? value : null;
}

type Props = Omit<BaseFieldProps<CalculatorProps> & CalculatorProps, "component">;
export function CalculatorField(props: Props): JSX.Element {
    return <Field format={null} component={CalculatorComponent} {...props}/>;
}
