import { assertNever } from "src/Utils/assertionHelpers";
import { Locale } from "src/State/Parameters/Locale";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceOutputClassType } from "src/State/Balance/BalanceType";

export interface IOutputAvailabilityPositionTitleMapper {
    mapAvailabilityPositionTitle(
        balanceObject: BalanceObjectAccountAsset,
    ): string;
}

export class OutputAvailabilityPositionTitleMapper implements IOutputAvailabilityPositionTitleMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjLocale",
    ];
    public constructor(
        private locale: Locale,
    ) {
    }

    public mapAvailabilityPositionTitle(
        balanceObject: BalanceObjectAccountAsset,
    ): string {
        const outputClassType = balanceObject.outputClassType;
        switch (outputClassType) {
            case BalanceOutputClassType.ACCOUNT_NORMAL:
            case BalanceOutputClassType.ACCOUNT_DEPOT:
            case BalanceOutputClassType.ACCOUNT_EXCHANGE:
            case BalanceOutputClassType.ACCOUNT_METAL:
            case BalanceOutputClassType.ACCOUNT_DEPOSIT:
                return balanceObject.account.accountType.text[this.locale];

            default:
                return assertNever(`Unexpected outputDisplayClass "${outputClassType}"`, { balanceObject });
        }
    }
}
