import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import {
    AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS,
    AvailabilityChangeObjectAmountRequest,
} from "src/Actions/AvailabilityActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* availabilityChangeObjectAmountSaga(
    { data: { object, amount } }: AvailabilityChangeObjectAmountRequest,
): SagaIterator {
    const availableAmountValue = amount
        ? amount.greaterThan(object.amount)
            ? object.amount
            : amount
        : null;

    yield put(AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS.success({
        ...object,
        availableAmount: {
            ...object.availableAmount,
            value: availableAmountValue,
        },
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
