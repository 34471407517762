import IntlMessageFormat from "intl-messageformat";
import { MapOf } from "src/Utils/MapOf";
import { I18nMessages } from "src/Constants/I18nMessages";
import { Locale } from "src/State/Parameters/Locale";

export interface ITranslationService {
    translate(key: string, data?: MapOf<string | number>): string;
}

export class TranslationService implements ITranslationService {
    private cache: MapOf<IntlMessageFormat> = {};

    public static $inject: string[] = [
        "evjLocale",
        "i18nMessages",
    ];
    public constructor(
        private locale: Locale,
        private i18nMessages: I18nMessages,
    ) {
    }

    public translate(key: string, data?: MapOf<string | number>): string {
        const locale = this.locale;
        const message = this.i18nMessages[locale][key] || key;

        if (!this.cache[key]) {
            this.cache = {
                ...this.cache,
                [key]: new IntlMessageFormat(message, locale),
            };
        }

        return this.cache[key].format(data).toString();
    }
}
