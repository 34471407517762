import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { balanceObjectSumInBank } from "src/Services/Utils/balanceObjectSumInBank";

export function balanceTotalSumInBank(
    categories: ReadonlyArray<BalanceCategory>,
    objects: ReadonlyArray<BalanceObject>,
): Decimal {
    return categories.reduce((result, category) =>
        result.add(balanceObjectSumInBank(objects.filter((it) => it.category.id === category.id))),
        ZERO_DECIMAL,
    );
}
