import { SagaIterator } from "redux-saga";
import { all, fork, put, take, takeLatest } from "redux-saga/effects";

import { configurationSaga } from "src/Sagas/ConfigurationSaga";
import { consultationSaga } from "src/Sagas/ConsultationSaga";
import { availabilitySaga } from "src/Sagas/AvailabilitySaga";
import { liquidityPlanningSaga } from "src/Sagas/LiquidityPlanningSaga";
import { checklistSaga } from "src/Sagas/ChecklistSaga";
import { customerBalanceSaga } from "src/Sagas/CustomerBalanceSaga";
import { documentSaga } from "src/Sagas/DocumentSaga";

import { hostCommunicationSaga } from "src/Sagas/HostCommunication/HostCommunicationSaga";
import { CONFIGURATION_ACTIONS } from "src/Actions/ConfigurationActions";
import { CONSULTATION_ACTIONS } from "src/Actions/ConsultationActions";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";
import { synchronizationSaga } from "src/Sagas/SynchronizationSaga";

export function* applicationSaga(): SagaIterator {
    yield fork(hostCommunicationSaga);

    yield all([
        takeLatest(CONFIGURATION_ACTIONS.REQUEST, configurationSaga),
        takeLatest(CONSULTATION_ACTIONS.REQUEST, consultationSaga),
        takeLatest(SYNCHRONIZATION_ACTIONS.REQUEST, synchronizationSaga),
    ]);
    yield put(CONFIGURATION_ACTIONS.request(null));
    const configurationResult = yield take([CONFIGURATION_ACTIONS.SUCCESS, CONFIGURATION_ACTIONS.FAILURE]);
    if (CONFIGURATION_ACTIONS.isFailure(configurationResult)) {
        yield put(HOST_COMMAND_ACTIONS.loadFailure(configurationResult.data));
        return;
    }

    yield put(CONSULTATION_ACTIONS.request(null));
    const consultationResult = yield take([CONSULTATION_ACTIONS.SUCCESS, CONSULTATION_ACTIONS.FAILURE]);
    if (CONSULTATION_ACTIONS.isFailure(consultationResult)) {
        yield put(HOST_COMMAND_ACTIONS.loadFailure(consultationResult.data));
        return;
    }

    yield put(HOST_COMMAND_ACTIONS.loadComplete());

    yield fork(availabilitySaga);
    yield fork(liquidityPlanningSaga);
    yield fork(checklistSaga);
    yield fork(customerBalanceSaga);
    yield fork(documentSaga);
}
