import { BaseError } from "src/Errors/BaseError";
import { LogicError } from "src/Errors/LogicError";

export class AssertionError extends LogicError {
    public constructor(
        errorMessage: string,
        contextData?: object,
        previous?: BaseError,
    ) {
        super(errorMessage, contextData, previous);
    }

    public get name(): string {
        return "AssertionError";
    }
}
