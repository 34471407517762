import { createSelector } from "reselect";
import { getParameters } from "src/Selectors/getParameters";
import { EndpointName } from "src/Services/ApiClient/EndpointName";

export const getRefGenerator = createSelector([
    getParameters,
], (parameters) => (endpoint: EndpointName, id: string) => {
    const apiRefUrl = parameters.refURL.replace(/\/+$/, "");
    return `${apiRefUrl}/${endpoint}/${encodeURIComponent(id)}`;
});
