import * as React from "react";
import { noop } from "lodash-es";
import { bind } from "lodash-decorators/bind";
import { memoize } from "lodash-decorators/memoize";
import {
    EvojaDatebox,
    EvojaPlacement,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverContext,
    EvojaPopoverTarget,
    EvojaStaticField,
} from "@evoja-web/uikit";
import { classList } from "src/Utils/classList";
import { FormattedGravitonDate } from "src/Components/Common/FormattedGravitonDate";

type Props = {
    readonly id: string;
    readonly placement: EvojaPlacement;
    readonly name?: string;
    readonly disabled?: boolean;
    readonly invalid?: boolean;
    readonly className?: string;

    readonly value: Date | null;
    readonly onChange?: (value: Date | null) => void;
    readonly formatValue?: (value: Date) => string;

    readonly onOpenPopover?: () => void;
    readonly onClosePopover?: () => void;
};

export class DateboxDropdown extends React.Component<Props> {
    public render(): JSX.Element {
        const { id, placement, onOpenPopover, onClosePopover } = this.props;
        const { disabled } = this.props;

        return (
            <EvojaPopover id={id}
                          placement={placement}
                          disabled={disabled}
                          onOpenPopover={onOpenPopover}
                          onClosePopover={onClosePopover}>
                <EvojaPopoverTarget>
                    {this.renderValue}
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    {this.renderPopover}
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    @bind()
    private renderValue(context: EvojaPopoverContext): JSX.Element {
        const { value, disabled, invalid, name, className } = this.props;

        return (
            <EvojaStaticField ref={context.targetRef}
                              onClick={context.togglePopover}
                              name={name}
                              disabled={disabled}
                              invalid={invalid}
                              className={classList(className, "m03-datebox-dropdown__value")}>
                {value ? <FormattedGravitonDate value={value}/> : null}
            </EvojaStaticField>
        );
    }

    @bind()
    private renderPopover(context: EvojaPopoverContext): JSX.Element {
        const { value, disabled, name } = this.props;

        return (
            <EvojaDatebox ref={context.contentRef}
                          value={value}
                          name={name ? `${name}-datebox` : undefined}
                          className="m03-datebox-dropdown__field"
                          disabled={disabled}
                          onChange={this.getChangeHandler(context.closePopover)}/>
        );
    }

    @memoize()
    private getChangeHandler(
        closePopover: () => void,
    ): (value: Date | null) => void {
        return (value) => {
            const { disabled } = this.props;
            if (disabled) {
                closePopover();
                return;
            }

            const { onChange = noop } = this.props;
            onChange(value);
            closePopover();
        };
    }
}
