import { BalanceOutputClassType } from "src/State/Balance/BalanceType";

export type OutputClassLink = {
    readonly number: string;
    readonly type: BalanceOutputClassType;
};
export const OUTPUT_CLASS_LINKS: ReadonlyArray<OutputClassLink> = [
    {
        number: "1",
        type: BalanceOutputClassType.ACCOUNT_NORMAL,
    },
    {
        number: "2",
        type: BalanceOutputClassType.ACCOUNT_METAL,
    },
    {
        number: "3",
        type: BalanceOutputClassType.ACCOUNT_DEPOSIT,
    },
    {
        number: "4",
        type: BalanceOutputClassType.ACCOUNT_DEPOT,
    },
    {
        number: "5",
        type: BalanceOutputClassType.ACCOUNT_EXCHANGE,
    },
    {
        number: "6",
        type: BalanceOutputClassType.FINANCING,
    },
    {
        number: "7",
        type: BalanceOutputClassType.CUSTOMER_BALANCE,
    },
    {
        number: "8",
        type: BalanceOutputClassType.NEW_CONTRACT,
    },
    {
        number: "9",
        type: BalanceOutputClassType.NEW_ENGAGEMENT,
    },
    {
        number: "10",
        type: BalanceOutputClassType.FINANCING_DEBT,
    },
];
