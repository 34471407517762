import * as React from "react";
import { Decimal } from "decimal.js-light";
import { bind } from "lodash-decorators/bind";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { CalculatorDropdown } from "src/Components/Common/CalculatorDropdown";

type Props = {
    readonly id: string;
    readonly value: Decimal;
    readonly disabled: boolean;
    readonly onChange: (value: Decimal) => void;
};

export class LiquidityPlanningInput extends React.Component<Props> {
    public render(): JSX.Element {
        const { id, disabled, value } = this.props;

        return (
            <CalculatorDropdown id={`liquidity-planning--${id}`}
                                className="m03-liquidity-planning-input"
                                placement="top"
                                value={value}
                                disabled={disabled}
                                onChange={this.onChange}
                                hasMinus={false}
                                hasPoint={true}/>
        );
    }

    @bind()
    private onChange(decimalValue: Decimal | null): void {
        const { onChange } = this.props;
        onChange(decimalValue ? decimalValue : ZERO_DECIMAL);
    }
}
