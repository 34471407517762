import { BalanceDetailGroup, BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { BalanceAvailabilityCategory } from "src/State/Balance/BalanceAvailabilityCategory";

export enum BalanceCategoryType {
    ASSETS = "assets",
    LIABILITIES = "liabilities",
}

export type BalanceCategory = {
    readonly id: string;
    readonly balanceCategoryId: string;
    readonly title: Graviton.Common.Translatable;
    readonly type: BalanceCategoryType;
    readonly order: number;
    readonly coreTypes: ReadonlyArray<Graviton.Common.ExtRefObject<Graviton.Entity.Code>>;

    readonly availabilityCategory: BalanceAvailabilityCategory | undefined;
    readonly availabilityDetailGroups: ReadonlyArray<BalanceDetailGroup>;

    readonly displayClassType: BalanceDisplayClassType;
    readonly outputClassType: BalanceOutputClassType;
    readonly detailGroupTypes: ReadonlyArray<BalanceDetailGroup>;

    readonly isProvision: boolean;
    readonly isRealEstate: boolean;
};
