import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";
import {
    CUSTOMER_BALANCE_CREATE_ACTIONS,
    CUSTOMER_BALANCE_UPDATE_ACTIONS,
    CustomerBalanceUpdateRequest,
} from "src/Actions/CustomerBalanceActions";
import { updateCustomerBalance } from "src/Services/Utils/customerBalanceFormUtils";
import { customerBalanceCreateRealEstateSaga } from "src/Sagas/CustomerBalance/customerBalanceCreateRealEstateSaga";
import { customerBalanceUpdateRealEstateSaga } from "src/Sagas/CustomerBalance/customerBalanceUpdateRealEstateSaga";

export function* customerBalanceUpdateSaga(
    { data: { object, formData } }: CustomerBalanceUpdateRequest,
): SagaIterator {
    const updated: BalanceObjectCustomerBalance = yield call(
        updateCustomerBalance,
        object,
        formData,
    );
    yield put(CUSTOMER_BALANCE_UPDATE_ACTIONS.success(updated));

    if (updated.customerBalance.currentMarketPrice) {
        if (!object.customerBalance.currentMarketPrice) {
            const createdRealEstateAsset: BalanceObjectCustomerBalance = yield call(
                customerBalanceCreateRealEstateSaga,
                updated,
            );
            yield put(CUSTOMER_BALANCE_CREATE_ACTIONS.success(createdRealEstateAsset));
        } else {
            const updatedRealEstateAsset: BalanceObjectCustomerBalance = yield call(
                customerBalanceUpdateRealEstateSaga,
                updated,
            );
            yield put(CUSTOMER_BALANCE_UPDATE_ACTIONS.success(updatedRealEstateAsset));
        }
    }

    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
