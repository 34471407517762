import { PatchOpType, PatchRequest } from "src/Services/ApiClient/Request";
import { ConsultationData } from "src/State/ConsultationState";
import { IBalancePatchMapper } from "src/Services/SynchronizationData/BalancePatchMapper";
import { IChecklistPatchMapper } from "src/Services/SynchronizationData/ChecklistPatchMapper";
import { IInvestmentAccountsPatchMapper } from "src/Services/SynchronizationData/InvestmentAccountsPatchMapper";


export interface ISynchronizationMapper {
    createSyncRequest(consultationData: ConsultationData): Promise<PatchRequest>;
}

export class SynchronizationMapper implements ISynchronizationMapper {
    public static $inject: string[] = [
        "evjBalancePatchMapper",
        "evjChecklistPatchMapper",
        "evjInvestmentAccountsPatchMapper",
    ];
    public constructor(
        private balancePatchMapper: IBalancePatchMapper,
        private checklistPatchMapper: IChecklistPatchMapper,
        private investmentAccountsPatchMapper: IInvestmentAccountsPatchMapper,
    ) {
    }

    public async createSyncRequest(consultationData: ConsultationData): Promise<PatchRequest> {
        const mappedBalance = this.balancePatchMapper.mapBalance(consultationData);
        const operations = [
            {
                op: PatchOpType.ADD,
                path: "/dossierData/balance/consultation",
                value: mappedBalance,
            },
            {
                op: PatchOpType.ADD,
                path: "/dossierData/checklist",
                value: this.checklistPatchMapper.mapChecklist(consultationData),
            },
        ];

        if (consultationData.investmentAccounts === undefined) {
            return operations;
        }

        return [
            ...operations,
            {
                op: PatchOpType.ADD,
                path: "/dossierData/investment/consultation/accounts",
                value: this.investmentAccountsPatchMapper.mapInvestmentAccounts(
                    consultationData,
                    mappedBalance,
                ),
            },
        ];
    }
}
