import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import {
    AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS,
    AvailabilityChangeNkkPercentRequest,
} from "src/Actions/AvailabilityActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* availabilityChangeNkkPercentSaga(
    { data: { nkkPercent, object } }: AvailabilityChangeNkkPercentRequest,
): SagaIterator {
    yield put(AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS.success({
        ...object,
        wishedAmount: {
            ...object.wishedAmount,
            nkkPercent: nkkPercent,
        },
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
