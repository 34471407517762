import { IDateConverter } from "src/Services/Utils/DateConverter";
import { EnvironmentContextData } from "src/Context/EnvironmentContext";

EnvironmentContextFactory.$inject = [
    "evjDateNow",
    "evjDateConverter",
];
export function EnvironmentContextFactory(
    dateNow: Date,
    dateConverter: IDateConverter,
): EnvironmentContextData {
    return {
        dateNow: dateNow,
        parseGravitonDate: (date) => dateConverter.convertToApp(date),
    };
}
