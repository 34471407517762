import { Provider } from "@xiag/container";
import { CoreConfigurationMapper } from "src/Services/ConfigurationData/CoreConfigurationMapper";
import { CoreConfigurationService } from "src/Services/ConfigurationData/CoreConfigurationService";
import { FileConfigurationService } from "src/Services/ConfigurationData/FileConfigurationService";
import { InstanceConfigurationService } from "src/Services/ConfigurationData/InstanceConfigurationService";

export const CONFIGURATION_DATA_PROVIDER = new Provider([])
    .service(
        "evjCoreConfigurationMapper",
        CoreConfigurationMapper,
        CoreConfigurationMapper.$inject || [],
    )
    .service(
        "evjCoreConfigurationService",
        CoreConfigurationService,
        CoreConfigurationService.$inject || [],
    )
    .service(
        "evjFileConfigurationService",
        FileConfigurationService,
        FileConfigurationService.$inject || [],
    )
    .service(
        "evjInstanceConfigurationService",
        InstanceConfigurationService,
        InstanceConfigurationService.$inject || [],
    );
