import { buffers, EventChannel, eventChannel } from "redux-saga";

export function hostMessageChannel($window: EventTarget): EventChannel<string> {
    return eventChannel((emit) => {
        function handleMessageEvent(event: MessageEvent): void {
            if (typeof event.data === "string") {
                emit(event.data);
            }
        }

        $window.addEventListener("message", handleMessageEvent);
        return () => $window.removeEventListener("message", handleMessageEvent);
    }, buffers.sliding(1));
}
