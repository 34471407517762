import { Provider } from "@xiag/container";

import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";
import { ApiUrlFactory } from "src/Services/ApiClient/ApiUrlFactory";

import { IJsonEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { IFileEndpoint } from "src/Services/ApiClient/FileEndpoint";
import { IInfoEndpoint } from "src/Services/ApiClient/InfoEndpoint";

import { JsonEndpointFactory } from "src/Services/ApiClient/JsonEndpointFactory";
import { FileEndpointFactory } from "src/Services/ApiClient/FileEndpointFactory";
import { InfoEndpointFactory } from "src/Services/ApiClient/InfoEndpointFactory";

import { ExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { ExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { ExtRefResolver } from "src/Services/ApiClient/ExtRefResolver";

export const API_CLIENT_PROVIDER = new Provider([])
    .service(
        "evjApiUrlFactory",
        ApiUrlFactory,
        ApiUrlFactory.$inject || [],
    )
    .service(
        "evjApiEndpointJsonFactory",
        JsonEndpointFactory,
        JsonEndpointFactory.$inject || [],
    )
    .service(
        "evjApiEndpointFileFactory",
        FileEndpointFactory,
        FileEndpointFactory.$inject || [],
    )
    .service(
        "evjApiEndpointInfoFactory",
        InfoEndpointFactory,
        InfoEndpointFactory.$inject || [],
    )

    .service(
        "evjExtRefGenerator",
        ExtRefGenerator,
        ExtRefGenerator.$inject || [],
    )
    .service(
        "evjExtRefComparator",
        ExtRefComparator,
        ExtRefComparator.$inject || [],
    )
    .service(
        "evjExtRefResolver",
        ExtRefResolver,
        ExtRefResolver.$inject || [],
    )

    .factory(
        "evjApiEndpointFile",
        createJsonEndpointFactory(EndpointName.FILE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFileContent",
        createFileEndpointFactory(EndpointName.FILE),
        ["evjApiEndpointFileFactory"],
    )

    .factory(
        "evjApiEndpointAgendaConfig",
        createJsonEndpointFactory(EndpointName.AGENDA_CONFIG),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointChecklistConfig",
        createJsonEndpointFactory(EndpointName.CHECKLIST_CONFIG),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointEntityCode",
        createJsonEndpointFactory(EndpointName.ENTITY_CODE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointEntityContract",
        createJsonEndpointFactory(EndpointName.ENTITY_CONTRACT),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointAccountGroup",
        createJsonEndpointFactory(EndpointName.ACCOUNT_GROUP),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointAccountAccount",
        createJsonEndpointFactory(EndpointName.ACCOUNT_ACCOUNT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointAccountPosition",
        createJsonEndpointFactory(EndpointName.ACCOUNT_POSITION),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointBasicProduct",
        createJsonEndpointFactory(EndpointName.BASIC_PRODUCT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointBasicProductCategory",
        createJsonEndpointFactory(EndpointName.BASIC_PRODUCT_CATEGORY),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointBasicProductDocument",
        createJsonEndpointFactory(EndpointName.BASIC_PRODUCT_DOCUMENT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointBasicProductAddition",
        createJsonEndpointFactory(EndpointName.BASIC_PRODUCT_ADDITION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointBasicIntermediateView",
        createJsonEndpointFactory(EndpointName.BASIC_INTERMEDIATE_VIEW),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointServiceCard",
        createJsonEndpointFactory(EndpointName.SERVICE_CARD),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointServiceEservice",
        createJsonEndpointFactory(EndpointName.SERVICE_ESERVICE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointServiceLsv",
        createJsonEndpointFactory(EndpointName.SERVICE_LSV),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointServiceStandingOrder",
        createJsonEndpointFactory(EndpointName.SERVICE_STANDINGORDER),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointServiceSettlementCosts",
        createJsonEndpointFactory(EndpointName.SERVICE_SETTLEMENT_COSTS),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointPersistenceFavouriteDocuments",
        createJsonEndpointFactory(EndpointName.PERSISTENCE_FAVOURITE_DOCUMENTS),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersistenceFavouriteInstruments",
        createJsonEndpointFactory(EndpointName.PERSISTENCE_FAVOURITE_INSTRUMENTS),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersistenceRequisitionItem",
        createJsonEndpointFactory(EndpointName.PERSISTENCE_REQUISITION_ITEM),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersistenceRiskTolerance",
        createJsonEndpointFactory(EndpointName.PERSISTENCE_RISK_TOLERANCE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersistenceWishReserve",
        createJsonEndpointFactory(EndpointName.PERSISTENCE_WISH_RESERVE),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointCoreApp",
        createJsonEndpointFactory(EndpointName.CORE_APP),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreConfig",
        createJsonEndpointFactory(EndpointName.CORE_CONFIG),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreExternalLink",
        createJsonEndpointFactory(EndpointName.CORE_EXTERNAL_LINK),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreMapping",
        createJsonEndpointFactory(EndpointName.CORE_MAPPING),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreModule",
        createJsonEndpointFactory(EndpointName.CORE_MODULE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreScreen",
        createJsonEndpointFactory(EndpointName.CORE_SCREEN),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreTextBlock",
        createJsonEndpointFactory(EndpointName.CORE_TEXT_BLOCK),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointCoreVersion",
        createInfoEndpointFactory(EndpointName.CORE_VERSION),
        ["evjApiEndpointInfoFactory"],
    )

    .factory(
        "evjApiEndpointPersonWhoAmI",
        createInfoEndpointFactory(EndpointName.PERSON_WHOAMI),
        ["evjApiEndpointInfoFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomer",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomerBasicProduct",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER_BASICPRODUCT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomerAssignment",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER_ASSIGNMENT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomerBalance",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER_BALANCE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomerGroup",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER_GROUP),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonCustomerIncome",
        createJsonEndpointFactory(EndpointName.PERSON_CUSTOMER_INCOME),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPersonConsultant",
        createJsonEndpointFactory(EndpointName.PERSON_CONSULTANT),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointInvestmentAssetAllocation",
        createJsonEndpointFactory(EndpointName.INVESTMENT_ASSET_ALLOCATION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentAssetClass",
        createJsonEndpointFactory(EndpointName.INVESTMENT_ASSET_CLASS),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentCustomerRiskProfile",
        createJsonEndpointFactory(EndpointName.INVESTMENT_CUSTOMER_RISK_PROFILE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentDocument",
        createJsonEndpointFactory(EndpointName.INVESTMENT_DOCUMENT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentDuration",
        createJsonEndpointFactory(EndpointName.INVESTMENT_DURATION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentFinancing",
        createJsonEndpointFactory(EndpointName.INVESTMENT_FINANCING),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentFxRate",
        createJsonEndpointFactory(EndpointName.INVESTMENT_FX_RATE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentProduct",
        createJsonEndpointFactory(EndpointName.INVESTMENT_PRODUCT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentProfile",
        createJsonEndpointFactory(EndpointName.INVESTMENT_PROFILE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentQuestionnaire",
        createJsonEndpointFactory(EndpointName.INVESTMENT_QUESTIONNAIRE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentRange",
        createJsonEndpointFactory(EndpointName.INVESTMENT_RANGE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentRecommendation",
        createJsonEndpointFactory(EndpointName.INVESTMENT_RECOMMENDATION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentServiceClassRestriction",
        createJsonEndpointFactory(EndpointName.INVESTMENT_SERVICE_CLASS_RESTRICTION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentServiceSubType",
        createJsonEndpointFactory(EndpointName.INVESTMENT_SERVICE_SUB_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentServiceType",
        createJsonEndpointFactory(EndpointName.INVESTMENT_SERVICE_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentStrategy",
        createJsonEndpointFactory(EndpointName.INVESTMENT_STRATEGY),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentStrategyAssignment",
        createJsonEndpointFactory(EndpointName.INVESTMENT_STRATEGY_ASSIGNMENT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentStrategyReturn",
        createJsonEndpointFactory(EndpointName.INVESTMENT_STRATEGY_RETURN),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointInvestmentValor",
        createJsonEndpointFactory(EndpointName.INVESTMENT_VALOR),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointFinancingAffordability",
        createJsonEndpointFactory(EndpointName.FINANCING_AFFORDABILITY),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingAmortization",
        createJsonEndpointFactory(EndpointName.FINANCING_AMORTIZATION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingBlockModule",
        createJsonEndpointFactory(EndpointName.FINANCING_BLOCK_MODULE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingCashFlowType",
        createJsonEndpointFactory(EndpointName.FINANCING_CASH_FLOW_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingCollateral",
        createJsonEndpointFactory(EndpointName.FINANCING_COLLATERAL),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingCollateralType",
        createJsonEndpointFactory(EndpointName.FINANCING_COLLATERAL_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingDuration",
        createJsonEndpointFactory(EndpointName.FINANCING_DURATION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingEquityType",
        createJsonEndpointFactory(EndpointName.FINANCING_EQUITY_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingForward",
        createJsonEndpointFactory(EndpointName.FINANCING_FORWARD),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingHoliday",
        createJsonEndpointFactory(EndpointName.FINANCING_HOLIDAY),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingLibor",
        createJsonEndpointFactory(EndpointName.FINANCING_LIBOR),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingLoanProduct",
        createJsonEndpointFactory(EndpointName.FINANCING_LOAN_PRODUCT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingLoanProductGroup",
        createJsonEndpointFactory(EndpointName.FINANCING_LOAN_PRODUCT_GROUP),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingObject",
        createJsonEndpointFactory(EndpointName.FINANCING_OBJECT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingRate",
        createJsonEndpointFactory(EndpointName.FINANCING_RATE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingRealEstateType",
        createJsonEndpointFactory(EndpointName.FINANCING_REAL_ESTATE_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingRefiInterestRate",
        createJsonEndpointFactory(EndpointName.FINANCING_REFI_INTEREST_RATE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingSurchargeUseType",
        createJsonEndpointFactory(EndpointName.FINANCING_SURCHARGE_USE_TYPE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingSurchargeLendingRate",
        createJsonEndpointFactory(EndpointName.FINANCING_SURCHARGE_LENDING_RATE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointFinancingTranche",
        createJsonEndpointFactory(EndpointName.FINANCING_TRANCHE),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointEventAction",
        createJsonEndpointFactory(EndpointName.EVENT_ACTION),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointEventStatus",
        createJsonEndpointFactory(EndpointName.EVENT_STATUS),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointEventWorker",
        createJsonEndpointFactory(EndpointName.EVENT_WORKER),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointWorkCustomerNote",
        createJsonEndpointFactory(EndpointName.WORK_CUSTOMER_NOTE),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointWorkCustomerTask",
        createJsonEndpointFactory(EndpointName.WORK_CUSTOMER_TASK),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointPrintDocument",
        createJsonEndpointFactory(EndpointName.PRINT_DOCUMENT),
        ["evjApiEndpointJsonFactory"],
    )
    .factory(
        "evjApiEndpointPrintInvestmentDocument",
        createJsonEndpointFactory(EndpointName.PRINT_INVESTMENT_DOCUMENT),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointRequisitionGroup",
        createJsonEndpointFactory(EndpointName.REQUISITION_GROUP),
        ["evjApiEndpointJsonFactory"],
    )

    .factory(
        "evjApiEndpointConsultation",
        createJsonEndpointFactory(EndpointName.CONSULTATION),
        ["evjApiEndpointJsonFactory"],
    );

type JsonEndpointServiceFactory<T extends EndpointName> = {
    (factory: JsonEndpointFactory): IJsonEndpoint<EndpointEntity[T]>;
};
function createJsonEndpointFactory<T extends EndpointName>(endpoint: T): JsonEndpointServiceFactory<T> {
    return (endpointFactory) => endpointFactory.createEndpoint(endpoint);
}

type FileEndpointServiceFactory = {
    (factory: FileEndpointFactory): IFileEndpoint;
};
function createFileEndpointFactory(endpoint: EndpointName): FileEndpointServiceFactory {
    return (endpointFactory) => endpointFactory.createEndpoint(endpoint);
}

type InfoEndpointServiceFactory<T extends EndpointName> = {
    (factory: InfoEndpointFactory): IInfoEndpoint<EndpointEntity[T]>;
};
function createInfoEndpointFactory<T extends EndpointName>(endpoint: T): InfoEndpointServiceFactory<T> {
    return (endpointFactory) => endpointFactory.createEndpoint(endpoint);
}
