import { WebkitAwareWindow } from "webkit-window-extension";
import { NativeError } from "src/Errors/NativeError";
import { RuntimeError } from "src/Errors/RuntimeError";
import { IHostCommandDispatcher } from "src/Services/HostCommunication/HostCommandDispatcher";

export class IosCommandDispatcher implements IHostCommandDispatcher {
    public static $inject: ReadonlyArray<string> = [
        "evjIosSendMessageChannel",
        "$window",
    ];
    public constructor(
        private channel: string,
        private $window: WebkitAwareWindow,
    ) {
    }

    public dispatchCommand(command: object): void {
        if (
            !this.$window.webkit ||
            !this.$window.webkit.messageHandlers ||
            !this.$window.webkit.messageHandlers[this.channel]
        ) {
            throw new RuntimeError(
                `Webkit message handler "${this.channel}" is not open`,
                {channel: this.channel},
            );
        }

        try {
            this.$window.webkit
                .messageHandlers[this.channel]
                .postMessage(JSON.stringify(command));
        } catch (error) {
            throw new RuntimeError(
                `Could not send iOS command`,
                {command, error, channel: this.channel},
                NativeError.wrapError(error),
            );
        }
    }
}
