import { BaseError } from "src/Errors/BaseError";
import { ErrorResponse } from "src/Services/ApiClient/Response";

export class HttpError extends BaseError {
    public constructor(public readonly response: ErrorResponse, previous?: BaseError) {
        super(previous);
    }

    public get status(): number {
        return this.response.response ? this.response.response.status : 0;
    }

    public get name(): string {
        return "HttpError";
    }
    public get message(): string {
        const { status, statusText } = this.response.response
            ? this.response.response
            : {status: 0, statusText: "Unknown Error"};
        return `HTTP error ${status} ("${statusText}")`;
    }
    public toJSON(): object {
        return {
            ...super.toJSON(),
            response: this.response,
        };
    }
}
