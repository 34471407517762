import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";
import { BalanceOutputLiquidity } from "api/Types/BalanceOutputJson";

export interface IOutputLiquidityMapper {
    mapLiquidity(liquidity: BalanceLiquidityPlanning): BalanceOutputLiquidity;
}

export class OutputLiquidityMapper implements IOutputLiquidityMapper {
    public mapLiquidity(liquidity: BalanceLiquidityPlanning): BalanceOutputLiquidity {
        return {
            totalLiquidity: liquidity.total.toNumber(),
            planned: liquidity.planned.toNumber(),
            wishReserve: liquidity.wishReserve.toNumber(),
            provision: liquidity.provision.toNumber(),
            investment: liquidity.investment.toNumber(),
        };
    }
}
