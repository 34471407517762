import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { selectService } from "src/Modules/Bind/selectService";
import { ISynchronizationService } from "src/Services/SynchronizationService";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* synchronizationSaga(): SagaIterator {
    try {
        yield put(SYNCHRONIZATION_ACTIONS.pending());

        const synchronizationService: ISynchronizationService = yield select(
            selectService,
            "evjSynchronizationService",
        );
        yield call(synchronizationService);

        yield put(SYNCHRONIZATION_ACTIONS.success(null));
    } catch (error) {
        yield put(SYNCHRONIZATION_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
