import { ChecklistData } from "src/State/Checklist/ChecklistData";
import { CHECKLIST_CREATE_ACTIONS, ChecklistCreateActions } from "src/Actions/ChecklistActions";

export function checklistCreateReducer(
    state: ChecklistData,
    action: ChecklistCreateActions,
): ChecklistData {
    switch (action.type) {
        case CHECKLIST_CREATE_ACTIONS.SUCCESS:
            return action.data;

        default:
            return state;
    }
}
