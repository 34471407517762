import * as React from "react";
import { Decimal } from "decimal.js-light";
import {
    EvojaHtmlButton,
    EvojaIcon,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverTarget,
    EvojaTableCell,
    EvojaTableRow,
} from "@evoja-web/uikit";
import { BalanceAccountPosition } from "src/State/Balance/BalanceAccountPosition";
import {
    DepotDetailsTableCellAmount,
    DepotDetailsTableCellButton,
    DepotDetailsTableCellCurrency,
    DepotDetailsTableCellDate,
} from "src/Components/DepotDetails/DepotDetailsTable";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedGravitonDate } from "src/Components/Common/FormattedGravitonDate";
import { DepotDetailsAdditional } from "src/Components/DepotDetails/DepotDetailsAdditional";

type Props = {
    readonly accountPosition: BalanceAccountPosition;
};

export class DepotDetailsPosition extends React.Component<Props> {
    public render(): JSX.Element {
        const { accountPosition: { position } } = this.props;
        const quantity = new Decimal(position.quantity);
        const value = new Decimal(position.value);
        return (
            <EvojaTableRow>
                <DepotDetailsTableCellCurrency>
                    <TranslatedMessage value={position.valueCurrencyIso.text}/>
                </DepotDetailsTableCellCurrency>
                <DepotDetailsTableCellAmount>
                    <FormattedNumber value={quantity.toNumber()}/>
                </DepotDetailsTableCellAmount>
                <EvojaTableCell>
                    {position.valor.valorShortName
                        ? <TranslatedMessage value={position.valor.valorShortName}/>
                        : null}
                </EvojaTableCell>
                <DepotDetailsTableCellButton>
                    {this.renderDetailsButton()}
                </DepotDetailsTableCellButton>
                <EvojaTableCell>
                    {position.valor.riskValorType && position.valor.riskValorType.assetClass
                        ? <TranslatedMessage value={position.valor.riskValorType.assetClass.text}/>
                        : null}
                </EvojaTableCell>
                <DepotDetailsTableCellDate>
                    {position.valor.expiryDate
                        ? <FormattedGravitonDate value={position.valor.expiryDate}/>
                        : null}
                </DepotDetailsTableCellDate>
                <DepotDetailsTableCellAmount>
                    <FormattedNumber value={value.div(quantity).toNumber()}/>
                </DepotDetailsTableCellAmount>
                <DepotDetailsTableCellAmount>
                    <FormattedNumber value={value.toNumber()}/>
                </DepotDetailsTableCellAmount>
            </EvojaTableRow>
        );
    }

    private renderDetailsButton(): JSX.Element | null {
        const { accountPosition } = this.props;
        if (!accountPosition.additionalDetails.length) {
            return null;
        }

        return (
            <EvojaPopover id={`detail-groups--${accountPosition.position.id}`} placement="right">
                <EvojaPopoverTarget>
                    <EvojaHtmlButton shape="circle">
                        <EvojaIcon icon="ellipsis"/>
                    </EvojaHtmlButton>
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <DepotDetailsAdditional additionalDetails={accountPosition.additionalDetails}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }
}
