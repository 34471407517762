import * as React from "react";
import { createStructuredSelector } from "reselect";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { ApplicationState } from "src/State/ApplicationState";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { DepotDetails } from "src/Components/DepotDetails/DepotDetails";
import { getBalanceObjectAccountById } from "src/Selectors/Balance/getBalanceObjectAccountById";
import { Locale } from "src/State/Parameters/Locale";
import { getLocale } from "src/Selectors/getLocale";

type OwnProps = {
    readonly objectId: string;
};
type StateProps = {
    readonly balanceObject: BalanceObjectAccount;
    readonly locale: Locale;
};
type DispatchProps = {};

export type DepotProps =
    & OwnProps
    & StateProps
    & DispatchProps;

const mapStateToProps = createStructuredSelector<ApplicationState, OwnProps, StateProps>({
    balanceObject: (state, props) => getBalanceObjectAccountById(state, props.objectId),
    locale: getLocale,
});
const mapDispatchToProps: DispatchProps = {};

@connectDecorator<OwnProps, StateProps, DispatchProps>(DepotDetails, mapStateToProps, mapDispatchToProps)
export class DepotPage extends React.Component<OwnProps> {
}
