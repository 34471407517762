import * as React from "react";
import { FormattedMessage } from "react-intl";
import { EnvironmentContext, EnvironmentContextData } from "src/Context/EnvironmentContext";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { FormattedGravitonDate } from "src/Components/Common/FormattedGravitonDate";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};

export function DetailGroupDataCancellations({ balanceObject }: Props): JSX.Element {
    return (
        <DetailGroupTable>
            <CancellationsLimitAll balanceObject={balanceObject}/>
            <CancellationsList balanceObject={balanceObject}/>
        </DetailGroupTable>
    );
}

function CancellationsLimitAll({ balanceObject }: Props): JSX.Element | null {
    const { account: { withdrawalAmount = 0 } } = balanceObject;
    if (withdrawalAmount > 0) {
        return null;
    }

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_cancellations_limitAll"/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount/>
        </DetailGroupTableRow>
    );
}

function CancellationsList({ balanceObject }: Props): JSX.Element | null {
    const context = React.useContext(EnvironmentContext);

    const { account: { withdrawalCancellation = [] } } = balanceObject;
    const visibleItems = withdrawalCancellation
        .filter((item) => (
            item.amount !== undefined &&
            item.amount > 0.00
        ))
        .sort((a, b) => (
            getCancellationFromDate(a, context).valueOf() -
            getCancellationFromDate(b, context).valueOf()
        ));
    if (visibleItems.length === 0) {
        return null;
    }

    return (
        <>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_title_cancellations"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            {visibleItems.map((cancellation, index) => (
                <CancellationsListItem key={index}
                                       cancellation={cancellation}/>
            ))}
        </>
    );
}

type CancellationsListItemProps = {
    readonly cancellation: Readonly<Graviton.Account.AccountWithdrawalCancellation>,
};
function CancellationsListItem({ cancellation }: CancellationsListItemProps): JSX.Element {
    const fromValue = cancellation.from
        ? <FormattedGravitonDate value={cancellation.from}/>
        : null;
    const toValue = cancellation.to
        ? <FormattedGravitonDate value={cancellation.to}/>
        : null;

    return (
        <DetailGroupTableRow>
            <DetailGroupTableCellValue>
                <FormattedMessage id="detailGroupPopover_cancellations_item"
                                  values={{ from: fromValue, to: toValue }}/>
            </DetailGroupTableCellValue>
            <DetailGroupTableCellAmount>
                {renderAmountValue(cancellation.amount)}
            </DetailGroupTableCellAmount>
        </DetailGroupTableRow>
    );
}

function renderAmountValue(amount: number | undefined): JSX.Element | null {
    return amount !== undefined
        ? <FormattedNumber value={amount}/>
        : null;
}

function getCancellationFromDate(
    cancellation: Readonly<Graviton.Account.AccountWithdrawalCancellation>,
    context: EnvironmentContextData,
): Date {
    return cancellation.from
        ? context.parseGravitonDate(cancellation.from)
        : new Date(NaN);
}
