import { SagaIterator } from "redux-saga";
import { select } from "redux-saga/effects";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { assertDefined } from "src/Utils/assertionHelpers";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";

export function* customerBalanceUpdateRealEstateSaga(
    realEstateLiability: BalanceObjectCustomerBalance,
): SagaIterator<BalanceObjectCustomerBalance> {
    const balanceObjects: BalanceObjectCustomerBalance[] = yield select(getBalanceObjects);
    const realEstateAsset = assertDefined(
        balanceObjects.find((it) => it.mainId === realEstateLiability.id),
        `Could not find main balance object for "${realEstateLiability.id}"`,
        { realEstateLiability, balanceObjects },
    );
    const amount = assertDefined(
        realEstateLiability.customerBalance.currentMarketPrice,
        `"customerBalance.currentMarketPrice" is not defined`,
        { realEstateLiability },
    );
    return {
        ...realEstateAsset,
        amount: amount,
        customerBalance: { ...realEstateLiability.customerBalance },
    };
}
