import { assertDefined } from "src/Utils/assertionHelpers";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";
import { sortByAll } from "src/Utils/sortByAll";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";

export interface IBalanceCategoryMappingMapper {
    mapBalanceCategoryMappings(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCategoryMapping[];
}

export class BalanceCategoryMappingMapper implements IBalanceCategoryMappingMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
    ];
    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
    ) {
    }

    public mapBalanceCategoryMappings(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCategoryMapping[] {
        return [...balance.parameterData.categoryMapping]
            .sort(sortByAll((mapping) => mapping.order === undefined
                ? Number.MAX_SAFE_INTEGER
                : mapping.order
            ))
            .map((mapping) => {
                const displayClass = assertDefined(
                    mapping.displayClass,
                    `DisplayClass is not defined in categoryMapping "${mapping.id}"`,
                    { mapping },
                );
                const outputClass = assertDefined(
                    mapping.outputClass,
                    `OutputClass is not defined in categoryMapping "${mapping.id}"`,
                    { mapping },
                );
                return {
                    id: mapping.id,
                    category: this.findByRef(
                        EndpointName.BALANCE_CATEGORY,
                        balance.parameterData.categories,
                        mapping.category.$ref,
                    ),
                    creationClass: this.findByRef(
                        EndpointName.ENTITY_CODE,
                        [
                            ...balance.parameterData.balanceCreationClassAssets,
                            ...balance.parameterData.balanceCreationClassLiabilities,
                        ],
                        mapping.creationClass.$ref,
                    ),
                    assetSegment: !mapping.assetSegment ? undefined : this.findByRef(
                        EndpointName.ENTITY_CODE,
                        balance.parameterData.assetSegments,
                        mapping.assetSegment.$ref,
                    ),
                    displayClass: this.findByRef(
                        EndpointName.ENTITY_CODE,
                        balance.parameterData.balanceDisplayClasses,
                        displayClass.$ref,
                    ),
                    outputClass: this.findByRef(
                        EndpointName.ENTITY_CODE,
                        balance.parameterData.balanceOutputClasses,
                        outputClass.$ref,
                    ),
                };
            });
    }

    private findByRef<TEndpoint extends EndpointName>(
        endpoint: TEndpoint,
        entities: ReadonlyArray<EndpointEntity[TEndpoint] & { id: string }>,
        reference: Graviton.Common.ExtReference<EndpointEntity[TEndpoint]>,
    ): EndpointEntity[TEndpoint] {
        return assertDefined(
            entities.find((entity) => this.extRefComparator.compareRefs(
                reference,
                this.extRefGenerator.createRef(endpoint, entity.id),
            )),
            `Could not find entity "${endpoint}" with reference "${reference}"`,
            { endpoint, entities, reference },
        );
    }
}
