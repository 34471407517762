import * as React from "react";
import { isNotNull } from "src/Utils/isNotNull";
import { invalidDetailGroup } from "src/Utils/invalidDetailGroup";
import { BalanceObjectAccount, BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { BalanceDetailGroup } from "src/State/Balance/BalanceType";
import { DetailGroupDataCurrency } from "src/Components/DetailGroup/DetailGroupDataCurrency";
import { DetailGroupDataPledges } from "src/Components/DetailGroup/DetailGroupDataPledges";
import { DetailGroupDataLimits } from "src/Components/DetailGroup/DetailGroupDataLimits";
import { DetailGroupDataFinancings } from "src/Components/DetailGroup/DetailGroupDataFinancings";
import { hasDetailGroupData } from "src/Services/Utils/hasDetailGroupData";
import { assertBalanceObjectAccount, assertBalanceObjectFinancing } from "src/Services/Utils/balanceObjectGuard";
import { DetailGroupDataWithdrawalLimits } from "src/Components/DetailGroup/DetailGroupDataWithdrawalLimits";
import { DetailGroupDataCancellations } from "src/Components/DetailGroup/DetailGroupDataCancellations";
import { DetailGroupDataBalanceDetails } from "src/Components/DetailGroup/DetailGroupDataBalanceDetails";

type Props = {
    readonly balanceObject: BalanceObjectAccount | BalanceObjectFinancing;
    readonly detailGroupTypes: ReadonlyArray<BalanceDetailGroup>;
};
export function DetailGroup({ balanceObject, detailGroupTypes }: Props): JSX.Element | null {
    const tables = detailGroupTypes
        .map((detailGroup) => renderDetailGroup(detailGroup, balanceObject))
        .filter(isNotNull);

    return tables.length
        ? <div className="m03-detail-group">{tables}</div>
        : null;
}

export function renderDetailGroup(
    detailGroup: BalanceDetailGroup,
    balanceObject: BalanceObjectAccount | BalanceObjectFinancing,
): JSX.Element | null {
    if (!hasDetailGroupData(balanceObject, detailGroup)) {
        return null;
    }

    switch (detailGroup) {
        case BalanceDetailGroup.CURRENCY:
            return (
                <DetailGroupDataCurrency key={detailGroup}
                                         balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        case BalanceDetailGroup.PLEDGES:
            return (
                <DetailGroupDataPledges key={detailGroup}
                                        balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        case BalanceDetailGroup.LIMITS:
            return (
                <DetailGroupDataLimits key={detailGroup}
                                       balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        case BalanceDetailGroup.MORTGAGE:
            return (
                <DetailGroupDataFinancings key={detailGroup}
                                           balanceObject={assertBalanceObjectFinancing(balanceObject)}/>
            );

        case BalanceDetailGroup.DEPOT_DETAILS:
            // TODO: depot account details
            return null;

        case BalanceDetailGroup.WITHDRAWAL_LIMITS:
            return (
                <DetailGroupDataWithdrawalLimits key={detailGroup}
                                                 balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        case BalanceDetailGroup.CANCELLATIONS:
            return (
                <DetailGroupDataCancellations key={detailGroup}
                                              balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        case BalanceDetailGroup.BALANCE_DETAILS:
            return (
                <DetailGroupDataBalanceDetails key={detailGroup}
                                               balanceObject={assertBalanceObjectAccount(balanceObject)}/>
            );

        default:
            return invalidDetailGroup(detailGroup, { detailGroup, balanceObject });
    }
}
