import { createSelector } from "reselect";
import { getBalanceCategoryMappings } from "src/Selectors/Balance/getBalanceCategoryMappings";
import { getParameterData } from "src/Selectors/Balance/getParameterData";
import { sortByAll } from "src/Utils/sortByAll";
import { CategoriesByEntityCode } from "src/State/Balance/CategoriesByEntityCode";

export const getCategoriesByAssetSegment = createSelector([
    getParameterData,
    getBalanceCategoryMappings,
], (
    { assetSegments },
    categoryMappings,
): CategoriesByEntityCode[] => {
    return assetSegments
        .sort(sortByAll((assetSegment) => {
            const number = parseInt(assetSegment.number, 10);
            return Number.isFinite(number) ? number : Number.MAX_SAFE_INTEGER;
        }))
        .map((assetSegment) => ({
            code: assetSegment,
            categoryIds: categoryMappings
                .filter((it) => it.assetSegment?.id === assetSegment.id)
                .map((it) => it.category.id),
        }))
        .filter((it) => it.categoryIds.length > 0);
});
