import { BalanceAvailabilityCategoryType } from "src/State/Balance/BalanceAvailabilityCategory";

export type AvailabilityCategoryLink = {
    readonly id: string;
    readonly type: BalanceAvailabilityCategoryType;
};
export const AVAILABILITY_CATEGORY_LINKS: ReadonlyArray<AvailabilityCategoryLink> = [
    {
        id: "balanceAvailabilityType-1",
        type: BalanceAvailabilityCategoryType.DIRECT,
    },
    {
        id: "balanceAvailabilityType-2",
        type: BalanceAvailabilityCategoryType.SELECTIVE,
    },
    {
        id: "balanceAvailabilityType-3",
        type: BalanceAvailabilityCategoryType.DEFINED,
    },
    {
        id: "balanceAvailabilityType-4",
        type: BalanceAvailabilityCategoryType.NONE,
    },
];
