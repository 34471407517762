import { AxiosInstance } from "axios";
import { Query } from "@xiag/rql-query";
import { HttpError } from "src/Errors/HttpError";
import { IApiUrlFactory } from "src/Services/ApiClient/ApiUrlFactory";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { ErrorResponse, ListResponse } from "src/Services/ApiClient/Response";
import { PatchRequest } from "src/Services/ApiClient/Request";
import { IJsonEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";

export class JsonEndpointFactory {
    public static $inject: string[] = [
        "$http",
        "evjApiUrlFactory",
    ];
    public constructor(
        private $http: AxiosInstance,
        private urlFactory: IApiUrlFactory,
    ) {
    }

    public createEndpoint<T extends EndpointName>(endpoint: T): IJsonEndpoint<EndpointEntity[T]> {
        type Data = EndpointEntity[T];
        return {
            get: (id: string, fields?: string[]) => this.$http
                .get<Data>(this.urlFactory.createItemUrl(endpoint, id, fields))
                .catch((error: ErrorResponse) => this.processError(error)),
            head: (id: string) => this.$http
                .head(this.urlFactory.createItemUrl(endpoint, id))
                .catch((error: ErrorResponse) => this.processError(error)),

            list: (q?: Query) => this.$http
                .get<Data[]>(this.urlFactory.createListUrl(endpoint, q))
                .catch((error: ErrorResponse) => this.processError(error)),
            all: (q?: Query) => this.fetchAll<Data>(endpoint, q || new Query())
                .catch((error: ErrorResponse) => this.processError(error)),

            create: (d: Data) => this.$http
                .post<void>(this.urlFactory.createListUrl(endpoint), d)
                .catch((error: ErrorResponse) => this.processError(error)),
            update: (id: string, d: Data) => this.$http
                .put<void>(this.urlFactory.createItemUrl(endpoint, id), d)
                .catch((error: ErrorResponse) => this.processError(error)),
            patch: (id: string, patch: PatchRequest) => this.$http
                .patch<void>(this.urlFactory.createItemUrl(endpoint, id), patch)
                .catch((error: ErrorResponse) => this.processError(error)),
            delete: (id: string) => this.$http
                .delete(this.urlFactory.createItemUrl(endpoint, id))
                .catch((error: ErrorResponse) => this.processError(error)),
        };
    }

    private fetchAll<T>(endpoint: EndpointName, query: Query): Promise<ListResponse<T>> {
        const limit = 1000;
        const offset = 0;

        return this.$http
            .get<T[]>(this.urlFactory.createListUrl(endpoint, query.limit(limit, offset)))
            .then((response: ListResponse<T>) => {
                const totalCount = parseInt(response.headers["x-total-count"], 10);
                if (isNaN(totalCount) || totalCount <= limit) {
                    return response;
                }

                return this.fetchExtra(endpoint, query, totalCount).then((extraData: T[]) => ({
                    ...response,
                    data: [...response.data, ...extraData],
                }));
            });
    }
    private fetchExtra<T>(endpoint: EndpointName, query: Query, totalCount: number): Promise<T[]> {
        const firstLimit = 1000;
        const extraLimit = 3000;

        const requestCount = Math.ceil((totalCount - firstLimit) / extraLimit);
        return Promise.all(new Array(requestCount).fill(null).map((_, index) => {
            const limit = extraLimit;
            const offset = firstLimit + index * extraLimit;

            return this.$http
                .get<T[]>(this.urlFactory.createListUrl(endpoint, query.limit(limit, offset)))
                .then((response: ListResponse<T>) => response.data);
        })).then((data: T[][]) => new Array<T>().concat(...data));
    }

    private processError(error: ErrorResponse): Promise<never> {
        return Promise.reject(new HttpError(error));
    }
}
