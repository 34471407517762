import { v4 } from "uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { Translatable } from "src/State/Translatable";
import { ChecklistData } from "src/State/Checklist/ChecklistData";
import { replaceTranslatable } from "src/Utils/replaceTranslatable";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import { CHECKLIST_CREATE_ACTIONS, ChecklistCreateRequest } from "src/Actions/ChecklistActions";
import { CHECKLIST_ITEM_TEMPLATE } from "src/Constants/ChecklistItemTemplate";
import { getChecklistData } from "src/Selectors/getChecklistData";
import { checklistCreateItem } from "src/Services/Utils/checklistCreateItem";

export function* checklistCreateEntrySaga(
    { data: formData }: ChecklistCreateRequest,
): SagaIterator {
    const checklistId: string = yield call(v4);
    const checklistText: Translatable = yield call(replaceTranslatable, CHECKLIST_ITEM_TEMPLATE, {
        "#title#": formData.title,
        "#text#": formData.text,
    });

    const currentChecklistData: ChecklistData = yield select(getChecklistData);
    const updatedChecklistData: ChecklistData = yield call(checklistCreateItem, currentChecklistData, {
        id: checklistId,
        name: checklistText,

        isOwnItem: true,
        isChecked: false,

        isHidden: false,
        displayCondition: undefined,
    });

    yield put(CHECKLIST_CREATE_ACTIONS.success(updatedChecklistData));

    yield put(SYNCHRONIZATION_ACTIONS.request(null));
    yield put(HOST_COMMAND_ACTIONS.reloadChecklist());
}
