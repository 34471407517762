import { ComponentClass, ComponentType } from "react";
import { reduxForm, ConfigProps, InjectedFormProps, DecoratedComponentClass } from "redux-form";
import { FieldErrors } from "src/State/FieldError";

export type InnerFormProps<TFormData, TOwnProps> =
    & TOwnProps
    & InjectedFormProps<TFormData, TOwnProps, FieldErrors>;

export type OuterFormProps<TFormData, TOwnProps> =
    & TOwnProps
    & ConfigProps<TFormData, TOwnProps, FieldErrors>;

export function formDecorator<TFormData extends {}, TOwnProps extends {}>(
    original: ComponentType<InnerFormProps<TFormData, TOwnProps>>,
    config: Partial<ConfigProps<TFormData, TOwnProps, FieldErrors>>,
): (
    target: ComponentClass<OuterFormProps<TFormData, TOwnProps>>,
) => DecoratedComponentClass<TFormData, OuterFormProps<TFormData, TOwnProps>, FieldErrors> {
    return () => reduxForm<TFormData, TOwnProps, FieldErrors>(config)(original);
}
