export const CORE_CONFIG_KEYS = {
    styling: "evjStyling",
    bankId: "ownBank",
    provisionCategoryId: "balanceProvisionCategory",
    realEstateCategoryId: "balanceRealEstateCategory",
    nkkPercent: "balanceNKK",
    selectiveAvailableAmountDefaultValue: "balanceUseForAvailabilityDefault",
    positionBasedAvaliabilityCategoryIds: "balancePositionBasedAvaliabilityTypes",
    assetGroupings: "balanceAssetGroupings",
    investablePositions: "investmentInvestablePositions",
};
