import { BalanceData } from "src/State/Balance/BalanceData";
import { CUSTOMER_BALANCE_UPDATE_ACTIONS, CustomerBalanceUpdateActions } from "src/Actions/CustomerBalanceActions";

export function customerBalanceUpdateReducer(
    state: BalanceData,
    action: CustomerBalanceUpdateActions,
): BalanceData {
    switch (action.type) {
        case CUSTOMER_BALANCE_UPDATE_ACTIONS.SUCCESS:
            return {
                ...state,
                objects: state.objects.map((object) => (
                    object.id === action.data.id
                        ? action.data
                        : object
                )),
            };

        default:
            return state;
    }
}
