import ExtReference = Graviton.Common.ExtReference;
import * as URI from "urijs";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { LogicError } from "src/Errors/LogicError";

export interface ResolvedExtRef {
    readonly endpoint: EndpointName;
    readonly id: string;
}

export interface IExtRefResolver {
    resolveRef<T>(ref: ExtReference<T>): ResolvedExtRef;
}

export class ExtRefResolver implements IExtRefResolver {
    public static $inject: string[] = [];
    public resolveRef(ref: ExtReference<any>): ResolvedExtRef {
        const path = URI(ref).pathname().replace(/^\/+/, "").replace(/\/+$/, "");
        for (const endpoint of this.getEndpointList()) {
            const id = this.extractEntityId(path, endpoint);
            if (id) {
                return {endpoint, id};
            }
        }

        throw new LogicError(`Invalid extref "${ref}"`, {ref, path});
    }

    private getEndpointList(): ReadonlyArray<EndpointName> {
        return Object.keys(EndpointName)
            .filter((key) => isNaN(+key))
            .map((key) => (EndpointName as any)[key]);
    }
    private extractEntityId(path: string, endpoint: EndpointName): string | undefined {
        if (!path.startsWith(`${endpoint}/`)) {
            return undefined;
        }

        const id = path.substr(endpoint.length + 1);
        if (id.includes("/")) {
            return undefined;
        }

        return decodeURIComponent(id);
    }
}
