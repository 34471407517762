import { ResolvedEnitityCode } from "src/State/ResolvedEnitityCode";

export enum BalanceCreationClassAssetType {
    ACCOUNT = "account",
    SAVINGS = "savings",
    SECURITIES = "securities",
    CONTRACT = "contract",
    PROVISION = "provision",
}

export type BalanceCreationClassAsset = ResolvedEnitityCode<BalanceCreationClassAssetType>;
