import { Provider } from "@xiag/container";
import { IosCommunicationEnabled } from "src/Services/IosCommunication/IosCommunicationEnabled";
import { IosLogger } from "src/Services/IosCommunication/IosLogger";
import { IosLoggerDecorator } from "src/Services/IosCommunication/IosLoggerDecorator";
import { IosCommandDispatcher } from "src/Services/IosCommunication/IosCommandDispatcher";
import { IosCommandDispatcherDecorator } from "src/Services/IosCommunication/IosCommandDispatcherDecorator";

export const IOS_COMMUNICATION_PROVIDER = new Provider([])
    .constant(
        "evjIosSendMessageChannel",
        "EXCHANGE",
    )
    .constant(
        "evjIosLoggerChannel",
        "console",
    )

    .service(
        "evjIosCommandDispatcher",
        IosCommandDispatcher,
        IosCommandDispatcher.$inject || [],
    )
    .decorate(
        "evjHostCommandDispatcher",
        IosCommandDispatcherDecorator,
        IosCommandDispatcherDecorator.$inject || [],
    )

    .service(
        "evjIosLogger",
        IosLogger,
        IosLogger.$inject || [],
    )
    .decorate(
        "$logger",
        IosLoggerDecorator,
        IosLoggerDecorator.$inject || [],
    )

    .factory(
        "evjIosCommunicationEnabled",
        IosCommunicationEnabled,
        IosCommunicationEnabled.$inject || [],
    );
