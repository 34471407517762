import * as React from "react";
import { createStructuredSelector } from "reselect";
import { Decimal } from "decimal.js-light";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { ApplicationState } from "src/State/ApplicationState";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";
import { LiquidityPlanning } from "src/Components/LiquidityPlanning/LiquidityPlanning";
import { getBalanceLiquidityPlanning } from "src/Selectors/Balance/getBalanceLiquidityPlanning";
import {
    LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS,
} from "src/Actions/LiquidityPlanningActions";

type OwnProps = {};
type StateProps = {
    readonly liquidityPlanning: BalanceLiquidityPlanning;
};
type DispatchProps = {
    readonly changeTotal: (value: Decimal) => void;
    readonly changePlanned: (value: Decimal) => void;
    readonly changeWishReserve: (value: Decimal) => void;
    readonly changeProvision: (value: Decimal) => void;
    readonly changeInvestment: (value: Decimal) => void;
};

export type LiquidityPlanningProps =
    & OwnProps
    & StateProps
    & DispatchProps;

const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    liquidityPlanning: getBalanceLiquidityPlanning,
});
const mapDispatchToProps: DispatchProps = {
    changeTotal: (value) => LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS.request(value),
    changePlanned: (value) => LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS.request(value),
    changeWishReserve: (value) => LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS.request(value),
    changeProvision: (value) => LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS.request(value),
    changeInvestment: (value) => LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS.request(value),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(LiquidityPlanning, mapStateToProps, mapDispatchToProps)
export class LiquidityPlanningPage extends React.Component {}
