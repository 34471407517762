import { WebkitAwareWindow } from "webkit-window-extension";

IosCommunicationEnabled.$inject = [
    "$window",
    "$location",
];
export function IosCommunicationEnabled(
    $window: WebkitAwareWindow,
    $location: Location,
): boolean {
    const params = new URLSearchParams($location.search);
    return params.has("iosMode") ? true : !!$window.webkit;
}
