import * as React from "react";
import { FormattedMessage } from "react-intl";
import { memoize } from "lodash-decorators/memoize";
import { Decimal } from "decimal.js-light";
import { EvojaTable, EvojaTableCell, EvojaTableHead } from "@evoja-web/uikit";
import { DepotProps } from "src/Pages/DepotPage";
import { BalanceAccountPosition } from "src/State/Balance/BalanceAccountPosition";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { ObjectTitle } from "src/Components/Common/ObjectTitle";
import { ObjectSubTitle } from "src/Components/Common/ObjectSubTitle";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { SortIndicator } from "src/Components/Common/SortIndicator";
import { sortByAll } from "src/Utils/sortByAll";
import { assertNever } from "src/Utils/assertionHelpers";
import {
    DepotDetailsTableCellAmount,
    DepotDetailsTableCellButton,
    DepotDetailsTableCellCurrency,
    DepotDetailsTableCellDate,
} from "src/Components/DepotDetails/DepotDetailsTable";
import { DepotDetailsPosition } from "src/Components/DepotDetails/DepotDetailsItem";

type State = {
    readonly sortField: string | undefined;
    readonly sortType: "asc" | "desc" | undefined;
    readonly positions: ReadonlyArray<BalanceAccountPosition>;
};

export class DepotDetails extends React.Component<DepotProps, State> {
    public state: State = {
        sortField: undefined,
        sortType: undefined,
        positions: this.props.balanceObject.accountPositions,
    };

    public render(): JSX.Element {
        const { balanceObject } = this.props;
        const { positions } = this.state;

        return (
            <div className="m03-depot-details">
                <div className="m03-depot-details__header">
                    <div className="m03-depot-details__title">
                        <ObjectTitle balanceObject={balanceObject}/>
                        <span className="m03-depot-details__amount">
                            <FormattedNumber value={balanceObject.amount.toNumber()}/>
                        </span>
                    </div>
                    <div className="m03-depot-details__subtitle">
                        <ObjectSubTitle balanceObject={balanceObject}/>
                    </div>
                </div>

                <div className="m03-depot-details__content">
                    <EvojaTable className="m03-depot-details__table">
                        <EvojaTableHead>
                            <DepotDetailsTableCellCurrency onClick={this.sortBy("currency")}>
                                {this.sortableField("currency", <FormattedMessage id="depotDetails_currency"/>)}
                            </DepotDetailsTableCellCurrency>
                            <DepotDetailsTableCellAmount onClick={this.sortBy("quantity")}>
                                {this.sortableField("quantity", <FormattedMessage id="depotDetails_number"/>)}
                            </DepotDetailsTableCellAmount>
                            <EvojaTableCell onClick={this.sortBy("designation")}>
                                {this.sortableField("designation", <FormattedMessage id="depotDetails_designation"/>)}
                            </EvojaTableCell>
                            <DepotDetailsTableCellButton>
                                {" "}
                            </DepotDetailsTableCellButton>
                            <EvojaTableCell onClick={this.sortBy("assetClass")}>
                                {this.sortableField("assetClass", <FormattedMessage id="depotDetails_class"/>)}
                            </EvojaTableCell>
                            <DepotDetailsTableCellDate onClick={this.sortBy("expiration")}>
                                {this.sortableField("expiration", <FormattedMessage id="depotDetails_expiration"/>)}
                            </DepotDetailsTableCellDate>
                            <DepotDetailsTableCellAmount onClick={this.sortBy("rate")}>
                                {this.sortableField("rate", <FormattedMessage id="depotDetails_rate"/>)}
                            </DepotDetailsTableCellAmount>
                            <DepotDetailsTableCellAmount onClick={this.sortBy("marketValue")}>
                                {this.sortableField("marketValue", <FormattedMessage id="depotDetails_marketValue"/>)}
                            </DepotDetailsTableCellAmount>
                        </EvojaTableHead>

                        {positions.map((position) => (
                            <DepotDetailsPosition accountPosition={position} key={position.position.id}/>
                        ))}
                    </EvojaTable>
                </div>

                <div className="m03-depot-details__footer">
                    <div className="m03-depot-details__total-title">
                        <FormattedMessage id="depotDetails_total"/>
                    </div>
                    <div className="m03-depot-details__total-amount">
                        <FormattedNumber value={this.getTotalSum()}/>
                    </div>
                </div>
            </div>
        );
    }

    private sortableField(key: string, title: React.ReactNode): JSX.Element {
        const { sortField, sortType } = this.state;
        return (
            <span className="m03-depot-details__sortable">
                {title}
                <SortIndicator sort={sortField === key ? sortType : undefined}/>
            </span>
        );
    }

    private getTotalSum(): number {
        const { balanceObject } = this.props;
        return balanceObject.accountPositions
            .reduce((result, it) => result.add(it.position.value), ZERO_DECIMAL).toNumber();
    }

    @memoize()
    private sortBy(field: string): () => void {
        return () => this.setState((prev) => {
            const sortField = prev.sortField !== field
                ? field
                : prev.sortType === "asc" ? field : undefined;
            const sortType = prev.sortField !== field
                ? "asc"
                : prev.sortType === "asc" ? "desc" : undefined;

            return {
                sortField,
                sortType,
                positions: this.sortPositions(sortField, sortType),
            };
        });
    }

    private sortPositions(sortField?: string, sortType?: "asc" | "desc"): BalanceAccountPosition[] {
        const { balanceObject, locale } = this.props;
        if (!sortField) {
            return [...balanceObject.accountPositions];
        }

        const sorted = [...balanceObject.accountPositions].sort(
            sortByAll((item) => {
                const quantity = new Decimal(item.position.quantity);
                const value = new Decimal(item.position.value);
                switch (sortField) {
                    case "currency":
                        return item.position.valueCurrencyIso.text[locale];
                    case "quantity":
                        return quantity.toNumber();
                    case "designation":
                        return item.position.valor.valorShortName
                            ? item.position.valor.valorShortName[locale]
                            : "";
                    case "assetClass":
                        return item.position.valor.riskValorType && item.position.valor.riskValorType.assetClass
                            ? item.position.valor.riskValorType.assetClass.text[locale]
                            : "";
                    case "expiration":
                        return item.position.valor.expiryDate
                            ? item.position.valor.expiryDate
                            : "";
                    case "rate":
                        return value.div(quantity).toNumber();
                    case "marketValue":
                        return value.toNumber();
                    default:
                        return assertNever(`Unknown sort field "${sortField}"`);
                }
            })
        );

        return sortType === "desc"
            ? sorted.reverse()
            : sorted;
    }
}
