import { History } from "history";
import { RouterState } from "connected-react-router";

RouterDefaultState.$inject = [
    "$history",
];
export function RouterDefaultState(
    $history: History,
): RouterState {
    return {
        location: $history.location,
        action: "POP",
    };
}
