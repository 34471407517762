import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Omit } from "mapped-types-utils";
import { EvojaTextarea, EvojaTextareaProps } from "@evoja-web/uikit";
import { isFieldInvalid } from "src/Utils/isFieldInvalid";

function renderTextarea(props: WrappedFieldProps & EvojaTextareaProps): JSX.Element {
    const { input, meta, disabled = false, ...inputProps } = props;

    return (
        <EvojaTextarea {...inputProps}
                       invalid={isFieldInvalid(meta, { disabled })}
                       disabled={disabled}
                       name={input.name}
                       value={input.value}
                       onBlur={input.onBlur}
                       onFocus={input.onFocus}
                       onChange={input.onChange}/>
    );
}

type Props = Omit<BaseFieldProps<EvojaTextareaProps> & EvojaTextareaProps, "component" | "value"  | "ref">;
export function TextareaField(props: Props): JSX.Element {
    return <Field component={renderTextarea} {...props}/>;
}
