import { assertNever } from "src/Utils/assertionHelpers";
import { Locale } from "src/State/Parameters/Locale";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { IFormatterService } from "src/Services/FormatterService";

export interface IOutputAvailabilityPositionSubtitleMapper {
    mapAvailabilityPositionSubtitle(
        balanceObject: BalanceObjectAccountAsset,
    ): string;
}

export class OutputAvailabilityPositionSubtitleMapper implements IOutputAvailabilityPositionSubtitleMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjLocale",
        "evjFormatterService",
    ];
    public constructor(
        private locale: Locale,
        private formatter: IFormatterService,
    ) {
    }

    public mapAvailabilityPositionSubtitle(
        balanceObject: BalanceObjectAccountAsset,
    ): string {
        const outputClassType = balanceObject.outputClassType;
        switch (outputClassType) {
            case BalanceOutputClassType.ACCOUNT_NORMAL:
            case BalanceOutputClassType.ACCOUNT_DEPOT:
            case BalanceOutputClassType.ACCOUNT_EXCHANGE: {
                const currency = balanceObject.account.accountCurrency.currency.textShort
                    ? balanceObject.account.accountCurrency.currency.textShort
                    : balanceObject.account.accountCurrency.currency.text;

                return [
                    currency[this.locale],
                    balanceObject.account.rubric,
                    balanceObject.account.numberFormatted,
                ].filter(Boolean).join(" / ");
            }

            case BalanceOutputClassType.ACCOUNT_METAL: {
                return balanceObject.account.accountCurrency.currency.textShort
                    ? balanceObject.account.accountCurrency.currency.textShort[this.locale]
                    : balanceObject.account.accountCurrency.currency.text[this.locale];
            }

            case BalanceOutputClassType.ACCOUNT_DEPOSIT: {
                return balanceObject.account.balanceAt
                    ? this.formatter.formatDate(balanceObject.account.balanceAt, "DD.MM.YYYY")
                    : "";
            }

            default:
                return assertNever(`Unexpected outputDisplayClass "${outputClassType}"`, { balanceObject });
        }
    }
}
