import { createContext } from "react";
import { assertNever } from "src/Utils/assertionHelpers";

export type EnvironmentContextData = {
    readonly dateNow: Date;
    readonly parseGravitonDate: (date: Graviton.Common.DateTime) => Date;
};

// tslint:disable-next-line:variable-name
export const EnvironmentContext = createContext<EnvironmentContextData>({
    dateNow: new Date(NaN),
    parseGravitonDate: () => assertNever("Environment context should be initialized"),
});
