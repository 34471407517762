import { BalanceDisplayClassType } from "src/State/Balance/BalanceType";

export interface DisplayClassLink {
    readonly number: string;
    readonly type: BalanceDisplayClassType;
}
export const DISPLAY_CLASS_LINKS: ReadonlyArray<DisplayClassLink> = [
    {
        number: "1",
        type: BalanceDisplayClassType.ACCOUNT_NORMAL,
    },
    {
        number: "2",
        type: BalanceDisplayClassType.ACCOUNT_METAL,
    },
    {
        number: "3",
        type: BalanceDisplayClassType.ACCOUNT_DEPOSIT,
    },
    {
        number: "4",
        type: BalanceDisplayClassType.ACCOUNT_DEPOT,
    },
    {
        number: "5",
        type: BalanceDisplayClassType.ACCOUNT_EXCHANGE,
    },
    {
        number: "6",
        type: BalanceDisplayClassType.FINANCING,
    },
    {
        number: "7",
        type: BalanceDisplayClassType.CUSTOMER_BALANCE,
    },
    {
        number: "8",
        type: BalanceDisplayClassType.NEW_CONTRACT,
    },
    {
        number: "9",
        type: BalanceDisplayClassType.NEW_ENGAGEMENT,
    },
    {
        number: "10",
        type: BalanceDisplayClassType.FINANCING_DEBT,
    },
];
