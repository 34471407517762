import { WebkitAwareWindow } from "webkit-window-extension";
import { ILogger } from "src/Modules/Logger/Logger";
import { LogLevel } from "src/Modules/Logger/LogLevel";
import { NativeError } from "src/Errors/NativeError";

export class IosLogger implements ILogger {
    public static $inject: string[] = [
        "$window",
        "$loggerConsole",
        "evjIosLoggerChannel",
    ];
    public constructor(
        private $window: WebkitAwareWindow,
        private $loggerConsole: ILogger,
        private channel: string,
    ) {
    }

    public debug(message: string, context?: any): void {
        return this.log(LogLevel.DEBUG, message, context);
    }
    public info(message: string, context?: any): void {
        return this.log(LogLevel.INFO, message, context);
    }
    public notice(message: string, context?: any): void {
        return this.log(LogLevel.NOTICE, message, context);
    }
    public warning(message: string, context?: any): void {
        return this.log(LogLevel.WARNING, message, context);
    }
    public error(message: string, context?: any): void {
        return this.log(LogLevel.ERROR, message, context);
    }

    public log(level: LogLevel, message: string, context?: any): void {
        this.$loggerConsole.log(level, message, context);
        if (
            !this.$window.webkit ||
            !this.$window.webkit.messageHandlers ||
            !this.$window.webkit.messageHandlers[this.channel]
        ) {
            // tslint:disable-next-line:no-console
            console.warn(
                `Webkit message handler "${this.channel}" is not open`,
                {channel: this.channel, window: this.$window},
            );
            return;
        }

        try {
            const data = JSON.parse(JSON.stringify(
                context === undefined
                    ? [message]
                    : [message, context]
            ));

            this.$window.webkit
                .messageHandlers[this.channel]
                .postMessage(data);
        } catch (error) {
            // tslint:disable-next-line:no-console
            console.error(
                `Could not send logs to iOS`,
                {message, context, error, channel: this.channel},
                NativeError.wrapError(error),
            );
        }
    }
}
