import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";

type Props = {
    readonly balanceObject: BalanceObjectFinancing;
};
export function DetailGroupDataFinancings({ balanceObject }: Props): JSX.Element | null {
    if (!balanceObject.tranches.length) {
        return null;
    }

    return (
        <DetailGroupTable>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_title_financings"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            {balanceObject.tranches.map(({ financingTranche, loanProduct } ) => (
                <DetailGroupTableRow key={financingTranche.id}>
                    <DetailGroupTableCellValue>
                        <TranslatedMessage value={loanProduct.text}/>
                    </DetailGroupTableCellValue>
                    <DetailGroupTableCellAmount>
                        <FormattedNumber value={new Decimal(financingTranche.amount).toNumber()}/>
                    </DetailGroupTableCellAmount>
                </DetailGroupTableRow>
            ))}
        </DetailGroupTable>
    );
}
