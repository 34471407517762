import { NativeError } from "src/Errors/NativeError";
import { RuntimeError } from "src/Errors/RuntimeError";
import { ConfigurationData } from "src/State/ConfigurationState";
import { ILogger } from "src/Modules/Logger/Logger";
import { ICoreConfigurationService } from "src/Services/ConfigurationData/CoreConfigurationService";
import { IFileConfigurationService } from "src/Services/ConfigurationData/FileConfigurationService";
import { IInstanceConfigurationService } from "src/Services/ConfigurationData/InstanceConfigurationService";

export type IConfigurationService = () => Promise<ConfigurationData>;

ConfigurationServiceFactory.$inject = [
    "$logger",
    "evjCoreConfigurationService",
    "evjFileConfigurationService",
    "evjInstanceConfigurationService",
];
export function ConfigurationServiceFactory(
    $logger: ILogger,
    coreConfigurationService: ICoreConfigurationService,
    fileConfigurationService: IFileConfigurationService,
    instanceConfigurationService: IInstanceConfigurationService,
): IConfigurationService {
    return async () => {
        try {
            $logger.info(`Fetching core configuration...`);
            const coreConfiguration = await coreConfigurationService.fetchCoreConfiguration();
            $logger.info(`Fetching core configuration... done`, { coreConfiguration });

            $logger.info(`Fetching file configuration...`);
            const fileConfiguration = await fileConfigurationService.fetchFileConfiguration();
            $logger.info(`Fetching file configuration... done`, { fileConfiguration });

            $logger.info(`Fetching instance configuration...`);
            const instanceConfiguration = await instanceConfigurationService.fetchConfiguration();
            $logger.info(`Fetching instance configuration... done`, { instanceConfiguration });

            return {
                coreConfiguration,
                fileConfiguration,
                instanceConfiguration,
            };
        } catch (error) {
            const wrappedError = NativeError.wrapError(error);
            $logger.error("Error while loading configuration", wrappedError);
            throw new RuntimeError("Could not fetch configuration data", {}, wrappedError);
        }
    };
}
