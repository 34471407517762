import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";

export interface IBalanceLiquidityMapper {
    mapBalanceLiquidity(
        liquidityPlanning: BalanceLiquidityPlanning,
    ): Graviton.Consultation.Balance.Consultation.LiquidityPlanning;
}

export class BalanceLiquidityMapper implements IBalanceLiquidityMapper {
    public mapBalanceLiquidity(
        liquidityPlanning: BalanceLiquidityPlanning,
    ): Graviton.Consultation.Balance.Consultation.LiquidityPlanning {
        return {
            total: liquidityPlanning.total.toString(),
            planned: liquidityPlanning.planned.toString(),
            wishReserve: liquidityPlanning.wishReserve.toString(),
            provision: liquidityPlanning.provision.toString(),
            investment: liquidityPlanning.investment.toString(),
        };
    }
}
