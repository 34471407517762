import { ActionAny, ActionRequest, createActions } from "src/Utils/createActions";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";

export type CustomerBalanceCreateData = {
    readonly formData: CustomerBalanceForm;
};
export type CustomerBalanceCreateRequest = ActionRequest<CustomerBalanceCreateData>;
export type CustomerBalanceCreateActions = ActionAny<CustomerBalanceCreateData, BalanceObjectCustomerBalance>;

export const CUSTOMER_BALANCE_CREATE_ACTIONS
    = createActions<CustomerBalanceCreateData, BalanceObjectCustomerBalance>("CUSTOMER_BALANCE_CREATE");


export type CustomerBalanceUpdateData = {
    readonly formData: CustomerBalanceForm;
    readonly object: BalanceObjectCustomerBalance;
};
export type CustomerBalanceUpdateRequest = ActionRequest<CustomerBalanceUpdateData>;
export type CustomerBalanceUpdateActions = ActionAny<CustomerBalanceUpdateData, BalanceObjectCustomerBalance>;

export const CUSTOMER_BALANCE_UPDATE_ACTIONS
    = createActions<CustomerBalanceUpdateData, BalanceObjectCustomerBalance>("CUSTOMER_BALANCE_UPDATE");


export type CustomerBalanceDeleteData = {
    readonly object: BalanceObjectCustomerBalance;
};
export type CustomerBalanceDeleteRequest = ActionRequest<CustomerBalanceDeleteData>;
export type CustomerBalanceDeleteActions = ActionAny<CustomerBalanceDeleteData, BalanceObjectCustomerBalance>;

export const CUSTOMER_BALANCE_DELETE_ACTIONS
    = createActions<CustomerBalanceDeleteData, BalanceObjectCustomerBalance>("CUSTOMER_BALANCE_DELETE");
