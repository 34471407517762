import { createSelector } from "reselect";
import { BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";
import { isBalanceObjectWithPortfolio } from "src/Services/Utils/balanceObjectGuard";
import { getPortfolios } from "src/Selectors/Balance/getPortfolios";

export const getLiabilityPortfolios = createSelector([
    getPortfolios,
    getBalanceObjects,
], (
    portfolios,
    balanceObjects,
): BalancePortfolio[] => portfolios.filter((portfolio) => balanceObjects
    .filter((it) => it.category.type === BalanceCategoryType.LIABILITIES)
    .filter((it) => isBalanceObjectWithPortfolio(it) && it.portfolio?.id === portfolio.id)
    .length > 0
));
