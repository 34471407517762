import { v4 } from "uuid";
import { SagaIterator } from "redux-saga";
import { call, select } from "redux-saga/effects";
import { Decimal } from "decimal.js-light";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { getBalanceCategories } from "src/Selectors/Balance/getBalanceCategories";
import { assertDefined } from "src/Utils/assertionHelpers";

export function* customerBalanceCreateRealEstateSaga(
    realEstateLiability: BalanceObjectCustomerBalance,
): SagaIterator<BalanceObjectCustomerBalance> {
    const balanceCategories: BalanceCategory[] = yield select(getBalanceCategories);
    const category = assertDefined(
        balanceCategories.find((it) => it.isRealEstate && it.type === BalanceCategoryType.ASSETS),
        `Could not find asset category for "${realEstateLiability.category.balanceCategoryId}"`,
        { balanceCategories, customerBalance: realEstateLiability },
    );
    const amount = assertDefined(
        realEstateLiability.customerBalance.currentMarketPrice,
        `"customerBalance.currentMarketPrice" is not defined`,
        { realEstateLiability },
    );
    return {
        ...realEstateLiability,
        id: yield call(v4),
        mainId: realEstateLiability.id,
        category: category,
        amount: new Decimal(amount),
    };
}
