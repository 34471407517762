import { BalanceData } from "src/State/Balance/BalanceData";
import {
    LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS,
    LiquidityPlanningChangeActions,
} from "src/Actions/LiquidityPlanningActions";

export function liquidityPlanningReducer(
    state: BalanceData,
    action: LiquidityPlanningChangeActions,
): BalanceData {
    switch (action.type) {
        case LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS.SUCCESS:
        case LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS.SUCCESS:
        case LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS.SUCCESS:
        case LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS.SUCCESS:
        case LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS.SUCCESS:
            return {
                ...state,
                liquidityPlanning: action.data,
            };

        default:
            return state;
    }
}
