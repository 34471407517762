import { Provider } from "@xiag/container";
import { BalanceCategoryMappingMapper } from "src/Services/BalanceData/BalanceCategoryMappingMapper";
import { BalanceCategoryMapper } from "src/Services/BalanceData/BalanceCategoryMapper";
import { BalanceObjectMapper } from "src/Services/BalanceData/BalanceObjectMapper";
import { BalanceObjectAccountMapper } from "src/Services/BalanceData/BalanceObjectAccountMapper";
import { BalanceObjectCustomerBalanceMapper } from "src/Services/BalanceData/BalanceObjectCustomerBalanceMapper";
import { BalanceObjectFinancingMapper } from "src/Services/BalanceData/BalanceObjectFinancingMapper";
import { BalanceDataMapper } from "src/Services/BalanceData/BalanceDataMapper";
import { BalanceAvailabilityCategoryMapper } from "src/Services/BalanceData/BalanceAvailabilityCategoryMapper";
import { BalanceAccountAvailableAmountMapper } from "src/Services/BalanceData/BalanceAccountAvailableAmountMapper";
import { BalanceLiquidityPlanningMapper } from "src/Services/BalanceData/BalanceLiquidityPlanningMapper";
import { BalanceAccountPositionMapper } from "src/Services/BalanceData/BalanceAccountPositionMapper";
import { BalanceAccountPositionDetailsMapper } from "src/Services/BalanceData/BalanceAccountPositionDetailsMapper";
import { BalanceCreationClassAssetMapper } from "src/Services/BalanceData/BalanceCreationClassAssetMapper";
import { BalanceCreationClassLiabilityMapper } from "src/Services/BalanceData/BalanceCreationClassLiabilityMapper";
import { BalanceObjectAdditionMapper } from "src/Services/BalanceData/BalanceObjectAdditionMapper";
import { InvestmentToBalanceAccountMapper } from "src/Services/BalanceData/InvestmentToBalanceAccountMapper";
import { BalanceInvestmentSyncService } from "src/Services/BalanceData/BalanceInvestmentSyncService";
import { BalanceObjectCreateService } from "src/Services/BalanceData/BalanceObjectCreateService";
import { BalanceAccountWishedAmountMapper } from "src/Services/BalanceData/BalanceAccountWishedAmountMapper";

export const BALANCE_DATA_PROVIDER = new Provider([])
    .service(
        "evjBalanceDataMapper",
        BalanceDataMapper,
        BalanceDataMapper.$inject || [],
    )
    .service(
        "evjBalanceCategoryMappingMapper",
        BalanceCategoryMappingMapper,
        BalanceCategoryMappingMapper.$inject || [],
    )
    .service(
        "evjBalanceCategoryMapper",
        BalanceCategoryMapper,
        BalanceCategoryMapper.$inject || [],
    )
    .service(
        "evjBalanceObjectAccountMapper",
        BalanceObjectAccountMapper,
        BalanceObjectAccountMapper.$inject || [],
    )
    .service(
        "evjBalanceObjectCustomerBalanceMapper",
        BalanceObjectCustomerBalanceMapper,
        BalanceObjectCustomerBalanceMapper.$inject || [],
    )
    .service(
        "evjBalanceObjectFinancingMapper",
        BalanceObjectFinancingMapper,
        BalanceObjectFinancingMapper.$inject || [],
    )
    .service(
        "evjBalanceObjectMapper",
        BalanceObjectMapper,
        BalanceObjectMapper.$inject || [],
    )
    .service(
        "evjBalanceObjectAdditionMapper",
        BalanceObjectAdditionMapper,
        BalanceObjectAdditionMapper.$inject || [],
    )
    .service(
        "evjBalanceLiquidityPlanningMapper",
        BalanceLiquidityPlanningMapper,
        BalanceLiquidityPlanningMapper.$inject || [],
    )
    .service(
        "evjBalanceAvailabilityCategoryMapper",
        BalanceAvailabilityCategoryMapper,
        BalanceAvailabilityCategoryMapper.$inject || [],
    )
    .service(
        "evjBalanceAccountAvailableAmountMapper",
        BalanceAccountAvailableAmountMapper,
        BalanceAccountAvailableAmountMapper.$inject || [],
    )
    .service(
        "evjBalanceAccountWishedAmountMapper",
        BalanceAccountWishedAmountMapper,
        BalanceAccountWishedAmountMapper.$inject || [],
    )
    .service(
        "evjBalanceAccountPositionMapper",
        BalanceAccountPositionMapper,
        BalanceAccountPositionMapper.$inject || [],
    )
    .service(
        "evjBalanceAccountPositionDetailsMapper",
        BalanceAccountPositionDetailsMapper,
        BalanceAccountPositionDetailsMapper.$inject || [],
    )
    .service(
        "evjBalanceCreationClassAssetMapper",
        BalanceCreationClassAssetMapper,
        BalanceCreationClassAssetMapper.$inject || [],
    )
    .service(
        "evjBalanceCreationClassLiabilityMapper",
        BalanceCreationClassLiabilityMapper,
        BalanceCreationClassLiabilityMapper.$inject || [],
    )
    .service(
        "evjInvestmentToBalanceAccountMapper",
        InvestmentToBalanceAccountMapper,
        InvestmentToBalanceAccountMapper.$inject || [],
    )
    .service(
        "evjBalanceInvestmentSyncService",
        BalanceInvestmentSyncService,
        BalanceInvestmentSyncService.$inject || [],
    )
    .service(
        "evjBalanceObjectCreateService",
        BalanceObjectCreateService,
        BalanceObjectCreateService.$inject || [],
    )
;
