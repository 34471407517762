import { SagaIterator } from "redux-saga";
import { all, fork, takeEvery, takeLatest } from "redux-saga/effects";
import { hostCommandSaga } from "src/Sagas/HostCommunication/HostCommandSaga";
import { hostRequestSaga } from "src/Sagas/HostCommunication/HostRequestSaga";
import { attachHostSaga } from "src/Sagas/HostSagas/attachHostSaga";
import { hostSaveRequestSaga } from "src/Sagas/HostSagas/HostSaveRequestSaga";
import { hostOutputGenerateSaga } from "src/Sagas/HostSagas/HostOutputGenerateSaga";
import { HOST_COMMAND, HOST_REQUEST, HOST_REQUEST_ACTIONS } from "src/Actions/HostCommandActions";


export function* hostCommunicationSaga(): SagaIterator {
    yield takeEvery(HOST_COMMAND, hostCommandSaga);
    yield fork(hostRequestSaga);

    yield all([
        takeLatest(HOST_REQUEST, attachHostSaga(
            HOST_REQUEST_ACTIONS.REQUEST_SAVE,
            hostSaveRequestSaga,
        )),
        takeLatest(HOST_REQUEST, attachHostSaga(
            HOST_REQUEST_ACTIONS.REQUEST_OUTPUT,
            hostOutputGenerateSaga,
        )),
    ]);
}
