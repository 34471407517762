import * as React from "react";
import { NotFound } from "src/Components/NotFound";

export function NotFoundPage(): JSX.Element {
    return (
        <div className="m03-not-found-page">
            <NotFound/>
        </div>
    );
}
