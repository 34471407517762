import { BaseError } from "src/Errors/BaseError";

export class RuntimeError extends BaseError {
    public constructor(
        private errorMessage: string,
        private contextData?: object,
        previous?: BaseError,
    ) {
        super(previous);
    }

    public get name(): string {
        return "RuntimeError";
    }
    public get message(): string {
        return this.errorMessage;
    }
    public get context(): object | undefined {
        return this.contextData;
    }
    public toJSON(): object {
        return {
            ...super.toJSON(),
            contextData: this.contextData,
        };
    }
}
