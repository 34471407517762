import { IHostCommandDispatcher } from "src/Services/HostCommunication/HostCommandDispatcher";

IosCommandDispatcherDecorator.$inject = [
    "$delegate",
    "evjIosCommandDispatcher",
    "evjIosCommunicationEnabled",
];
export function IosCommandDispatcherDecorator(
    defaultCommandDispatcher: IHostCommandDispatcher,
    iosCommandDispatcher: IHostCommandDispatcher,
    iosCommunicationEnabled: boolean,
): IHostCommandDispatcher {
    return iosCommunicationEnabled
        ? iosCommandDispatcher
        : defaultCommandDispatcher;
}
