import { createSelector } from "reselect";
import { sortByAll } from "src/Utils/sortByAll";
import { getBalanceOriginal } from "src/Selectors/Balance/getBalanceOriginal";

export const getBankList = createSelector([
    getBalanceOriginal,
], (balance) => [...balance.parameterData.bankList].sort(
    sortByAll((entityCode) => entityCode.order === undefined
        ? Number.MAX_SAFE_INTEGER
        : entityCode.order
    )
));
