import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";

export function getHasBalanceObjectOverdraw(
    object: BalanceObjectAccountAsset,
): boolean {
    return object.wishedAmount.wishedAmount
        .add(getBalanceObjectNkkAmount(object))
        .greaterThan(object.amount);
}
