import { createSelector } from "reselect";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { getBalanceCreationClassAssets } from "src/Selectors/Balance/getBalanceCreationClassAssets";

export const getAssetFormInitialData = createSelector([
    getBalanceCreationClassAssets,
], ([creationClass]): CustomerBalanceForm => ({
    creationClass: creationClass,
    bank: null,

    title: "",
    rubric: "",

    amount: null,
    endDate: null,
    currentMarketPrice: null,
}));
