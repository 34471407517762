import { AnyAction } from "redux";
import { BaseError } from "src/Errors/BaseError";

enum ActionType {
    REQUEST = "REQUEST",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}
type ActionHelperBase = {
    readonly REQUEST: ActionType.REQUEST;
    readonly PENDING: ActionType.PENDING;
    readonly SUCCESS: ActionType.SUCCESS;
    readonly FAILURE: ActionType.FAILURE;
};
type ActionHelperDefault<TRequest, TSuccess> = ActionHelperBase & {
    request(data: TRequest): ActionRequest<TRequest, undefined>;
    pending(): ActionPending<undefined>;
    success(data: TSuccess): ActionSuccess<TSuccess, undefined>;
    failure(error: BaseError): ActionFailure<undefined>;

    isRequest(action: AnyAction): action is ActionRequest<TRequest, undefined>;
    isPending(action: AnyAction): action is ActionPending<undefined>;
    isSuccess(action: AnyAction): action is ActionSuccess<TSuccess, undefined>;
    isFailure(action: AnyAction): action is ActionFailure<undefined>;
};
type ActionHelperWithMeta<TRequest, TSuccess, TMeta> = ActionHelperBase & {
    request(data: TRequest, meta: TMeta): ActionRequest<TRequest, TMeta>;
    pending(meta: TMeta): ActionPending<TMeta>;
    success(data: TSuccess, meta: TMeta): ActionSuccess<TSuccess, TMeta>;
    failure(error: BaseError, meta: TMeta): ActionFailure<TMeta>;

    isRequest(action: AnyAction): action is ActionRequest<TRequest, TMeta>;
    isPending(action: AnyAction): action is ActionPending<TMeta>;
    isSuccess(action: AnyAction): action is ActionSuccess<TSuccess, TMeta>;
    isFailure(action: AnyAction): action is ActionFailure<TMeta>;
};
type ActionHelper<TRequest, TSuccess, TMeta = undefined> = TMeta extends undefined
    ? ActionHelperDefault<TRequest, TSuccess>
    : ActionHelperWithMeta<TRequest, TSuccess, TMeta>;

type ActionBase<TType extends string, TData, TMeta = undefined> = {
    readonly type: TType;
    readonly data: TData;
    readonly meta: TMeta;
};

export type ActionAny<TRequest, TSuccess, TMeta = undefined> =
    | ActionRequest<TRequest, TMeta>
    | ActionPending<TMeta>
    | ActionSuccess<TSuccess, TMeta>
    | ActionFailure<TMeta>;

export type ActionRequest<TRequest, TMeta = undefined> = ActionBase<ActionType.REQUEST, TRequest, TMeta>;
export type ActionPending<TMeta = undefined> = ActionBase<ActionType.PENDING, undefined, TMeta>;
export type ActionSuccess<TSuccess, TMeta = undefined> = ActionBase<ActionType.SUCCESS, TSuccess, TMeta>;
export type ActionFailure<TMeta = undefined> = ActionBase<ActionType.FAILURE, BaseError, TMeta>;

export function createActions<
    TRequest,
    TSuccess,
    TMeta = undefined
>(type: string): ActionHelper<TRequest, TSuccess, TMeta> {
    const REQUEST = (type + "_REQUEST") as ActionType.REQUEST;
    const PENDING = (type + "_PENDING") as ActionType.PENDING;
    const SUCCESS = (type + "_SUCCESS") as ActionType.SUCCESS;
    const FAILURE = (type + "_FAILURE") as ActionType.FAILURE;

    return {
        REQUEST: REQUEST,
        PENDING: PENDING,
        SUCCESS: SUCCESS,
        FAILURE: FAILURE,

        isRequest: (action: AnyAction) => action.type === REQUEST,
        isPending: (action: AnyAction) => action.type === PENDING,
        isSuccess: (action: AnyAction) => action.type === SUCCESS,
        isFailure: (action: AnyAction) => action.type === FAILURE,

        request: (data: TRequest, meta?: TMeta) => ({
            type: REQUEST,
            data: data,
            meta: meta,
        }),
        pending: (meta?: TMeta) => ({
            type: PENDING,
            data: undefined,
            meta: meta,
        }),
        success: (data: TSuccess, meta?: TMeta) => ({
            type: SUCCESS,
            data: data,
            meta: meta,
        }),
        failure: (data: BaseError, meta?: TMeta) => ({
            type: FAILURE,
            data: data,
            meta: meta,
        }),
    } as ActionHelper<TRequest, TSuccess, TMeta>;
}
