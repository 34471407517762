import * as React from "react";

type Props = {
    readonly children: React.ReactNode;
};

export function DefaultLayout({ children }: Props): JSX.Element {
    return (
        <div className="m03-default-layout">
            {children}
        </div>
    );
}
