import * as React from "react";
import { Decimal } from "decimal.js-light";
import { classList } from "src/Utils/classList";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";

type Props = {
    readonly mode: "total" | "value";
    readonly style: keyof BalanceLiquidityPlanning;
    readonly value: Decimal;
    readonly total: Decimal;
};
export class LiquidityPlanningBar extends React.Component<Props> {
    public render(): JSX.Element {
        const { style } = this.props;
        const className = classList("m03-liquidity-planning-bar", {
            "m03-liquidity-planning-bar--empty": this.isEmpty(),
            [`m03-liquidity-planning-bar--style-${style}`]: !this.isEmpty(),
        });

        return (
            <div className={className}>
                {this.renderValue()}
            </div>
        );
    }

    private renderValue(): JSX.Element | null {
        const { value, total } = this.props;
        if (value.isZero() || total.isZero()) {
            return null;
        }

        return (
            <div className="m03-liquidity-planning-bar__value"
                 style={{ height: value.div(total).mul(100).toFixed(3) + "%" }}>
                <FormattedNumber value={value.toNumber()}/>
            </div>
        );
    }

    private isEmpty(): boolean {
        const { mode, value, total } = this.props;

        return (
            (mode === "total" && total.isZero()) ||
            (mode === "value" && value.isZero())
        );
    }
}
