import * as React from "react";
import { bind } from "lodash-decorators/bind";
import {
    EvojaHtmlButton,
    EvojaIcon,
    EvojaLinkButton,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverTarget,
    EvojaTableCell,
    EvojaTableRow,
} from "@evoja-web/uikit";
import { hasDetailGroupData } from "src/Services/Utils/hasDetailGroupData";
import { hasDepotData } from "src/Services/Utils/hasDepotData";
import {
    isBalanceObjectAccount,
    isBalanceObjectCustomerBalance,
    isBalanceObjectFinancing,
    isBalanceObjectWithPortfolio,
} from "src/Services/Utils/balanceObjectGuard";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { ObjectTitle } from "src/Components/Common/ObjectTitle";
import { ObjectSubTitle } from "src/Components/Common/ObjectSubTitle";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { DetailGroup } from "src/Components/DetailGroup/DetailGroup";
import { ObjectPortfolioSubTitle } from "src/Components/Home/ObjectPortfolioSubTitle";

type Props = {
    readonly showPortfolio: boolean;
    readonly object: BalanceObject;
    readonly editObject: () => void;
};

export class HomeObject extends React.Component<Props> {
    private detailGroupButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

    public render(): JSX.Element {
        const { object, showPortfolio } = this.props;
        return (
            <EvojaTableRow className="m03-home-object">
                <EvojaTableCell className="m03-home-object__cell">
                    <div className="m03-home-object__title">
                        <ObjectTitle balanceObject={object}/>
                    </div>
                    {showPortfolio && isBalanceObjectWithPortfolio(object) && object.portfolio && (
                        <div className="m03-home-object__subtitle m03-home-object__subtitle--portfolio">
                            <ObjectPortfolioSubTitle portfolio={object.portfolio}/>
                        </div>
                    )}
                    <div className="m03-home-object__subtitle m03-home-object__subtitle--object">
                        <ObjectSubTitle balanceObject={object}/>
                    </div>
                </EvojaTableCell>
                <EvojaTableCell className="m03-home-object__action">
                    <div className="m03-home-object__action-wrapper">
                        {this.renderObjectButton()}
                        {this.renderDepotButton()}
                        {this.renderRealEstateButton()}
                    </div>
                </EvojaTableCell>
                <EvojaTableCell className="m03-home-object__amount">
                    <FormattedNumber value={object.amount.toNumber()}/>
                </EvojaTableCell>
            </EvojaTableRow>
        );
    }

    private renderObjectButton(): JSX.Element | null {
        const { object } = this.props;
        if (isBalanceObjectCustomerBalance(object)) {
            const { editObject } = this.props;
            return (
                <EvojaHtmlButton shape="circle"
                                 block={false}
                                 className="m03-home-object__edit"
                                 onClick={editObject}>
                    <EvojaIcon icon="pen"/>
                </EvojaHtmlButton>
            );
        }

        const hasDetailData = object.detailGroupTypes.some((detailGroup) => hasDetailGroupData(object, detailGroup));
        if (!hasDetailData) {
            return null;
        }

        return (
            <EvojaPopover id={`detail-groups--${object.id}`} placement={this.getPopoverPosition}>
                <EvojaPopoverTarget>
                    <EvojaHtmlButton ref={this.detailGroupButtonRef}
                                     shape="circle"
                                     className="m03-home-object__details">
                        <EvojaIcon icon="ellipsis"/>
                    </EvojaHtmlButton>
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <DetailGroup balanceObject={object}
                                 detailGroupTypes={object.detailGroupTypes}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    private renderDepotButton(): JSX.Element | null {
        const { object } = this.props;
        if (!isBalanceObjectAccount(object)) {
            return null;
        }

        if (!object.detailGroupTypes.some((detailGroup) => hasDepotData(object, detailGroup))) {
            return null;
        }

        return (
            <EvojaLinkButton to={{ search: `?depot-id=${encodeURIComponent(object.id)}` }}
                             shape="circle"
                             className="m03-home-object__depot-details">
                <EvojaIcon icon="search"/>
            </EvojaLinkButton>
        );
    }

    private renderRealEstateButton(): JSX.Element | null {
        const { object, editObject } = this.props;
        if (!isBalanceObjectFinancing(object)) {
            return null;
        }

        return (
            <EvojaHtmlButton shape="circle"
                             block={false}
                             className="m03-home-object__real-estate-details"
                             onClick={editObject}>
                <EvojaIcon icon="search"/>
            </EvojaHtmlButton>
        );
    }

    @bind()
    private getPopoverPosition(): "top" | "bottom" {
        const { current: thisElement } = this.detailGroupButtonRef;
        if (!thisElement) {
            return "top";
        }

        const thisPosition = thisElement.getBoundingClientRect();
        const topOffset = thisPosition.top;
        const bottomOffset = window.innerHeight - thisPosition.bottom;

        return topOffset > bottomOffset ? "top" : "bottom";
    }
}
