import { BalanceOutputJson } from "api/Types/BalanceOutputJson";
import { BalanceData } from "src/State/Balance/BalanceData";
import { IOutputProductsMapper } from "src/Services/Output/OutputProductsMapper";
import { IOutputAvailabilityMapper } from "src/Services/Output/OutputAvailabilityMapper";
import { IOutputLiquidityMapper } from "src/Services/Output/OutputLiquidityMapper";

export interface IOutputJsonGenerator {
    generate(balance: BalanceData): BalanceOutputJson;
}

export class OutputJsonGenerator implements IOutputJsonGenerator {
    public static $inject: string[] = [
        "evjOutputProductsMapper",
        "evjOutputAvailabilityMapper",
        "evjOutputLiquidityMapper",
    ];
    public constructor(
        private outputProductsMapper: IOutputProductsMapper,
        private outputAvailabilityMapper: IOutputAvailabilityMapper,
        private outputLiquidityMapper: IOutputLiquidityMapper,
    ) {
    }

    public generate(balance: BalanceData): BalanceOutputJson {
        return {
            products: this.outputProductsMapper.mapProducts(
                balance.objects,
                balance.categories,
            ),
            availability: this.outputAvailabilityMapper.mapAvailability(
                balance.objects,
                balance.categories,
            ),
            liquidity: this.outputLiquidityMapper.mapLiquidity(balance.liquidityPlanning),
        };
    }
}
