import { Decimal } from "decimal.js-light";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import {
    BalanceAvailabilityCategory,
    BalanceAvailabilityCategoryType,
} from "src/State/Balance/BalanceAvailabilityCategory";
import { BalanceObjectAccountAvailableAmount } from "src/State/Balance/BalanceObjectAccountAvailableAmount";
import { invalidAvailabilityCategory } from "src/Utils/invalidAvailabilityCategory";

export interface IBalanceAccountAvailableAmountMapper {
    mapBalanceAccountAvailableAmount(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceAvailabilityCategory,
    ): BalanceObjectAccountAvailableAmount;
}

export class BalanceAccountAvailableAmountMapper implements IBalanceAccountAvailableAmountMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjCoreConfiguration",
    ];
    public constructor(
        private coreConfiguration: CoreConfiguration,
    ) {
    }

    public mapBalanceAccountAvailableAmount(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceAvailabilityCategory,
    ): BalanceObjectAccountAvailableAmount {
        switch (category.type) {
            case BalanceAvailabilityCategoryType.DIRECT:
                return {
                    value: this.getAccountAvailableAmount(account),
                    included: true,
                };

            case BalanceAvailabilityCategoryType.SELECTIVE:
                return {
                    value: new Decimal(account.reportingCurrency.balance),
                    included: this.isSelectiveAvailableAmountIncluded(account, balance),
                };

            case BalanceAvailabilityCategoryType.DEFINED:
                return {
                    value: this.getDefinedAvailableAmount(account, balance),
                    included: true,
                };

            case BalanceAvailabilityCategoryType.NONE:
                return {
                    value: null,
                    included: false,
                };

            default:
                return invalidAvailabilityCategory(category.type, { category, account });
        }
    }

    private getAccountAvailableAmount(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
    ): Decimal | null {
        if (account.withdrawalAmount === undefined) {
            return null;
        } else if (account.withdrawalAmount < 0.01) {
            return null;
        }

        return account.withdrawalAvailableAmount !== undefined
            ? new Decimal(account.withdrawalAvailableAmount)
            : null;
    }

    private getDefinedAvailableAmount(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Decimal | null {
        const storedValue = balance.consultation.availability
            .definedAvailableAmounts
            .find((it) => it.accountId === account.id);
        return storedValue && storedValue.availableAmount
            ? new Decimal(storedValue.availableAmount)
            : null;
    }

    private isSelectiveAvailableAmountIncluded(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): boolean {
        const storedValue = balance.consultation.availability
            .selectiveAvailableAmounts
            .find((it) => it.accountId === account.id);
        return storedValue
            ? storedValue.isSelected
            : this.coreConfiguration.selectiveAvailableAmountDefaultValue;
    }
}
