import { createSelector } from "reselect";
import { getBalanceCategoryMappings } from "src/Selectors/Balance/getBalanceCategoryMappings";
import { isDefined } from "src/Utils/isDefined";
import { getParameterData } from "src/Selectors/Balance/getParameterData";
import { sortByAll } from "src/Utils/sortByAll";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";
import { assertDefined } from "src/Utils/assertionHelpers";
import { getRefComparator } from "src/Selectors/getRefComparator";
import { getRefGenerator } from "src/Selectors/getRefGenerator";
import { CategoriesByEntityCode } from "src/State/Balance/CategoriesByEntityCode";

export const getCategoriesByAssetClass = createSelector([
    getParameterData,
    getBalanceCategoryMappings,
    getRefGenerator,
    getRefComparator,
], (
    {
        investmentAssetClasses = [],
        assetClasses = [],
        assetSegments,
    },
    categoryMappings,
    refGenerator,
    refComparator,
): CategoriesByEntityCode[] => {
    function findByRef<TEndpoint extends EndpointName>(
        endpoint: TEndpoint,
        entities: ReadonlyArray<EndpointEntity[TEndpoint] & { id: string }>,
        reference: Graviton.Common.ExtReference<EndpointEntity[TEndpoint]>,
    ): EndpointEntity[TEndpoint] {
        return assertDefined(
            entities.find((entity) => refComparator.compareRefs(
                reference,
                refGenerator(endpoint, entity.id),
            )),
            `Could not find entity "${endpoint}" with reference "${reference}"`,
            { endpoint, entities, reference },
        );
    }

    return investmentAssetClasses
        .filter((it) => it.coreType.length > 0 && it.assetSegment.length > 0)
        .sort(sortByAll((mapping) => mapping.displayPriority === undefined
            ? Number.MAX_SAFE_INTEGER
            : mapping.displayPriority,
        ))
        .map((mapping) => {
            const assetClass = mapping.coreType[0];
            const categories = mapping.assetSegment.map(({ $ref }) => {
                const assetSegment = findByRef(EndpointName.ENTITY_CODE, assetSegments, $ref);
                const categoryMapping = categoryMappings.find((it) => it.assetSegment?.id === assetSegment.id);
                return categoryMapping ? categoryMapping.category.id : undefined;
            }).filter(isDefined);

            return {
                code: findByRef(EndpointName.ENTITY_CODE, assetClasses, assetClass.$ref),
                categoryIds: categories,
            };
        })
        .filter((it) => it.categoryIds.length > 0);
});
