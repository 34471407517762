import { SagaIterator } from "redux-saga";
import { put, take } from "redux-saga/effects";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* hostSaveRequestSaga(): SagaIterator {
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
    const syncResult = yield take([SYNCHRONIZATION_ACTIONS.isSuccess, SYNCHRONIZATION_ACTIONS.isFailure]);

    if (SYNCHRONIZATION_ACTIONS.isSuccess(syncResult)) {
        yield put(HOST_COMMAND_ACTIONS.syncComplete());
    }
}
