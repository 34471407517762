import * as React from "react";
import { ErrorInfo } from "react";
import { NativeError } from "src/Errors/NativeError";
import { BaseError } from "src/Errors/BaseError";
import { ErrorMessage } from "src/Components/ErrorMessage";
import { ErrorWrapper } from "src/Components/ErrorWrapper";

interface Props {

}
interface State {
    readonly error: BaseError | undefined;
    readonly errorInfo: ErrorInfo | undefined;
}
export class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        error: undefined,
        errorInfo: undefined,
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState(() => ({
            error: error instanceof BaseError ? error : new NativeError(error),
            errorInfo: errorInfo,
        }));
    }
    public render(): React.ReactNode {
        const { error, errorInfo } = this.state;
        if (!error || !errorInfo) {
            return this.props.children;
        }

        return (
            <ErrorWrapper>
                <ErrorMessage error={error} errorInfo={errorInfo}/>
            </ErrorWrapper>
        );
    }
}
