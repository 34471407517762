import * as React from "react";
import { EvojaPopoverStateContext } from "@evoja-web/uikit";
import { ApplicationState } from "src/State/ApplicationState";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { uiPopoverClose, uiPopoverOpen } from "src/Actions/UiActions";

type OwnProps = {
    readonly children: React.ReactNode;
};
type StateProps = {
    readonly isPopoverOpen: (popoverId: string) => boolean;
    readonly clientHeight: number;
};
type DispatchProps = {
    readonly openPopover: (popoverId: string) => void;
    readonly closePopover: (popoverId: string) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

export class PopoverProviderWithState extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { children, openPopover, closePopover, isPopoverOpen } = this.props;
        const popoverContext: EvojaPopoverStateContext = {
            clientHeight: document.documentElement.clientHeight,
            isPopoverOpen,
            openPopover,
            closePopover,
        };

        return (
            <EvojaPopoverStateContext.Provider value={popoverContext}>
                {children}
            </EvojaPopoverStateContext.Provider>
        );
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isPopoverOpen: (popoverId: string) => state.ui.openPopovers.includes(popoverId),
        clientHeight: window.document.documentElement.clientHeight,
    };
}
const mapDispatchToProps: DispatchProps = {
    openPopover: uiPopoverOpen,
    closePopover: uiPopoverClose,
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(
    PopoverProviderWithState,
    mapStateToProps,
    mapDispatchToProps,
)
export class PopoverProvider extends React.Component<OwnProps> {}

