import { Reducer, AnyAction } from "redux";
import { History } from "history";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { chainReducers } from "src/Utils/chainReducers";
import { partialReducers } from "src/Utils/partialReducers";
import { ApplicationState } from "src/State/ApplicationState";
import { ConsultationData, ConsultationState } from "src/State/ConsultationState";
import { BalanceData } from "src/State/Balance/BalanceData";
import { uiReducer } from "src/Reducers/UiReducer";
import { configurationReducer } from "src/Reducers/ConfigurationReducer";
import { consultationReducer } from "src/Reducers/ConsultationReducer";
import { liquidityPlanningReducer } from "src/Reducers/LiquidityPlanningReducer";
import { availabilityChangeNkkPercentReducer } from "src/Reducers/AvailabilityChangeNkkPercentReducer";
import { availabilityChangeWishedAmountReducer } from "src/Reducers/AvailabilityChangeWishedAmountReducer";
import { availabilityChangeObjectAmountReducer } from "src/Reducers/AvailabilityChangeObjectAmountReducer";
import { availabilityToggleObjectAmountReducer } from "src/Reducers/AvailabilityToggleObjectAmountReducer";
import { checklistCreateReducer } from "src/Reducers/ChecklistCreateReducer";
import { customerBalanceCreateReducer } from "src/Reducers/CustomerBalanceCreateReducer";
import { customerBalanceDeleteReducer } from "src/Reducers/CustomerBalanceDeleteReducer";
import { customerBalanceUpdateReducer } from "src/Reducers/CustomerBalanceUpdateReducer";

ReducerFactory.$inject = [
    "$history",
];
export function ReducerFactory(
    $history: History,
): Reducer<ApplicationState, AnyAction> {
    return partialReducers<ApplicationState>({
        form: formReducer,
        router: connectRouter($history),
        ui: uiReducer,
        configuration: configurationReducer,
        consultation: chainReducers<ConsultationState>(
            consultationReducer,
            partialReducers<ConsultationState>({
                data: partialReducers<ConsultationData>({
                    balance: chainReducers<BalanceData>(
                        liquidityPlanningReducer,
                        availabilityChangeNkkPercentReducer,
                        availabilityChangeWishedAmountReducer,
                        availabilityChangeObjectAmountReducer,
                        availabilityToggleObjectAmountReducer,
                        customerBalanceCreateReducer,
                        customerBalanceDeleteReducer,
                        customerBalanceUpdateReducer,
                    ),
                    checklist: checklistCreateReducer,
                }),
            }),
        ),
    });
}
