import { Decimal } from "decimal.js-light";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import {
    BalanceOutputCategory,
    BalanceOutputGroupTotals,
    BalanceOutputProducts,
} from "api/Types/BalanceOutputJson";
import { Locale } from "src/State/Parameters/Locale";
import { balanceTotalSum } from "src/Services/Utils/balanceTotalSum";
import { balanceTotalSumInBank } from "src/Services/Utils/balanceTotalSumInBank";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { Translatable } from "src/State/Translatable";
import { ITranslationService } from "src/Services/TranslationService";
import { IFormatterService } from "src/Services/FormatterService";
import { balanceObjectSum } from "src/Services/Utils/balanceObjectSum";
import { IOutputCategoryPositionMapper } from "src/Services/Output/OutputCategoryPositionMapper";

export interface IOutputProductsMapper {
    mapProducts(
        balanceObjects: ReadonlyArray<BalanceObject>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceOutputProducts;
}

export class OutputProductsMapper implements IOutputProductsMapper {
    public static $inject: string[] = [
        "evjLocale",
        "evjBankName",
        "evjTranslationService",
        "evjFormatterService",
        "evjOutputCategoryPositionMapper",
    ];

    public constructor(
        private locale: Locale,
        private bankName: Translatable,
        private translator: ITranslationService,
        private formatter: IFormatterService,
        private categoryPositionMapper: IOutputCategoryPositionMapper,
    ) {
    }

    public mapProducts(
        balanceObjects: ReadonlyArray<BalanceObject>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceOutputProducts {
        const assetCategories = categories.filter((it) => it.type === BalanceCategoryType.ASSETS);
        const liabilityCategories = categories.filter((it) => it.type === BalanceCategoryType.LIABILITIES);
        return {
            assets: {
                totals: this.mapTotals(
                    balanceObjects,
                    assetCategories,
                    this.translator.translate("output_assets_total"),
                ),
                categories: assetCategories.map((category) => this.mapCategory(balanceObjects, category)),
            },
            liabilities: {
                totals: this.mapTotals(
                    balanceObjects,
                    liabilityCategories,
                    this.translator.translate("output_liabilities_total"),
                ),
                categories: liabilityCategories.map((category) => this.mapCategory(balanceObjects, category)),
            },
        };
    }

    private mapTotals(
        balanceObjects: ReadonlyArray<BalanceObject>,
        categories: ReadonlyArray<BalanceCategory>,
        title: string,
    ): BalanceOutputGroupTotals {
        const totalAmount = balanceTotalSum(categories, balanceObjects);
        const totalAmountInBank = balanceTotalSumInBank(categories, balanceObjects);

        return [
            {
                title: title,
                value: this.formatNumber(totalAmount),
            },
            {
                title: this.bankName[this.locale],
                value: this.formatNumber(totalAmountInBank),
            },
        ];
    }

    private mapCategory(
        balanceObjects: ReadonlyArray<BalanceObject>,
        category: BalanceCategory,
    ): BalanceOutputCategory {
        const categoryObjects = balanceObjects.filter((it) => it.category.id === category.id);
        const totalAmount = balanceObjectSum(categoryObjects);

        return {
            title: category.title[this.locale],
            total: this.formatNumber(totalAmount),
            positions: categoryObjects.map((object) => this.categoryPositionMapper.mapPosition(object)),
        };
    }

    private formatNumber(value: Decimal): string {
        return this.formatter.formatNumber(value.toNumber(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
}
