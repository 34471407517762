import * as React from "react";
import { FormattedMessage } from "react-intl";
import { bind } from "lodash-decorators/bind";
import { EvojaButtonGrid, EvojaCheckbox, EvojaHtmlButton } from "@evoja-web/uikit";
import { AssetGraphicsProps } from "src/Pages/AssetGraphicsPage";
import { AssetGraphicsSlider } from "src/Components/AssetGraphics/AssetGraphicsSlider";
import { AssetGraphicsGroup } from "src/State/AssetGraphics/AssetGraphicsGroup";
import { LogicError } from "src/Errors/LogicError";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";

type State = {
    readonly inPercents: boolean;
    readonly isInvestable: boolean;
    readonly selectedGroup: number;
};

export class AssetGraphics extends React.Component<AssetGraphicsProps, State> {
    public readonly state: State = {
        inPercents: false,
        isInvestable: false,
        selectedGroup: 0,
    };

    public render(): JSX.Element {
        const { groups } = this.props;
        const { isInvestable, inPercents, selectedGroup } = this.state;

        return (
            <div className="m03-asset-graphics">
                <div className="m03-asset-graphics__header">
                    <FormattedMessage id="assetGraphics_title"/>
                </div>
                <div className="m03-asset-graphics__content">
                    <div className="m03-asset-graphics__chart">
                        <AssetGraphicsSlider groups={groups}
                                             isInvestable={isInvestable}
                                             inPercents={inPercents}
                                             activeSlide={selectedGroup}
                                             onChange={this.setSelectedGroup}/>
                    </div>
                    <div className="m03-asset-graphics__legend">
                        {this.renderCurrencyOrPercentToggle()}

                        <label className="m03-asset-graphics__investable">
                            <EvojaCheckbox checked={isInvestable}
                                           onChange={this.toggleInvestable}
                                           className="m03-asset-graphics__checkbox"/>
                            <FormattedMessage id="assetGraphics_investable"/>
                        </label>

                        <div className="m03-asset-graphics__groups">
                            {this.renderLegendItems()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderLegendItems(): React.ReactNode {
        const group = this.getSelectedGroup();
        if (group.items.length === 0) {
            return (
                <FormattedMessage id="assetGraphics_noData"/>
            );
        }

        return group.items.map((item) => (
            <div className="m03-asset-graphics__group" key={item.id}>
                <div className="m03-asset-graphics__group-icon" style={{ background: item.color }}/>
                <div className="m03-asset-graphics__group-label">
                    <TranslatedMessage value={item.title}/>
                </div>
            </div>
        ));
    }

    private renderCurrencyOrPercentToggle(): JSX.Element {
        const { inPercents } = this.state;
        return (
            <EvojaButtonGrid className="m03-asset-graphics__toggle">
                <EvojaHtmlButton type="button"
                                 shape="square"
                                 block={false}
                                 active={!inPercents}
                                 onClick={this.toggleCurrencyOrPercent}
                                 className="m03-asset-graphics__button">
                    <FormattedMessage id="assetGraphics_currency"/>
                </EvojaHtmlButton>
                <EvojaHtmlButton type="button"
                                 shape="square"
                                 block={false}
                                 active={inPercents}
                                 onClick={this.toggleCurrencyOrPercent}
                                 className="m03-asset-graphics__button">
                    %
                </EvojaHtmlButton>
            </EvojaButtonGrid>
        );
    }

    private getSelectedGroup(): AssetGraphicsGroup {
        const { groups } = this.props;
        if (!groups.length) {
            throw new LogicError("No asset allocation data to display");
        }

        const { selectedGroup } = this.state;
        return groups[selectedGroup] ? groups[selectedGroup] : groups[0];
    }

    @bind()
    private toggleCurrencyOrPercent(): void {
        this.setState((prev) => ({ inPercents: !prev.inPercents }));
    }

    @bind()
    private toggleInvestable(): void {
        this.setState((prev) => ({ isInvestable: !prev.isInvestable }));
    }

    @bind()
    private setSelectedGroup(index: number): void {
        this.setState(() => ({ selectedGroup: index }));
    }
}
