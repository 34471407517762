import * as React from "react";
import { Location } from "history";
import { EvojaModal } from "@evoja-web/uikit";
import { bind } from "lodash-decorators/bind";
import { AssetGraphicsPage } from "src/Pages/AssetGraphicsPage";
import { createStructuredSelector } from "reselect";
import { ApplicationState } from "src/State/ApplicationState";
import { getLocation, replace } from "connected-react-router";
import { connectDecorator } from "src/Decorators/connectDecorator";

type OwnProps = {};
type StateProps = {
    readonly location: Readonly<Location>;
};
type DispatchProps = {
    readonly replaceLocation: (location: Readonly<Location>) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class AssetGraphicsModalConnected extends React.Component<AllProps> {
    public render(): JSX.Element {
        return (
            <EvojaModal isOpen={this.isOpen()}
                        onExit={this.handleClose}
                        className="m03-asset-graphics-modal">
                <AssetGraphicsPage/>
            </EvojaModal>
        );
    }

    private isOpen(): boolean {
        const { location } = this.props;
        return (
            location.pathname === "/" &&
            new URLSearchParams(location.search).get("view") === "asset-graphics"
        );
    }

    @bind()
    private handleClose(): void {
        const { location, replaceLocation } = this.props;
        replaceLocation({ ...location, search: "" });
    }
}


const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    location: getLocation,
});
const mapDispatchToProps: DispatchProps = {
    replaceLocation: replace,
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(AssetGraphicsModalConnected, mapStateToProps, mapDispatchToProps)
export class AssetGraphicsModal extends React.Component<OwnProps> {}
