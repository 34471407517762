import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_FAST } from "src/Constants/AnimationConstants";

type Props = {
    readonly expanded: boolean;
    readonly children: React.ReactNode;
};
export function ExpandContent({ expanded, children }: Props): JSX.Element {
    return (
        <CSSTransition in={expanded}
                       classNames="m03-expand-content"
                       appear={false}
                       timeout={ANIMATION_FAST}
                       mountOnEnter={false}
                       unmountOnExit={true}

                       onExit={setContentHeight}
                       onExiting={setZeroHeight}
                       onExited={cleanupElement}

                       onEnter={setZeroHeight}
                       onEntering={setContentHeight}
                       onEntered={cleanupElement}>
            <div className="m03-expand-content">{children}</div>
        </CSSTransition>
    );
}

function setContentHeight(el: HTMLElement): void {
    const bodyHeight = el.firstElementChild!.clientHeight;
    el.style.height = `${bodyHeight}px`;
}
function setZeroHeight(el: HTMLElement): void {
    el.style.height = "0";
}
function cleanupElement(el: HTMLElement): void {
    el.style.height = "";
}
