import * as React from "react";
import { EvojaTable, EvojaTableCell, EvojaTableRow } from "@evoja-web/uikit";

export function DetailGroupTable(props: JSX.IntrinsicElements["table"]): JSX.Element {
    return (
        <EvojaTable {...props}
                    className="m03-detail-group-table">
            {props.children}
        </EvojaTable>
    );
}

export function DetailGroupTableRow(props: JSX.IntrinsicElements["tr"]): JSX.Element {
    return (
        <EvojaTableRow {...props}
                       className="m03-detail-group-table__row">
            {props.children}
        </EvojaTableRow>
    );
}

export function DetailGroupTableHead(props: JSX.IntrinsicElements["tr"]): JSX.Element {
    return (
        <EvojaTableRow {...props}
                       className="m03-detail-group-table__head">
            {props.children}
        </EvojaTableRow>
    );
}

export function DetailGroupTableCellValue(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props}
                        className="m03-detail-group-table__cell m03-detail-group-table__cell--value">
            {props.children}
        </EvojaTableCell>
    );
}

export function DetailGroupTableCellAmount(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props}
                        className="m03-detail-group-table__cell m03-detail-group-table__cell--amount">
            {props.children}
        </EvojaTableCell>
    );
}
