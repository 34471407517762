import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ChecklistForm } from "src/State/Form/ChecklistForm";
import { ChecklistFormContent } from "src/Components/ChecklistForm/ChecklistFormContent";
import { checklistFormValidator } from "src/Validators/ChecklistFormValidator";

type Props = {
    readonly createChecklist: (formData: ChecklistForm) => void;
};
export function ChecklistFormModal({ createChecklist }: Props): JSX.Element {
    return (
        <div className="m03-checklist-form-modal">
            <div className="m03-checklist-form-modal__header">
                <FormattedMessage id="checklistForm_header"/>
            </div>
            <div className="m03-checklist-form-modal__body">
                <ChecklistFormContent form="checklist-form"
                                      initialValues={INITIAL_VALUES}
                                      touchOnBlur={true}
                                      touchOnChange={true}
                                      destroyOnUnmount={true}
                                      validate={checklistFormValidator}
                                      onSubmit={createChecklist}/>
            </div>
        </div>
    );
}

const INITIAL_VALUES: ChecklistForm = {
    title: "",
    text: "",
};
