import * as React from "react";

type PreserveContentProps = {
    readonly children: React.ReactElement<any> | null | undefined | boolean;
};
type PreserveContentState = {
    readonly children: React.ReactElement<any> | null | undefined | boolean;
};

export class PreserveContent extends React.Component<PreserveContentProps, PreserveContentState> {
    public readonly state: PreserveContentState = {
        children: this.props.children,
    };

    public static getDerivedStateFromProps(
        nextProps: PreserveContentProps,
        prevState: PreserveContentState,
    ): PreserveContentState {
        const isEmpty = (
            nextProps.children === null ||
            nextProps.children === undefined ||
            nextProps.children === true ||
            nextProps.children === false
        );

        return isEmpty ? prevState : nextProps;
    }

    public render(): React.ReactNode {
        const { children } = this.state;
        return children;
    }
}
