import { values } from "lodash-es";
import { Query } from "@xiag/rql-query";
import { IListEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { CORE_CONFIG_KEYS } from "src/Constants/CoreConfigKeys";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { ICoreConfigurationMapper } from "src/Services/ConfigurationData/CoreConfigurationMapper";

export interface ICoreConfigurationService {
    fetchCoreConfiguration(): Promise<CoreConfiguration>;
}

export class CoreConfigurationService implements ICoreConfigurationService {
    public static $inject: ReadonlyArray<string> = [
        "evjApiEndpointCoreConfig",
        "evjCoreConfigurationMapper",
    ];
    public constructor(
        private apiEndpointCoreConfig: IListEndpoint<Graviton.Core.Config>,
        private coreConfigurationMapper: ICoreConfigurationMapper,
    ) {
    }

    public async fetchCoreConfiguration(): Promise<CoreConfiguration> {
        const keys = values(CORE_CONFIG_KEYS);
        const { data: configuration } = await this.apiEndpointCoreConfig.list(
            new Query()
                .in("key", keys)
                .limit(keys.length, 0)
        );

        return this.coreConfigurationMapper.mapCoreConfiguration(configuration);
    }
}
