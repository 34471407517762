import * as React from "react";
import { FormattedMessage } from "react-intl";
import { EvojaLoader } from "@evoja-web/uikit";
import { BaseError } from "src/Errors/BaseError";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { ApplicationState } from "src/State/ApplicationState";

type OwnProps = {};
type StateProps = {
    loading: boolean;
    error: BaseError | undefined;
};
type DispatchProps = {};
type AllProps = OwnProps & StateProps & DispatchProps;

class InitializationWithState extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { error } = this.props;
        if (error) {
            throw error;
        }

        const { loading } = this.props;
        if (loading) {
            return (
                <div className="m03-initialization m03-initialization--loading">
                    <div className="m03-initialization__loader">
                        <div className="m03-initialization__loader-spinner">
                            <EvojaLoader/>
                        </div>
                        <div className="im03-nitialization__loader-text">
                            <FormattedMessage id="initialization_loading"/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="m03-initialization m03-initialization--ready">
                {this.props.children}
            </div>
        );
    }
}


function mapStateToProps(state: ApplicationState): StateProps {
    return {
        loading: (
            !state.configuration.configuration ||
            !state.consultation.data
        ),
        error: (
            state.configuration.error ||
            state.consultation.error
        ),
    };
}

@connectDecorator<OwnProps, StateProps, DispatchProps>(InitializationWithState, mapStateToProps, {})
export class Initialization extends React.Component<OwnProps> {}
