import { Provider } from "@xiag/container";
import { I18N_MESSAGES } from "src/Constants/I18nMessages";
import { EnvironmentContextFactory } from "src/Services/Environment/EnvironmentContextFactory";

export const ENVIRONMENT_PROVIDER = new Provider([])
    .constant(
        "evjDateFormat",
        "YYYY-MM-DDTHH:mm:ssZZ",
    )
    .constant(
        "evjDateTimezone",
        "Europe/Zurich",
    )
    .factory(
        "evjDateNow",
        () => new Date(),
        [],
    )
    .constant(
        "i18nMessages",
        I18N_MESSAGES,
    )
    .factory(
        "evjEnvironmentContext",
        EnvironmentContextFactory,
        EnvironmentContextFactory.$inject,
    );
