import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";
import { getBalanceLiquidityPlanning } from "src/Selectors/Balance/getBalanceLiquidityPlanning";
import {
    LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS,
    LiquidityPlanningChangeRequest,
} from "src/Actions/LiquidityPlanningActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* liquidityPlanningChangeTotalSaga({ data: value }: LiquidityPlanningChangeRequest): SagaIterator {
    const liquidityPlanning: BalanceLiquidityPlanning = yield select(getBalanceLiquidityPlanning);

    const minValue = ZERO_DECIMAL
        .add(liquidityPlanning.planned)
        .add(liquidityPlanning.wishReserve)
        .add(liquidityPlanning.provision)
        .add(liquidityPlanning.investment)
        .toDecimalPlaces(0);

    yield put(LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS.success({
        ...liquidityPlanning,
        total: value.toDecimalPlaces(0).greaterThan(minValue)
            ? value.toDecimalPlaces(0)
            : minValue,
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
