import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export function NotFound(): JSX.Element {
    return (
        <div className="m03-not-found">
            <div className="m03-not-found__title">
                <FormattedMessage id="notFound_title"/>
            </div>
            <div className="m03-not-found__back">
                <Link to="/"><FormattedMessage id="notFound_goHome"/></Link>
            </div>
        </div>
    );
}
