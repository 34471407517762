import * as React from "react";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";

type Props = {
    readonly portfolio: BalancePortfolio;
};

export function ObjectPortfolioSubTitle({ portfolio }: Props): JSX.Element {
    return (
        <div className="m03-object-portfolio-subtitle">
            <div className="m03-object-portfolio-subtitle__symbol">
                {portfolio.alphabetChar}
            </div>
            <div className="m03-object-portfolio-subtitle__title">
                {portfolio.portfolioKind && <TranslatedMessage value={portfolio.portfolioKind.text}/>}
            </div>
        </div>
    );
}
