import * as React from "react";
import { Decimal } from "decimal.js-light";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { AssetGraphicsGroup } from "src/State/AssetGraphics/AssetGraphicsGroup";
import { AssetGraphicsItem } from "src/State/AssetGraphics/AssetGraphicsItem";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";

const SIZE = 500;
const CENTER = SIZE / 2;
const RADIUS_LABEL = CENTER * 0.85;
const RADIUS_OUTER = RADIUS_LABEL * 0.8;
const RADIUS_INNER = RADIUS_OUTER * 0.8;
const RADIUS_INVESTABLE_OUTER = RADIUS_INNER * 0.99;
const RADIUS_INVESTABLE_INNER = RADIUS_INVESTABLE_OUTER * 0.92;

interface Props {
    readonly group: AssetGraphicsGroup;
    readonly isInvestable: boolean;
    readonly inPercents: boolean;
}

export class AssetGraphicsChart extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return (
            <div className="m03-asset-graphics-chart">
                <div className="m03-asset-graphics-chart__title">
                    <div className="m03-asset-graphics-chart__title-text">
                        <FormattedMessage id="assetGraphics_total"/>
                    </div>
                    <div className="m03-asset-graphics-chart__title-value">
                        {this.renderTotalSum()}
                    </div>
                </div>

                <svg className="m03-asset-graphics-chart__chart" viewBox={`0 0 ${SIZE} ${SIZE}`}>
                    <g className="m03-asset-graphics-chart__pie" transform={`rotate(-90 ${CENTER} ${CENTER})`}>
                        {this.renderSegments()}
                    </g>
                </svg>
            </div>
        );
    }

    private renderTotalSum(): JSX.Element {
        const { group, inPercents, isInvestable } = this.props;
        if (!isInvestable) {
            return inPercents
                ? <FormattedNumber value={1} style="percent"/>
                : <FormattedNumber value={group.total.toNumber()}/>;
        }

        const investableTotal = group.items
            .filter((it) => it.isInvestable)
            .reduce((result, it) => result.add(it.value), ZERO_DECIMAL);

        return inPercents
            ? (
                <FormattedNumber value={investableTotal.div(group.total).toNumber()}
                                 style="percent"
                                 maximumFractionDigits={2}
                />
            )
            : <FormattedNumber value={investableTotal.toNumber()}/>;
    }

    private renderCircle(
        key: string,
        color: string,
        radiusOuter: number,
        radiusInner: number,
    ): JSX.Element {
        const radius = (radiusOuter + radiusInner) / 2;
        const stroke = (radiusOuter - radiusInner);

        return (
            <circle key={key}
                    className="m03-asset-graphics-chart__circle"
                    cx={CENTER}
                    cy={CENTER}
                    r={radius}
                    strokeWidth={stroke}
                    stroke={color}
                    fill="none"/>
        );
    }

    private renderSegments(): JSX.Element {
        const { group, isInvestable } = this.props;
        if (this.getTotalSum().lessThan(0.01)) {
            return this.renderCircle(
                "empty",
                "rgba(0, 0, 0, 0.1)",
                RADIUS_OUTER,
                RADIUS_INNER,
            );
        }

        const itemsWithValues = group.items.filter((item) => item.value.greaterThan(0));
        if (itemsWithValues.length === 1) {
            return (
                <>
                    {this.renderCircle(
                        itemsWithValues[0].id,
                        itemsWithValues[0].color,
                        RADIUS_OUTER,
                        RADIUS_INNER,
                    )}
                    {isInvestable && itemsWithValues[0].isInvestable && this.renderCircle(
                        "investable",
                        "rgba(0, 0, 0, 0.2)",
                        RADIUS_INVESTABLE_OUTER,
                        RADIUS_INVESTABLE_INNER,
                    )}
                </>
            );
        }

        return <>{group.items.map((it) => this.renderSegment(it))}</>;
    }

    private renderSegment(item: AssetGraphicsItem): JSX.Element | null {
        const { inPercents, isInvestable } = this.props;
        const [ angleA, angleB ] = this.getSegmentAngles(item);
        if (angleA === angleB) {
            return null;
        }

        const labelAngle = ((angleB + angleA) / 2);
        const label = {
            x: CENTER + Math.cos(labelAngle) * RADIUS_LABEL,
            y: CENTER + Math.sin(labelAngle) * RADIUS_LABEL,
        };

        const value = inPercents
            ? item.value.div(this.getTotalSum())
            : item.value;

        return (
            <React.Fragment key={item.id}>
                <path className="m03-asset-graphics-chart__segment"
                      style={{ fill: item.color }}
                      d={createSegmentPath(angleA, angleB, RADIUS_OUTER, RADIUS_INNER)}
                />
                {isInvestable && item.isInvestable && (
                    <path className="m03-asset-graphics-chart__segment-investable"
                          d={createSegmentPath(angleA, angleB, RADIUS_INVESTABLE_OUTER, RADIUS_INVESTABLE_INNER)}
                    />
                )}
                <FormattedNumber value={value.toNumber()}
                                 style={inPercents ? "percent" : undefined}
                                 maximumFractionDigits={2}>
                    {(formattedValue) => (
                        <text className="m03-asset-graphics-chart__label"
                              x={label.x}
                              y={label.y}
                              textAnchor="middle"
                              transform={`rotate(90 ${label.x} ${label.y})`}>
                            {formattedValue}
                        </text>
                    )}
                </FormattedNumber>
            </React.Fragment>
        );
    }

    private getSegmentAngles(item: AssetGraphicsItem): [number, number] {
        const { group } = this.props;
        const itemsBefore = group.items.slice(0, group.items.indexOf(item));

        const totalSum = this.getTotalSum();
        const sumBefore = itemsBefore.reduce((result, it) => result.add(it.value), ZERO_DECIMAL);

        return [
            2 * Math.PI * sumBefore.div(totalSum).toNumber(),
            2 * Math.PI * sumBefore.add(item.value).div(totalSum).toNumber(),
        ];
    }

    private getTotalSum(): Decimal {
        const { group } = this.props;
        return group.items.reduce((result, it) => result.add(it.value), ZERO_DECIMAL);
    }
}

function createSegmentPath(
    angleA: number,
    angleB: number,
    radiusOuter: number,
    radiusInner: number,
): string {
    const pointA = {
        x: CENTER + Math.cos(angleA) * radiusOuter,
        y: CENTER + Math.sin(angleA) * radiusOuter,
    };
    const pointB = {
        x: CENTER + Math.cos(angleB) * radiusOuter,
        y: CENTER + Math.sin(angleB) * radiusOuter,
    };
    const pointC = {
        x: CENTER + Math.cos(angleB) * radiusInner,
        y: CENTER + Math.sin(angleB) * radiusInner,
    };
    const pointD = {
        x: CENTER + Math.cos(angleA) * radiusInner,
        y: CENTER + Math.sin(angleA) * radiusInner,
    };
    const longArc = angleB - angleA > Math.PI ? 1 : 0;

    return [
        `M ${pointA.x} ${pointA.y}`,
        `A ${radiusOuter} ${radiusOuter}, 0, ${longArc}, 1, ${pointB.x} ${pointB.y}`,
        `L ${pointC.x} ${pointC.y}`,
        `A ${radiusInner} ${radiusInner}, 0, ${longArc}, 0, ${pointD.x} ${pointD.y}`,
        "Z",
    ].join(" ");
}
