import { BalanceOutputAvailability } from "api/Types/BalanceOutputJson";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import {
    IOutputAvailabilityTotalMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityTotalMapper";
import {
    IOutputAvailabilityCategoryMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityCategoryMapper";
import {
    IOutputAvailabilityOverdrawMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityOverdrawMapper";
import { isBalanceObjectAccountAsset } from "src/Services/Utils/balanceObjectGuard";

export interface IOutputAvailabilityMapper {
    mapAvailability(
        balanceObjects: ReadonlyArray<BalanceObject>,
        balanceCategories: ReadonlyArray<BalanceCategory>,
    ): BalanceOutputAvailability;
}

export class OutputAvailabilityMapper implements IOutputAvailabilityMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjOutputAvailabilityTotalMapper",
        "evjOutputAvailabilityCategoryMapper",
        "evjOutputAvailabilityOverdrawMapper",
    ];
    public constructor(
        private totalMapper: IOutputAvailabilityTotalMapper,
        private categoryMapper: IOutputAvailabilityCategoryMapper,
        private overdrawMapper: IOutputAvailabilityOverdrawMapper,
    ) {
    }

    public mapAvailability(
        balanceObjects: ReadonlyArray<BalanceObject>,
        balanceCategories: ReadonlyArray<BalanceCategory>,
    ): BalanceOutputAvailability {
        return {
            total: this.totalMapper.mapAvailabilityTotal(
                balanceObjects.filter(isBalanceObjectAccountAsset),
            ),
            categories: balanceCategories
                .filter((category) => category.availabilityCategory !== undefined)
                .map((category) => this.categoryMapper.mapAvailabilityCategory(
                    category,
                    balanceObjects
                        .filter(isBalanceObjectAccountAsset)
                        .filter((it) => it.category.id === category.id),
                )),
            overdraw: this.overdrawMapper.mapAvailabilityOverdraw(
                balanceObjects.filter(isBalanceObjectAccountAsset),
            ),
        };
    }
}
