import { Decimal } from "decimal.js-light";
import { BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { BalanceOutputPosition } from "api/Types/BalanceOutputJson";
import { Locale } from "src/State/Parameters/Locale";
import { IFormatterService } from "src/Services/FormatterService";
import { IOutputCategoryPositionMapper } from "src/Services/Output/OutputCategoryPositionMapper";

export class OutputPositionFinancingMapper implements IOutputCategoryPositionMapper {
    public static $inject: string[] = [
        "evjLocale",
        "evjFormatterService",
    ];

    public constructor(
        private locale: Locale,
        private formatter: IFormatterService,
    ) {
    }

    public mapPosition(balanceObject: BalanceObjectFinancing): BalanceOutputPosition {
        return [
            {
                width: "2",
                align: "left",
                overflow: "truncate",
                style: "",
                value: balanceObject.portfolio?.alphabetChar || "",
            },
            {
                width: "83",
                align: "left",
                overflow: "truncate",
                style: "",
                value: balanceObject.financing.realEstateType
                    ? balanceObject.financing.realEstateType.text[this.locale] || ""
                    : "",
            },
            {
                width: "5",
                align: "left",
                overflow: "truncate",
                style: "",
                value: "",
            },
            {
                width: "10",
                align: "right",
                overflow: "truncate",
                style: "",
                value: this.formatNumber(balanceObject.amount),
            },
        ];
    }

    private formatNumber(value: Decimal): string {
        return this.formatter.formatNumber(value.toNumber(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
}
