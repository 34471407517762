import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { Translatable } from "src/State/Translatable";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";

type Props = {
    readonly object: BalanceObjectFinancing;
    readonly bankName: Translatable;
};

export function RealEstateModal({ object, bankName }: Props): JSX.Element {
    const currentMarketPrice = object.financing.currentMarketPrice
        ? new Decimal(object.financing.currentMarketPrice)
        : undefined;

    return (
        <div className="m03-real-estate-modal">
            <div className="m03-real-estate-modal__header">
                <FormattedMessage id="customerBalanceForm_header_realEstate_view"/>
            </div>
            <div className="m03-real-estate-modal__body">
                <div className="m03-real-estate-modal__col">
                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_bank"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            <TranslatedMessage value={bankName}/>
                        </div>
                    </div>
                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_title"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            {object.financing.realEstateType && (
                                <TranslatedMessage value={object.financing.realEstateType.text}/>
                            )}
                        </div>
                    </div>

                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_rubric"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            {object.financing.rubric}
                        </div>
                    </div>
                </div>
                <div className="m03-real-estate-modal__col">
                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_creationClass"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            <TranslatedMessage value={object.category.title}/>
                        </div>
                    </div>
                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_mortgageAmount"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            <FormattedNumber value={object.amount.toNumber()}/>
                        </div>
                    </div>
                    <div className="m03-real-estate-modal__row">
                        <div className="m03-real-estate-modal__label">
                            <FormattedMessage id="customerBalanceForm_currentMarketPrice"/>
                        </div>
                        <div className="m03-real-estate-modal__value">
                            {currentMarketPrice && <FormattedNumber value={currentMarketPrice.toNumber()}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
