import { BalanceData } from "src/State/Balance/BalanceData";
import { IBalanceCategoryMappingMapper } from "src/Services/BalanceData/BalanceCategoryMappingMapper";
import { IBalanceObjectMapper } from "src/Services/BalanceData/BalanceObjectMapper";
import { IBalanceCategoryMapper } from "src/Services/BalanceData/BalanceCategoryMapper";
import { IBalanceLiquidityPlanningMapper } from "src/Services/BalanceData/BalanceLiquidityPlanningMapper";
import { IBalanceAvailabilityCategoryMapper } from "src/Services/BalanceData/BalanceAvailabilityCategoryMapper";
import { IBalanceCreationClassAssetMapper } from "src/Services/BalanceData/BalanceCreationClassAssetMapper";
import { IBalanceCreationClassLiabilityMapper } from "src/Services/BalanceData/BalanceCreationClassLiabilityMapper";

export interface IBalanceDataMapper {
    mapBalance(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceData;
}

export class BalanceDataMapper implements IBalanceDataMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjBalanceCategoryMappingMapper",
        "evjBalanceCategoryMapper",
        "evjBalanceObjectMapper",
        "evjBalanceLiquidityPlanningMapper",
        "evjBalanceAvailabilityCategoryMapper",
        "evjBalanceCreationClassAssetMapper",
        "evjBalanceCreationClassLiabilityMapper",
    ];
    public constructor(
        private balanceCategoryMappingMapper: IBalanceCategoryMappingMapper,
        private balanceCategoryMapper: IBalanceCategoryMapper,
        private balanceObjectMapper: IBalanceObjectMapper,
        private balanceLiquidityPlanningMapper: IBalanceLiquidityPlanningMapper,
        private balanceAvailabilityCategoryMapper: IBalanceAvailabilityCategoryMapper,
        private balanceCreationClassAssetMapper: IBalanceCreationClassAssetMapper,
        private balanceCreationClassLiabilityMapper: IBalanceCreationClassLiabilityMapper,
    ) {
    }

    public mapBalance(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceData {
        const categoryMappings = this.balanceCategoryMappingMapper
            .mapBalanceCategoryMappings(balance);
        const availabilityCategories = this.balanceAvailabilityCategoryMapper
            .mapBalanceAvailabilityCategories(balance);
        const balanceCategories = this.balanceCategoryMapper
            .mapBalanceCategories(balance, availabilityCategories);
        const liquidityPlanning = this.balanceLiquidityPlanningMapper
            .mapBalanceLiquidityPlanning(balance);
        const creationClassAssets = this.balanceCreationClassAssetMapper
            .mapBalanceCreationClassAssets(balance);
        const creationClassLiabilities = this.balanceCreationClassLiabilityMapper
            .mapBalanceCreationClassLiabilities(balance);
        const balanceObjects = this.balanceObjectMapper
            .mapBalanceObjects(
                balance,
                balanceCategories,
                [...creationClassAssets, ...creationClassLiabilities],
                categoryMappings,
            );

        return {
            categoryMappings: categoryMappings,
            categories: balanceCategories,
            objects: balanceObjects,
            liquidityPlanning: liquidityPlanning,
            availabilityCategories: availabilityCategories,
            creationClassAssets: creationClassAssets,
            creationClassLiabilities: creationClassLiabilities,
        };
    }
}
