import { assertDefined } from "src/Utils/assertionHelpers";
import { CreationClassAssetLink } from "src/Services/Reference/CreationClassAssetLink";
import {
    BalanceCreationClassAsset,
    BalanceCreationClassAssetType,
} from "src/State/Balance/BalanceCreationClassAsset";

export interface IBalanceCreationClassAssetMapper {
    mapBalanceCreationClassAssets(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCreationClassAsset[];
}

export class BalanceCreationClassAssetMapper implements IBalanceCreationClassAssetMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjReferenceCreationClassAssetLinks",
    ];
    public constructor(
        private creationClassAssetLinks: ReadonlyArray<CreationClassAssetLink>,
    ) {
    }

    public mapBalanceCreationClassAssets(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCreationClassAsset[] {
        return balance.parameterData.balanceCreationClassAssets
            .map((code) => ({ code, type: this.getType(code) }))
            .sort((a, b) => a.code.number > b.code.number ? 1 : -1);
    }

    private getType(code: Readonly<Graviton.Entity.Code>): BalanceCreationClassAssetType {
        return assertDefined(
            this.creationClassAssetLinks.find((link) => link.number === code.number),
            `Could not find balanceCreationClassAsset type "${code.id}"`,
            { code, links: this.creationClassAssetLinks },
        ).type;
    }
}
