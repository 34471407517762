import { v4 } from "uuid";

export interface IUuidGenerator {
    generate(): string;
}

export class UuidGenerator implements IUuidGenerator {
    public static $inject: string[] = [];
    public generate(): string {
        return v4();
    }
}
