import * as React from "react";
import { Decimal } from "decimal.js-light";
import { bind } from "lodash-decorators/bind";
import {
    EvojaCalculator,
    EvojaHtmlButton,
    EvojaIcon,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverTarget,
} from "@evoja-web/uikit";

type Props = {
    readonly id: string;
    readonly value: Decimal;
    readonly onChange: (value: Decimal | null) => void;
};

export class AvailabilityNkkEdit extends React.Component<Props> {
    private readonly popoverRef: React.RefObject<EvojaPopover> = React.createRef();

    public render(): JSX.Element {
        const { id, value } = this.props;

        return (
            <EvojaPopover ref={this.popoverRef}
                          id={id}
                          placement="left">
                <EvojaPopoverTarget>
                    <EvojaHtmlButton shape="circle"
                                     block={false}
                                     size="sm"
                                     className="m03-availability-nkk-edit__button">
                        <EvojaIcon icon="pen"/>
                    </EvojaHtmlButton>
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <EvojaCalculator className="m03-availability-nkk-edit__calculator"
                                     value={value.mul(100).toDecimalPlaces(2)}
                                     format={formatPercentValue}
                                     onChange={this.handleChange}
                                     hasEnter={true}
                                     hasReset={true}
                                     hasMinus={false}
                                     hasPoint={true}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    @bind()
    private handleChange(value: Decimal | null): void {
        const { onChange } = this.props;
        onChange(value ? value.toDecimalPlaces(2).div(100) : null);

        const popover = this.popoverRef.current;
        if (popover) {
            popover.closePopover();
        }
    }
}

function formatPercentValue(input: string): string {
    return `${input}%`;
}
