import { MapOf } from "src/Utils/MapOf";

type ClassValue = undefined | null | string | string[] | MapOf<boolean> | ClassArray;
interface ClassArray extends Array<ClassValue> {}

export function classList(...args: ClassValue[]): string {
    return args.reduce<string[]>((result, item) => {
        if (typeof item === "string") {
            return item ? [...result, item] : result;
        } else if (Array.isArray(item)) {
            const sub = classList(...item);
            return sub ? [...result, sub] : result;
        } else if (typeof item === "object") {
            return item ? [...result, ...Object.keys(item).filter((key) => item[key])] : result;
        } else {
            return result;
        }
    }, []).join(" ");
}
