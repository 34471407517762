import { AnyAction, Reducer } from "redux";

type PartialReducerMap<TState> = {
    [TKey in keyof TState]?: Reducer<TState[TKey]>
};

export function partialReducers<TState extends {}>(reducers: PartialReducerMap<TState>): Reducer<TState> {
    return (state: TState, action: AnyAction) => {
        if (!state) {
            return state;
        }

        return Object.keys(reducers).reduce((result: TState, key: Extract<keyof TState, string>) => {
            const reducer = reducers[key];
            if (!reducer) {
                return result;
            }

            const prevVal = result[key];
            const nextVal = reducer(result[key], action);
            if (prevVal === nextVal) {
                return result;
            }

            return Object.assign({}, result, {[key]: nextVal});
        }, state);
    };
}
