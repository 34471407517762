import * as React from "react";
import { FormattedMessage } from "react-intl";
import { memoize } from "lodash-decorators/memoize";
import { EvojaHtmlButton, EvojaIcon } from "@evoja-web/uikit";
import { FileConfigurationItem } from "src/State/Configuration/FileConfiguration";

type Props = {
    readonly documents: ReadonlyArray<FileConfigurationItem>;
    readonly openDocument: (documentId: string) => void;
    readonly componentRef?: React.RefObject<HTMLDivElement>;
};

export class AvailabilityDocumentsPopover extends React.Component<Props> {
    public render(): JSX.Element {
        const { componentRef } = this.props;
        return (
            <div className="m03-availability-documents-popover" ref={componentRef}>
                <div className="m03-availability-documents-popover__title">
                    <FormattedMessage id="availability_documents"/>
                </div>
                <div className="m03-availability-documents-popover__body">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    private renderContent(): JSX.Element {
        const { documents } = this.props;
        return documents.length
            ? <>{documents.map((document) => this.renderDocument(document))}</>
            : this.renderEmpty();
    }

    private renderEmpty(): JSX.Element {
        return (
            <div className="m03-availability-documents-popover__empty">
                <FormattedMessage id="availability_noDocuments"/>
            </div>
        );
    }

    private renderDocument(document: FileConfigurationItem): JSX.Element {
        return (
            <div className="m03-availability-documents-popover__document" key={document.id} >
                <div className="m03-availability-documents-popover__button">
                    <EvojaHtmlButton id={`availability-document--${document.id}`}
                                     shape="circle"
                                     onClick={this.openDocument(document.id)}>
                        <EvojaIcon icon="document-full"/>
                    </EvojaHtmlButton>
                </div>
                <div className="m03-availability-documents-popover__name">
                    <label htmlFor={`availability-document--${document.id}`}
                           className="m03-availability-documents-popover__label">
                        {document.name}
                    </label>
                </div>
            </div>
        );
    }

    @memoize()
    private openDocument(documentId: string): () => void {
        const { openDocument } = this.props;
        return () => openDocument(documentId);
    }
}
