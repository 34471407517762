import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { bind } from "lodash-decorators/bind";
import {
    EvojaHtmlButton,
    EvojaIcon,
    EvojaModal,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverContext,
    EvojaPopoverTarget,
} from "@evoja-web/uikit";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { isNotNull } from "src/Utils/isNotNull";
import { AvailabilityProps } from "src/Pages/AvailabilityPage";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { AvailabilityCategory } from "src/Components/Availability/AvailabilityCategory";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { isBalanceObjectAccountAsset } from "src/Services/Utils/balanceObjectGuard";
import { AvailabilityObject } from "src/Components/Availability/AvailabilityObject";
import { AvailabilitySummary } from "src/Components/Availability/AvailabilitySummary";
import { ChecklistFormModal } from "src/Components/ChecklistForm/ChecklistFormModal";
import { ChecklistForm } from "src/State/Form/ChecklistForm";
import { AvailabilityDocumentsPopover } from "src/Components/Availability/AvailabilityDocumentsPopover";
import { getBalanceObjectMissingAmount } from "src/Services/Utils/getBalanceObjectMissingAmount";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";
import { getHasBalanceObjectOverdraw } from "src/Services/Utils/getHasBalanceObjectOverdraw";

export class Availability extends React.Component<AvailabilityProps> {
    public render(): JSX.Element {
        const {
            bankName,
            isChecklistModalOpen,
            categories,
            openChecklistModal,
            closeChecklistModal,
        } = this.props;

        return (
            <div className="m03-availability">
                <div className="m03-availability__header">
                    <div className="m03-availability__header-title">
                        <FormattedMessage id="availability_title" values={{
                            bank: <TranslatedMessage value={bankName}/>,
                        }}/>
                    </div>
                    <div className="m03-availability__header-subtitle">
                        <div className="m03-availability__header-documents">
                            {this.renderWithdrawalDocuments()}
                        </div>
                    </div>
                </div>
                <div className="m03-availability__content">
                    <div className="m03-availability__summary">
                        <AvailabilitySummary isOverdrawing={this.getIsOverdrawing()}
                                             totalAmount={this.getTotalAmount()}
                                             totalAvailableAmount={this.getTotalAvailableAmount()}
                                             totalWishedAmount={this.getTotalWishedAmount()}
                                             totalMissingAmount={this.getTotalMissingAmount()}
                                             totalNkkAmount={this.getTotalNkkAmount()}/>
                    </div>
                    <div className="m03-availability__categories">
                        {categories.map((category) => this.renderCategory(category))}
                    </div>
                </div>
                <div className="m03-availability__actions">
                    <label>
                        <EvojaHtmlButton shape="circle"
                                         block={false}
                                         onClick={openChecklistModal}
                                         className="m03-availability__actions-checklist">
                            <EvojaIcon icon="cross"/>
                        </EvojaHtmlButton>
                        <FormattedMessage id="availability_create_checklist"/>
                    </label>
                </div>

                <EvojaModal isOpen={isChecklistModalOpen}
                            onExit={closeChecklistModal}
                            className="m03-availability__checklist-modal">
                    <ChecklistFormModal createChecklist={this.createChecklistEntry}/>
                </EvojaModal>
            </div>
        );
    }

    private getTotalAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .reduce((result, { amount }) => result.add(amount), ZERO_DECIMAL);
    }
    private getTotalAvailableAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .filter(({ availableAmount }) => availableAmount.included)
            .map(({ availableAmount }) => availableAmount.value)
            .filter(isNotNull)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }
    private getTotalWishedAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .map(({ wishedAmount }) => wishedAmount.wishedAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }
    private getTotalMissingAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .map(getBalanceObjectMissingAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }
    private getTotalNkkAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .map(getBalanceObjectNkkAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getIsOverdrawing(): boolean {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .some(getHasBalanceObjectOverdraw);
    }

    private getCategoryObjects(category: BalanceCategory): BalanceObjectAccountAsset[] {
        const { objects } = this.props;
        return objects
            .filter(isBalanceObjectAccountAsset)
            .filter((object) => object.category.id === category.id);
    }

    private renderCategory(category: BalanceCategory): JSX.Element | null {
        const { changeAvailableAmount, toggleAvailableAmount, changeWishedAmount, changeNkkPercent } = this.props;
        const { openProvisionInfo, provisionInfoFiles } = this.props;
        const objects = this.getCategoryObjects(category);

        return (
            <div key={category.id} className="m03-availability__category">
                <AvailabilityCategory category={category}
                                      objects={objects}
                                      provisionInfoFiles={provisionInfoFiles}
                                      openProvisionInfo={openProvisionInfo}>
                    <div className="m03-availability__category-objects">
                        {objects.map((object) => (
                            <div key={object.id} className="m03-availability__object">
                                <AvailabilityObject balanceObject={object}
                                                    changeWishedAmount={changeWishedAmount}
                                                    changeNkkPercent={changeNkkPercent}
                                                    changeAvailableAmount={changeAvailableAmount}
                                                    toggleAvailableAmount={toggleAvailableAmount}/>
                            </div>
                        ))}
                    </div>
                </AvailabilityCategory>
            </div>
        );
    }

    private renderWithdrawalDocuments(): JSX.Element {
        const { withdrawalConditionFiles, openWithdrawalCondition } = this.props;
        return (
            <EvojaPopover id="withdrawal-documents" placement="bottom">
                <EvojaPopoverTarget>
                    {this.renderWithdrawalDocumentsButton}
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <AvailabilityDocumentsPopover documents={withdrawalConditionFiles}
                                                  openDocument={openWithdrawalCondition}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    @bind()
    private renderWithdrawalDocumentsButton(context: EvojaPopoverContext): JSX.Element {
        return (
            <label ref={context.targetRef}>
                <EvojaHtmlButton ref={context.pointerRef}
                                 shape="circle"
                                 block={false}
                                 className="m03-availability__header-button"
                                 onClick={context.togglePopover}>
                    <EvojaIcon icon="document-full"/>
                </EvojaHtmlButton>
                <span className="m03-availability__header-label">
                    <FormattedMessage id="availability_withdrawal_conditions"/>
                </span>
            </label>
        );
    }

    @bind()
    private createChecklistEntry(formData: ChecklistForm): void {
        const { createChecklistEntry } = this.props;
        createChecklistEntry(formData);

        const { closeChecklistModal } = this.props;
        closeChecklistModal();
    }
}
