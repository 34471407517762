import { ComponentClass, ComponentType } from "react";
import { connect, MapDispatchToPropsNonObject, MapStateToPropsParam } from "react-redux";
import { ApplicationState } from "src/State/ApplicationState";

export function connectDecorator<TOwnProps extends {}, TStateProps extends {}, TDispatchProps extends {}>(
    original: ComponentType<TOwnProps & TStateProps & TDispatchProps>,
    stateToProps: MapStateToPropsParam<TStateProps, TOwnProps, ApplicationState>,
    dispatchToProps: MapDispatchToPropsNonObject<TDispatchProps, TOwnProps> | TDispatchProps,
): (target: ComponentClass<TOwnProps>) => ComponentClass<TOwnProps> {
    // @ts-ignore
    return () => connect(stateToProps, dispatchToProps)(original);
}
