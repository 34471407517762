import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceOutputPosition } from "api/Types/BalanceOutputJson";
import { BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { assertNever } from "src/Utils/assertionHelpers";

export interface IOutputCategoryPositionMapper {
    mapPosition(balanceObject: BalanceObject): BalanceOutputPosition;
}

export class OutputCategoryPositionMapper implements IOutputCategoryPositionMapper {
    public static $inject: string[] = [
        "evjOutputPositionAccountNormalMapper",
        "evjOutputPositionAccountMetalMapper",
        "evjOutputPositionAccountDepositMapper",
        "evjOutputPositionCustomerBalanceMapper",
        "evjOutputPositionFinancingMapper",
        "evjOutputPositionContractMapper",
    ];

    public constructor(
        private accountNormalMapper: IOutputCategoryPositionMapper,
        private accountMetalMapper: IOutputCategoryPositionMapper,
        private accountDepositMapper: IOutputCategoryPositionMapper,
        private customerBalanceMapper: IOutputCategoryPositionMapper,
        private financingMapper: IOutputCategoryPositionMapper,
        private contractMapper: IOutputCategoryPositionMapper,
    ) {
    }

    public mapPosition(balanceObject: BalanceObject): BalanceOutputPosition {
        switch (balanceObject.outputClassType) {
            case BalanceOutputClassType.ACCOUNT_NORMAL:
            case BalanceOutputClassType.ACCOUNT_DEPOT:
            case BalanceOutputClassType.ACCOUNT_EXCHANGE:
                return this.accountNormalMapper.mapPosition(balanceObject);

            case BalanceOutputClassType.ACCOUNT_METAL:
                return this.accountMetalMapper.mapPosition(balanceObject);

            case BalanceOutputClassType.ACCOUNT_DEPOSIT:
                return this.accountDepositMapper.mapPosition(balanceObject);

            case BalanceOutputClassType.CUSTOMER_BALANCE:
            case BalanceOutputClassType.NEW_ENGAGEMENT:
            case BalanceOutputClassType.FINANCING_DEBT:
                return this.customerBalanceMapper.mapPosition(balanceObject);

            case BalanceOutputClassType.NEW_CONTRACT:
                return this.contractMapper.mapPosition(balanceObject);

            case BalanceOutputClassType.FINANCING:
                return this.financingMapper.mapPosition(balanceObject);

            default:
                return assertNever(`Unexpected outputDisplayClass`, { balanceObject });
        }
    }
}
