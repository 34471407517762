import { createSelector } from "reselect";
import { assertDefined } from "src/Utils/assertionHelpers";
import { getBankList } from "src/Selectors/Balance/getBankList";
import { getCoreConfiguration } from "src/Selectors/Configuration/getCoreConfiguration";

export const getBankName = createSelector([
    getCoreConfiguration,
    getBankList,
], (coreConfiguration, bankList) => {
    const bank = assertDefined(
        bankList.find((code) => code.id === coreConfiguration.bankId),
        "Invalid Bank ID configuration",
        { coreConfiguration, bankList },
    );
    return bank.textShort ? bank.textShort : bank.text;
});
