import * as React from "react";
import { EvojaIcon } from "@evoja-web/uikit";
import { classList } from "src/Utils/classList";

type Props = {
    readonly expanded: boolean;
};
export function ExpandIndicator({ expanded }: Props): JSX.Element {
    const className = classList("m03-expand-indicator", {
        "m03-expand-indicator--expanded": expanded,
        "m03-expand-indicator--collapsed": !expanded,
    });

    return (
        <span className={className}>
            <span className="m03-expand-indicator__arrow">
                <EvojaIcon icon="triangle-up"/>
            </span>
        </span>
    );
}
