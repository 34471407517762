import { Provider } from "@xiag/container";
import { createStore } from "redux";
import { default as sagaMiddlewareFactory } from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { LOGGER_PROVIDER } from "src/Modules/Logger/LoggerProvider";
import { BROWSER_PROVIDER } from "src/Modules/Browser/BrowserProvider";
import { SERVICE_STATELESS_PROVIDER } from "src/Services/ServiceStatelessProvider";
import { DEFAULT_STATE_PROVIDER } from "src/Bootstrap/DefaultState/DefaultStateProvider";
import { EnhancerFactory } from "src/Bootstrap/EnhancerFactory";
import { StateFactory } from "src/Bootstrap/StateFactory";
import { LocaleConfiguration } from "src/Bootstrap/LocaleConfiguration";
import { ReducerFactory } from "src/Bootstrap/ReducerFactory";
import { applicationSaga } from "src/Sagas/ApplicationSaga";

export const BOOSTRAP_PROVIDER = new Provider([
    LOGGER_PROVIDER,
    BROWSER_PROVIDER,
    DEFAULT_STATE_PROVIDER,
    SERVICE_STATELESS_PROVIDER,
])
    .factory(
        "evjMiddlewareRouter",
        routerMiddleware,
        ["$history"],
    )
    .factory(
        "evjMiddlewareSaga",
        sagaMiddlewareFactory,
        [],
    )

    .constant(
        "evjApplicationSaga",
        applicationSaga,
    )
    .factory(
        "evjApplicationReducer",
        ReducerFactory,
        ReducerFactory.$inject,
    )

    .factory(
        "evjApplicationEnhancer",
        EnhancerFactory,
        EnhancerFactory.$inject,
    )
    .factory(
        "evjApplicationState",
        StateFactory,
        StateFactory.$inject,
    )
    .factory(
        "evjApplicationStore",
        createStore,
        ["evjApplicationReducer", "evjApplicationState", "evjApplicationEnhancer"],
    )

    .configure(
        LocaleConfiguration,
        LocaleConfiguration.$inject,
    );
