import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import { BalanceOutputJson } from "api/Types/BalanceOutputJson";
import { IOutputJsonGenerator } from "src/Services/Output/OutputJsonGenerator";
import { selectService } from "src/Modules/Bind/selectService";
import { BalanceData } from "src/State/Balance/BalanceData";
import { getBalance } from "src/Selectors/Balance/getBalance";

export function* hostOutputGenerateSaga(): SagaIterator {
    const outputJsonGenerator: IOutputJsonGenerator = yield select(selectService, "evjOutputJsonGenerator");
    const balanceData: BalanceData = yield select(getBalance);
    const output: BalanceOutputJson = yield call({
        fn: outputJsonGenerator.generate,
        context: outputJsonGenerator,
    }, balanceData);
    yield put(HOST_COMMAND_ACTIONS.generateOutput(output));
}
