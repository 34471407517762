import * as React from "react";
import { FormattedMessage } from "react-intl";
import Decimal from "decimal.js-light";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};
export function DetailGroupDataPledges({ balanceObject }: Props): JSX.Element | null {
    const { account, category } = balanceObject;
    if (category.isProvision) {
        return <DetailGroupDataPledgesProvision balanceObject={balanceObject}/>;
    }

    if (account.pledge === undefined || !account.pledge.length) {
        return null;
    }

    const balance = new Decimal(account.reportingCurrency.balance);
    const pledgeSum = account.pledge
        .reduce((sum, it) => it.amount ? sum.add(it.amount) : sum, ZERO_DECIMAL);

    return (
        <DetailGroupDataPledgesBase balance={balance} pledgeSum={pledgeSum} />
    );
}

export function DetailGroupDataPledgesProvision({ balanceObject }: Props): JSX.Element | null {
    const { account, accountsFromGroup } = balanceObject;
    if (!account.accountGroup.pledge || !account.accountGroup.pledge.length) {
        return null;
    }

    const balance = accountsFromGroup
        .reduce((sum, it) => sum.add(it.reportingCurrency.balance), ZERO_DECIMAL);
    const pledgeSum = account.accountGroup.pledge
        .reduce((sum, it) => it.amount ? sum.add(it.amount) : sum, ZERO_DECIMAL);

    return (
        <DetailGroupDataPledgesBase balance={balance} pledgeSum={pledgeSum} />
    );
}

type DetailGroupDataPledgesBaseProps = {
    readonly balance: Decimal;
    readonly pledgeSum: Decimal;
};
function DetailGroupDataPledgesBase({ balance, pledgeSum }: DetailGroupDataPledgesBaseProps): JSX.Element {
    return (
        <DetailGroupTable>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue colSpan={2}>
                    <FormattedMessage id="detailGroupPopover_title_pledge"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_empty"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_pledge_saldo"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={balance.toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_dotSlashDot"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_pledge_sum"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={pledgeSum.toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue className="m03-detail-group-popover__td">
                    <FormattedMessage id="detailGroupPopover_equals"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue className="m03-detail-group-popover__td">
                    <FormattedMessage id="detailGroupPopover_pledge_available"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={balance.sub(pledgeSum).toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>
        </DetailGroupTable>
    );
}
