import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";

export function getBalanceObjectMissingAmount(
    object: BalanceObjectAccountAsset,
): Decimal {
    const { wishedAmount: { wishedAmount, withdrawalAmount } } = object;
    return wishedAmount.greaterThan(withdrawalAmount)
        ? wishedAmount.sub(withdrawalAmount)
        : ZERO_DECIMAL;
}
