import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Omit } from "mapped-types-utils";
import { EvojaTextbox, EvojaTextboxProps } from "@evoja-web/uikit";
import { isFieldInvalid } from "src/Utils/isFieldInvalid";

function TextboxComponent(props: WrappedFieldProps & EvojaTextboxProps): JSX.Element {
    const { input, meta, disabled = false, ...inputProps } = props;

    return (
        <EvojaTextbox {...inputProps}
                      invalid={isFieldInvalid(meta, { disabled })}
                      disabled={disabled}
                      name={input.name}
                      value={input.value}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      onChange={input.onChange}/>
    );
}

type Props = Omit<BaseFieldProps<EvojaTextboxProps> & EvojaTextboxProps, "component" | "value"  | "ref">;
export function TextboxField(props: Props): JSX.Element {
    return <Field component={TextboxComponent} {...props}/>;
}
