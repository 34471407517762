import * as React from "react";
import { EvojaTable, EvojaTableCell, EvojaTableRow } from "@evoja-web/uikit";
import { BalanceAccountPositionDetail } from "src/State/Balance/BalanceAccountPosition";

type Props = {
    readonly additionalDetails: ReadonlyArray<BalanceAccountPositionDetail>;
};

export function DepotDetailsAdditional({ additionalDetails }: Props): JSX.Element | null {
    if (!additionalDetails.length) {
        return null;
    }

    return (
        <EvojaTable className="m03-depot-details-additional">
            {additionalDetails.map((it, key) => (
                <EvojaTableRow key={key}>
                    <EvojaTableCell className="m03-depot-details-additional__title">
                        {it.title}
                    </EvojaTableCell>
                    <EvojaTableCell className="m03-depot-details-additional__value">
                        {it.value}
                    </EvojaTableCell>
                </EvojaTableRow>
            ))}
        </EvojaTable>
    );
}
