import { BalanceData } from "src/State/Balance/BalanceData";
import { CUSTOMER_BALANCE_CREATE_ACTIONS, CustomerBalanceCreateActions } from "src/Actions/CustomerBalanceActions";

export function customerBalanceCreateReducer(
    state: BalanceData,
    action: CustomerBalanceCreateActions,
): BalanceData {
    switch (action.type) {
        case CUSTOMER_BALANCE_CREATE_ACTIONS.SUCCESS:
            return {
                ...state,
                objects: [
                    ...state.objects,
                    action.data,
                ],
            };

        default:
            return state;
    }
}
