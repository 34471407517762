import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ValidateCallback } from "redux-form";
import { createStructuredSelector } from "reselect";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { FieldErrors } from "src/State/FieldError";
import { ApplicationState } from "src/State/ApplicationState";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { BalanceCreationClass } from "src/State/Balance/BalanceCreationClass";
import { CustomerBalanceFormContent } from "src/Components/CustomerBalanceForm/CustomerBalanceFormContent";
import { customBalanceFormValidator } from "src/Validators/CustomBalanceFormValidator";
import { getFormData } from "src/Selectors/getFormData";
import { getBankList } from "src/Selectors/Balance/getBankList";
import { getBalanceCreationClassAssets } from "src/Selectors/Balance/getBalanceCreationClassAssets";
import { getBalanceCreationClassLiabilities } from "src/Selectors/Balance/getBalanceCreationClassLiabilities";

type OwnProps = {
    readonly isEditing: boolean;
    readonly initialValues: CustomerBalanceForm;
    readonly saveCustomerBalance: (formData: CustomerBalanceForm) => void;
    readonly deleteCustomerBalance: () => void;
};
type StateProps = {
    readonly formType: "asset" | "liability";
    readonly bankList: ReadonlyArray<Graviton.Entity.Code>;
    readonly creationClasses: ReadonlyArray<BalanceCreationClass>;
    readonly currentValues: CustomerBalanceForm | undefined;
};
type DispatchProps = {};

type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

function CustomerBalanceFormModalWithState(props: AllProps): JSX.Element {
    const {
        isEditing,
        initialValues,
        currentValues,
        formType,
        bankList,
        creationClasses,
        saveCustomerBalance,
        deleteCustomerBalance,
    } = props;

    return (
        <div className="m03-customer-balance-form-modal">
            <div className="m03-customer-balance-form-modal__header">
                {formType === "asset"
                    ? isEditing
                        ? <FormattedMessage id="customerBalanceForm_header_asset_edit"/>
                        : <FormattedMessage id="customerBalanceForm_header_asset_add"/>
                    : isEditing
                        ? <FormattedMessage id="customerBalanceForm_header_liability_edit"/>
                        : <FormattedMessage id="customerBalanceForm_header_liability_add"/>}
            </div>
            <div className="m03-customer-balance-form-modal__body">
                <CustomerBalanceFormContent isEditing={isEditing}
                                            formData={currentValues || initialValues}
                                            bankList={bankList}
                                            creationClasses={creationClasses}
                                            form="customer-balance-form"
                                            initialValues={initialValues}
                                            touchOnBlur={true}
                                            touchOnChange={true}
                                            destroyOnUnmount={true}
                                            validate={customBalanceFormValidator}
                                            shouldError={doNotValidateOnFirstRender}
                                            onSubmit={saveCustomerBalance}
                                            onDelete={deleteCustomerBalance}/>
            </div>
        </div>
    );
}

function doNotValidateOnFirstRender(params: ValidateCallback<CustomerBalanceForm, {}, FieldErrors>): boolean {
    return !params.initialRender;
}

const mapStateToPropsAsset = createStructuredSelector<ApplicationState, StateProps>({
    currentValues: getFormData<CustomerBalanceForm>("customer-balance-form"),
    formType: () => "asset",
    bankList: getBankList,
    creationClasses: getBalanceCreationClassAssets,
});
const mapStateToPropsLiability = createStructuredSelector<ApplicationState, StateProps>({
    currentValues: getFormData<CustomerBalanceForm>("customer-balance-form"),
    formType: () => "liability",
    bankList: getBankList,
    creationClasses: getBalanceCreationClassLiabilities,
});

@connectDecorator<OwnProps, StateProps, DispatchProps>(CustomerBalanceFormModalWithState, mapStateToPropsAsset, {})
export class CustomerBalanceFormModalAsset extends React.Component<OwnProps> {}

@connectDecorator<OwnProps, StateProps, DispatchProps>(CustomerBalanceFormModalWithState, mapStateToPropsLiability, {})
export class CustomerBalanceFormModalLiability extends React.Component<OwnProps> {}
