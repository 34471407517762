import { Decimal } from "decimal.js-light";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";

export interface IBalanceLiquidityPlanningMapper {
    mapBalanceLiquidityPlanning(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceLiquidityPlanning;
}

export class BalanceLiquidityPlanningMapper implements IBalanceLiquidityPlanningMapper {
    public mapBalanceLiquidityPlanning(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceLiquidityPlanning {
        return {
            total: new Decimal(balance.consultation.liquidityPlanning.total)
                .toDecimalPlaces(0),
            planned: new Decimal(balance.consultation.liquidityPlanning.planned)
                .toDecimalPlaces(0),
            wishReserve: new Decimal(balance.consultation.liquidityPlanning.wishReserve)
                .toDecimalPlaces(0),
            provision: new Decimal(balance.consultation.liquidityPlanning.provision)
                .toDecimalPlaces(0),
            investment: new Decimal(balance.consultation.liquidityPlanning.investment)
                .toDecimalPlaces(0),
        };
    }
}
