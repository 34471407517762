import * as React from "react";
import { createSelector } from "reselect";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { Translatable } from "src/State/Translatable";
import { Locale } from "src/State/Parameters/Locale";
import { getLocale } from "src/Selectors/getLocale";

type OwnProps = {
    readonly value: Translatable;
};
type StateProps = {
    readonly locale: Locale;
};
type DispatchProps = {};
type AllProps = OwnProps & StateProps & DispatchProps;

class TranslatedMessageWithState extends React.Component<AllProps> {
    public render(): React.ReactNode {
        const { value, locale } = this.props;
        return value[locale] || value.en;
    }
}

const mapStateToProps = createSelector([
    getLocale,
], (locale): StateProps => ({
    locale,
}));

@connectDecorator<OwnProps, StateProps, DispatchProps>(TranslatedMessageWithState, mapStateToProps, {})
export class TranslatedMessage extends React.Component<OwnProps> {}
