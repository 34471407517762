import { Query } from "@xiag/rql-query";
import { EndpointName } from "src/Services/ApiClient/EndpointName";

export interface IApiUrlFactory {
    createInfoUrl(endpoint: EndpointName): string;
    createListUrl(endpoint: EndpointName, rql?: Query): string;
    createItemUrl(endpoint: EndpointName, id: string, fields?: string[]): string;
}

export class ApiUrlFactory implements IApiUrlFactory {
    public createInfoUrl(endpoint: EndpointName): string {
        return `/${endpoint}`;
    }
    public createListUrl(endpoint: EndpointName, rql?: Query): string {
        const queryString: string = rql
            ? rql.toString()
            : "";

        const urlPath = `/${endpoint}/`;
        return urlPath + (queryString ? "?" + queryString : "");
    }
    public createItemUrl(endpoint: EndpointName, id: string, fields?: string[]): string {
        const queryString: string = fields && fields.length
            ? new Query().select(fields).toString()
            : "";

        const urlPath = `/${endpoint}/${encodeURIComponent(id)}`;
        return urlPath + (queryString ? "?" + queryString : "");
    }
}
