import { SagaIterator } from "redux-saga";
import { put, call, select } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { selectService } from "src/Modules/Bind/selectService";
import { CONFIGURATION_ACTIONS } from "src/Actions/ConfigurationActions";
import { IConfigurationService } from "src/Services/ConfigurationService";
import { ConfigurationData } from "src/State/ConfigurationState";

export function* configurationSaga(): SagaIterator {
    try {
        yield put(CONFIGURATION_ACTIONS.pending());

        const configurationService: IConfigurationService = yield select(selectService, "evjConfigurationService");
        const configuration: ConfigurationData = yield call(configurationService);

        yield put(CONFIGURATION_ACTIONS.success(configuration));
    } catch (error) {
        yield put(CONFIGURATION_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
