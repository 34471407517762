import { assertTypeIs } from "src/Utils/assertionHelpers";
import { BalanceDisplayClassType } from "src/State/Balance/BalanceType";
import {
    BalanceObject,
    BalanceObjectAccount,
    BalanceObjectAccountAsset,
    BalanceObjectCustomerBalance,
    BalanceObjectFinancing,
    BalanceObjectWithPortfolio,
} from "src/State/Balance/BalanceObject";

export function isBalanceObjectAccount(object: BalanceObject): object is BalanceObjectAccount {
    return (
        object.displayClassType === BalanceDisplayClassType.ACCOUNT_NORMAL ||
        object.displayClassType === BalanceDisplayClassType.ACCOUNT_METAL ||
        object.displayClassType === BalanceDisplayClassType.ACCOUNT_DEPOSIT ||
        object.displayClassType === BalanceDisplayClassType.ACCOUNT_DEPOT ||
        object.displayClassType === BalanceDisplayClassType.ACCOUNT_EXCHANGE
    );
}

export function isBalanceObjectAccountAsset(object: BalanceObject): object is BalanceObjectAccountAsset {
    return (
        isBalanceObjectAccount(object) &&
        object.category.availabilityCategory !== undefined
    );
}

export function isBalanceObjectFinancing(object: BalanceObject): object is BalanceObjectFinancing {
    return (
        object.displayClassType === BalanceDisplayClassType.FINANCING
    );
}

export function isBalanceObjectCustomerBalance(object: BalanceObject): object is BalanceObjectCustomerBalance {
    return (
        object.displayClassType === BalanceDisplayClassType.CUSTOMER_BALANCE ||
        object.displayClassType === BalanceDisplayClassType.NEW_CONTRACT ||
        object.displayClassType === BalanceDisplayClassType.FINANCING_DEBT ||
        object.displayClassType === BalanceDisplayClassType.NEW_ENGAGEMENT
    );
}

export function isBalanceObjectWithPortfolio(object: BalanceObject): object is BalanceObjectWithPortfolio {
    return isBalanceObjectAccount(object) || isBalanceObjectFinancing(object);
}

export function assertBalanceObjectAccount(object: BalanceObject): BalanceObjectAccount {
    return assertTypeIs(
        object,
        isBalanceObjectAccount,
        `Invalid account object type "${object.displayClassType}"`,
        { object },
    );
}

export function assertBalanceObjectFinancing(object: BalanceObject): BalanceObjectFinancing {
    return assertTypeIs(
        object,
        isBalanceObjectFinancing,
        `Invalid financing object type "${object.displayClassType}"`,
        { object },
    );
}

export function assertBalanceObjectCustomerBalance(object: BalanceObject): BalanceObjectCustomerBalance {
    return assertTypeIs(
        object,
        isBalanceObjectCustomerBalance,
        `Invalid customer balance object type "${object.displayClassType}"`,
        { object },
    );
}
