import { createSelector } from "reselect";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { assertNever } from "src/Utils/assertionHelpers";
import { getCoreConfiguration } from "src/Selectors/Configuration/getCoreConfiguration";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";
import { getAssetColors } from "src/Selectors/Configuration/getAssetColors";
import { getCategoriesByAssetClass } from "src/Selectors/Balance/getCategoriesByAssetClass";
import { getCategoriesByAssetSegment } from "src/Selectors/Balance/getCategoriesByAssetSegment";
import { getCategoriesByBalanceCategory } from "src/Selectors/Balance/getCategoriesByBalanceCategory";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { AssetGraphicsGroup } from "src/State/AssetGraphics/AssetGraphicsGroup";
import { AssetGraphicsItem } from "src/State/AssetGraphics/AssetGraphicsItem";
import { CategoriesByEntityCode } from "src/State/Balance/CategoriesByEntityCode";
import { BalanceCategoryType } from "src/State/Balance/BalanceCategory";

export const getAssetGraphicsGroups = createSelector([
    getBalanceObjects,
    getCategoriesByAssetClass,
    getCategoriesByAssetSegment,
    getCategoriesByBalanceCategory,
    getCoreConfiguration,
    getAssetColors,
], (
    balanceObjects,
    categoriesByAssetClass,
    categoriesByAssetSegment,
    categoriesByBalanceCategory,
    configuration,
    assetColors,
): ReadonlyArray<AssetGraphicsGroup> => {
    if (!configuration.assetGroupings.length) {
        return [];
    }

    const assets = balanceObjects
        .filter((balanceObject) => balanceObject.category.type === BalanceCategoryType.ASSETS)
        .filter((balanceObject) => balanceObject.amount.greaterThan(0));
    return configuration.assetGroupings.map((type) => {
        const categories = getCategories(
            type,
            categoriesByAssetSegment,
            categoriesByBalanceCategory,
            categoriesByAssetClass,
        );
        const items = getItems(
            assets,
            categories,
            assetColors,
            configuration.investablePositions,
        );
        return {
            type: type,
            total: items.reduce((result, it) => result.add(it.value), ZERO_DECIMAL),
            items: items,
        };
    }).filter((group) => group.items.length > 0);
});

function getCategories(
    group: string,
    categoriesByAssetSegment: ReadonlyArray<CategoriesByEntityCode>,
    categoriesByBalanceCategory: ReadonlyArray<CategoriesByEntityCode>,
    categoriesByAssetClass: ReadonlyArray<CategoriesByEntityCode>,
): ReadonlyArray<CategoriesByEntityCode> {
    switch (group) {
        case "assetSegment":
            return categoriesByAssetSegment;
        case "balanceCategory":
            return categoriesByBalanceCategory;
        case "assetClass":
            return categoriesByAssetClass;
        default:
            return assertNever(`Unexpected assets grouping "${group}"`);
    }
}

function getItems(
    assets: ReadonlyArray<BalanceObject>,
    categories: ReadonlyArray<CategoriesByEntityCode>,
    assetColors: Record<string, string>,
    investablePositions: ReadonlyArray<string>,
): AssetGraphicsItem[] {
    return categories.map(({ categoryIds, code }) => {
        return {
            id: code.id,
            color: assetColors[code.id] || "#000000",
            title: code.text,
            value: assets
                .filter((it) => categoryIds.includes(it.category.balanceCategoryId))
                .reduce((result, it) => result.add(it.amount), ZERO_DECIMAL),
            isInvestable: investablePositions.includes(code.id),
        };
    }).filter((it) => it.value.greaterThan(0));
}
