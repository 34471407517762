import * as React from "react";
import { Decimal } from "decimal.js-light";
import { bind } from "lodash-decorators/bind";
import {
    EvojaCheckbox,
    EvojaHtmlButton,
    EvojaIcon,
    EvojaLinkButton,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverTarget,
} from "@evoja-web/uikit";
import { invalidAvailabilityCategory } from "src/Utils/invalidAvailabilityCategory";
import { hasDetailGroupData } from "src/Services/Utils/hasDetailGroupData";
import { hasDepotData } from "src/Services/Utils/hasDepotData";
import { DetailGroup } from "src/Components/DetailGroup/DetailGroup";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceAvailabilityCategoryType } from "src/State/Balance/BalanceAvailabilityCategory";
import { ObjectTitle } from "src/Components/Common/ObjectTitle";
import { ObjectSubTitle } from "src/Components/Common/ObjectSubTitle";
import { CalculatorDropdown } from "src/Components/Common/CalculatorDropdown";
import { AvailabilityNkkEdit } from "src/Components/Availability/AvailabilityNkkEdit";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { getBalanceObjectMissingAmount } from "src/Services/Utils/getBalanceObjectMissingAmount";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";

type Props = {
    readonly balanceObject: BalanceObjectAccountAsset;
    readonly changeAvailableAmount: (object: BalanceObjectAccountAsset, amount: Decimal | null) => void;
    readonly toggleAvailableAmount: (object: BalanceObjectAccountAsset, included: boolean) => void;
    readonly changeWishedAmount: (object: BalanceObjectAccountAsset, amount: Decimal) => void;
    readonly changeNkkPercent: (object: BalanceObjectAccountAsset, value: Decimal) => void;
};
export class AvailabilityObject extends React.Component<Props> {
    private detailGroupButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

    public render(): JSX.Element {
        const { balanceObject } = this.props;

        return (
            <div className="m03-availability-object">
                <div className="m03-availability-object__name">
                    <div className="m03-availability-object__title">
                        <ObjectTitle balanceObject={balanceObject}/>
                    </div>
                    <div className="m03-availability-object__subtitle">
                        <ObjectSubTitle balanceObject={balanceObject}/>
                    </div>
                </div>
                <div className="m03-availability-object__action">
                    {this.renderDetailGroupButton()}
                    {this.renderDepotButton()}
                </div>
                <div className="m03-availability-object__amount">
                    <FormattedNumber value={balanceObject.amount.toNumber()}/>
                </div>
                <div className="m03-availability-object__available">
                    {this.renderAvailableAmount()}
                </div>
                <div className="m03-availability-object__toggle">
                    {this.renderToggleCheckbox()}
                </div>

                <div className="m03-availability-object__div"/>

                <div className="m03-availability-object__wished">
                    {this.renderWishedAmount()}
                </div>
                <div className="m03-availability-object__missing">
                    {this.renderMissingAmount()}
                </div>
                <div className="m03-availability-object__nkk-amount">
                    {this.renderNkkAmount()}
                </div>
                <div className="m03-availability-object__nkk-percent">
                    {this.renderNkkPercent()}
                </div>
            </div>
        );
    }

    private renderAvailableAmount(): JSX.Element | null {
        const { balanceObject } = this.props;
        switch (balanceObject.availabilityCategory.type) {
            case BalanceAvailabilityCategoryType.DIRECT:
                return <AvailableAmountValue amount={balanceObject.availableAmount.value}/>;

            case BalanceAvailabilityCategoryType.SELECTIVE:
                return <AvailableAmountValue amount={balanceObject.amount}/>;

            case BalanceAvailabilityCategoryType.DEFINED:
                return this.renderDefinedAvailableAmount();

            case BalanceAvailabilityCategoryType.NONE:
                return <AvailableAmountValue amount={null}/>;

            default:
                return invalidAvailabilityCategory(balanceObject.availabilityCategory.type, { balanceObject });
        }
    }

    private renderWishedAmount(): JSX.Element | null {
        const { balanceObject } = this.props;
        if (!balanceObject.availabilityCategory.hasPositionBasedAvailability) {
            return null;
        }

        return (
            <CalculatorDropdown id={`wished-amount--${balanceObject.id}`}
                                className="m03-availability-object__input"
                                placement="right"
                                name="wishedAmount"
                                value={balanceObject.wishedAmount.wishedAmount}
                                onChange={this.changeWishedAmountValue}
                                hasMinus={false}
                                hasPoint={true}
                                formatValue={formatValue}/>
        );
    }

    private renderMissingAmount(): JSX.Element | null {
        const { balanceObject } = this.props;
        if (!balanceObject.availabilityCategory.hasPositionBasedAvailability) {
            return null;
        }

        return <FormattedNumber value={getBalanceObjectMissingAmount(balanceObject).toNumber()}/>;
    }

    private renderNkkAmount(): JSX.Element | null {
        const { balanceObject } = this.props;
        if (!balanceObject.availabilityCategory.hasPositionBasedAvailability) {
            return null;
        }

        return <FormattedNumber value={getBalanceObjectNkkAmount(balanceObject).toNumber()}/>;
    }

    private renderNkkPercent(): JSX.Element | null {
        const { balanceObject } = this.props;
        if (!balanceObject.availabilityCategory.hasPositionBasedAvailability) {
            return null;
        }

        return (
            <>
                <span className="m03-availability-object__nkk-percent-value">
                    <FormattedNumber
                        value={balanceObject.wishedAmount.nkkPercent.toDecimalPlaces(4).toNumber()}
                        style="percent"/>
                </span>
                <span className="m03-availability-object__nkk-percent-input">
                    <AvailabilityNkkEdit id={`nkk-percent--${balanceObject.id}`}
                                         value={balanceObject.wishedAmount.nkkPercent}
                                         onChange={this.changeNkkPercentValue}/>
                </span>
            </>
        );
    }

    private renderDetailGroupButton(): JSX.Element | null {
        const { balanceObject, balanceObject: { availabilityDetailGroups } } = this.props;
        const hasData = availabilityDetailGroups.some((detailGroup) => hasDetailGroupData(balanceObject, detailGroup));
        if (!hasData) {
            return null;
        }

        return (
            <EvojaPopover id={`availability-groups--${balanceObject.id}`} placement={this.getPopoverPosition}>
                <EvojaPopoverTarget>
                    <EvojaHtmlButton shape="circle"
                                     ref={this.detailGroupButtonRef}
                                     className="m03-availability-object__details">
                        <EvojaIcon icon="ellipsis"/>
                    </EvojaHtmlButton>
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <DetailGroup balanceObject={balanceObject}
                                 detailGroupTypes={availabilityDetailGroups}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    private renderDepotButton(): JSX.Element | null {
        const { balanceObject, balanceObject: { availabilityDetailGroups } } = this.props;
        const hasData = availabilityDetailGroups.some((detailGroup) => hasDepotData(balanceObject, detailGroup));
        if (!hasData) {
            return null;
        }

        const location = new URLSearchParams({
            "view": "availability",
            "depot-id": balanceObject.id,
        }).toString();

        return (
            <EvojaLinkButton to={{search: location}}
                             shape="circle"
                             className="m03-availability-object__depot-details">
                <EvojaIcon icon="search"/>
            </EvojaLinkButton>
        );
    }

    private renderDefinedAvailableAmount(): JSX.Element {
        const { balanceObject: { id, availableAmount } } = this.props;
        return (
            <CalculatorDropdown id={`defined-available-amount--${id}`}
                                className="m03-availability-object__input"
                                placement="right"
                                name="availableAmount"
                                value={availableAmount.value}
                                onChange={this.changeDefinedAvailableAmountValue}
                                hasMinus={false}
                                hasPoint={true}
                                formatValue={formatValue}/>
        );
    }

    private renderToggleCheckbox(): JSX.Element | null {
        const { balanceObject } = this.props;
        if (balanceObject.availabilityCategory.type !== BalanceAvailabilityCategoryType.SELECTIVE) {
            return null;
        }

        return (
            <EvojaCheckbox checked={balanceObject.availableAmount.included}
                           onChange={this.toggleSelectiveAvailableAmountInclusion}/>
        );
    }

    @bind()
    private changeDefinedAvailableAmountValue(amount: Decimal | null): void {
        const { balanceObject, changeAvailableAmount } = this.props;
        changeAvailableAmount(balanceObject, amount);
    }
    @bind()
    private toggleSelectiveAvailableAmountInclusion(event: React.ChangeEvent<HTMLInputElement>): void {
        const { balanceObject, toggleAvailableAmount } = this.props;
        toggleAvailableAmount(
            balanceObject,
            event.target.checked,
        );
    }

    @bind()
    private changeWishedAmountValue(amount: Decimal | null): void {
        if (!amount) {
            return;
        }

        const { balanceObject, changeWishedAmount } = this.props;
        changeWishedAmount(balanceObject, amount);
    }
    @bind()
    private changeNkkPercentValue(value: Decimal | null): void {
        if (!value) {
            return;
        }

        const { balanceObject, changeNkkPercent } = this.props;
        changeNkkPercent(balanceObject, value);
    }

    @bind()
    private getPopoverPosition(): "top" | "bottom" {
        const { current: thisElement } = this.detailGroupButtonRef;
        if (!thisElement) {
            return "top";
        }

        const thisPosition = thisElement.getBoundingClientRect();
        const topOffset = thisPosition.top;
        const bottomOffset = window.innerHeight - thisPosition.bottom;

        return topOffset > bottomOffset ? "top" : "bottom";
    }
}

type AvailableAmountValueProps = {
    readonly amount: Decimal | null;
};
function AvailableAmountValue({ amount }: AvailableAmountValueProps): JSX.Element {
    return formatValue(amount);
}
function formatValue(amount: Decimal | null): JSX.Element {
    return amount
        ? <FormattedNumber value={amount.toNumber()}/>
        : <span className="m03-availability-object__empty">-</span>;
}
