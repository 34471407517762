import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";

type Props = {
    readonly isOverdrawing: boolean;
    readonly totalAmount: Decimal;
    readonly totalAvailableAmount: Decimal;
    readonly totalWishedAmount: Decimal;
    readonly totalMissingAmount: Decimal;
    readonly totalNkkAmount: Decimal;
};
export function AvailabilitySummary({
                                        isOverdrawing,
                                        totalAmount,
                                        totalAvailableAmount,
                                        totalWishedAmount,
                                        totalMissingAmount,
                                        totalNkkAmount,
}: Props): JSX.Element {
    return (
        <div className="m03-availability-summary">
            <div className="m03-availability-summary__row">
                <div className="m03-availability-summary__amount">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_amount"/>
                    </div>
                </div>
                <div className="m03-availability-summary__available">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_available"/>
                    </div>
                </div>

                <div className="m03-availability-summary__wished">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_wished"/>
                    </div>
                </div>
                <div className="m03-availability-summary__missing">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_missing"/>
                    </div>
                </div>
                <div className="m03-availability-summary__nkk-amount">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_nkkAmount"/>
                    </div>
                </div>
                <div className="m03-availability-summary__nkk-percent">
                    <div className="m03-availability-summary__header">
                        <FormattedMessage id="availability_summary_nkkPercent"/>
                    </div>
                </div>
            </div>
            <div className="m03-availability-summary__row">
                <div className="m03-availability-summary__title">
                    <FormattedMessage id="availability_summary_total"/>
                </div>
                <div className="m03-availability-summary__amount">
                    <FormattedNumber value={totalAmount.toNumber()}/>
                </div>
                <div className="m03-availability-summary__available">
                    <FormattedNumber value={totalAvailableAmount.toNumber()}/>
                </div>

                <div className="m03-availability-summary__wished">
                    <FormattedNumber value={totalWishedAmount.toNumber()}/>
                </div>
                <div className="m03-availability-summary__missing">
                    <FormattedNumber value={totalMissingAmount.toNumber()}/>
                </div>
                <div className="m03-availability-summary__nkk-amount">
                    <FormattedNumber value={totalNkkAmount.toNumber()}/>
                </div>
                <div className="m03-availability-summary__nkk-percent"/>
            </div>
            {isOverdrawing && (
                <div className="m03-availability-summary__warning">
                    <FormattedMessage id="availability_summary_warning"/>
                </div>
            )}
        </div>
    );
}
