import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";

export function getBalanceObjectNkkAmount(
    object: BalanceObjectAccountAsset,
): Decimal {
    const { wishedAmount: { wishedAmount, withdrawalAmount, nkkPercent } } = object;
    return wishedAmount.greaterThan(withdrawalAmount)
        ? wishedAmount.sub(withdrawalAmount).mul(nkkPercent)
        : ZERO_DECIMAL;
}
