import { IFileDownloadEndpoint } from "src/Services/ApiClient/FileEndpoint";
import { InstanceConfiguration, InstanceConfigurationRaw } from "src/State/Configuration/InstanceConfiguration";

export interface IInstanceConfigurationService {
    fetchConfiguration(): Promise<InstanceConfiguration>;
}

export class InstanceConfigurationService implements IInstanceConfigurationService {
    public static $inject: ReadonlyArray<string> = [
        "evjApiEndpointFileContent",
    ];

    public constructor(
        private apiFileDownload: IFileDownloadEndpoint,
    ) {
    }

    public async fetchConfiguration(): Promise<InstanceConfiguration> {
        try {
            const { data: content } = await this.apiFileDownload.download("balance-configuration");
            const configuration: InstanceConfigurationRaw = JSON.parse(
                String.fromCharCode.apply(null, new Uint8Array(content)),
            );

            const assetColors = configuration.assetAllocation && configuration.assetAllocation.colors
                ? configuration.assetAllocation.colors
                : {};

            return {
                assetColors,
            };
        } catch (error) {
            return {
                assetColors: {},
            };
        }
    }
}
