import { WrappedFieldMetaProps } from "redux-form";

type FieldState = {
    readonly disabled: boolean;
};

export function isFieldInvalid(
    meta: WrappedFieldMetaProps,
    state: FieldState,
): boolean {
    if (state.disabled) {
        return false;
    }

    return meta.touched || meta.submitFailed
        ? meta.invalid
        : false;
}
