import { Provider } from "@xiag/container";
import { RouterDefaultState } from "src/Bootstrap/DefaultState/RouterDefaultState";
import { ParametersDefaultState } from "src/Bootstrap/DefaultState/ParametersDefaultState";

export const DEFAULT_STATE_PROVIDER = new Provider([])
    .factory(
        "evjDefaultStateRouter",
        RouterDefaultState,
        RouterDefaultState.$inject || [],
    )
    .factory(
        "evjDefaultStateParameters",
        ParametersDefaultState,
        ParametersDefaultState.$inject || [],
    );
