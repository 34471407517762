import * as React from "react";
import { invalidDisplayClass } from "src/Utils/invalidDisplayClass";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceDisplayClassType } from "src/State/Balance/BalanceType";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";

type Props = {
    readonly balanceObject: BalanceObject;
};

export function ObjectTitle({ balanceObject }: Props): JSX.Element | null {
    switch (balanceObject.displayClassType) {
        case BalanceDisplayClassType.ACCOUNT_NORMAL:
        case BalanceDisplayClassType.ACCOUNT_METAL:
        case BalanceDisplayClassType.ACCOUNT_DEPOSIT:
        case BalanceDisplayClassType.ACCOUNT_DEPOT:
        case BalanceDisplayClassType.ACCOUNT_EXCHANGE:
            return (
                <span className="m03-object-title">
                    <TranslatedMessage value={balanceObject.account.accountType.text}/>
                </span>
            );

        case BalanceDisplayClassType.CUSTOMER_BALANCE:
        case BalanceDisplayClassType.NEW_ENGAGEMENT:
        case BalanceDisplayClassType.FINANCING_DEBT:
        case BalanceDisplayClassType.NEW_CONTRACT:
            return (
                <span className="m03-object-title">
                    {balanceObject.customerBalance.title}
                </span>
            );

        case BalanceDisplayClassType.FINANCING:
            return balanceObject.financing.realEstateType
                ? (
                    <span className="m03-object-title">
                        <TranslatedMessage value={balanceObject.financing.realEstateType.text}/>
                    </span>
                )
                : null;

        default:
            return invalidDisplayClass(balanceObject, { balanceObject });
    }
}

