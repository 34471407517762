export type BalanceOutputJson = {
    readonly products: BalanceOutputProducts;
    readonly liquidity: BalanceOutputLiquidity;
    readonly availability: BalanceOutputAvailability;
};

export type BalanceOutputProducts = {
    readonly assets: BalanceOutputGroup;
    readonly liabilities: BalanceOutputGroup;
};

export type BalanceOutputLiquidity = {
    readonly totalLiquidity: number;
    readonly planned: number;
    readonly wishReserve: number;
    readonly provision: number;
    readonly investment: number;
};

export type BalanceOutputAvailability = {
    readonly total: BalanceOutputAvailabilityTotal;
    readonly categories: ReadonlyArray<BalanceOutputAvailabilityCategory>;
    readonly overdraw: BalanceOutputAvailabilityOverdraw | null;
};
export type BalanceOutputAvailabilityAmount = {
    readonly value: number | null;
    readonly formatted: string;
};
export type BalanceOutputAvailabilityOverdraw = {
    readonly warning: string;
};
export type BalanceOutputAvailabilityTotal = {
    readonly amount: BalanceOutputAvailabilityAmount;
    readonly availableAmount: BalanceOutputAvailabilityAmount;
    readonly wishedAmount: BalanceOutputAvailabilityAmount;
    readonly missingAmount: BalanceOutputAvailabilityAmount;
    readonly nkkAmount: BalanceOutputAvailabilityAmount;
};
export type BalanceOutputAvailabilityCategory = {
    readonly title: string;
    readonly positions: ReadonlyArray<BalanceOutputAvailabilityPosition>;

    readonly amount: BalanceOutputAvailabilityAmount;
    readonly availableAmount: BalanceOutputAvailabilityAmount;
    readonly wishedAmount: BalanceOutputAvailabilityAmount;
    readonly missingAmount: BalanceOutputAvailabilityAmount;
    readonly nkkAmount: BalanceOutputAvailabilityAmount;
};
export type BalanceOutputAvailabilityPosition = {
    readonly title: string;
    readonly subtitle: string;

    readonly amount: BalanceOutputAvailabilityAmount;
    readonly availableAmount: BalanceOutputAvailabilityAmount;
    readonly availableAmountIncluded: BalanceOutputAvailableAmountIncluded;
    readonly wishedAmount: BalanceOutputAvailabilityAmount;
    readonly missingAmount: BalanceOutputAvailabilityAmount;
    readonly nkkAmount: BalanceOutputAvailabilityAmount;
    readonly nkkPercent: BalanceOutputAvailabilityAmount;
};
export enum BalanceOutputAvailableAmountIncluded {
    YES = "yes",
    NO = "no",

    ALWAYS = "always",
    NEVER = "never",
}

export type BalanceOutputGroupTotalItem = {
    readonly title: string;
    readonly value: Graviton.Common.Decimal;
};
export type BalanceOutputGroupTotals = [BalanceOutputGroupTotalItem, BalanceOutputGroupTotalItem];

export type BalanceOutputGroup = {
    readonly totals: BalanceOutputGroupTotals;
    readonly categories: ReadonlyArray<BalanceOutputCategory>;
};

export type BalanceOutputCategory = {
    readonly title: string;
    readonly total: Graviton.Common.Decimal;
    readonly positions: ReadonlyArray<BalanceOutputPosition>;
};

export type BalanceOutputPosition = ReadonlyArray<BalanceOutputPositionItem>;

export type BalanceOutputPositionItem = {
    readonly width: string;
    readonly align: "left" | "right" | "center";
    readonly overflow: "truncate" | "wrap";
    readonly style: string,
    readonly value: string;
};
