import { SagaIterator } from "redux-saga";
import { call, select } from "redux-saga/effects";
import { HostCommandAction } from "src/Actions/HostCommandActions";
import { ILogger } from "src/Modules/Logger/Logger";
import { IHostCommandDispatcher } from "src/Services/HostCommunication/HostCommandDispatcher";
import { selectService } from "src/Modules/Bind/selectService";

export function* hostCommandSaga(
    {command}: HostCommandAction<any>,
): SagaIterator {
    const $logger: ILogger = yield select(selectService, "$logger");
    const hostCommandDispatcher: IHostCommandDispatcher = yield select(selectService, "evjHostCommandDispatcher");

    try {
        yield call({
            fn: $logger.info,
            context: $logger,
        }, "Sending host communication command...", {command});

        yield call({
            fn: hostCommandDispatcher.dispatchCommand,
            context: hostCommandDispatcher,
        }, command);

        yield call({
            fn: $logger.info,
            context: $logger,
        }, "Sending host communication command... done", {command});
    } catch (error) {
        yield call({
            fn: $logger.error,
            context: $logger,
        }, "Error while sending host command", {command, error});
    }
}
