import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import {
    DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS,
    DocumentOpenWithdrawalConditionAction,
} from "src/Actions/DocumentActions";

export function* documentOpenWithdrawalConditionSaga(
    { meta: fileId }: DocumentOpenWithdrawalConditionAction,
): SagaIterator {
    try {
        yield put(DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS.pending(fileId));
        yield put(HOST_COMMAND_ACTIONS.openDocument(fileId));
        yield put(DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS.success(undefined, fileId));
    } catch (error) {
        yield put(DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS.failure(NativeError.wrapError(error), fileId));
    }
}
