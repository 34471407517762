import { BaseError } from "src/Errors/BaseError";
import { BalanceOutputJson } from "api/Types/BalanceOutputJson";

export const HOST_COMMAND = "HOST_COMMAND";
export const HOST_REQUEST = "HOST_REQUEST";

export interface HostCommandAction<TCommand> {
    readonly type: typeof HOST_COMMAND;
    readonly command: TCommand;
}
export interface HostRequestAction<TRequest> {
    readonly type: typeof HOST_REQUEST;
    readonly request: TRequest;
}
export function hostRequest<TRequest>(request: TRequest): HostRequestAction<TRequest> {
    return {
        type: HOST_REQUEST,
        request: request,
    };
}
export function hostCommand<TCommand>(command: TCommand): HostCommandAction<TCommand> {
    return {
        type: HOST_COMMAND,
        command: command,
    };
}

export namespace HOST_REQUEST_ACTIONS {
    export const REQUEST_SAVE = "iOSSaveTrigger";
    export const REQUEST_OUTPUT = "iOSOutputTrigger";
}

export namespace HOST_COMMAND_ACTIONS {
    export const loadFailure = (error: BaseError) => hostCommand({
        action: "error",
        data: error.toJSON(),
    });
    export const loadComplete = () => hostCommand({
        action: "ready",
    });
    export const syncComplete = () => hostCommand({
        action: "saved",
    });
    export const reloadChecklist = () => hostCommand({
        action: "checklist",
    });
    export const openDocument = (fileId: string) => hostCommand({
        action: "document.open",
        data: fileId,
    });
    export const generateOutput = (data: BalanceOutputJson) => hostCommand({
        action: "output.generate",
        data: data,
    });
}
