import {
    BalanceOutputAvailabilityAmount,
    BalanceOutputAvailabilityCategory,
    BalanceOutputAvailableAmountIncluded,
} from "api/Types/BalanceOutputJson";
import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { isNotNull } from "src/Utils/isNotNull";
import { assertDefined } from "src/Utils/assertionHelpers";
import { invalidAvailabilityCategory } from "src/Utils/invalidAvailabilityCategory";
import { Locale } from "src/State/Parameters/Locale";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import {
    BalanceAvailabilityCategory,
    BalanceAvailabilityCategoryType,
} from "src/State/Balance/BalanceAvailabilityCategory";
import { IFormatterService } from "src/Services/FormatterService";
import { ITranslationService } from "src/Services/TranslationService";
import { getBalanceObjectMissingAmount } from "src/Services/Utils/getBalanceObjectMissingAmount";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";
import {
    IOutputAvailabilityPositionTitleMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityPositionTitleMapper";
import {
    IOutputAvailabilityPositionSubtitleMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityPositionSubtitleMapper";

export interface IOutputAvailabilityCategoryMapper {
    mapAvailabilityCategory(
        balanceCategory: BalanceCategory,
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityCategory;
}

export class OutputAvailabilityCategoryMapper implements IOutputAvailabilityCategoryMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjLocale",
        "evjFormatterService",
        "evjTranslationService",
        "evjOutputAvailabilityPositiontTitleMapper",
        "evjOutputAvailabilityPositiontSubtitleMapper",
    ];
    public constructor(
        private locale: Locale,
        private formatter: IFormatterService,
        private translator: ITranslationService,
        private positionTitleMapper: IOutputAvailabilityPositionTitleMapper,
        private positionSubtitleMapper: IOutputAvailabilityPositionSubtitleMapper,
    ) {
    }

    public mapAvailabilityCategory(
        balanceCategory: BalanceCategory,
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityCategory {
        const { type: availabilityType, hasPositionBasedAvailability } = this.getAvailabilityCategory(balanceCategory);
        switch (availabilityType) {
            case BalanceAvailabilityCategoryType.DIRECT: {
                const availableAmount = this.getTotalAvailableAmount(categoryObjects);

                return {
                    title: balanceCategory.title[this.locale],

                    amount: this.formatAmount(this.getTotalAmount(categoryObjects)),
                    availableAmount: this.formatAmount(availableAmount || ZERO_DECIMAL),

                    wishedAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalWishedAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    missingAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalMissingAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    nkkAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalNkkAmount(categoryObjects))
                        : { value: null, formatted: "" },

                    positions: categoryObjects.map((balanceObject) => ({
                        title: this.positionTitleMapper.mapAvailabilityPositionTitle(balanceObject),
                        subtitle: this.positionSubtitleMapper.mapAvailabilityPositionSubtitle(balanceObject),

                        amount: this.formatAmount(balanceObject.amount),
                        availableAmount: balanceObject.availableAmount.value
                            ? this.formatAmount(balanceObject.availableAmount.value)
                            : { value: null, formatted: "-" },
                        availableAmountIncluded: BalanceOutputAvailableAmountIncluded.ALWAYS,

                        wishedAmount: hasPositionBasedAvailability
                            ? this.formatAmount(balanceObject.wishedAmount.wishedAmount)
                            : { value: null, formatted: "" },
                        missingAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectMissingAmount(balanceObject))
                            : { value: null, formatted: "" },

                        nkkAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectNkkAmount(balanceObject))
                            : { value: null, formatted: "" },
                        nkkPercent: hasPositionBasedAvailability
                            ? this.formatPercent(balanceObject.wishedAmount.nkkPercent)
                            : { value: null, formatted: "" },
                    })),
                };
            }

            case BalanceAvailabilityCategoryType.DEFINED: {
                const availableAmount = this.getTotalAvailableAmount(categoryObjects);

                return {
                    title: balanceCategory.title[this.locale],

                    amount: this.formatAmount(this.getTotalAmount(categoryObjects)),
                    availableAmount: availableAmount
                        ? this.formatAmount(availableAmount)
                        : { value: null, formatted: this.translator.translate("availability_noAvailableAmount") },

                    wishedAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalWishedAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    missingAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalMissingAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    nkkAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalNkkAmount(categoryObjects))
                        : { value: null, formatted: "" },

                    positions: categoryObjects.map((balanceObject) => ({
                        title: this.positionTitleMapper.mapAvailabilityPositionTitle(balanceObject),
                        subtitle: this.positionSubtitleMapper.mapAvailabilityPositionSubtitle(balanceObject),

                        amount: this.formatAmount(balanceObject.amount),
                        availableAmount: balanceObject.availableAmount.value
                            ? this.formatAmount(balanceObject.availableAmount.value)
                            : { value: null, formatted: "-" },
                        availableAmountIncluded: BalanceOutputAvailableAmountIncluded.ALWAYS,

                        wishedAmount: hasPositionBasedAvailability
                            ? this.formatAmount(balanceObject.wishedAmount.wishedAmount)
                            : { value: null, formatted: "" },
                        missingAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectMissingAmount(balanceObject))
                            : { value: null, formatted: "" },

                        nkkAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectNkkAmount(balanceObject))
                            : { value: null, formatted: "" },
                        nkkPercent: hasPositionBasedAvailability
                            ? this.formatPercent(balanceObject.wishedAmount.nkkPercent)
                            : { value: null, formatted: "" },
                    })),
                };
            }

            case BalanceAvailabilityCategoryType.SELECTIVE: {
                const availableAmount = this.getTotalAvailableAmount(categoryObjects);

                return {
                    title: balanceCategory.title[this.locale],

                    amount: this.formatAmount(this.getTotalAmount(categoryObjects)),
                    availableAmount: availableAmount
                        ? this.formatAmount(availableAmount)
                        : { value: null, formatted: "-" },

                    wishedAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalWishedAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    missingAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalMissingAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    nkkAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalNkkAmount(categoryObjects))
                        : { value: null, formatted: "" },

                    positions: categoryObjects.map((balanceObject) => ({
                        title: this.positionTitleMapper.mapAvailabilityPositionTitle(balanceObject),
                        subtitle: this.positionSubtitleMapper.mapAvailabilityPositionSubtitle(balanceObject),

                        amount: this.formatAmount(balanceObject.amount),
                        availableAmount: balanceObject.availableAmount.value
                            ? this.formatAmount(balanceObject.availableAmount.value)
                            : { value: null, formatted: "-" },
                        availableAmountIncluded: balanceObject.availableAmount.included
                            ? BalanceOutputAvailableAmountIncluded.YES
                            : BalanceOutputAvailableAmountIncluded.NO,

                        wishedAmount: hasPositionBasedAvailability
                            ? this.formatAmount(balanceObject.wishedAmount.wishedAmount)
                            : { value: null, formatted: "" },
                        missingAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectMissingAmount(balanceObject))
                            : { value: null, formatted: "" },

                        nkkAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectNkkAmount(balanceObject))
                            : { value: null, formatted: "" },
                        nkkPercent: hasPositionBasedAvailability
                            ? this.formatPercent(balanceObject.wishedAmount.nkkPercent)
                            : { value: null, formatted: "" },
                    })),
                };
            }

            case BalanceAvailabilityCategoryType.NONE: {
                return {
                    title: balanceCategory.title[this.locale],

                    amount: this.formatAmount(this.getTotalAmount(categoryObjects)),
                    availableAmount: { value: null, formatted: "-" },

                    wishedAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalWishedAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    missingAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalMissingAmount(categoryObjects))
                        : { value: null, formatted: "" },
                    nkkAmount: hasPositionBasedAvailability
                        ? this.formatAmount(this.getTotalNkkAmount(categoryObjects))
                        : { value: null, formatted: "" },

                    positions: categoryObjects.map((balanceObject) => ({
                        title: this.positionTitleMapper.mapAvailabilityPositionTitle(balanceObject),
                        subtitle: this.positionSubtitleMapper.mapAvailabilityPositionSubtitle(balanceObject),

                        amount: this.formatAmount(balanceObject.amount),
                        availableAmount: { value: null, formatted: "-" },
                        availableAmountIncluded: BalanceOutputAvailableAmountIncluded.NEVER,

                        wishedAmount: hasPositionBasedAvailability
                            ? this.formatAmount(balanceObject.wishedAmount.wishedAmount)
                            : { value: null, formatted: "" },
                        missingAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectMissingAmount(balanceObject))
                            : { value: null, formatted: "" },

                        nkkAmount: hasPositionBasedAvailability
                            ? this.formatAmount(getBalanceObjectNkkAmount(balanceObject))
                            : { value: null, formatted: "" },
                        nkkPercent: hasPositionBasedAvailability
                            ? this.formatPercent(balanceObject.wishedAmount.nkkPercent)
                            : { value: null, formatted: "" },
                    })),
                };
            }

            default:
                return invalidAvailabilityCategory(availabilityType, { balanceCategory });
        }
    }

    private getTotalAmount(
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): Decimal {
        return categoryObjects
            .reduce((result, { amount }) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalAvailableAmount(
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): Decimal | null {
        const valuableObjects = categoryObjects
            .filter(({ availableAmount }) => availableAmount.included)
            .map(({ availableAmount }) => availableAmount.value)
            .filter(isNotNull);

        return valuableObjects.length
            ? valuableObjects.reduce((result, value) => result.add(value), ZERO_DECIMAL)
            : null;
    }

    private getTotalWishedAmount(
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): Decimal {
        return categoryObjects
            .map(({ wishedAmount }) => wishedAmount.wishedAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalMissingAmount(
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): Decimal {
        return categoryObjects
            .map(getBalanceObjectMissingAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalNkkAmount(
        categoryObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): Decimal {
        return categoryObjects
            .map(getBalanceObjectNkkAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getAvailabilityCategory(
        balanceCategory: BalanceCategory,
    ): BalanceAvailabilityCategory {
        return assertDefined(
            balanceCategory.availabilityCategory,
            `No "availabilityCategory" defined for category "${balanceCategory.id}"`,
            { balanceCategory },
        );
    }

    private formatAmount(amount: Decimal): BalanceOutputAvailabilityAmount {
        return {
            value: amount.toNumber(),
            formatted: this.formatter.formatNumber(amount.toNumber(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        };
    }

    private formatPercent(value: Decimal): BalanceOutputAvailabilityAmount {
        return {
            value: value.toNumber(),
            formatted: this.formatter.formatNumber(value.toNumber(), {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        };
    }
}
