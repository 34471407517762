export enum PatchOpType {
    ADD     = "add",
    REPLACE = "replace",
    REMOVE  = "remove",
}

export interface PatchOperation {
    op: PatchOpType;
    path: string;
    value: any;
}

export type PatchRequest = PatchOperation[];
