import * as React from "react";
import { createStructuredSelector } from "reselect";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { ApplicationState } from "src/State/ApplicationState";
import { AssetGraphics } from "src/Components/AssetGraphics/AssetGraphics";
import { AssetGraphicsGroup } from "src/State/AssetGraphics/AssetGraphicsGroup";
import { getAssetGraphicsGroups } from "src/Selectors/Balance/getAssetGraphicsGroups";

type OwnProps = {};
type StateProps = {
    readonly groups: ReadonlyArray<AssetGraphicsGroup>;
};
type DispatchProps = {};

export type AssetGraphicsProps =
    & OwnProps
    & StateProps
    & DispatchProps;

const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    groups: getAssetGraphicsGroups,
});
const mapDispatchToProps: DispatchProps = {};

@connectDecorator<OwnProps, StateProps, DispatchProps>(AssetGraphics, mapStateToProps, mapDispatchToProps)
export class AssetGraphicsPage extends React.Component {}
