import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";
import { isDefined } from "src/Utils/isDefined";
import { IInvestmentToBalanceAccountMapper } from "src/Services/BalanceData/InvestmentToBalanceAccountMapper";
import { IBalanceCategoryMappingMapper } from "src/Services/BalanceData/BalanceCategoryMappingMapper";

export interface IBalanceInvestmentSyncService {
    syncInvestmentToBalanceAccounts(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        investmentAccounts: ReadonlyArray<Graviton.Consultation.Investment.Consultation.Account> | undefined,
    ): Graviton.Consultation.Balance.Balance;
}

export class BalanceInvestmentSyncService implements IBalanceInvestmentSyncService {
    public static $inject: string[] = [
        "evjInvestmentToBalanceAccountMapper",
        "evjBalanceCategoryMappingMapper",
    ];

    public constructor(
        private investmentToBalanceAccountMapper: IInvestmentToBalanceAccountMapper,
        private balanceCategoryMappingMapper: IBalanceCategoryMappingMapper,
    ) {
    }

    public syncInvestmentToBalanceAccounts(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        investmentAccounts: ReadonlyArray<Graviton.Consultation.Investment.Consultation.Account> | undefined,
    ): Graviton.Consultation.Balance.Balance {
        if (!investmentAccounts) {
            return balance;
        }

        const categoryMappings = this.balanceCategoryMappingMapper.mapBalanceCategoryMappings(balance);

        const sharedAccounts = this.getSharedAccountsFromInvestment(
            balance,
            investmentAccounts,
            categoryMappings,
        );
        const balanceAccounts = balance.consultation.balanceAccounts.filter((balanceAccount) =>
            !balanceAccount.isShared);

        return {
            ...balance,
            consultation: {
                ...balance.consultation,
                sharedAccounts: investmentAccounts.filter((account) =>
                    sharedAccounts.some((it) => it.id === account.id)),
                balanceAccounts: [
                    ...balanceAccounts,
                    ...sharedAccounts,
                ],
            },
        };
    }

    private getSharedAccountsFromInvestment(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        investmentAccounts: ReadonlyArray<Graviton.Consultation.Investment.Consultation.Account>,
        categoryMappings: ReadonlyArray<BalanceCategoryMapping>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance[] {
        return investmentAccounts.map((investmentAccount) => {
            const sharedAccount = balance.consultation.balanceAccounts
                .filter((balanceAccount) => balanceAccount.isShared)
                .find((balanceAccount) => balanceAccount.id === investmentAccount.id);
            if (sharedAccount) {
                return this.investmentToBalanceAccountMapper.updateBalanceAccountFromInvestment(
                    sharedAccount,
                    investmentAccount,
                    balance.parameterData.bankList,
                );
            }

            const categoryMapping = categoryMappings.find((mapping) => (
                mapping.assetSegment
                    ? mapping.assetSegment.id === investmentAccount.assetSegment.id
                    : false
            ));
            if (!categoryMapping) {
                return undefined;
            }

            return this.investmentToBalanceAccountMapper.createBalanceAccountFromInvestment(
                investmentAccount,
                categoryMapping,
                balance.parameterData.bankList,
            );
        }).filter(isDefined);
    }
}
