import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { bind } from "lodash-decorators/bind";
import { EvojaHtmlButton, EvojaIcon } from "@evoja-web/uikit";
import { formDecorator, InnerFormProps, OuterFormProps } from "src/Decorators/formDecorator";
import { ResolvedEnitityCode } from "src/State/ResolvedEnitityCode";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { BalanceCreationClass } from "src/State/Balance/BalanceCreationClass";
import { DateboxField } from "src/Components/Form/DateboxField";
import { TextboxField } from "src/Components/Form/TextboxField";
import { DropdownField } from "src/Components/Form/DropdownField";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { CalculatorField } from "src/Components/Form/CalculatorField";
import { isContractForm, isFinancingForm, isOtherForm } from "src/Services/Utils/customerBalanceFormUtils";

type OwnProps = {
    readonly isEditing: boolean;
    readonly formData: CustomerBalanceForm;
    readonly bankList: ReadonlyArray<Graviton.Entity.Code>;
    readonly creationClasses: ReadonlyArray<BalanceCreationClass>;
    readonly onDelete: () => void;
};

class CustomerBalanceFormContentWithForm extends React.Component<InnerFormProps<CustomerBalanceForm, OwnProps>> {
    public render(): JSX.Element {
        const { handleSubmit, submitFailed, submitSucceeded, submitting, invalid } = this.props;
        const { isEditing, bankList, creationClasses, formData, onDelete } = this.props;

        return (
            <form className="m03-customer-balance-form-content" onSubmit={handleSubmit}>
                <div className="m03-customer-balance-form-content__form">
                    <div className="m03-customer-balance-form-content__col">
                        <RenderIf condition={isOtherForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_creditor"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <DropdownField name="bank"
                                                   options={bankList}
                                                   renderOption={renderEntityCode}
                                                   compareValue={compareEntityCode}/>
                                </div>
                            </div>
                        </RenderIf>
                        <RenderIf condition={!isOtherForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_bank"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <DropdownField name="bank"
                                                   options={bankList}
                                                   renderOption={renderEntityCode}
                                                   compareValue={compareEntityCode}/>
                                </div>
                            </div>
                        </RenderIf>

                        <div className="m03-customer-balance-form-content__row">
                            <div className="m03-customer-balance-form-content__label">
                                <FormattedMessage id="customerBalanceForm_title"/>
                            </div>
                            <div className="m03-customer-balance-form-content__input">
                                <TextboxField type="text"
                                              name="title"
                                              autoComplete="off"/>
                            </div>
                        </div>

                        <RenderIf condition={!isOtherForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_rubric"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <TextboxField type="text"
                                                  name="rubric"
                                                  autoComplete="off"/>
                                </div>
                            </div>
                        </RenderIf>
                    </div>
                    <div className="m03-customer-balance-form-content__col">
                        <div className="m03-customer-balance-form-content__row">
                            <div className="m03-customer-balance-form-content__label">
                                <FormattedMessage id="customerBalanceForm_creationClass"/>
                            </div>
                            <div className="m03-customer-balance-form-content__input">
                                <DropdownField name="creationClass"
                                               onChange={this.handleCreationClassChange}
                                               options={creationClasses}
                                               renderOption={renderResolvedEntityCode}
                                               compareValue={compareResolvedEntityCode}/>
                            </div>
                        </div>

                        <RenderIf condition={isFinancingForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_mortgageAmount"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <CalculatorField name="amount"
                                                     placement="bottom"
                                                     formatValue={formatDecimal}/>
                                </div>
                            </div>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_currentMarketPrice"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <CalculatorField name="currentMarketPrice"
                                                     placement="bottom"
                                                     formatValue={formatDecimal}/>
                                </div>
                            </div>
                        </RenderIf>
                        <RenderIf condition={!isFinancingForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_amount"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <CalculatorField name="amount"
                                                     placement="bottom"
                                                     formatValue={formatDecimal}/>
                                </div>
                            </div>
                        </RenderIf>
                        <RenderIf condition={isContractForm(formData)}>
                            <div className="m03-customer-balance-form-content__row">
                                <div className="m03-customer-balance-form-content__label">
                                    <FormattedMessage id="customerBalanceForm_endDate"/>
                                </div>
                                <div className="m03-customer-balance-form-content__input">
                                    <DateboxField name="endDate"
                                                  placement="top"/>
                                </div>
                            </div>
                        </RenderIf>
                    </div>
                </div>
                <div className="m03-customer-balance-form-content__actions">
                    <div className="m03-customer-balance-form-content__action">
                        {isEditing && (
                            <EvojaHtmlButton shape="circle"
                                             block={false}
                                             className="m03-customer-balance-form-content__delete"
                                             onClick={onDelete}>
                                <EvojaIcon icon="trash"/>
                            </EvojaHtmlButton>
                        )}
                    </div>
                    <div className="m03-customer-balance-form-content__action">
                        <EvojaHtmlButton shape="square"
                                         block={false}
                                         type="submit"
                                         disabled={submitFailed ? invalid : submitSucceeded || submitting}>
                            <FormattedMessage id="customerBalanceForm_submit"/>
                        </EvojaHtmlButton>
                    </div>
                </div>
            </form>
        );
    }

    @bind()
    private handleCreationClassChange(event: unknown, creationClass: BalanceCreationClass): void {
        const { formData: currentFormData } = this.props;
        const updatedFormData: CustomerBalanceForm = {
            ...currentFormData,
            creationClass,

            rubric: isOtherForm(currentFormData)
                ? ""
                : currentFormData.rubric,
            endDate: isContractForm(currentFormData)
                ? currentFormData.endDate
                : null,
            currentMarketPrice: isFinancingForm(currentFormData)
                ? currentFormData.currentMarketPrice
                : null,
        };

        const { initialize } = this.props;
        initialize(updatedFormData);
    }
}

@formDecorator<CustomerBalanceForm, OwnProps>(CustomerBalanceFormContentWithForm, {})
export class CustomerBalanceFormContent extends React.Component<OuterFormProps<CustomerBalanceForm, OwnProps>> {}


function renderEntityCode(option: Readonly<Graviton.Entity.Code>): JSX.Element {
    return <TranslatedMessage value={option.text}/>;
}
function renderResolvedEntityCode(option: ResolvedEnitityCode<unknown>): JSX.Element {
    return <TranslatedMessage value={option.code.text}/>;
}

function compareEntityCode(
    option: Readonly<Graviton.Entity.Code>,
    value: Readonly<Graviton.Entity.Code>,
): boolean {
    return option.id === value.id;
}
function compareResolvedEntityCode(
    option: ResolvedEnitityCode<unknown>,
    value: ResolvedEnitityCode<unknown>,
): boolean {
    return option.type === value.type;
}

function formatDecimal(value: Decimal | null): JSX.Element | null {
    return value ? <FormattedNumber value={value.toNumber()}/> : null;
}

type RenderIfProps = {
    readonly condition: boolean;
    readonly children: React.ReactNode;
};
function RenderIf({ condition, children }: RenderIfProps): JSX.Element | null {
    return condition ? <>{children}</> : null;
}
