import { ConfigurationState } from "src/State/ConfigurationState";
import { CONFIGURATION_ACTIONS, ConfigurationActions } from "src/Actions/ConfigurationActions";

const defaultState: ConfigurationState =  {
    loading: false,
    error: undefined,
    configuration: undefined,
};
export function configurationReducer(
    state: ConfigurationState = defaultState,
    action: ConfigurationActions,
): ConfigurationState {
    switch (action.type) {
        case CONFIGURATION_ACTIONS.PENDING:
            return {...state, loading: true, configuration: undefined, error: undefined};

        case CONFIGURATION_ACTIONS.SUCCESS:
            return {...state, loading: false, configuration: action.data, error: undefined};

        case CONFIGURATION_ACTIONS.FAILURE:
            return {...state, loading: false, configuration: undefined, error: action.data};

        default:
            return state;
    }
}
