import { Provider } from "@xiag/container";
import { OutputLiquidityMapper } from "src/Services/Output/OutputLiquidityMapper";
import { OutputAvailabilityMapper } from "src/Services/Output/OutputAvailabilityMapper";
import { OutputProductsMapper } from "src/Services/Output/OutputProductsMapper";
import { OutputJsonGenerator } from "src/Services/Output/OutputJsonGenerator";
import { OutputCategoryPositionMapper } from "src/Services/Output/OutputCategoryPositionMapper";
import {
    OutputPositionAccountNormalMapper,
} from "src/Services/Output/OutputPositionMappers/OutputPositionAccountNormalMapper";
import {
    OutputPositionAccountMetalMapper,
} from "src/Services/Output/OutputPositionMappers/OutputPositionAccountMetalMapper";
import {
    OutputPositionAccountDepositMapper,
} from "src/Services/Output/OutputPositionMappers/OutputPositionAccountDepositMapper";
import {
    OutputPositionCustomerBalanceMapper,
} from "src/Services/Output/OutputPositionMappers/OutputPositionCustomerBalanceMapper";
import {
    OutputPositionFinancingMapper,
} from "src/Services/Output/OutputPositionMappers/OutputPositionFinancingMapper";
import { OutputPositionContractMapper } from "src/Services/Output/OutputPositionMappers/OutputPositionContractMapper";
import {
    OutputAvailabilityTotalMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityTotalMapper";
import {
    OutputAvailabilityCategoryMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityCategoryMapper";
import {
    OutputAvailabilityOverdrawMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityOverdrawMapper";
import {
    OutputAvailabilityPositionTitleMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityPositionTitleMapper";
import {
    OutputAvailabilityPositionSubtitleMapper,
} from "src/Services/Output/OutputAvailabilityMappers/OutputAvailabilityPositionSubtitleMapper";

export const OUTPUT_JSON_PROVIDER = new Provider([])
    .service(
        "evjOutputLiquidityMapper",
        OutputLiquidityMapper,
        OutputLiquidityMapper.$inject || [],
    )
    .service(
        "evjOutputAvailabilityMapper",
        OutputAvailabilityMapper,
        OutputAvailabilityMapper.$inject || [],
    )
    .service(
        "evjOutputAvailabilityTotalMapper",
        OutputAvailabilityTotalMapper,
        OutputAvailabilityTotalMapper.$inject,
    )
    .service(
        "evjOutputAvailabilityCategoryMapper",
        OutputAvailabilityCategoryMapper,
        OutputAvailabilityCategoryMapper.$inject,
    )
    .service(
        "evjOutputAvailabilityOverdrawMapper",
        OutputAvailabilityOverdrawMapper,
        OutputAvailabilityOverdrawMapper.$inject,
    )
    .service(
        "evjOutputAvailabilityPositiontTitleMapper",
        OutputAvailabilityPositionTitleMapper,
        OutputAvailabilityPositionTitleMapper.$inject,
    )
    .service(
        "evjOutputAvailabilityPositiontSubtitleMapper",
        OutputAvailabilityPositionSubtitleMapper,
        OutputAvailabilityPositionSubtitleMapper.$inject,
    )
    .service(
        "evjOutputProductsMapper",
        OutputProductsMapper,
        OutputProductsMapper.$inject || [],
    )
    .service(
        "evjOutputCategoryPositionMapper",
        OutputCategoryPositionMapper,
        OutputCategoryPositionMapper.$inject || [],
    )
    .service(
        "evjOutputPositionAccountNormalMapper",
        OutputPositionAccountNormalMapper,
        OutputPositionAccountNormalMapper.$inject || [],
    )
    .service(
        "evjOutputPositionAccountMetalMapper",
        OutputPositionAccountMetalMapper,
        OutputPositionAccountMetalMapper.$inject || [],
    )
    .service(
        "evjOutputPositionAccountDepositMapper",
        OutputPositionAccountDepositMapper,
        OutputPositionAccountDepositMapper.$inject || [],
    )
    .service(
        "evjOutputPositionCustomerBalanceMapper",
        OutputPositionCustomerBalanceMapper,
        OutputPositionCustomerBalanceMapper.$inject || [],
    )
    .service(
        "evjOutputPositionContractMapper",
        OutputPositionContractMapper,
        OutputPositionContractMapper.$inject || [],
    )
    .service(
        "evjOutputPositionFinancingMapper",
        OutputPositionFinancingMapper,
        OutputPositionFinancingMapper.$inject || [],
    )
    .service(
        "evjOutputJsonGenerator",
        OutputJsonGenerator,
        OutputJsonGenerator.$inject || [],
    )
;
