export enum BalanceDisplayClassType {
    ACCOUNT_NORMAL = "accountNormal",
    ACCOUNT_METAL = "accountMetal",
    ACCOUNT_DEPOSIT = "accountDeposit",
    ACCOUNT_DEPOT = "accountDepot",
    ACCOUNT_EXCHANGE = "accountExchange",
    FINANCING = "financing",
    FINANCING_DEBT = "financingDebt",
    CUSTOMER_BALANCE = "customerBalance",
    NEW_ENGAGEMENT = "newEngagement",
    NEW_CONTRACT = "newContract",
}
export enum BalanceOutputClassType {
    ACCOUNT_NORMAL = "accountNormal",
    ACCOUNT_METAL = "accountMetal",
    ACCOUNT_DEPOSIT = "accountDeposit",
    ACCOUNT_DEPOT = "accountDepot",
    ACCOUNT_EXCHANGE = "accountExchange",
    FINANCING = "financing",
    FINANCING_DEBT = "financingDebt",
    CUSTOMER_BALANCE = "customerBalance",
    NEW_ENGAGEMENT = "newEngagement",
    NEW_CONTRACT = "newContract",
}
export enum BalanceDetailGroup {
    CURRENCY = "currency",
    PLEDGES = "pledges",
    LIMITS = "limits",
    DEPOT_DETAILS = "depotdetails",
    MORTGAGE = "mortgage",
    WITHDRAWAL_LIMITS = "withdrawalLimits",
    CANCELLATIONS = "cancellations",
    BALANCE_DETAILS = "balanceDetails",
}
