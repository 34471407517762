import { SagaIterator } from "redux-saga";
import { takeLatest } from "redux-saga/effects";
import {
    CUSTOMER_BALANCE_CREATE_ACTIONS,
    CUSTOMER_BALANCE_DELETE_ACTIONS,
    CUSTOMER_BALANCE_UPDATE_ACTIONS,
} from "src/Actions/CustomerBalanceActions";
import { customerBalanceCreateSaga } from "src/Sagas/CustomerBalance/customerBalanceCreateSaga";
import { customerBalanceDeleteSaga } from "src/Sagas/CustomerBalance/customerBalanceDeleteSaga";
import { customerBalanceUpdateSaga } from "src/Sagas/CustomerBalance/customerBalanceUpdateSaga";

export function* customerBalanceSaga(): SagaIterator {
    yield takeLatest(CUSTOMER_BALANCE_CREATE_ACTIONS.REQUEST, customerBalanceCreateSaga);
    yield takeLatest(CUSTOMER_BALANCE_DELETE_ACTIONS.REQUEST, customerBalanceDeleteSaga);
    yield takeLatest(CUSTOMER_BALANCE_UPDATE_ACTIONS.REQUEST, customerBalanceUpdateSaga);
}
