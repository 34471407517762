import { Provider } from "@xiag/container";
import { BalancePatchMapper } from "src/Services/SynchronizationData/BalancePatchMapper";
import { ChecklistPatchMapper } from "src/Services/SynchronizationData/ChecklistPatchMapper";
import { SynchronizationMapper } from "src/Services/SynchronizationData/SynchronizationMapper";
import { BalanceAvailabilityMapper } from "src/Services/SynchronizationData/BalanceAvailabilityMapper";
import { BalanceLiquidityMapper } from "src/Services/SynchronizationData/BalanceLiquidityMapper";
import { BalanceAccountsMapper } from "src/Services/SynchronizationData/BalanceAccountsMapper";
import { BalanceSharedAccountsMapper } from "src/Services/SynchronizationData/BalanceSharedAccountsMapper";
import { InvestmentAccountsPatchMapper } from "src/Services/SynchronizationData/InvestmentAccountsPatchMapper";

export const SYNCHRONIZATION_DATA_PROVIDER = new Provider([])
    .service(
        "evjBalanceAvailabilityMapper",
        BalanceAvailabilityMapper,
        BalanceAvailabilityMapper.$inject || [],
    )
    .service(
        "evjBalanceLiquidityMapper",
        BalanceLiquidityMapper,
        BalanceLiquidityMapper.$inject || [],
    )
    .service(
        "evjBalanceAccountsMapper",
        BalanceAccountsMapper,
        BalanceAccountsMapper.$inject || [],
    )
    .service(
        "evjBalanceSharedAccountsMapper",
        BalanceSharedAccountsMapper,
        BalanceSharedAccountsMapper.$inject || [],
    )
    .service(
        "evjBalancePatchMapper",
        BalancePatchMapper,
        BalancePatchMapper.$inject || [],
    )
    .service(
        "evjChecklistPatchMapper",
        ChecklistPatchMapper,
        ChecklistPatchMapper.$inject || [],
    )
    .service(
        "evjInvestmentAccountsPatchMapper",
        InvestmentAccountsPatchMapper,
        InvestmentAccountsPatchMapper.$inject || [],
    )
    .service(
        "evjSynchronizationMapper",
        SynchronizationMapper,
        SynchronizationMapper.$inject || [],
    );
