import { mapValues } from "lodash-es";
import enMessages from "static/translations/en.po";
import deMessages from "static/translations/de.po";
import frMessages from "static/translations/fr.po";
import { MapOf } from "src/Utils/MapOf";
import { Locale } from "src/State/Parameters/Locale";

export type I18nMessages = {
    readonly [key in Locale]: MapOf<string>;
};

export const I18N_MESSAGES = {
    en: mapValues(enMessages || {}, ([, value]) => value),
    de: mapValues(deMessages || {}, ([, value]) => value),
    fr: mapValues(frMessages || {}, ([, value]) => value),
};
