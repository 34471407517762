import * as React from "react";
import { noop } from "lodash-es";
import { bind } from "lodash-decorators/bind";
import { memoize } from "lodash-decorators/memoize";
import { Decimal } from "decimal.js-light";
import {
    EvojaCalculator,
    EvojaPlacement,
    EvojaPopover,
    EvojaPopoverContent,
    EvojaPopoverContext,
    EvojaPopoverTarget,
    EvojaStaticField,
} from "@evoja-web/uikit";
import { classList } from "src/Utils/classList";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";

type Props = {
    readonly id: string;
    readonly placement: EvojaPlacement;
    readonly name?: string;
    readonly disabled?: boolean;
    readonly invalid?: boolean;
    readonly className?: string;

    readonly value: Decimal | null;
    readonly onChange?: (value: Decimal | null) => void;

    readonly formatValue?: (value: Decimal | null) => React.ReactNode;
    readonly formatInput?: (value: string) => React.ReactNode;

    readonly hasReset?: boolean;
    readonly hasPoint?: boolean;
    readonly hasEnter?: boolean;
    readonly hasMinus?: boolean;

    readonly onOpenPopover?: () => void;
    readonly onClosePopover?: () => void;
};

export class CalculatorDropdown extends React.Component<Props> {
    public render(): JSX.Element {
        const { id, placement, onOpenPopover, onClosePopover } = this.props;
        const { disabled } = this.props;

        return (
            <EvojaPopover id={id}
                          placement={placement}
                          disabled={disabled}
                          onOpenPopover={onOpenPopover}
                          onClosePopover={onClosePopover}>
                <EvojaPopoverTarget>
                    {this.renderValue}
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    {this.renderPopover}
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    @bind()
    private renderValue(context: EvojaPopoverContext): JSX.Element {
        const { value, disabled, invalid, name, className, formatValue = formatValueDecimal } = this.props;

        return (
            <EvojaStaticField ref={context.targetRef}
                              onClick={context.togglePopover}
                              name={name}
                              disabled={disabled}
                              invalid={invalid}
                              className={classList(className, "m03-calculator-dropdown__value")}>
                {formatValue(value)}
            </EvojaStaticField>
        );
    }

    @bind()
    private renderPopover(context: EvojaPopoverContext): JSX.Element {
        const { value, disabled, invalid, name, hasEnter, hasMinus, hasPoint, hasReset } = this.props;
        const { formatInput = formatInputDecimal } = this.props;

        return (
            <EvojaCalculator ref={context.contentRef}
                             name={name ? `${name}-calculator` : undefined}
                             value={value}
                             disabled={disabled}
                             invalid={invalid}
                             className="m03-calculator-dropdown__field"
                             onChange={this.getChangeHandler(context.closePopover)}
                             format={formatInput}
                             hasEnter={hasEnter}
                             hasMinus={hasMinus}
                             hasPoint={hasPoint}
                             hasReset={hasReset}/>
        );
    }

    @memoize()
    private getChangeHandler(
        closePopover: () => void,
    ): (value: Decimal | null) => void {
        return (value) => {
            const { disabled } = this.props;
            if (disabled) {
                closePopover();
                return;
            }

            const { onChange = noop } = this.props;
            onChange(value);
            closePopover();
        };
    }
}

function formatValueDecimal(value: Decimal | null): JSX.Element | null {
    return value ? <FormattedNumber value={value.toNumber()}/> : null;
}
function formatInputDecimal(input: string): React.ReactNode {
    if (!input.length) {
        return "";
    }

    const decimal = new Decimal(input);
    const points = input.includes(".")
        ? input.length - input.lastIndexOf(".") - 1
        : 0;

    return (
        <FormattedNumber value={decimal.toNumber()}
                         minimumFractionDigits={points}
                         maximumFractionDigits={points}/>
    );
}
