import { Translatable } from "src/State/Translatable";
import { MapOf } from "src/Utils/MapOf";
import { replaceAll } from "src/Utils/replaceAll";

export function replaceTranslatable(
    pattern: Translatable,
    replacements: MapOf<string>,
): Translatable {
    return ["de", "en", "fr"].reduce<Translatable>((result, locale) => ({
        ...result,
        [locale]: replaceAll(pattern[locale], replacements),
    }), {});
}
