import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { balanceObjectSum } from "src/Services/Utils/balanceObjectSum";

export function balanceTotalSum(
    categories: ReadonlyArray<BalanceCategory>,
    objects: ReadonlyArray<BalanceObject>,
): Decimal {
    return categories.reduce((result, category) =>
        result.add(balanceObjectSum(objects.filter((it) => it.category.id === category.id))),
        ZERO_DECIMAL,
    );
}
