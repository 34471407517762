import { Query } from "@xiag/rql-query";
import { IAllEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { FileConfiguration, FileConfigurationItem } from "src/State/Configuration/FileConfiguration";

export interface IFileConfigurationService {
    fetchFileConfiguration(): Promise<FileConfiguration>;
}

export class FileConfigurationService implements IFileConfigurationService {
    public static $inject: ReadonlyArray<string> = [
        "evjApiEndpointFile",
    ];
    public constructor(
        private apiEndpointFile: IAllEndpoint<Graviton.File.File>,
    ) {
    }

    public async fetchFileConfiguration(): Promise<FileConfiguration> {
        const [
            withdrawalConditionFiles,
            provisionInfoFiles,
        ] = await Promise.all([
            this.getButtonFileIds("button-balanceWithdrawalCondition"),
            this.getButtonFileIds("button-balanceProvisionInfo"),
        ]);

        return {
            withdrawalConditionFiles,
            provisionInfoFiles,
        };
    }

    private async getButtonFileIds(buttonId: string): Promise<FileConfigurationItem[]> {
        const { data: files } = await this.apiEndpointFile.all(
            new Query()
                .select(["id", "metadata.filename"])
                .elemMatch("metadata.additionalProperties", (condition) => condition
                    .eq("name", "button")
                    .eq("value", buttonId))
        );

        return files.map((file) => ({
            id: file.id,
            name: file.metadata.filename || "",
        }));
    }
}
