import { Decimal } from "decimal.js-light";
import { isDefined } from "src/Utils/isDefined";
import { assertDefined } from "src/Utils/assertionHelpers";
import { LogicError } from "src/Errors/LogicError";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { BalanceAddition } from "src/State/Balance/BalanceAddition";
import { BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { BalanceObjectFinancingTranche } from "src/State/Balance/BalanceObjectFinancingTranche";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { IBalanceObjectMapper } from "src/Services/BalanceData/BalanceObjectMapper";
import { IBalanceObjectAdditionMapper } from "src/Services/BalanceData/BalanceObjectAdditionMapper";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";

export class BalanceObjectFinancingMapper implements IBalanceObjectMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjBalanceObjectAdditionMapper",
    ];
    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private objectAdditionMapper: IBalanceObjectAdditionMapper,
    ) {
    }

    public mapBalanceObjects(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceObjectFinancing[] {
        const financingObjects = balance.stockData.financings.map((financing): BalanceObjectFinancing | undefined => {
            const { balanceCategory } = financing;
            if (!balanceCategory) {
                return undefined;
            }

            const category = assertDefined(
                this.getBalanceCategory(balanceCategory, categories),
                `Could not find balanceCategory "${balanceCategory.id}"`,
                { categories, balanceCategory },
            );
            const addition = financing.realEstateType
                ? this.objectAdditionMapper.mapAddition(
                    financing.realEstateType,
                    balance.parameterData,
                )
                : undefined;

            return {
                id: financing.id,
                mainId: undefined,
                financing: financing,
                tranches: this.mapTranches(financing, balance),
                category: category,
                portfolio: this.findPortfolio(financing, balance),
                amount: this.mapAmount(financing, balance),

                displayClassType: this.ensureDisplayClass(financing, category, addition),
                outputClassType: this.ensureOutputClass(financing, category, addition),
                detailGroupTypes: addition && addition.detailGroupTypes.length > 0
                    ? addition.detailGroupTypes
                    : category.detailGroupTypes,
            };
        }).filter(isDefined);

        return financingObjects.reduce((result, financing) => {
            if (financing.financing.currentMarketPrice && financing.category.isRealEstate) {
                const category = categories.find((it) => it.isRealEstate && it.type === BalanceCategoryType.ASSETS);
                if (category) {
                    const realEstateAsset: BalanceObjectFinancing = {
                        ...financing,
                        id: `${financing.id}/duplicate`,
                        mainId: financing.id,
                        category: category,
                        amount: new Decimal(financing.financing.currentMarketPrice),
                    };
                    return result.concat(financing, realEstateAsset);
                }
            }

            return result.concat(financing);
        }, [] as BalanceObjectFinancing[]);
    }

    private getBalanceCategory(
        coreType: Readonly<Graviton.Entity.Code>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceCategory | undefined {
        const coreTypeRef = this.extRefGenerator.createRef(
            EndpointName.ENTITY_CODE,
            coreType.id,
        );

        return categories
            .find(({ coreTypes }) => coreTypes
                .some(({ $ref }) => this.extRefComparator.compareRefs($ref, coreTypeRef)));
    }

    private ensureDisplayClass(
        financing: Readonly<Graviton.Consultation.Investment.Stock.Account>,
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): BalanceObjectFinancing["displayClassType"] {
        const displayClass = addition
            ? addition.displayClassType
            : category.displayClassType;
        switch (displayClass) {
            case BalanceDisplayClassType.FINANCING:
                return displayClass;

            default:
                throw new LogicError(
                    `Unexpected display class type "${displayClass}" for financing`,
                    { displayClass, financing, category, addition },
                );
        }
    }

    private ensureOutputClass(
        financing: Readonly<Graviton.Consultation.Investment.Stock.Account>,
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): BalanceObjectFinancing["outputClassType"] {
        const outputClass = addition
            ? addition.outputClassType
            : category.outputClassType;
        switch (outputClass) {
            case BalanceOutputClassType.FINANCING:
                return outputClass;

            default:
                throw new LogicError(
                    `Unexpected output class type "${outputClass}" for financing`,
                    { outputClass, financing, category, addition },
                );
        }
    }

    private mapTranches(
        financing: Readonly<Graviton.Consultation.Investment.Stock.Account>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceObjectFinancingTranche[] {
        return balance.stockData.financingTranches
            .filter((tranche) => tranche.orderId === financing.orderId)
            .sort((a, b) => a.sort - b.sort)
            .map((tranche) => ({
                financingTranche: tranche,
                loanProduct: assertDefined(
                    this.findCodeByRef(balance.parameterData.loanProducts, tranche.loanProduct.$ref),
                    `Could not find loanProduct "${tranche.loanProduct.$ref}"`,
                    { tranche, financing, loanProducts: balance.parameterData.loanProducts },
                ),
            }));
    }

    private mapAmount(
        financing: Readonly<Graviton.Consultation.Investment.Stock.Account>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Decimal {
        return balance.stockData.financingTranches
            .filter((tranche) => tranche.orderId === financing.orderId)
            .reduce((result, tranche) => result.add(tranche.amount), ZERO_DECIMAL);
    }

    private findCodeByRef(
        codes: ReadonlyArray<Graviton.Entity.Code>,
        $ref: Graviton.Common.ExtReference<Graviton.Entity.Code>,
    ): Graviton.Entity.Code | undefined {
        return codes.find((code) => this.extRefComparator.compareRefs(
            $ref,
            this.extRefGenerator.createRef(EndpointName.ENTITY_CODE, code.id),
        ));
    }

    private findPortfolio(
        financing: Readonly<Graviton.Consultation.Investment.Stock.Account>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalancePortfolio | undefined {
        const portfolioRef = financing.portfolio?.$ref;
        if (!portfolioRef) {
            return undefined;
        }

        return balance.stockData.portfolios.find((it) => this.extRefComparator.compareRefs(
            portfolioRef,
            this.extRefGenerator.createRef(EndpointName.ACCOUNT_GROUP, it.id),
        ));
    }
}
