import { Provider } from "@xiag/container";
import { UuidGenerator } from "src/Services/Utils/UuidGenerator";
import { DateConverter } from "src/Services/Utils/DateConverter";

export const UTILS_PROVIDER = new Provider([])
    .service(
        "evjUuidGenerator",
        UuidGenerator,
        UuidGenerator.$inject || [],
    )
    .service(
        "evjDateConverter",
        DateConverter,
        DateConverter.$inject || [],
    );
