import { v4 } from "uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";
import { CUSTOMER_BALANCE_CREATE_ACTIONS, CustomerBalanceCreateRequest } from "src/Actions/CustomerBalanceActions";
import { getBalanceCategories } from "src/Selectors/Balance/getBalanceCategories";
import { getBalanceCategoryMappings } from "src/Selectors/Balance/getBalanceCategoryMappings";
import { IBalanceObjectCreateService } from "src/Services/BalanceData/BalanceObjectCreateService";
import { selectService } from "src/Modules/Bind/selectService";
import { customerBalanceCreateRealEstateSaga } from "src/Sagas/CustomerBalance/customerBalanceCreateRealEstateSaga";

export function* customerBalanceCreateSaga(
    { data: { formData } }: CustomerBalanceCreateRequest,
): SagaIterator {
    try {
        const objectCreateService: IBalanceObjectCreateService = yield select(
            selectService,
            "evjBalanceObjectCreateService",
        );
        const balanceCategories: BalanceCategory[] = yield select(getBalanceCategories);
        const balanceCategoryMappings: BalanceCategoryMapping[] = yield select(getBalanceCategoryMappings);
        const balanceObjectId: string = yield call(v4);
        const created: BalanceObjectCustomerBalance = yield call({
            fn: objectCreateService.create,
            context: objectCreateService,
        }, balanceObjectId, balanceCategories, balanceCategoryMappings, formData);
        yield put(CUSTOMER_BALANCE_CREATE_ACTIONS.success(created));

        if (created.customerBalance.currentMarketPrice) {
            const createdRealEstateAsset: BalanceObjectCustomerBalance = yield call(
                customerBalanceCreateRealEstateSaga,
                created,
            );
            yield put(CUSTOMER_BALANCE_CREATE_ACTIONS.success(createdRealEstateAsset));
        }

        yield put(SYNCHRONIZATION_ACTIONS.request(null));
    } catch (error) {
        yield put(CUSTOMER_BALANCE_CREATE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
