import { Decimal } from "decimal.js-light";
import { ActionAny, ActionRequest, createActions } from "src/Utils/createActions";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";

export type AvailabilityChangeWishedAmountData = {
    readonly object: BalanceObjectAccountAsset;
    readonly amount: Decimal;
};
export type AvailabilityChangeWishedAmountRequest
    = ActionRequest<AvailabilityChangeWishedAmountData>;
export type AvailabilityChangeWishedAmountActions
    = ActionAny<AvailabilityChangeWishedAmountData, BalanceObjectAccountAsset>;

export const AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS
    = createActions<AvailabilityChangeWishedAmountData, BalanceObjectAccountAsset>("AVAILABILITY_CHANGE_WISHED_AMOUNT");


export type AvailabilityChangeNkkPercentData = {
    readonly object: BalanceObjectAccountAsset;
    readonly nkkPercent: Decimal;
};
export type AvailabilityChangeNkkPercentRequest
    = ActionRequest<AvailabilityChangeNkkPercentData>;
export type AvailabilityChangeNkkPercentActions
    = ActionAny<AvailabilityChangeNkkPercentData, BalanceObjectAccountAsset>;

export const AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS
    = createActions<AvailabilityChangeNkkPercentData, BalanceObjectAccountAsset>("AVAILABILITY_CHANGE_NKK_PERCENT");


export type AvailabilityChangeObjectAmountData = {
    readonly object: BalanceObjectAccountAsset;
    readonly amount: Decimal | null;
};
export type AvailabilityChangeObjectAmountRequest
    = ActionRequest<AvailabilityChangeObjectAmountData>;
export type AvailabilityChangeObjectAmountActions
    = ActionAny<AvailabilityChangeObjectAmountData, BalanceObjectAccountAsset>;

export const AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS
    = createActions<AvailabilityChangeObjectAmountData, BalanceObjectAccountAsset>("AVAILABILITY_CHANGE_OBJECT_AMOUNT");


export type AvailabilityToggleObjectAmountData = {
    readonly object: BalanceObjectAccountAsset;
    readonly included: boolean;
};
export type AvailabilityToggleObjectAmountRequest
    = ActionRequest<AvailabilityToggleObjectAmountData>;
export type AvailabilityToggleObjectAmountActions
    = ActionAny<AvailabilityToggleObjectAmountData, BalanceObjectAccountAsset>;

export const AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS
    = createActions<AvailabilityToggleObjectAmountData, BalanceObjectAccountAsset>("AVAILABILITY_TOGGLE_OBJECT_AMOUNT");
