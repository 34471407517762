import * as React from "react";
import { ErrorInfo } from "react";
import { EvojaError, EvojaErrorCode, EvojaErrorTitle } from "@evoja-web/uikit";
import { BaseError } from "src/Errors/BaseError";
import { HttpError } from "src/Errors/HttpError";
import { LogicError } from "src/Errors/LogicError";
import { RuntimeError } from "src/Errors/RuntimeError";

type Props = {
    error: BaseError;
    errorInfo: ErrorInfo | undefined;
};
export function ErrorMessage({ error, errorInfo }: Props): JSX.Element {
    return (
        <EvojaError className="m03-error-message">
            {renderError(error, errorInfo)}
        </EvojaError>
    );
}

function renderError(error: BaseError, errorInfo: ErrorInfo | undefined): JSX.Element {
    return (
        <div className="m03-error-message__block">
            <EvojaErrorTitle className="m03-error-message__title">
                <b>{error.name}</b>. {error.message}
            </EvojaErrorTitle>
            {renderDetails(error)}
            {renderStackTrace(error)}
            {renderErrorInfo(errorInfo)}
            {renderPrevious(error)}
        </div>
    );
}
function renderPrevious(error: BaseError): JSX.Element | null {
    if (!error.previous) {
        return null;
    }

    return (
        <div className="m03-error-message__previous">
            {renderError(error.previous, undefined)}
        </div>
    );
}

function renderDetails(error: BaseError): JSX.Element | null {
    if (error instanceof LogicError) {
        return (
            <details open={false} className="m03-error-message__details">
                <summary>Context</summary>
                <EvojaErrorCode>{JSON.stringify(error.context, null, 2)}</EvojaErrorCode>
            </details>
        );
    } else if (error instanceof RuntimeError) {
        return (
            <details open={false} className="m03-error-message__details">
                <summary>Context</summary>
                <EvojaErrorCode>{JSON.stringify(error.context, null, 2)}</EvojaErrorCode>
            </details>
        );
    } else if (error instanceof HttpError) {
        return (
            <details open={false} className="m03-error-message__details">
                <summary>Details</summary>
                <EvojaErrorCode>{JSON.stringify(error.response, null, 2)}</EvojaErrorCode>
            </details>
        );
    } else {
        return null;
    }
}
function renderStackTrace(error: BaseError): JSX.Element | null {
    return (
        <details open={false} className="m03-error-message__details">
            <summary>Stacktrace</summary>
            <EvojaErrorCode>{error.stack}</EvojaErrorCode>
        </details>
    );
}
function renderErrorInfo(errorInfo: ErrorInfo | undefined): JSX.Element | null {
    if (!errorInfo) {
        return null;
    }

    return (
        <details open={false} className="m03-error-message__details">
            <summary>Component stack</summary>
            <EvojaErrorCode>{errorInfo ? errorInfo.componentStack : ""}</EvojaErrorCode>
        </details>
    );
}
