import { BalanceData } from "src/State/Balance/BalanceData";
import {
    AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS,
    AvailabilityChangeWishedAmountActions,
} from "src/Actions/AvailabilityActions";

export function availabilityChangeWishedAmountReducer(
    state: BalanceData,
    action: AvailabilityChangeWishedAmountActions,
): BalanceData {
    switch (action.type) {
        case AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS.SUCCESS:
            return {
                ...state,
                objects: state.objects.map((object) => (
                    object.id === action.data.id
                        ? action.data
                        : object
                )),
            };

        default:
            return state;
    }
}
