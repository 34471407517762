import * as React from "react";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Omit } from "mapped-types-utils";
import { EvojaPlacement } from "@evoja-web/uikit";
import { isFieldInvalid } from "src/Utils/isFieldInvalid";
import { DateboxDropdown } from "src/Components/Common/DateboxDropdown";

const nullFocusEvent = null as any as React.FocusEvent<any>;

type DateboxProps = {
    readonly name: string;
    readonly placement: EvojaPlacement;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly formatValue?: (value: Date) => string;
};

function DateboxComponent(props: WrappedFieldProps & DateboxProps): JSX.Element {
    const { input, meta, disabled = false } = props;
    const { formatValue, placement } = props;

    const invalid = isFieldInvalid(meta, { disabled });
    const value = ensureValueType(input.value);

    const onOpen = () => input.onFocus(nullFocusEvent);
    const onClose = () => input.onBlur(undefined);
    const onChange = (date: Date) => {
        input.onChange(date);
        input.onBlur(undefined);
    };

    return (
        <DateboxDropdown id={`field-${meta.form}-${input.name}`}
                         placement={placement}
                         onOpenPopover={onOpen}
                         onClosePopover={onClose}
                         invalid={invalid}
                         disabled={disabled}
                         formatValue={formatValue}
                         name={input.name}
                         value={value}
                         onChange={onChange}/>
    );
}

function ensureValueType(value: unknown): Date | null {
    return value instanceof Date ? value : null;
}

type Props = Omit<BaseFieldProps<DateboxProps> & DateboxProps, "component">;
export function DateboxField(props: Props): JSX.Element {
    return <Field component={DateboxComponent} format={null} {...props}/>;
}
