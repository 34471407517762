import { ILogger } from "src/Modules/Logger/Logger";

IosLoggerDecorator.$inject = [
    "$delegate",
    "evjIosLogger",
    "evjIosCommunicationEnabled",
];
export function IosLoggerDecorator(
    $logger: ILogger,
    iosLogger: ILogger,
    iosCommunicationEnabled: boolean,
): ILogger {
    return iosCommunicationEnabled ? iosLogger : $logger;
}
