import * as React from "react";
import { EvojaSliderNavigation } from "@evoja-web/uikit";
import { Slider } from "src/Components/Common/Slider";
import { AssetGraphicsChart } from "src/Components/AssetGraphics/AssetGraphicsChart";
import { AssetGraphicsGroup } from "src/State/AssetGraphics/AssetGraphicsGroup";

type Props = {
    readonly groups: ReadonlyArray<AssetGraphicsGroup>;
    readonly isInvestable: boolean;
    readonly inPercents: boolean;
    readonly activeSlide: number;
    readonly onChange: (index: number) => void;
};

export function AssetGraphicsSlider({ groups, activeSlide, onChange, isInvestable, inPercents }: Props): JSX.Element {
    if (groups.length === 1) {
        return (
            <div className="m03-asset-graphics-slider m03-asset-graphics-slider--no-slide">
                <div className="m03-asset-graphics-slider__slide">
                    <AssetGraphicsChart group={groups[0]}
                                        isInvestable={isInvestable}
                                        inPercents={inPercents}/>
                </div>
            </div>
        );
    }

    return (
        <div className="m03-asset-graphics-slider">
            <Slider activeSlide={activeSlide}
                    onChangeSlide={onChange}
                    className="m03-asset-graphics-slider__slider">
                {groups.map((group) => (
                    <div key={group.type} className="m03-asset-graphics-slider__slide">
                        <AssetGraphicsChart group={group}
                                            isInvestable={isInvestable}
                                            inPercents={inPercents}/>
                    </div>
                ))}
            </Slider>
            <div className="m03-asset-graphics-slider__nav">
                <EvojaSliderNavigation showArrows={true}
                                       activeSlide={activeSlide}
                                       slideCount={groups.length}
                                       onChangeSlide={onChange}/>
            </div>
        </div>
    );
}
