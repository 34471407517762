import * as React from "react";
import { createStructuredSelector } from "reselect";
import { Translatable } from "src/State/Translatable";
import { ApplicationState } from "src/State/ApplicationState";
import { connectDecorator } from "src/Decorators/connectDecorator";
import { HomeScreen } from "src/Components/Home/HomeScreen";
import { BalanceObject, BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { getAssetCategories } from "src/Selectors/Balance/getAssetCategories";
import { getLiabilityCategories } from "src/Selectors/Balance/getLiabilityCategories";
import { getCreationDate } from "src/Selectors/getCreationDate";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";
import { getBankName } from "src/Selectors/Configuration/getBankName";
import { getIsModalOpen } from "src/Selectors/getIsModalOpen";
import { uiModalClose, uiModalOpen } from "src/Actions/UiActions";
import { getAssetFormInitialData } from "src/Selectors/Balance/getAssetFormInitialData";
import { getLiabilityFormInitialData } from "src/Selectors/Balance/getLiabilityFormInitialData";
import {
    CUSTOMER_BALANCE_CREATE_ACTIONS,
    CUSTOMER_BALANCE_DELETE_ACTIONS,
    CUSTOMER_BALANCE_UPDATE_ACTIONS,
} from "src/Actions/CustomerBalanceActions";
import { getShowDistribution } from "src/Selectors/getShowDistribution";
import { getAssetPortfolios } from "src/Selectors/Balance/getAssetPortfolios";
import { getLiabilityPortfolios } from "src/Selectors/Balance/getLiabilityPortfolios";

type OwnProps = {};
type StateProps = {
    readonly assetCategories: ReadonlyArray<BalanceCategory>;
    readonly liabilityCategories: ReadonlyArray<BalanceCategory>;
    readonly assetPortfolios: ReadonlyArray<BalancePortfolio>;
    readonly liabilityPortfolios: ReadonlyArray<BalancePortfolio>;
    readonly objects: ReadonlyArray<BalanceObject>;
    readonly creationDate: Date;
    readonly bankName: Translatable;
    readonly isAssetModalOpen: boolean;
    readonly isLiabilityModalOpen: boolean;
    readonly isRealEstateModalOpen: boolean;
    readonly assetFormInitialData: CustomerBalanceForm;
    readonly liabilityFormInitialData: CustomerBalanceForm;
    readonly showDistribution: boolean;
};
type DispatchProps = {
    readonly openAssetModal: () => void;
    readonly closeAssetModal: () => void;
    readonly openLiabilityModal: () => void;
    readonly closeLiabilityModal: () => void;
    readonly openRealEstateModal: () => void;
    readonly closeRealEstateModal: () => void;
    readonly createCustomerBalance: (formData: CustomerBalanceForm) => void;
    readonly updateCustomerBalance: (object: BalanceObjectCustomerBalance, formData: CustomerBalanceForm) => void;
    readonly deleteCustomerBalance: (object: BalanceObjectCustomerBalance) => void;
};

export type HomePageProps =
    & OwnProps
    & StateProps
    & DispatchProps;

const mapStateToProps = createStructuredSelector<ApplicationState, StateProps>({
    assetCategories: getAssetCategories,
    liabilityCategories: getLiabilityCategories,
    assetPortfolios: getAssetPortfolios,
    liabilityPortfolios: getLiabilityPortfolios,
    creationDate: getCreationDate,
    objects: getBalanceObjects,
    bankName: getBankName,
    isAssetModalOpen: (state) => getIsModalOpen(state, "asset-form"),
    isLiabilityModalOpen: (state) => getIsModalOpen(state, "liability-form"),
    isRealEstateModalOpen: (state) => getIsModalOpen(state, "real-estate-modal"),
    assetFormInitialData: getAssetFormInitialData,
    liabilityFormInitialData: getLiabilityFormInitialData,
    showDistribution: getShowDistribution,
});
const mapDispatchToProps: DispatchProps = {
    openAssetModal: () => uiModalOpen("asset-form"),
    closeAssetModal: () => uiModalClose("asset-form"),
    openLiabilityModal: () => uiModalOpen("liability-form"),
    closeLiabilityModal: () => uiModalClose("liability-form"),
    openRealEstateModal: () => uiModalOpen("real-estate-modal"),
    closeRealEstateModal: () => uiModalClose("real-estate-modal"),
    createCustomerBalance: (formData) => CUSTOMER_BALANCE_CREATE_ACTIONS.request({ formData }),
    updateCustomerBalance: (object, formData) => CUSTOMER_BALANCE_UPDATE_ACTIONS.request({ formData, object }),
    deleteCustomerBalance: (object) => CUSTOMER_BALANCE_DELETE_ACTIONS.request({ object }),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(HomeScreen, mapStateToProps, mapDispatchToProps)
export class HomePage extends React.Component {}
