import "moment-timezone";
import * as moment from "moment";
import { RuntimeError } from "src/Errors/RuntimeError";

export interface IDateConverter {
    convertToApp(date: string): Date;
    convertToApi(date: Date): string;
}

export class DateConverter implements IDateConverter {
    public static $inject: string[] = [
        "evjDateFormat",
        "evjDateTimezone",
    ];
    public constructor(
        private dateFormat: string,
        private dateTimezone: string,
    ) {
    }

    public convertToApp(date: Graviton.Common.DateTime): Date {
        const m = moment(date, this.dateFormat);
        if (!m.isValid()) {
            throw new RuntimeError(`Date "${date}" is not valid`, {
                date: date,
                format: this.dateFormat,
                timeZone: this.dateTimezone,
            });
        }

        return moment(m.tz(this.dateTimezone).format("YYYY-MM-DD HH:mm:ss")).toDate();
    }
    public convertToApi(date: Date): Graviton.Common.DateTime {
        const m = moment(date);
        if (!m.isValid()) {
            throw new RuntimeError(`Date "${date}" is not valid`, {
                date: date,
                format: this.dateFormat,
                timeZone: this.dateTimezone,
            });
        }

        return moment.tz(m.format("YYYY-MM-DD HH:mm:ss"), this.dateTimezone).format(this.dateFormat);
    }
}
