import { SagaIterator } from "redux-saga";
import { takeLatest } from "redux-saga/effects";
import {
    DOCUMENT_OPEN_PROVISION_INFO_ACTIONS,
    DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS,
} from "src/Actions/DocumentActions";
import { documentOpenProvisionInfoSaga } from "src/Sagas/Documents/documentOpenProvisionInfoSaga";
import { documentOpenWithdrawalConditionSaga } from "src/Sagas/Documents/documentOpenWithdrawalConditionSaga";

export function* documentSaga(): SagaIterator {
    yield takeLatest(DOCUMENT_OPEN_PROVISION_INFO_ACTIONS.REQUEST, documentOpenProvisionInfoSaga);
    yield takeLatest(DOCUMENT_OPEN_WITHDRAWAL_CONDITION_ACTIONS.REQUEST, documentOpenWithdrawalConditionSaga);
}
