import * as React from "react";
import { FormattedMessage } from "react-intl";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";
import { Omit } from "mapped-types-utils";
import { EvojaDropdown, EvojaListOption } from "@evoja-web/uikit";
import { isFieldInvalid } from "src/Utils/isFieldInvalid";
import { isNotEmpty } from "src/Utils/isNotEmpty";
import { classList } from "src/Utils/classList";

const defaultCompare: <T>(value: T, option: T) => boolean = (v, o) => v === o;
const defaultOptionKey: <T>(option: T, index: number) => React.Key = (o, i) => i;
const defaultPlaceholder = <FormattedMessage id="formField_input_placeholder"/>;
const nullFocusEvent = null as any as React.FocusEvent<any>;

type DropdownProps<TOption> = {
    readonly name: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly options: ReadonlyArray<TOption>;
    readonly renderOption: (option: TOption) => React.ReactNode;
    readonly renderValue?: (value: TOption | null) => React.ReactNode;
    readonly compareValue?: (value: TOption, option: TOption) => boolean;
    readonly getOptionKey?: (option: TOption, index: number) => string | number;
};

function DropdownComponent<TOption>(props: WrappedFieldProps & DropdownProps<TOption>): JSX.Element {
    const { input, meta, disabled = false } = props;

    const onOpen = () => input.onFocus(nullFocusEvent);
    const onClose = () => input.onBlur(undefined);
    const onSelect = (option: TOption) => {
        input.onChange(option);
        input.onBlur(undefined);
    };

    return (
        <EvojaDropdown id={`field-${meta.form}-${input.name}`}
                       value={renderDropdownValue(props)}
                       invalid={isFieldInvalid(meta, { disabled })}
                       disabled={disabled}
                       name={input.name}
                       onOpen={onOpen}
                       onClose={onClose}
                       onSelect={onSelect}>
            {renderDropdownOptions(props)}
        </EvojaDropdown>
    );
}
function renderDropdownValue<TOption>(props: WrappedFieldProps & DropdownProps<TOption>): JSX.Element {
    const { renderValue, input } = props;
    if (renderValue) {
        return <span className="swisscom-dropdown__value-option">{renderValue(input.value)}</span>;
    }

    const hasValue = isNotEmpty(input.value);
    const className = classList({
        "evoja-dropdown__value-option": hasValue,
        "evoja-dropdown__value-placeholder": !hasValue,
    });

    const { renderOption } = props;
    const valueTemplate = hasValue
        ? renderOption(input.value)
        : defaultPlaceholder;

    return <span className={className}>{valueTemplate}</span>;
}
function renderDropdownOptions<TOption>(props: WrappedFieldProps & DropdownProps<TOption>): JSX.Element[] {
    const { renderOption, compareValue = defaultCompare, getOptionKey = defaultOptionKey, options, input } = props;
    const hasValue = isNotEmpty(input.value);

    return options.map((option, index) => (
        <EvojaListOption key={getOptionKey(option, index)}
                         option={option}
                         active={hasValue ? compareValue(input.value, option) : false}>
            {renderOption(option)}
        </EvojaListOption>
    ));
}

type Props<T> = Omit<BaseFieldProps<DropdownProps<T>> & DropdownProps<T>, "component">;
export function DropdownField<TOption>(props: Props<TOption>): JSX.Element {
    return <Field format={null} component={DropdownComponent} {...props}/>;
}
