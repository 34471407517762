import * as React from "react";
import { FormattedMessage } from "react-intl";
import { bind } from "lodash-decorators/bind";
import { Decimal } from "decimal.js-light";
import { EvojaHtmlButton, EvojaIcon, EvojaPopover, EvojaPopoverContent, EvojaPopoverTarget } from "@evoja-web/uikit";
import { isNotNull } from "src/Utils/isNotNull";
import { assertDefined } from "src/Utils/assertionHelpers";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { invalidAvailabilityCategory } from "src/Utils/invalidAvailabilityCategory";
import { balanceObjectSum } from "src/Services/Utils/balanceObjectSum";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { ExpandContent } from "src/Components/Common/ExpandContent";
import { ExpandIndicator } from "src/Components/Common/ExpandIndicator";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { BalanceAvailabilityCategoryType } from "src/State/Balance/BalanceAvailabilityCategory";
import { FileConfigurationItem } from "src/State/Configuration/FileConfiguration";
import { AvailabilityDocumentsPopover } from "src/Components/Availability/AvailabilityDocumentsPopover";
import { getBalanceObjectMissingAmount } from "src/Services/Utils/getBalanceObjectMissingAmount";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";

type Props = {
    readonly category: BalanceCategory;
    readonly objects: ReadonlyArray<BalanceObjectAccountAsset>;
    readonly provisionInfoFiles: ReadonlyArray<FileConfigurationItem>;
    readonly openProvisionInfo: (fileId: string) => void;
};
type State = {
    readonly expanded: boolean;
};

export class AvailabilityCategory extends React.PureComponent<Props, State> {
    private provisionInfoButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();
    private provisionInfoPopoverRef: React.RefObject<HTMLDivElement> = React.createRef();

    public state: State = {
        expanded: false,
    };

    public render(): JSX.Element {
        const { category, objects, children } = this.props;
        const { expanded } = this.state;

        return (
            <div className="m03-availability-category">
                <div className="m03-availability-category__header" onClick={this.toggleExpanded}>
                    <div className="m03-availability-category__header-title">
                        <div className="m03-availability-category__header-title-text">
                            <TranslatedMessage value={category.title}/>
                        </div>
                        <div className="m03-availability-category__header-title-button">
                            {this.renderProvisionInfoButton()}
                        </div>
                    </div>
                    <div className="m03-availability-category__header-amount">
                        <FormattedNumber value={balanceObjectSum(objects).toNumber()}/>
                    </div>
                    <div className="m03-availability-category__header-available">
                        {this.renderAvailableAmount()}
                    </div>
                    <div className="m03-availability-category__header-toggle">
                        <ExpandIndicator expanded={expanded}/>
                    </div>

                    <div className="m03-availability-category__header-div"/>

                    <div className="m03-availability-category__header-wished">
                        {this.renderWishedAmount()}
                    </div>
                    <div className="m03-availability-category__header-missing">
                        {this.renderMissingAmount()}
                    </div>
                    <div className="m03-availability-category__header-nkk-amount">
                        {this.renderNkkAmount()}
                    </div>
                </div>
                <ExpandContent expanded={expanded}>
                    <div className="m03-availability-category__body">{children}</div>
                </ExpandContent>
            </div>
        );
    }

    @bind()
    private toggleExpanded({ target: clickedElement }: React.MouseEvent): void {
        if (!(clickedElement instanceof HTMLElement)) {
            return;
        }

        const { current: provisionInfoButton } = this.provisionInfoButtonRef;
        if (provisionInfoButton && provisionInfoButton.contains(clickedElement)) {
            return;
        }

        const { current: popover } = this.provisionInfoPopoverRef;
        if (popover && popover.contains(clickedElement)) {
            return;
        }

        this.setState((prev) => ({ expanded: !prev.expanded }));
    }

    private renderProvisionInfoButton(): JSX.Element | null {
        const availabilityType = this.getAvailabilityType();
        if (availabilityType !== BalanceAvailabilityCategoryType.DEFINED) {
            return null;
        }

        const { provisionInfoFiles, openProvisionInfo } = this.props;
        return (
            <EvojaPopover id="provision-documents" placement="right">
                <EvojaPopoverTarget>
                    <EvojaHtmlButton ref={this.provisionInfoButtonRef}
                                     shape="circle"
                                     block={false}
                                     size="sm"
                                     className="m03-availability-category__provision-info">
                        <EvojaIcon icon="document-full"/>
                    </EvojaHtmlButton>
                </EvojaPopoverTarget>
                <EvojaPopoverContent>
                    <AvailabilityDocumentsPopover documents={provisionInfoFiles}
                                                  openDocument={openProvisionInfo}
                                                  componentRef={this.provisionInfoPopoverRef}/>
                </EvojaPopoverContent>
            </EvojaPopover>
        );
    }

    private renderAvailableAmount(): JSX.Element {
        const { category } = this.props;
        const availabilityType = this.getAvailabilityType();

        switch (availabilityType) {
            case BalanceAvailabilityCategoryType.DIRECT:
                const directValue = this.getTotalAvailableAmount() || ZERO_DECIMAL;
                return <FormattedNumber value={directValue.toNumber()}/>;

            case BalanceAvailabilityCategoryType.SELECTIVE:
                const selectiveValue = this.getTotalAvailableAmount();
                return selectiveValue
                    ? <FormattedNumber value={selectiveValue.toNumber()}/>
                    : <span className="m03-availability-category__empty">-</span>;

            case BalanceAvailabilityCategoryType.DEFINED:
                const definedValue = this.getTotalAvailableAmount();
                return definedValue
                    ? <FormattedNumber value={definedValue.toNumber()}/>
                    : <FormattedMessage id="availability_noAvailableAmount"/>;

            case BalanceAvailabilityCategoryType.NONE:
                return <span className="m03-availability-category__empty">-</span>;

            default:
                return invalidAvailabilityCategory(availabilityType, { category });
        }
    }

    private renderWishedAmount(): JSX.Element | null {
        return this.hasPositionBasedAvailability()
            ? <FormattedNumber value={this.getTotalWishedAmount().toNumber()}/>
            : null;
    }

    private renderMissingAmount(): JSX.Element | null {
        return this.hasPositionBasedAvailability()
            ? <FormattedNumber value={this.getTotalMissingAmount().toNumber()}/>
            : null;
    }

    private renderNkkAmount(): JSX.Element | null {
        return this.hasPositionBasedAvailability()
            ? <FormattedNumber value={this.getTotalNkkAmount().toNumber()}/>
            : null;
    }

    private getTotalAvailableAmount(): Decimal | null {
        const { objects } = this.props;
        const valuableObjects = objects
            .filter(({ availableAmount }) => availableAmount.included)
            .map(({ availableAmount }) => availableAmount.value)
            .filter(isNotNull);

        return valuableObjects.length
            ? valuableObjects.reduce((result, value) => result.add(value), ZERO_DECIMAL)
            : null;
    }

    private getTotalWishedAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .map(({ wishedAmount }) => wishedAmount.wishedAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalMissingAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .map(getBalanceObjectMissingAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalNkkAmount(): Decimal {
        const { objects } = this.props;
        return objects
            .map(getBalanceObjectNkkAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getAvailabilityType(): BalanceAvailabilityCategoryType {
        const { category } = this.props;
        return assertDefined(
            category.availabilityCategory,
            `No "availabilityCategory" defined for category "${category.id}"`,
            { category },
        ).type;
    }

    private hasPositionBasedAvailability(): boolean {
        const { category } = this.props;
        return assertDefined(
            category.availabilityCategory,
            `No "availabilityCategory" defined for category "${category.id}"`,
            { category },
        ).hasPositionBasedAvailability;
    }
}
