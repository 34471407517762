import { BalanceAccountPosition } from "src/State/Balance/BalanceAccountPosition";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { IBalanceAccountPositionDetailsMapper } from "src/Services/BalanceData/BalanceAccountPositionDetailsMapper";
import { isDefined } from "src/Utils/isDefined";

export interface IBalanceAccountPositionMapper {
    mapBalanceAccountPositions(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
    ): BalanceAccountPosition[];
}

export class BalanceAccountPositionMapper implements IBalanceAccountPositionMapper {
    public static $inject: string[] = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjBalanceAccountPositionDetailsMapper",
    ];

    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private additionalDetailsMapper: IBalanceAccountPositionDetailsMapper,
    ) {
    }

    public mapBalanceAccountPositions(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
    ): BalanceAccountPosition[] {
        return balance.stockData.accountPositions
            .filter((position) => this.extRefComparator.compareRefs(
                position.account.$ref,
                this.extRefGenerator.createRef(EndpointName.ACCOUNT_ACCOUNT, account.id),
            ))
            .map((accountPosition) => {
                const valor = accountPosition.instrumentId
                    ? balance.stockData.investmentValors.find((it) => accountPosition.instrumentId === it.id)
                    : undefined;

                if (!valor) {
                    return undefined;
                }

                const position = {
                    ...accountPosition,
                    valor: valor,
                };

                return {
                    position: position,
                    additionalDetails: this.additionalDetailsMapper.mapAdditionalDetails(position, balance),
                };
            })
            .filter(isDefined);
    }
}
