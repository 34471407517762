import { createSelector } from "reselect";
import { getBalance } from "src/Selectors/Balance/getBalance";
import { BalanceCreationClassLiabilityType } from "src/State/Balance/BalanceCreationClassLiability";

export const getBalanceCreationClassAssets = createSelector([
    getBalance,
], ({ creationClassAssets, creationClassLiabilities }) => {
    const realEstate = creationClassLiabilities.find((it) => it.type === BalanceCreationClassLiabilityType.PROPERTY);
    return realEstate
        ? [...creationClassAssets, realEstate]
        : creationClassAssets;
});
