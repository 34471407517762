import { SagaIterator } from "redux-saga";
import { takeEvery } from "redux-saga/effects";
import {
    LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS,
    LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS,
} from "src/Actions/LiquidityPlanningActions";
import {
    liquidityPlanningChangeTotalSaga,
} from "src/Sagas/LiquidityPlanning/liquidityPlanningChangeTotalSaga";
import {
    liquidityPlanningChangePlannedSaga,
} from "src/Sagas/LiquidityPlanning/liquidityPlanningChangePlannedSaga";
import {
    liquidityPlanningChangeWishReserveSaga,
} from "src/Sagas/LiquidityPlanning/liquidityPlanningChangeWishReserveSaga";
import {
    liquidityPlanningChangeProvisionSaga,
} from "src/Sagas/LiquidityPlanning/liquidityPlanningChangeProvisionSaga";
import {
    liquidityPlanningChangeInvestmentSaga,
} from "src/Sagas/LiquidityPlanning/liquidityPlanningChangeInvestmentSaga";

export function* liquidityPlanningSaga(): SagaIterator {
    yield takeEvery(LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS.REQUEST, liquidityPlanningChangeTotalSaga);
    yield takeEvery(LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS.REQUEST, liquidityPlanningChangePlannedSaga);
    yield takeEvery(LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS.REQUEST, liquidityPlanningChangeWishReserveSaga);
    yield takeEvery(LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS.REQUEST, liquidityPlanningChangeProvisionSaga);
    yield takeEvery(LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS.REQUEST, liquidityPlanningChangeInvestmentSaga);
}
