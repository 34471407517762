import { BalanceOutputAvailabilityAmount, BalanceOutputAvailabilityTotal } from "api/Types/BalanceOutputJson";
import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { isNotNull } from "src/Utils/isNotNull";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { IFormatterService } from "src/Services/FormatterService";
import { getBalanceObjectMissingAmount } from "src/Services/Utils/getBalanceObjectMissingAmount";
import { getBalanceObjectNkkAmount } from "src/Services/Utils/getBalanceObjectNkkAmount";

export interface IOutputAvailabilityTotalMapper {
    mapAvailabilityTotal(
        balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityTotal;
}

export class OutputAvailabilityTotalMapper implements IOutputAvailabilityTotalMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjFormatterService",
    ];
    public constructor(
        private formatter: IFormatterService,
    ) {
    }

    public mapAvailabilityTotal(
        balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityTotal {
        return {
            amount: this.formatAmount(this.getTotalAmount(balanceObjects)),
            availableAmount: this.formatAmount(this.getTotalAvailableAmount(balanceObjects)),
            wishedAmount: this.formatAmount(this.getTotalWishedAmount(balanceObjects)),
            missingAmount: this.formatAmount(this.getTotalMissingAmount(balanceObjects)),
            nkkAmount: this.formatAmount(this.getTotalNkkAmount(balanceObjects)),
        };
    }

    private getTotalAmount(balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>): Decimal {
        return balanceObjects
            .map(({ amount }) => amount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalAvailableAmount(balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>): Decimal {
        return balanceObjects
            .filter(({ availableAmount }) => availableAmount.included)
            .map(({ availableAmount }) => availableAmount.value)
            .filter(isNotNull)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalWishedAmount(balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>): Decimal {
        return balanceObjects
            .map(({ wishedAmount }) => wishedAmount.wishedAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalMissingAmount(balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>): Decimal {
        return balanceObjects
            .map(getBalanceObjectMissingAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private getTotalNkkAmount(balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>): Decimal {
        return balanceObjects
            .map(getBalanceObjectNkkAmount)
            .reduce((result, amount) => result.add(amount), ZERO_DECIMAL);
    }

    private formatAmount(amount: Decimal): BalanceOutputAvailabilityAmount {
        return {
            value: amount.toNumber(),
            formatted: this.formatter.formatNumber(amount.toNumber(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        };
    }
}
