import { createSelector } from "reselect";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { getBalanceCreationClassLiabilities } from "src/Selectors/Balance/getBalanceCreationClassLiabilities";

export const getLiabilityFormInitialData = createSelector([
    getBalanceCreationClassLiabilities,
], ([creationClass]): CustomerBalanceForm => ({
    creationClass: creationClass,
    bank: null,

    title: "",
    rubric: "",

    amount: null,
    endDate: null,
    currentMarketPrice: null,
}));
