import { ConsultationData } from "src/State/ConsultationState";
import { IBalanceAvailabilityMapper } from "src/Services/SynchronizationData/BalanceAvailabilityMapper";
import { IBalanceLiquidityMapper } from "src/Services/SynchronizationData/BalanceLiquidityMapper";
import { IBalanceAccountsMapper } from "src/Services/SynchronizationData/BalanceAccountsMapper";
import { IBalanceSharedAccountsMapper } from "src/Services/SynchronizationData/BalanceSharedAccountsMapper";

export interface IBalancePatchMapper {
    mapBalance(
        consultationData: ConsultationData,
    ): Graviton.Consultation.Balance.ConsultationData;
}

export class BalancePatchMapper implements IBalancePatchMapper {
    public static $inject: string[] = [
        "evjBalanceAvailabilityMapper",
        "evjBalanceLiquidityMapper",
        "evjBalanceAccountsMapper",
        "evjBalanceSharedAccountsMapper",
    ];

    public constructor(
        private availabilityMapper: IBalanceAvailabilityMapper,
        private liquidityPlanningMapper: IBalanceLiquidityMapper,
        private balanceAccountsMapper: IBalanceAccountsMapper,
        private sharedAccountsMapper: IBalanceSharedAccountsMapper,
    ) {
    }

    public mapBalance(
        consultationData: ConsultationData,
    ): Graviton.Consultation.Balance.ConsultationData {
        return {
            balanceAccounts: this.balanceAccountsMapper.mapBalanceAccounts(
                consultationData.balance,
                consultationData.originalBalance,
            ),
            sharedAccounts: this.sharedAccountsMapper.mapSharedAccounts(
                consultationData.balance,
                consultationData.originalBalance,
            ),
            availability: this.availabilityMapper.mapBalanceAvailability(consultationData.balance),
            liquidityPlanning: this.liquidityPlanningMapper.mapBalanceLiquidity(
                consultationData.balance.liquidityPlanning,
            ),
            resultColumn: [],
        };
    }
}
