import { isBalanceObjectCustomerBalance } from "src/Services/Utils/balanceObjectGuard";
import { isDefined } from "src/Utils/isDefined";
import { BalanceData } from "src/State/Balance/BalanceData";
import { IDateConverter } from "src/Services/Utils/DateConverter";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { DisplayClassLink } from "src/Services/Reference/DisplayClassLink";
import { OutputClassLink } from "src/Services/Reference/OutputClassLink";
import { assertDefined } from "src/Utils/assertionHelpers";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";

export interface IBalanceAccountsMapper {
    mapBalanceAccounts(
        balance: BalanceData,
        originalBalance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance[];
}

export class BalanceAccountsMapper implements IBalanceAccountsMapper {
    public static $inject: string[] = [
        "evjExtRefGenerator",
        "evjDateConverter",
        "evjReferenceDisplayClassLinks",
        "evjReferenceOutputClassLinks",
    ];

    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private dateConverter: IDateConverter,
        private displayClassLinks: ReadonlyArray<DisplayClassLink>,
        private outputClassLinks: ReadonlyArray<OutputClassLink>,
    ) {
    }

    public mapBalanceAccounts(
        balance: BalanceData,
        originalBalance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Graviton.Consultation.Balance.Consultation.CustomerBalance[] {
        return balance.objects.filter((it) => !it.mainId).map((balanceObject) => {
            if (!isBalanceObjectCustomerBalance(balanceObject)) {
                return undefined;
            }

            const original = originalBalance.consultation.balanceAccounts
                .find((it) => it.id === balanceObject.id);
            const categoryMapping = balance.categoryMappings.find((it) =>
                it.category.id === balanceObject.category.balanceCategoryId &&
                it.creationClass.id === balanceObject.creationClass.code.id
            );

            return {
                id: balanceObject.id,
                category: {
                    $ref: this.extRefGenerator.createRef(
                        EndpointName.BALANCE_CATEGORY,
                        balanceObject.category.balanceCategoryId,
                    ),
                },
                creationClass: balanceObject.creationClass.code,
                displayClass: this.mapDisplayClass(balanceObject, originalBalance.parameterData.balanceDisplayClasses),
                outputClass: this.mapOutputClass(balanceObject, originalBalance.parameterData.balanceOutputClasses),
                balanceSubType: original ? original.balanceSubType : undefined,
                isShared: !!(categoryMapping && categoryMapping.assetSegment),
                bank: balanceObject.customerBalance.bank,
                title: balanceObject.customerBalance.title,
                rubric: balanceObject.customerBalance.rubric,
                amount: balanceObject.amount.toString(),
                endDate: balanceObject.customerBalance.endDate
                    ? this.dateConverter.convertToApi(balanceObject.customerBalance.endDate)
                    : undefined,
                currentMarketPrice: balanceObject.customerBalance.currentMarketPrice
                    ? balanceObject.customerBalance.currentMarketPrice.toString()
                    : undefined,
                isAtThirdParty: balanceObject.customerBalance.isAtThirdParty,
            };
        }).filter(isDefined);
    }

    private mapDisplayClass(
        balanceObject: BalanceObjectCustomerBalance,
        balanceDisplayClasses: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Entity.Code {
        const balanceDisplayClassLink = assertDefined(
            this.displayClassLinks.find((it) => it.type === balanceObject.displayClassType),
            `Could not find balanceDisplayClass link "${balanceObject.displayClassType}"`,
            { displayClassType: balanceObject.displayClassType, links: this.displayClassLinks },
        );

        return assertDefined(
            balanceDisplayClasses.find((it) => it.number === balanceDisplayClassLink.number),
            `Could not find balanceDisplayClass "${balanceDisplayClassLink.number}"`,
            { balanceDisplayClasses, balanceDisplayClassLink },
        );
    }

    private mapOutputClass(
        balanceObject: BalanceObjectCustomerBalance,
        balanceOutputClasses: ReadonlyArray<Graviton.Entity.Code>,
    ): Graviton.Entity.Code {
        const balanceOutputClassLink = assertDefined(
            this.outputClassLinks.find((it) => it.type === balanceObject.outputClassType),
            `Could not find balanceOutputClass link "${balanceObject.outputClassType}"`,
            { outputClassType: balanceObject.outputClassType, links: this.outputClassLinks },
        );

        return assertDefined(
            balanceOutputClasses.find((it) => it.number === balanceOutputClassLink.number),
            `Could not find balanceOutputClass "${balanceOutputClassLink.number}"`,
            { balanceOutputClasses, balanceOutputClassLink },
        );
    }
}
