import * as React from "react";
import { IntlProvider } from "react-intl";
import { Route, Switch } from "react-router-dom";
import { Store } from "redux";
import { Provider } from "react-redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import { ApplicationState } from "src/State/ApplicationState";
import { EnvironmentContext, EnvironmentContextData } from "src/Context/EnvironmentContext";
import { DefaultLayout } from "src/Components/DefaultLayout";
import { ErrorBoundary } from "src/Components/ErrorBoundary";
import { Initialization } from "src/Components/Initialization";
import { PopoverProvider } from "src/Components/Common/PopoverProvider";
import { NotFoundPage } from "src/Pages/NotFoundPage";
import { HomePage } from "src/Pages/HomePage";
import { AssetGraphicsModal } from "src/Pages/AssetGraphicsModal";
import { AvailabilityModal } from "src/Pages/AvailabilityModal";
import { LiquidityPlanningModal } from "src/Pages/LiquidityPlanningModal";
import { DepotModal } from "src/Pages/DepotModal";
import { I18N_LOCALES } from "src/Constants/I18nLocales";
import { I18N_MESSAGES } from "src/Constants/I18nMessages";

interface Props {
    readonly store: Store<ApplicationState>;
    readonly history: History;
    readonly context: EnvironmentContextData;
}

export function Root({ store, history, context }: Props): JSX.Element {
    const locale = store.getState().parameters.locale;
    const i18nLocale = I18N_LOCALES[locale];
    const i18nMessages = I18N_MESSAGES[locale];

    // TODO: wrap with <React.StrictMode/>
    // @see https://github.com/reactjs/react-redux/issues/897
    // @see https://github.com/react-dnd/react-dnd/issues/997
    return (
        <EnvironmentContext.Provider value={context}>
            <DefaultLayout>
                <ErrorBoundary>
                    <Provider store={store}>
                        <IntlProvider locale={i18nLocale} messages={i18nMessages}>
                            <PopoverProvider>
                                <Initialization>
                                    <ConnectedRouter history={history}>
                                        <>
                                            <Switch>
                                                <Route exact={true}
                                                       path="/"
                                                       component={HomePage}/>
                                                <Route exact={false}
                                                       path="/"
                                                       component={NotFoundPage}/>
                                            </Switch>
                                            <AssetGraphicsModal/>
                                            <AvailabilityModal/>
                                            <LiquidityPlanningModal/>
                                            <DepotModal/>
                                        </>
                                    </ConnectedRouter>
                                </Initialization>
                            </PopoverProvider>
                        </IntlProvider>
                    </Provider>
                </ErrorBoundary>
            </DefaultLayout>
        </EnvironmentContext.Provider>
    );
}
