import { Provider } from "@xiag/container";
import { StateApiRefUrlFactory } from "src/Services/StateAccess/StateApiRefUrlFactory";
import { getLocale } from "src/Selectors/getLocale";
import { getParameters } from "src/Selectors/getParameters";
import { getCoreConfiguration } from "src/Selectors/Configuration/getCoreConfiguration";
import { getFileConfiguration } from "src/Selectors/Configuration/getFileConfiguration";
import { getBankName } from "src/Selectors/Configuration/getBankName";

export const STATE_ACCESS_PROVIDER = new Provider([])
    .factory(
        "evjParameters",
        getParameters,
        ["$state"],
    )
    .factory(
        "evjCoreConfiguration",
        getCoreConfiguration,
        ["$state"],
    )
    .factory(
        "evjFileConfiguration",
        getFileConfiguration,
        ["$state"],
    )
    .factory(
        "evjApiRefUrl",
        StateApiRefUrlFactory,
        StateApiRefUrlFactory.$inject || [],
    )
    .factory(
        "evjLocale",
        getLocale,
        ["$state"],
    )
    .factory(
        "evjBankName",
        getBankName,
        ["$state"],
    )
;
