import { assertDefined } from "src/Utils/assertionHelpers";
import { CreationClassLiabilityLink } from "src/Services/Reference/CreationClassLiabilityLink";
import {
    BalanceCreationClassLiability,
    BalanceCreationClassLiabilityType,
} from "src/State/Balance/BalanceCreationClassLiability";

export interface IBalanceCreationClassLiabilityMapper {
    mapBalanceCreationClassLiabilities(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCreationClassLiability[];
}

export class BalanceCreationClassLiabilityMapper implements IBalanceCreationClassLiabilityMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjReferenceCreationClassLiabilityLinks",
    ];
    public constructor(
        private creationClassLiabilityLinks: ReadonlyArray<CreationClassLiabilityLink>,
    ) {
    }

    public mapBalanceCreationClassLiabilities(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCreationClassLiability[] {
        return balance.parameterData.balanceCreationClassLiabilities
            .map((code) => ({ code, type: this.getType(code) }))
            .sort((a, b) => a.code.number > b.code.number ? 1 : -1);
    }

    private getType(code: Readonly<Graviton.Entity.Code>): BalanceCreationClassLiabilityType {
        return assertDefined(
            this.creationClassLiabilityLinks.find((link) => link.number === code.number),
            `Could not find balanceCreationClassLiability type "${code.id}"`,
            { code, links: this.creationClassLiabilityLinks },
        ).type;
    }
}
