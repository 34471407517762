import * as React from "react";
import { FormattedMessage } from "react-intl";
import { EvojaHtmlButton } from "@evoja-web/uikit";
import { InnerFormProps, OuterFormProps, formDecorator } from "src/Decorators/formDecorator";
import { ChecklistForm } from "src/State/Form/ChecklistForm";
import { TextboxField } from "src/Components/Form/TextboxField";
import { TextareaField } from "src/Components/Form/TextareaField";

type OwnProps = {};

class ChecklistFormContentWithForm extends React.Component<InnerFormProps<ChecklistForm, OwnProps>> {
    public render(): JSX.Element {
        const { handleSubmit, submitFailed, submitSucceeded, submitting, invalid } = this.props;

        return (
            <form className="m03-checklist-form-content" onSubmit={handleSubmit}>
                <div className="m03-checklist-form-content__form">
                    <div className="m03-checklist-form-content__row">
                        <div className="m03-checklist-form-content__label">
                            <FormattedMessage id="checklistForm_title"/>
                        </div>
                        <div className="m03-checklist-form-content__input">
                            <TextboxField type="text"
                                          name="title"
                                          autoComplete="off"/>
                        </div>
                    </div>
                    <div className="m03-checklist-form-content__row">
                        <div className="m03-checklist-form-content__label">
                            <FormattedMessage id="checklistForm_text"/>
                        </div>
                        <div className="m03-checklist-form-content__input">
                            <TextareaField name="text" rows={6}/>
                        </div>
                    </div>
                </div>
                <div className="m03-checklist-form-content__actions">
                    <EvojaHtmlButton shape="square"
                                     block={false}
                                     type="submit"
                                     disabled={submitFailed ? invalid : submitSucceeded || submitting}>
                        <FormattedMessage id="checklistForm_submit"/>
                    </EvojaHtmlButton>
                </div>
            </form>
        );
    }
}

@formDecorator<ChecklistForm, OwnProps>(ChecklistFormContentWithForm, {})
export class ChecklistFormContent extends React.Component<OuterFormProps<ChecklistForm, OwnProps>> {}
