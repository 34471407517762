import { assertDefined } from "src/Utils/assertionHelpers";
import { DisplayClassLink } from "src/Services/Reference/DisplayClassLink";
import { OutputClassLink } from "src/Services/Reference/OutputClassLink";
import { DetailGroupLink } from "src/Services/Reference/DetailGroupLink";
import { BalanceAddition } from "src/State/Balance/BalanceAddition";
import { BalanceDetailGroup, BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";

export interface IBalanceObjectAdditionMapper {
    mapAddition(
        coreType: Readonly<Graviton.Consultation.ConsultationEntity>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceAddition | undefined;
}

export class BalanceObjectAdditionMapper implements IBalanceObjectAdditionMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjReferenceDisplayClassLinks",
        "evjReferenceOutputClassLinks",
        "evjReferenceDetailGroupLinks",
    ];
    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private displayClassLinks: ReadonlyArray<DisplayClassLink>,
        private outputClassLinks: ReadonlyArray<OutputClassLink>,
        private detailGroupLinks: ReadonlyArray<DetailGroupLink>,
    ) {
    }

    public mapAddition(
        coreType: Readonly<Graviton.Consultation.ConsultationEntity>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceAddition | undefined {
        const addition = this.getAddition(coreType, parameterData);
        if (!addition) {
            return undefined;
        }

        return {
            id: addition.id,

            displayClassType: this.mapDisplayClass(addition, parameterData),
            outputClassType: this.mapOutputClass(addition, parameterData),

            detailGroupTypes: this.mapDetailGroups(addition, parameterData),
            availabilityDetailGroupTypes: this.mapAvailabilityDetailGroups(addition, parameterData),
        };
    }


    private mapDisplayClass(
        addition: Readonly<Graviton.Balance.ProductAddition>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceDisplayClassType {
        const { balanceDisplayClasses } = parameterData;
        const displayClass = assertDefined(
            this.findByRef(EndpointName.ENTITY_CODE, balanceDisplayClasses, addition.displayClass.$ref),
            `Could not find balanceDisplayClass "${addition.displayClass.$ref}"`,
            { balanceDisplayClasses },
        );

        return assertDefined(
            this.displayClassLinks.find((it) => it.number === displayClass.number),
            `Could not find balanceDisplayClass link "${displayClass.number}"`,
            { displayClass, links: this.displayClassLinks },
        ).type;
    }

    private mapOutputClass(
        addition: Readonly<Graviton.Balance.ProductAddition>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceOutputClassType {
        const { balanceOutputClasses } = parameterData;
        const outputClass = assertDefined(
            this.findByRef(EndpointName.ENTITY_CODE, balanceOutputClasses, addition.outputClass.$ref),
            `Could not find balanceOutputClass "${addition.outputClass.$ref}"`,
            { balanceOutputClasses },
        );

        return assertDefined(
            this.outputClassLinks.find((it) => it.number === outputClass.number),
            `Could not find balanceOutputClass link "${outputClass.number}"`,
            { outputClass, links: this.outputClassLinks },
        ).type;
    }

    private mapDetailGroups(
        addition: Readonly<Graviton.Balance.ProductAddition>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceDetailGroup[] {
        return addition.detailGroup
            ? this.mapDetailGroupReferences(addition.detailGroup, parameterData)
            : [];
    }

    private mapAvailabilityDetailGroups(
        addition: Readonly<Graviton.Balance.ProductAddition>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceDetailGroup[] {
        return addition.availabilityDetailGroup
            ? this.mapDetailGroupReferences(addition.availabilityDetailGroup, parameterData)
            : [];
    }

    private mapDetailGroupReferences(
        detailGroupRefs: ReadonlyArray<Graviton.Common.ExtRefObject<Graviton.Consultation.ConsultationEntity>>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): BalanceDetailGroup[] {
        const { balanceDetailGroups } = parameterData;
        return detailGroupRefs
            .map((detailGroup) => assertDefined(
                this.findByRef(EndpointName.ENTITY_CODE, balanceDetailGroups, detailGroup.$ref),
                `Could not find balanceDetailGroup "${detailGroup.$ref}"`,
                { detailGroup, balanceDetailGroups },
            ))
            .map((detailGroup) => assertDefined(
                this.detailGroupLinks.find((it) => it.number === detailGroup.number),
                `Could not find balanceDetailGroup link "${detailGroup.number}"`,
                { detailGroup, links: this.detailGroupLinks },
            ).type);
    }

    private getAddition(
        coreType: Readonly<Graviton.Consultation.ConsultationEntity>,
        parameterData: Readonly<Graviton.Consultation.Balance.ParameterData>,
    ): Graviton.Balance.ProductAddition | undefined {
        const coreTypeRef = this.extRefGenerator.createRef(
            EndpointName.ENTITY_CODE,
            coreType.id,
        );

        return parameterData.additions.find((addition) => (
            addition.coreType
                ? addition.coreType.some((it) => this.extRefComparator.compareRefs(coreTypeRef, it.$ref))
                : false
        ));
    }

    private findByRef<TEndpoint extends EndpointName>(
        endpoint: TEndpoint,
        entities: ReadonlyArray<EndpointEntity[TEndpoint] & { id: string }>,
        reference: Graviton.Common.ExtReference<EndpointEntity[TEndpoint]>,
    ): EndpointEntity[TEndpoint] | undefined {
        return entities.find((entity) => this.extRefComparator.compareRefs(
            reference,
            this.extRefGenerator.createRef(endpoint, entity.id),
        ));
    }
}
