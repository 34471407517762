import { BalanceOutputAvailabilityOverdraw } from "api/Types/BalanceOutputJson";
import { BalanceObjectAccountAsset } from "src/State/Balance/BalanceObject";
import { ITranslationService } from "src/Services/TranslationService";
import { getHasBalanceObjectOverdraw } from "src/Services/Utils/getHasBalanceObjectOverdraw";

export interface IOutputAvailabilityOverdrawMapper {
    mapAvailabilityOverdraw(
        balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityOverdraw | null;
}

export class OutputAvailabilityOverdrawMapper implements IOutputAvailabilityOverdrawMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjTranslationService",
    ];
    public constructor(
        private translator: ITranslationService,
    ) {
    }

    public mapAvailabilityOverdraw(
        balanceObjects: ReadonlyArray<BalanceObjectAccountAsset>,
    ): BalanceOutputAvailabilityOverdraw | null {
        const hasWarning = balanceObjects.some(getHasBalanceObjectOverdraw);
        if (!hasWarning) {
            return null;
        }

        return {
            warning: this.translator.translate("availability_summary_warning"),
        };
    }
}
