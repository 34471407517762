import { Decimal } from "decimal.js-light";
import { isDefined } from "src/Utils/isDefined";
import { assertDefined } from "src/Utils/assertionHelpers";
import { LogicError } from "src/Errors/LogicError";
import { BalanceAddition } from "src/State/Balance/BalanceAddition";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalanceDetailGroup, BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import {
    BalanceObjectAccount,
    BalanceObjectAccountAsset,
    BalanceObjectAccountBase,
} from "src/State/Balance/BalanceObject";
import { IBalanceObjectMapper } from "src/Services/BalanceData/BalanceObjectMapper";
import { IBalanceAccountAvailableAmountMapper } from "src/Services/BalanceData/BalanceAccountAvailableAmountMapper";
import { IBalanceAccountWishedAmountMapper } from "src/Services/BalanceData/BalanceAccountWishedAmountMapper";
import { IBalanceAccountPositionMapper } from "src/Services/BalanceData/BalanceAccountPositionMapper";
import { IBalanceObjectAdditionMapper } from "src/Services/BalanceData/BalanceObjectAdditionMapper";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { EndpointName } from "src/Services/ApiClient/EndpointName";

export class BalanceObjectAccountMapper implements IBalanceObjectMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjBalanceAccountAvailableAmountMapper",
        "evjBalanceAccountWishedAmountMapper",
        "evjBalanceAccountPositionMapper",
        "evjBalanceObjectAdditionMapper",
    ];
    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private accountAvailableAmountMapper: IBalanceAccountAvailableAmountMapper,
        private accountWishedAmountMapper: IBalanceAccountWishedAmountMapper,
        private accountPositionMapper: IBalanceAccountPositionMapper,
        private objectAdditionMapper: IBalanceObjectAdditionMapper,
    ) {
    }

    public mapBalanceObjects(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceObjectAccount[] {
        return balance.stockData.accounts.map((account): BalanceObjectAccount | undefined => {
            const { balanceCategory } = account;
            if (!balanceCategory) {
                return undefined;
            }

            const category = assertDefined(
                this.getBalanceCategory(balanceCategory, categories),
                `Could not find balanceCategory "${balanceCategory.id}"`,
                { categories, balanceCategory },
            );
            const addition = this.objectAdditionMapper.mapAddition(
                account.accountType,
                balance.parameterData,
            );
            const accountsFromGroup = category.isProvision
                ? balance.stockData.accounts
                    .filter((it) => account.accountGroup.id === it.accountGroup.id)
                    .filter((it) => this.isBalanceCategoryAccount(it, category))
                : [];

            const baseObjectAccount: BalanceObjectAccountBase = {
                id: account.id,
                mainId: undefined,
                account: account,
                accountsFromGroup: accountsFromGroup,
                accountPositions: this.accountPositionMapper.mapBalanceAccountPositions(balance, account),
                category: category,
                portfolio: balance.stockData.portfolios.find((it) => it.id === account.accountGroup.id),
                amount: new Decimal(account.reportingCurrency.balance),

                displayClassType: this.ensureDisplayClass(account, category, addition),
                outputClassType: this.ensureOutputClass(account, category, addition),
                detailGroupTypes: this.mapDetailGroups(category, addition),
            };
            if (!category.availabilityCategory) {
                return baseObjectAccount;
            }

            const assetObjectAccount: BalanceObjectAccountAsset = {
                ...baseObjectAccount,

                availabilityCategory: category.availabilityCategory,
                availabilityDetailGroups: this.mapAvailabilityDetailGroups(category, addition),
                availableAmount: this.accountAvailableAmountMapper
                    .mapBalanceAccountAvailableAmount(balance, account, category.availabilityCategory),
                wishedAmount: this.accountWishedAmountMapper
                    .mapBalanceAccountWishedAmount(balance, account, category.availabilityCategory),
            };
            return assetObjectAccount;
        }).filter(isDefined);
    }

    private mapDetailGroups(
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): ReadonlyArray<BalanceDetailGroup> {
        if (category.isProvision) {
            return [BalanceDetailGroup.CURRENCY, BalanceDetailGroup.PLEDGES];
        }

        if (addition && addition.detailGroupTypes.length > 0) {
            return [BalanceDetailGroup.CURRENCY, ...addition.detailGroupTypes];
        }

        return [BalanceDetailGroup.CURRENCY, ...category.detailGroupTypes];
    }

    private mapAvailabilityDetailGroups(
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): ReadonlyArray<BalanceDetailGroup> {
        if (addition && addition.availabilityDetailGroupTypes.length > 0) {
            return [BalanceDetailGroup.CURRENCY, ...addition.availabilityDetailGroupTypes];
        }

        return [BalanceDetailGroup.CURRENCY, ...category.availabilityDetailGroups];
    }

    private getBalanceCategory(
        coreType: Readonly<Graviton.Entity.Code>,
        categories: ReadonlyArray<BalanceCategory>,
    ): BalanceCategory | undefined {
        const coreTypeRef = this.extRefGenerator.createRef(
            EndpointName.ENTITY_CODE,
            coreType.id,
        );

        return categories
            .find(({ coreTypes }) => coreTypes
                .some(({ $ref }) => this.extRefComparator.compareRefs($ref, coreTypeRef)));
    }

    private isBalanceCategoryAccount(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceCategory,
    ): boolean {
        const { balanceCategory } = account;
        if (!balanceCategory) {
            return false;
        }

        const coreTypeRef = this.extRefGenerator.createRef(
            EndpointName.ENTITY_CODE,
            balanceCategory.id,
        );

        return category.coreTypes.some(({ $ref }) => this.extRefComparator.compareRefs($ref, coreTypeRef));
    }

    private ensureDisplayClass(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): BalanceObjectAccount["displayClassType"] {
        const displayClass = addition
            ? addition.displayClassType
            : category.displayClassType;
        switch (displayClass) {
            case BalanceDisplayClassType.ACCOUNT_NORMAL:
            case BalanceDisplayClassType.ACCOUNT_METAL:
            case BalanceDisplayClassType.ACCOUNT_DEPOSIT:
            case BalanceDisplayClassType.ACCOUNT_DEPOT:
            case BalanceDisplayClassType.ACCOUNT_EXCHANGE:
                return displayClass;

            default:
                throw new LogicError(
                    `Unexpected display class type "${displayClass}" for account`,
                    { displayClass, account, category, addition },
                );
        }
    }

    private ensureOutputClass(
        account: Readonly<Graviton.Consultation.Balance.Stock.AccountAccount>,
        category: BalanceCategory,
        addition: BalanceAddition | undefined,
    ): BalanceObjectAccount["outputClassType"] {
        const outputClass = addition
            ? addition.outputClassType
            : category.outputClassType;
        switch (outputClass) {
            case BalanceOutputClassType.ACCOUNT_NORMAL:
            case BalanceOutputClassType.ACCOUNT_METAL:
            case BalanceOutputClassType.ACCOUNT_DEPOSIT:
            case BalanceOutputClassType.ACCOUNT_DEPOT:
            case BalanceOutputClassType.ACCOUNT_EXCHANGE:
                return outputClass;

            default:
                throw new LogicError(
                    `Unexpected output class type "${outputClass}" for account`,
                    { outputClass, account, category, addition },
                );
        }
    }
}
