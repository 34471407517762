import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { HOST_COMMAND_ACTIONS } from "src/Actions/HostCommandActions";
import { DOCUMENT_OPEN_PROVISION_INFO_ACTIONS, DocumentOpenProvisionInfoAction } from "src/Actions/DocumentActions";

export function* documentOpenProvisionInfoSaga(
    { meta: fileId }: DocumentOpenProvisionInfoAction,
): SagaIterator {
    try {
        yield put(DOCUMENT_OPEN_PROVISION_INFO_ACTIONS.pending(fileId));

        yield put(HOST_COMMAND_ACTIONS.openDocument(fileId));

        yield put(DOCUMENT_OPEN_PROVISION_INFO_ACTIONS.success(undefined, fileId));
    } catch (error) {
        yield put(DOCUMENT_OPEN_PROVISION_INFO_ACTIONS.failure(NativeError.wrapError(error), fileId));
    }
}
