import ExtReference = Graviton.Common.ExtReference;

import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";

export interface IExtRefGenerator {
    createRef<T extends EndpointName>(endpoint: T, id: string): ExtReference<EndpointEntity[T]>;
}

export class ExtRefGenerator implements IExtRefGenerator {
    public static $inject: string[] = [
        "evjApiRefUrl",
    ];
    public constructor(
        private apiRefUrl: string,
    ) {
    }

    public createRef<T extends EndpointName>(endpoint: T, id: string): ExtReference<EndpointEntity[T]> {
        return `${this.apiRefUrl}/${endpoint}/${encodeURIComponent(id)}`;
    }
}
