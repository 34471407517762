import * as React from "react";
import { EvojaCarousel, EvojaCarouselSlideState } from "@evoja-web/uikit";

type Props = {
    readonly className?: string;
    readonly activeSlide: number;
    readonly onChangeSlide: (slideIndex: number) => void;
    readonly children: React.ReactNode;
};
export function Slider({ className, activeSlide, onChangeSlide, children }: Props): JSX.Element {
    return (
        <EvojaCarousel className={className}
                       active={activeSlide}
                       onChange={onChangeSlide}
                       animate={animateSlide}>
            {children}
        </EvojaCarousel>
    );
}

function animateSlide(state: EvojaCarouselSlideState): React.CSSProperties {
    const maxOverScroll = 0.5;
    const limitOverScroll = (overScroll: number) => maxOverScroll * (1 - 1 / (1 + overScroll));

    const minScroll = 0;
    const maxScroll = state.slideCount - 1;

    const scroll = state.slideIndex - state.slideShift;
    const limitedScroll = scroll < minScroll
        ? minScroll - limitOverScroll(minScroll - scroll)
        : scroll > maxScroll
            ? maxScroll + limitOverScroll(scroll - maxScroll)
            : scroll;

    return {
        transform: [
            `translateX(${-state.slideIndex * 100}%)`,
            `translateX(${(state.slideIndex - limitedScroll) * 100}%)`,
        ].join(" "),
    };
}
