import { Provider } from "@xiag/container";
import { BALANCE_CATEGORY_LINKS } from "src/Services/Reference/BalanceCategoryLink";
import { DETAIL_GROUP_LINKS } from "src/Services/Reference/DetailGroupLink";
import { DISPLAY_CLASS_LINKS } from "src/Services/Reference/DisplayClassLink";
import { OUTPUT_CLASS_LINKS } from "src/Services/Reference/OutputClassLink";
import { AVAILABILITY_CATEGORY_LINKS } from "src/Services/Reference/AvailabilityCategoryLink";
import { CREATION_CLASS_ASSET_LINKS } from "src/Services/Reference/CreationClassAssetLink";
import { CREATION_CLASS_LIABILITY_LINKS } from "src/Services/Reference/CreationClassLiabilityLink";

export const REFERENCE_PROVIDER = new Provider([])
    .constant(
        "evjReferenceBalanceCategoryLinks",
        BALANCE_CATEGORY_LINKS,
    )
    .constant(
        "evjReferenceDisplayClassLinks",
        DISPLAY_CLASS_LINKS,
    )
    .constant(
        "evjReferenceOutputClassLinks",
        OUTPUT_CLASS_LINKS,
    )
    .constant(
        "evjReferenceDetailGroupLinks",
        DETAIL_GROUP_LINKS,
    )
    .constant(
        "evjReferenceAvailabilityCategoryLinks",
        AVAILABILITY_CATEGORY_LINKS,
    )
    .constant(
        "evjReferenceCreationClassAssetLinks",
        CREATION_CLASS_ASSET_LINKS,
    )
    .constant(
        "evjReferenceCreationClassLiabilityLinks",
        CREATION_CLASS_LIABILITY_LINKS,
    );
