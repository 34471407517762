import { get, set, negate } from "lodash-es";
import { FormErrors } from "redux-form";
import { Constraint, Validator } from "@xiag/validator";
import { FieldError, FieldErrors } from "src/State/FieldError";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { isContractForm, isFinancingForm } from "src/Services/Utils/customerBalanceFormUtils";

const CUSTOMER_BALANCE_FORM_VALIDATOR = new Validator(new Constraint.All([
    new Constraint.Required(),
    new Constraint.Type("object"),

    new Constraint.Field("creationClass", new Constraint.All([
        new Constraint.Required(),
        new Constraint.Type("object"),
    ])),
    new Constraint.Field("bank", new Constraint.All([
        new Constraint.Required(),
        new Constraint.Type("object"),
    ])),

    new Constraint.Field("title", new Constraint.All([
        new Constraint.Required(),
        new Constraint.Type("string"),
    ])),
    new Constraint.Field("rubric", new Constraint.All([
        new Constraint.Type("string"),
    ])),

    new Constraint.Field("amount", new Constraint.All([
        new Constraint.Required(),
    ])),

    new Constraint.When(
        isContractForm,
        new Constraint.Field("endDate", new Constraint.All([
            new Constraint.Required(),
            new Constraint.Type("date"),
        ])),
    ),
    new Constraint.When(
        negate(isContractForm),
        new Constraint.Field("endDate", new Constraint.All([
            new Constraint.Empty(),
        ])),
    ),

    new Constraint.When(
        isFinancingForm,
        new Constraint.All([
            new Constraint.Field("currentMarketPrice", new Constraint.All([
                new Constraint.Required(),
            ])),
        ]),
    ),
    new Constraint.When(
        negate(isFinancingForm),
        new Constraint.All([
            new Constraint.Field("currentMarketPrice", new Constraint.All([
                new Constraint.Empty(),
            ])),
        ]),
    ),

]));

export function customBalanceFormValidator(
    formData: CustomerBalanceForm,
): FormErrors<CustomerBalanceForm, FieldErrors> {
    return CUSTOMER_BALANCE_FORM_VALIDATOR
        .validate(formData)
        .reduce((result, { path = "", code, data }) => {
            const value: unknown = get(formData, path);
            const errors: FieldErrors = get(result, path, []);
            const error: FieldError = {
                value: value,
                path: path,
                code: code,
                context: data,
            };

            return set(result, path, [...errors, error]);
        }, {});
}
