import { BalanceData } from "src/State/Balance/BalanceData";
import { CUSTOMER_BALANCE_DELETE_ACTIONS, CustomerBalanceDeleteActions } from "src/Actions/CustomerBalanceActions";

export function customerBalanceDeleteReducer(
    state: BalanceData,
    action: CustomerBalanceDeleteActions,
): BalanceData {
    switch (action.type) {
        case CUSTOMER_BALANCE_DELETE_ACTIONS.SUCCESS:
            const duplicate = state.objects.find((it) => it.mainId === action.data.id);
            const updated = state.objects.filter((object) => object.id !== action.data.id);
            return {
                ...state,
                objects: duplicate
                    ? updated.filter((object) => object.id !== duplicate.id)
                    : updated,
            };

        default:
            return state;
    }
}
