import { ParametersState } from "src/State/ParametersState";

StateApiRefUrlFactory.$inject = [
    "evjParameters",
];
export function StateApiRefUrlFactory(
    parameters: ParametersState,
): string {
    return parameters.refURL.replace(/\/+$/, "");
}
