import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";
import { getBalanceLiquidityPlanning } from "src/Selectors/Balance/getBalanceLiquidityPlanning";
import {
    LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS,
    LiquidityPlanningChangeRequest,
} from "src/Actions/LiquidityPlanningActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* liquidityPlanningChangeWishReserveSaga({ data: value }: LiquidityPlanningChangeRequest): SagaIterator {
    const liquidityPlanning: BalanceLiquidityPlanning = yield select(getBalanceLiquidityPlanning);

    const maxValue = liquidityPlanning.total
        .sub(liquidityPlanning.planned)
        .sub(liquidityPlanning.provision)
        .sub(liquidityPlanning.investment)
        .toDecimalPlaces(0);

    yield put(LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS.success({
        ...liquidityPlanning,
        wishReserve: value.toDecimalPlaces(0).lessThan(maxValue)
            ? value.toDecimalPlaces(0)
            : maxValue,
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
