import { ChecklistSection } from "src/State/Checklist/ChecklistSection";
import { ChecklistUseCase } from "src/State/Checklist/ChecklistUseCase";
import { ChecklistModule } from "src/State/Checklist/ChecklistModule";

export const CHECKLIST_DEFAULT_SECTION: ChecklistSection = {
    id: "balanceSectionOutstandingsCustomer",

    imageKey: "calendar",
    collapsed: true,

    name: {
        de: "Pendenzen Kunde",
        en: "Outstandings client",
        fr: "Client en attente",
    },
    items: [],
};
export const CHECKLIST_DEFAULT_USE_CASE: ChecklistUseCase = {
    id: "balanceUsecase1",
    name: {
        de: "Bilanz",
        en: "Balance",
        fr: "Bilan",
    },
    sections: [
        CHECKLIST_DEFAULT_SECTION,
    ],
};
export const CHECKLIST_DEFAULT_MODULE: ChecklistModule = {
    key: "balance",
    order: 800,
    name: {
        de: "Bilanz",
        en: "Balance",
        fr: "Bilan",
    },

    collapsed: true,
    discussed: false,
    showsResultColumnItem: true,

    useCases: [],
    useCase: CHECKLIST_DEFAULT_USE_CASE,
};
