import { Decimal } from "decimal.js-light";
import { ActionAny, ActionRequest, createActions } from "src/Utils/createActions";
import { BalanceLiquidityPlanning } from "src/State/Balance/BalanceLiquidityPlanning";

export type LiquidityPlanningChangeRequest = ActionRequest<Decimal>;
export type LiquidityPlanningChangeActions = ActionAny<Decimal, BalanceLiquidityPlanning>;

export const LIQUIDITY_PLANNING_CHANGE_TOTAL_ACTIONS =
    createActions<Decimal, BalanceLiquidityPlanning>("LIQUIDITY_PLANNING_CHANGE_TOTAL");

export const LIQUIDITY_PLANNING_CHANGE_PLANNED_ACTIONS =
    createActions<Decimal, BalanceLiquidityPlanning>("LIQUIDITY_PLANNING_CHANGE_PLANNED");

export const LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE_ACTIONS =
    createActions<Decimal, BalanceLiquidityPlanning>("LIQUIDITY_PLANNING_CHANGE_WISH_RESERVE");

export const LIQUIDITY_PLANNING_CHANGE_PROVISION_ACTIONS =
    createActions<Decimal, BalanceLiquidityPlanning>("LIQUIDITY_PLANNING_CHANGE_PROVISION");

export const LIQUIDITY_PLANNING_CHANGE_INVESTMENT_ACTIONS =
    createActions<Decimal, BalanceLiquidityPlanning>("LIQUIDITY_PLANNING_SET_INVESTMENT");
