import { ChecklistData } from "src/State/Checklist/ChecklistData";
import { ChecklistModule } from "src/State/Checklist/ChecklistModule";
import { ChecklistUseCase } from "src/State/Checklist/ChecklistUseCase";
import { ChecklistSection } from "src/State/Checklist/ChecklistSection";
import { ChecklistItem } from "src/State/Checklist/ChecklistItem";
import { ChecklistTrash } from "src/State/Checklist/ChecklistTrash";

export interface IChecklistDataMapper {
    mapChecklist(
        checklist: Readonly<Graviton.Consultation.Checklist.Checklist>,
    ): ChecklistData;
}

export class ChecklistDataMapper implements IChecklistDataMapper {
    public mapChecklist(
        checklist: Readonly<Graviton.Consultation.Checklist.Checklist>,
    ): ChecklistData {
        return {
            modules: this.mapModules(checklist),
            trash: this.mapTrash(checklist.trash),
        };
    }

    private mapModules(checklist: Graviton.Consultation.Checklist.Checklist): ChecklistModule[] {
        type ChecklistKeys = keyof (typeof checklist);
        type ModuleOnlyKey = Exclude<ChecklistKeys, "trash">;

        return Object.keys(checklist)
            .filter((key: ChecklistKeys) => key !== "trash")
            .map((key: ModuleOnlyKey) => this.mapModule(checklist[key]!, key))
            .sort((a, b) => a.order - b.order)
            .map((module, index) => ({...module, order: index + 1}));
    }

    private mapModule(module: Graviton.Consultation.Checklist.Module, key: string): ChecklistModule {
        const useCases = module.useCases.map((useCase) => this.mapUseCase(useCase));

        return {
            key: key,
            name: module.name,
            order: module.order === undefined ? Number.MAX_SAFE_INTEGER : module.order,

            collapsed: module.collapsed,
            discussed: module.discussed,
            showsResultColumnItem: !!module.showsResultColumnItem,

            useCase: useCases.length === 1 ? useCases[0] : undefined,
            useCases: useCases.length === 1 ? [] : useCases,
        };
    }

    private mapUseCase(useCase: Graviton.Consultation.Checklist.UseCase): ChecklistUseCase {
        return {
            id: useCase.id,
            name: useCase.name,
            sections: useCase.sections.map((section) => this.mapSection(section)),
        };
    }

    private mapSection(section: Graviton.Consultation.Checklist.Section): ChecklistSection {
        return {
            id: section.id,
            name: section.name,

            collapsed: section.collapsed,
            imageKey: section.imageKey,

            items: section.items.map((item) => this.mapItem(item)),
        };
    }

    private mapItem(item: Graviton.Consultation.Checklist.Item): ChecklistItem {
        return {
            id: item.id,
            name: item.name,

            isChecked: item.isChecked,
            isOwnItem: item.isOwnItem,

            isHidden: !!item.isHidden,
            displayCondition: item.displayCondition,

            syncStatus: item.syncStatus,
            processStatus: item.processStatus,
            processExtraData: item.processExtraData,
            externalReference: item.externalReference,
        };
    }

    private mapTrash(trash: Graviton.Consultation.Checklist.Trash): ChecklistTrash {
        return {
            name: trash.name,
            items: trash.items.map((item) => this.mapItem(item)),

            collapsed: trash.collapsed,
            discussed: trash.discussed,
            showsResultColumnItem: !!trash.showsResultColumnItem,
        };
    }
}
