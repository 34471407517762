import * as React from "react";
import { EvojaTableCell } from "@evoja-web/uikit";

export function DepotDetailsTableCellAmount(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props} className="m03-depot-details-table__cell m03-depot-details-table__cell--amount">
            {props.children}
        </EvojaTableCell>
    );
}
export function DepotDetailsTableCellCurrency(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props} className="m03-depot-details-table__cell m03-depot-details-table__cell--currency">
            {props.children}
        </EvojaTableCell>
    );
}
export function DepotDetailsTableCellDate(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props} className="m03-depot-details-table__cell m03-depot-details-table__cell--date">
            {props.children}
        </EvojaTableCell>
    );
}
export function DepotDetailsTableCellButton(props: JSX.IntrinsicElements["td"]): JSX.Element {
    return (
        <EvojaTableCell {...props} className="m03-depot-details-table__cell m03-depot-details-table__cell--button">
            {props.children}
        </EvojaTableCell>
    );
}
