import { invalidDetailGroup } from "src/Utils/invalidDetailGroup";
import { BalanceObjectAccount, BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { BalanceDetailGroup } from "src/State/Balance/BalanceType";
import { assertBalanceObjectAccount, assertBalanceObjectFinancing } from "src/Services/Utils/balanceObjectGuard";

export function hasDetailGroupData(
    balanceObject: BalanceObjectAccount | BalanceObjectFinancing,
    detailGroup: BalanceDetailGroup,
): boolean {
    switch (detailGroup) {
        case BalanceDetailGroup.CURRENCY: {
            const { account } = assertBalanceObjectAccount(balanceObject);
            return account.accountCurrency.currency.id !== account.reportingCurrency.currency.id;
        }

        case BalanceDetailGroup.PLEDGES: {
            const { account, category } = assertBalanceObjectAccount(balanceObject);
            if (category.isProvision) {
                return account.accountGroup.pledge
                    ? account.accountGroup.pledge.length > 0
                    : false;
            }

            return account.pledge
                ? account.pledge.length > 0
                : false;
        }

        case BalanceDetailGroup.LIMITS: {
            const { account } = assertBalanceObjectAccount(balanceObject);
            return (
                account.creditLimit !== undefined ||
                account.reservedAmount !== undefined
            );
        }

        case BalanceDetailGroup.MORTGAGE: {
            const { tranches } = assertBalanceObjectFinancing(balanceObject);
            return tranches.length > 0;
        }

        case BalanceDetailGroup.DEPOT_DETAILS: {
            // there is a special helper `hasDepotData` for depots
            return false;
        }

        case BalanceDetailGroup.WITHDRAWAL_LIMITS: {
            const {
                account: {
                    withdrawalAmount,
                },
            } = assertBalanceObjectAccount(balanceObject);

            return (
                withdrawalAmount !== undefined &&
                withdrawalAmount >= 0.00
            );
        }

        case BalanceDetailGroup.CANCELLATIONS: {
            const {
                account: {
                    withdrawalAmount,
                    withdrawalCancellation = [],
                },
            } = assertBalanceObjectAccount(balanceObject);

            if (withdrawalAmount === undefined || withdrawalAmount < 0.00) {
                return false;
            }

            return withdrawalAmount > 0
                ? withdrawalCancellation.some(({ amount = 0 }) => amount > 0.00)
                : true;
        }

        case BalanceDetailGroup.BALANCE_DETAILS: {
            return true;
        }

        default:
            return invalidDetailGroup(detailGroup, { detailGroup, balanceObject });
    }
}
