import { BalanceCreationClassAssetType } from "src/State/Balance/BalanceCreationClassAsset";

export type CreationClassAssetLink = {
    readonly number: string;
    readonly type: BalanceCreationClassAssetType;
};
export const CREATION_CLASS_ASSET_LINKS: ReadonlyArray<CreationClassAssetLink> = [
    {
        number: "1",
        type: BalanceCreationClassAssetType.ACCOUNT,
    },
    {
        number: "2",
        type: BalanceCreationClassAssetType.SAVINGS,
    },
    {
        number: "3",
        type: BalanceCreationClassAssetType.SECURITIES,
    },
    {
        number: "4",
        type: BalanceCreationClassAssetType.CONTRACT,
    },
    {
        number: "5",
        type: BalanceCreationClassAssetType.PROVISION,
    },
];
