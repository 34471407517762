import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { noop } from "lodash-es";
import { bind } from "lodash-decorators/bind";
import { memoize } from "lodash-decorators/memoize";
import { EvojaButtonGrid, EvojaHtmlButton, EvojaIcon, EvojaModal, EvojaTable } from "@evoja-web/uikit";
import { HomePageProps } from "src/Pages/HomePage";
import { HomeCategory } from "src/Components/Home/HomeCategory";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { BalanceObject, BalanceObjectCustomerBalance, BalanceObjectFinancing } from "src/State/Balance/BalanceObject";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { FormattedGravitonDate } from "src/Components/Common/FormattedGravitonDate";
import { HomeObject } from "src/Components/Home/HomeObject";
import { balanceObjectSum } from "src/Services/Utils/balanceObjectSum";
import { balanceTotalSum } from "src/Services/Utils/balanceTotalSum";
import { balanceTotalSumInBank } from "src/Services/Utils/balanceTotalSumInBank";
import {
    isBalanceObjectCustomerBalance,
    isBalanceObjectFinancing,
    isBalanceObjectWithPortfolio,
} from "src/Services/Utils/balanceObjectGuard";
import {
    CustomerBalanceFormModalAsset,
    CustomerBalanceFormModalLiability,
} from "src/Components/CustomerBalanceForm/CustomerBalanceFormModal";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";
import { HomePortfolio } from "src/Components/Home/HomePortfolio";
import { RealEstateModal } from "src/Components/RealEstate/RealEstateModal";
import { PreserveContent } from "src/Components/Common/PreserveContent";

type State = {
    readonly editingObject: BalanceObjectCustomerBalance | null;
    readonly realEstateObject: BalanceObjectFinancing | null;
    readonly groupByPortfolio: boolean;
};

export class HomeScreen extends React.Component<HomePageProps, State> {
    public state: State = {
        editingObject: null,
        realEstateObject: null,
        groupByPortfolio: false,
    };

    public render(): JSX.Element {
        const { showDistribution } = this.props;
        return (
            <div className="m03-home-screen">
                <div className="m03-home-screen__row">
                    {this.renderAssets()}
                    {this.renderAssetsForm()}
                    {this.renderLiabilities()}
                    {this.renderLiabilitiesForm()}
                    {this.renderRealEstateModal()}
                </div>
                <div className="m03-home-screen__buttons">
                    <div className="m03-home-screen__buttons-item m03-home-screen__buttons-item--availability">
                        <label>
                            <Link to={{ search: "?view=availability" }}>
                                <EvojaHtmlButton shape="circle">
                                    <EvojaIcon icon="ellipsis"/>
                                </EvojaHtmlButton>
                            </Link>
                            <FormattedMessage id="homeScreen_availability"/>
                        </label>
                    </div>
                    <div className="m03-home-screen__buttons-item m03-home-screen__buttons-item--liquidity-planning">
                        <label>
                            <Link to={{ search: "?view=liquidity-planning" }}>
                                <EvojaHtmlButton shape="circle">
                                    <EvojaIcon icon="ellipsis"/>
                                </EvojaHtmlButton>
                            </Link>
                            <FormattedMessage id="homeScreen_liquidityPlaning"/>
                        </label>
                    </div>
                    {showDistribution && (
                        <div className="m03-home-screen__buttons-item m03-home-screen__buttons-item--asset-graphics">
                            <label>
                                <Link to={{ search: "?view=asset-graphics" }}>
                                    <EvojaHtmlButton shape="circle">
                                        <EvojaIcon icon="angleright"/>
                                    </EvojaHtmlButton>
                                </Link>
                                <FormattedMessage id="homeScreen_assetGraphics"/>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private renderAssets(): JSX.Element {
        const { groupByPortfolio } = this.state;
        const { assetCategories, assetPortfolios, creationDate, objects, bankName } = this.props;
        const totalAmount = balanceTotalSum(assetCategories, objects);
        const totalAmountInBank = balanceTotalSumInBank(assetCategories, objects);

        return (
            <div className="m03-home-screen__column">
                <div className="m03-home-screen__column-title">
                    <div className="m03-home-screen__column-title-text">
                        <FormattedMessage id="homeScreen_assets" values={{
                            date: (
                                <span className="m03-home-screen__creation-date">
                                    <FormattedMessage
                                        id="homeScreen_creationDate"
                                        values={{ date: <FormattedGravitonDate value={creationDate}/> }}/>
                                </span>
                            ),
                        }}/>
                    </div>
                    <EvojaHtmlButton className="m03-home-screen__create"
                                     onClick={this.openCreateAssetModal}
                                     shape="circle">
                        <EvojaIcon icon="plus-cross"/>
                    </EvojaHtmlButton>
                </div>
                <div className="m03-home-screen__total">
                    <div className="m03-home-screen__total-item">
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--title">
                            <FormattedMessage id="homeScreen_assets_total"/>
                        </div>
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--amount">
                            <FormattedNumber value={totalAmount.toNumber()}/>
                        </div>
                    </div>
                    <div className="m03-home-screen__total-item">
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--title">
                            <TranslatedMessage value={bankName}/>
                        </div>
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--amount">
                            <FormattedNumber value={totalAmountInBank.toNumber()}/>
                        </div>
                    </div>
                </div>
                <div className="m03-home-screen__categories m03-home-screen__categories--assets">
                    {groupByPortfolio
                        ? assetPortfolios.map((it) => this.renderPortfolio(it, BalanceCategoryType.ASSETS))
                        : assetCategories.map((it) => this.renderCategory(it))
                    }
                    {groupByPortfolio && this.renderOthersPortfolio(BalanceCategoryType.ASSETS)}
                </div>
            </div>
        );
    }

    private renderLiabilities(): JSX.Element {
        const { groupByPortfolio } = this.state;
        const { liabilityCategories, liabilityPortfolios, creationDate, objects, bankName } = this.props;
        const totalAmount = balanceTotalSum(liabilityCategories, objects);
        const totalAmountInBank = balanceTotalSumInBank(liabilityCategories, objects);

        return (
            <div className="m03-home-screen__column">
                <div className="m03-home-screen__column-title">
                    <div className="m03-home-screen__column-title-text">
                        <FormattedMessage id="homeScreen_liabilities" values={{
                            date: (
                                <span className="m03-home-screen__creation-date">
                                    <FormattedMessage
                                        id="homeScreen_creationDate"
                                        values={{ date: <FormattedGravitonDate value={creationDate}/> }}/>
                                </span>
                            ),
                        }}/>
                    </div>
                    <EvojaHtmlButton className="m03-home-screen__create"
                                     onClick={this.openCreateLiabilityModal}
                                     shape="circle">
                        <EvojaIcon icon="plus-cross"/>
                    </EvojaHtmlButton>
                </div>
                <div className="m03-home-screen__total">
                    <div className="m03-home-screen__total-item">
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--title">
                            <FormattedMessage id="homeScreen_liabilities_total"/>
                        </div>
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--amount">
                            <FormattedNumber value={totalAmount.toNumber()}/>
                        </div>
                    </div>
                    <div className="m03-home-screen__total-item">
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--title">
                            <TranslatedMessage value={bankName}/>
                        </div>
                        <div className="m03-home-screen__total-col m03-home-screen__total-col--amount">
                            <FormattedNumber value={totalAmountInBank.toNumber()}/>
                        </div>
                    </div>
                </div>
                <div className="m03-home-screen__grouping">
                    {this.renderCategoryOrPortfolioToggle()}
                </div>
                <div className="m03-home-screen__categories">
                    {groupByPortfolio
                        ? liabilityPortfolios.map((it) => this.renderPortfolio(it, BalanceCategoryType.LIABILITIES))
                        : liabilityCategories.map((it) => this.renderCategory(it))
                    }
                    {groupByPortfolio && this.renderOthersPortfolio(BalanceCategoryType.LIABILITIES)}
                </div>
            </div>
        );
    }

    private renderCategoryOrPortfolioToggle(): JSX.Element {
        const { groupByPortfolio } = this.state;
        return (
            <EvojaButtonGrid className="m03-home-screen__grouping-toggle">
                <EvojaHtmlButton type="button"
                                 size="sm"
                                 shape="square"
                                 block={false}
                                 active={!groupByPortfolio}
                                 onClick={this.toggleCategoryOrPortfolio}
                                 className="m03-home-screen__grouping-button">
                    <FormattedMessage id="homeScreen_grouping_category"/>
                </EvojaHtmlButton>
                <EvojaHtmlButton type="button"
                                 size="sm"
                                 shape="square"
                                 block={false}
                                 active={groupByPortfolio}
                                 onClick={this.toggleCategoryOrPortfolio}
                                 className="m03-home-screen__grouping-button">
                    <FormattedMessage id="homeScreen_grouping_portfolio"/>
                </EvojaHtmlButton>
            </EvojaButtonGrid>
        );
    }

    private renderAssetsForm(): JSX.Element {
        const { isAssetModalOpen, closeAssetModal, assetFormInitialData } = this.props;
        const { editingObject } = this.state;

        const initialFormValues = editingObject
            ? this.getUpdateInitialFormValues(editingObject)
            : assetFormInitialData;
        const deleteHandler = editingObject
            ? this.getDeleteObjectHandler(editingObject)
            : noop;

        return (
            <EvojaModal isOpen={isAssetModalOpen}
                        onExit={closeAssetModal}
                        className="m03-home-screen__customer-balance-modal">
                <PreserveContent>
                    {isAssetModalOpen && (
                        <CustomerBalanceFormModalAsset isEditing={editingObject !== null}
                                                       initialValues={initialFormValues}
                                                       saveCustomerBalance={this.saveCustomerBalance}
                                                       deleteCustomerBalance={deleteHandler}/>
                   )}
                </PreserveContent>
            </EvojaModal>
        );
    }

    private renderLiabilitiesForm(): JSX.Element {
        const { isLiabilityModalOpen, closeLiabilityModal, liabilityFormInitialData } = this.props;
        const { editingObject } = this.state;

        const initialFormValues = editingObject
            ? this.getUpdateInitialFormValues(editingObject)
            : liabilityFormInitialData;
        const deleteHandler = editingObject
            ? this.getDeleteObjectHandler(editingObject)
            : noop;

        return (
            <EvojaModal isOpen={isLiabilityModalOpen}
                        onExit={closeLiabilityModal}
                        className="m03-home-screen__customer-balance-modal">
                <PreserveContent>
                    {isLiabilityModalOpen && (
                        <CustomerBalanceFormModalLiability isEditing={editingObject !== null}
                                                           initialValues={initialFormValues}
                                                           saveCustomerBalance={this.saveCustomerBalance}
                                                           deleteCustomerBalance={deleteHandler}/>
                    )}
                </PreserveContent>
            </EvojaModal>
        );
    }

    private renderRealEstateModal(): JSX.Element {
        const { isRealEstateModalOpen, closeRealEstateModal, bankName } = this.props;
        const { realEstateObject } = this.state;

        return (
            <EvojaModal isOpen={isRealEstateModalOpen}
                        onExit={closeRealEstateModal}
                        className="m03-home-screen__real-estate-modal">
                <PreserveContent>
                    {realEstateObject && (
                        <RealEstateModal object={realEstateObject} bankName={bankName}/>
                    )}
                </PreserveContent>
            </EvojaModal>
        );
    }

    @bind()
    private saveCustomerBalance(formData: CustomerBalanceForm): void {
        const { closeAssetModal, closeLiabilityModal } = this.props;
        const { updateCustomerBalance, createCustomerBalance, deleteCustomerBalance } = this.props;
        closeAssetModal();
        closeLiabilityModal();

        const { editingObject } = this.state;
        if (editingObject) {
            if (editingObject.creationClass.type === formData.creationClass.type) {
                updateCustomerBalance(editingObject, formData);
            } else {
                deleteCustomerBalance(editingObject);
                createCustomerBalance(formData);
            }
        } else {
            createCustomerBalance(formData);
        }

        this.setState({ editingObject: null });
    }

    @bind()
    private deleteCustomerBalance(balanceObject: BalanceObjectCustomerBalance): void {
        const { closeAssetModal, closeLiabilityModal } = this.props;
        closeAssetModal();
        closeLiabilityModal();

        const { deleteCustomerBalance } = this.props;
        deleteCustomerBalance(balanceObject);

        this.setState({ editingObject: null });
    }

    @bind()
    private openCreateAssetModal(): void {
        const { openAssetModal } = this.props;
        this.setState({ editingObject: null }, openAssetModal);
    }

    @bind()
    private openCreateLiabilityModal(): void {
        const { openLiabilityModal } = this.props;
        this.setState({ editingObject: null }, openLiabilityModal);
    }

    @bind()
    private openUpdateAssetModal(balanceObject: BalanceObjectCustomerBalance): void {
        const { openAssetModal } = this.props;
        this.setState({ editingObject: balanceObject }, openAssetModal);
    }

    @bind()
    private openUpdateLiabilityModal(balanceObject: BalanceObjectCustomerBalance): void {
        const { openLiabilityModal } = this.props;
        this.setState({ editingObject: balanceObject }, openLiabilityModal);
    }

    @bind()
    private openRealEstateModal(balanceObject: BalanceObjectFinancing): void {
        const { openRealEstateModal } = this.props;
        this.setState({ realEstateObject: balanceObject }, openRealEstateModal);
    }

    @bind()
    private toggleCategoryOrPortfolio(): void {
        this.setState((prev) => ({ groupByPortfolio: !prev.groupByPortfolio }));
    }

    @memoize()
    private getUpdateObjectHandler(balanceObject: BalanceObject): () => void {
        if (isBalanceObjectFinancing(balanceObject)) {
            return () => this.openRealEstateModal(balanceObject);
        }

        if (isBalanceObjectCustomerBalance(balanceObject)) {
            return () => balanceObject.category.type === BalanceCategoryType.ASSETS
                ? this.openUpdateAssetModal(balanceObject)
                : this.openUpdateLiabilityModal(balanceObject);
        }

        return noop;
    }

    @memoize()
    private getDeleteObjectHandler(balanceObject: BalanceObject): () => void {
        if (!isBalanceObjectCustomerBalance(balanceObject)) {
            return noop;
        }

        return () => this.deleteCustomerBalance(balanceObject);
    }

    @memoize()
    private getUpdateInitialFormValues(balanceObject: BalanceObjectCustomerBalance): CustomerBalanceForm {
        return {
            creationClass: balanceObject.creationClass,
            bank: balanceObject.customerBalance.bank
                ? balanceObject.customerBalance.bank
                : null,

            title: balanceObject.customerBalance.title,
            rubric: balanceObject.customerBalance.rubric,

            amount: balanceObject.amount,
            endDate: balanceObject.customerBalance.endDate
                ? balanceObject.customerBalance.endDate
                : null,
            currentMarketPrice: balanceObject.customerBalance.currentMarketPrice
                ? balanceObject.customerBalance.currentMarketPrice
                : null,
        };
    }

    private renderCategory(category: BalanceCategory): JSX.Element | null {
        const { objects } = this.props;
        const categoryObjects = objects.filter((it) => it.category.id === category.id);
        const totalAmount = balanceObjectSum(categoryObjects);

        if (
            category.isRealEstate &&
            category.type === BalanceCategoryType.ASSETS &&
            categoryObjects.length === 0
        ) {
            return null;
        }

        return (
            <div key={category.id} className="m03-home-screen__category">
                <HomeCategory title={<TranslatedMessage value={category.title}/>}
                              amount={<FormattedNumber value={totalAmount.toNumber()}/>}>
                    {this.renderCategoryObjects(categoryObjects)}
                </HomeCategory>
            </div>
        );
    }

    private renderCategoryObjects(categoryObjects: ReadonlyArray<BalanceObject>): JSX.Element {
        if (!categoryObjects.length) {
            return (
                <div className="m03-home-screen__empty">
                    <FormattedMessage id="homeScreen_emptyCategory"/>
                </div>
            );
        }

        return (
            <EvojaTable className="m03-home-screen__table">
                {categoryObjects.map((object) => this.renderObject(object))}
            </EvojaTable>
        );
    }

    private renderObject(object: BalanceObject): JSX.Element {
        const { objects } = this.props;
        const mainObject = object.mainId
            ? objects.find((it) => object.mainId === it.id)
            : undefined;
        const editableObject = mainObject ?? object;
        const { groupByPortfolio } = this.state;

        return (
            <HomeObject key={object.id}
                        object={object}
                        showPortfolio={!groupByPortfolio}
                        editObject={this.getUpdateObjectHandler(editableObject)}/>
        );
    }

    private renderOthersPortfolio(categoryType: BalanceCategoryType): React.ReactNode {
        const { objects } = this.props;
        const othersPortfolioObjects = objects
            .filter((it) => it.category.type === categoryType)
            .filter((it) => isBalanceObjectWithPortfolio(it) && !it.portfolio);
        if (!othersPortfolioObjects.length) {
            return null;
        }

        const totalAmount = balanceObjectSum(othersPortfolioObjects);
        return (
            <div key={`no-portfolio-${categoryType}`} className="m03-home-screen__category">
                <HomeCategory amount={<FormattedNumber value={totalAmount.toNumber()}/>}
                              title={categoryType === BalanceCategoryType.ASSETS
                                  ? <FormattedMessage id="homeScreen_othersPortfolio_assets"/>
                                  : <FormattedMessage id="homeScreen_othersPortfolio_liabilities"/>
                              }
                >
                    {this.renderCategoryObjects(othersPortfolioObjects)}
                </HomeCategory>
            </div>
        );
    }

    private renderPortfolio(portfolio: BalancePortfolio, categoryType: BalanceCategoryType): JSX.Element {
        const { objects } = this.props;
        const portfolioObjects = objects
            .filter((it) => it.category.type === categoryType)
            .filter((it) => isBalanceObjectWithPortfolio(it) && it.portfolio?.id === portfolio.id);
        const totalAmount = balanceObjectSum(portfolioObjects);

        return (
            <div key={portfolio.id} className="m03-home-screen__category">
                <HomePortfolio portfolio={portfolio} amount={totalAmount}>
                    {this.renderCategoryObjects(portfolioObjects)}
                </HomePortfolio>
            </div>
        );
    }
}
