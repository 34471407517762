import { createPartial } from "src/Utils/createPartial";
import { isDefined } from "src/Utils/isDefined";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { BalanceData } from "src/State/Balance/BalanceData";
import { isBalanceObjectCustomerBalance } from "src/Services/Utils/balanceObjectGuard";
import { IDateConverter } from "src/Services/Utils/DateConverter";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";

export interface IBalanceSharedAccountsMapper {
    mapSharedAccounts(
        balance: BalanceData,
        originalBalance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Graviton.Consultation.Investment.Consultation.Account[];
}

export class BalanceSharedAccountsMapper implements IBalanceSharedAccountsMapper {
    public static $inject: string[] = [
        "evjDateConverter",
        "evjCoreConfiguration",
    ];

    public constructor(
        private dateConverter: IDateConverter,
        private coreConfiguration: CoreConfiguration,
    ) {
    }

    public mapSharedAccounts(
        balance: BalanceData,
        originalBalance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): Graviton.Consultation.Investment.Consultation.Account[] {
        return balance.objects.map((account) => {
            if (!isBalanceObjectCustomerBalance(account)) {
                return undefined;
            }

            const original = originalBalance.consultation.sharedAccounts.find((it) => it.id === account.id);
            const assetSegment = original ? original.assetSegment : this.mapAssetSegment(balance, account);
            if (!assetSegment) {
                return undefined;
            }

            return createPartial<Graviton.Consultation.Investment.Consultation.Account>({
                ...original,
                id: account.id,
                assetSegment: assetSegment,
                amount: account.amount.toString(),
                customAmount: account.amount.toString(),
                title: account.customerBalance.title,
                rubric: account.customerBalance.rubric,
                isAtThirdParty: account.customerBalance.isAtThirdParty,
                bankId: account.customerBalance.isAtThirdParty
                    ? account.customerBalance.bank
                        ? account.customerBalance.bank.id
                        : undefined
                    : this.coreConfiguration.bankId,
                customEndDate: account.customerBalance.endDate
                    ? this.dateConverter.convertToApi(account.customerBalance.endDate)
                    : undefined,
            });
        }).filter(isDefined);
    }

    private mapAssetSegment(
        balance: BalanceData,
        account: BalanceObjectCustomerBalance,
    ): Graviton.Entity.Code | undefined {
        const categoryMapping = balance.categoryMappings.find((it) =>
            it.category.id === account.category.balanceCategoryId &&
            it.creationClass.id === account.creationClass.code.id &&
            it.assetSegment !== undefined
        );
        return categoryMapping
            ? categoryMapping.assetSegment
            : undefined;
    }
}
