import { Container, Provider } from "@xiag/container";
import { ApplicationState } from "src/State/ApplicationState";
import { ILogger } from "src/Modules/Logger/Logger";
import { LOGGER_PROVIDER } from "src/Modules/Logger/LoggerProvider";
import { HTTP_PROVIDER } from "src/Modules/Http/HttpProvider";
import { BROWSER_PROVIDER } from "src/Modules/Browser/BrowserProvider";
import { SERVICE_STATELESS_PROVIDER } from "src/Services/ServiceStatelessProvider";
import { SERVICE_STATEFUL_PROVIDER } from "src/Services/ServiceStatefulProvider";

sagaLogging.$inject = ["$logger", "$selector", "$state"];
function sagaLogging($logger: ILogger, $selector: string, $state: any): void {
    $logger.debug("Executing saga selector...", { $selector, $state });
}

const statelessContainer = new Container(new Provider([
    LOGGER_PROVIDER,
    BROWSER_PROVIDER,
    SERVICE_STATELESS_PROVIDER,
]));
const statefulProvider = new Provider([
    HTTP_PROVIDER,
    LOGGER_PROVIDER,
    BROWSER_PROVIDER,
    SERVICE_STATELESS_PROVIDER,
    SERVICE_STATEFUL_PROVIDER,
]).configure(sagaLogging, sagaLogging.$inject || []);


export function selectService<T>(state: ApplicationState, service: string): T {
    if (statelessContainer.has(service)) {
        return statelessContainer.get(service);
    }

    const stateAwareProvider = new Provider()
        .constant("$state", state)
        .constant("$selector", service);

    const provider = new Provider([statefulProvider, stateAwareProvider]);
    const container = new Container(provider);
    return container.get<T>(service);
}
