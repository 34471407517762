import { UiState } from "src/State/UiState";
import {
    UI_MODAL_CLOSE,
    UI_MODAL_OPEN,
    UI_POPOVER_CLOSE,
    UI_POPOVER_OPEN,
    UiAction,
} from "src/Actions/UiActions";

export function uiReducer(
    state: UiState,
    action: UiAction,
): UiState {
    switch (action.type) {
        case UI_MODAL_OPEN:
            return {
                ...state,
                openModals: [
                    ...state.openModals.filter((id) => id !== action.modalId),
                    action.modalId,
                ],
            };

        case UI_MODAL_CLOSE:
            return {
                ...state,
                openModals: state.openModals.filter((id) => id !== action.modalId),
            };

        case UI_POPOVER_OPEN:
            return {
                ...state,
                openPopovers: [
                    ...state.openPopovers.filter((id) => id !== action.popoverId),
                    action.popoverId,
                ],
            };

        case UI_POPOVER_CLOSE:
            return {
                ...state,
                openPopovers: state.openPopovers.filter((id) => id !== action.popoverId),
            };

        default:
            return state;
    }
}
