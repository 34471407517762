import { Decimal } from "decimal.js-light";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { CORE_CONFIG_KEYS } from "src/Constants/CoreConfigKeys";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";

export interface ICoreConfigurationMapper {
    mapCoreConfiguration(configuration: ReadonlyArray<Graviton.Core.Config>): CoreConfiguration;
}

export class CoreConfigurationMapper implements ICoreConfigurationMapper {
    public mapCoreConfiguration(configuration: ReadonlyArray<Graviton.Core.Config>): CoreConfiguration {
        return {
            styling: this.mapStyling(configuration),
            bankId: this.mapBankId(configuration),
            provisionCategoryId: this.mapProvisionCategoryId(configuration),
            realEstateCategoryId: this.mapRealEstateCategoryId(configuration),
            nkkPercent: this.mapNkkPercent(configuration),
            selectiveAvailableAmountDefaultValue: this.mapSelectiveAvailableAmountDefaultValue(configuration),
            positionBasedAvaliabilityCategoryIds: this.mapPositionBasedAvaliabilityCategoryIds(configuration),
            assetGroupings: this.mapAssetGroupings(configuration),
            investablePositions: this.mapInvestablePositions(configuration),
        };
    }

    private mapStyling(configuration: ReadonlyArray<Graviton.Core.Config>): string {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.styling);
        return config ? config.value : "swisscom";
    }
    private mapBankId(configuration: ReadonlyArray<Graviton.Core.Config>): string {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.bankId);
        return config ? config.value : "";
    }
    private mapProvisionCategoryId(configuration: ReadonlyArray<Graviton.Core.Config>): string {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.provisionCategoryId);
        return config ? config.value : "balanceCategory-3";
    }
    private mapRealEstateCategoryId(configuration: ReadonlyArray<Graviton.Core.Config>): string {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.realEstateCategoryId);
        return config ? config.value : "balanceCategory-4";
    }
    private mapNkkPercent(configuration: ReadonlyArray<Graviton.Core.Config>): Decimal {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.nkkPercent);
        return config ? new Decimal(config.value) : ZERO_DECIMAL;
    }
    private mapSelectiveAvailableAmountDefaultValue(configuration: ReadonlyArray<Graviton.Core.Config>): boolean {
        const config = configuration.find((it) => it.key === CORE_CONFIG_KEYS.selectiveAvailableAmountDefaultValue);
        return config ? config.value === "true" : false;
    }
    private mapPositionBasedAvaliabilityCategoryIds(configuration: ReadonlyArray<Graviton.Core.Config>): string[] {
        return this.toArray(configuration, CORE_CONFIG_KEYS.positionBasedAvaliabilityCategoryIds);
    }
    private mapAssetGroupings(configuration: ReadonlyArray<Graviton.Core.Config>): string[] {
        return this.toArray(configuration, CORE_CONFIG_KEYS.assetGroupings);
    }
    private mapInvestablePositions(configuration: ReadonlyArray<Graviton.Core.Config>): string[] {
        return this.toArray(configuration, CORE_CONFIG_KEYS.investablePositions);
    }
    private toArray(configuration: ReadonlyArray<Graviton.Core.Config>, key: string): string[] {
        const config = configuration.find((it) => it.key === key);
        return config ? config.value.split(",").map((it) => it.trim()) : [];
    }
}
