import { applyMiddleware, Middleware, StoreEnhancer } from "redux";
import { ReduxDevToolsAwareWindow } from "redux-dev-tools-window-extension";

EnhancerFactory.$inject = [
    "$window",
    "evjMiddlewareSaga",
    "evjMiddlewareRouter",
];
export function EnhancerFactory(
    $window: ReduxDevToolsAwareWindow,
    ...middlewares: Middleware[]
): StoreEnhancer {
    const enhancer = applyMiddleware(...middlewares);
    return $window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? $window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(enhancer)
        : enhancer;
}
