import { assertDefined } from "src/Utils/assertionHelpers";
import { createSelector } from "reselect";
import { getConsultationData } from "src/Selectors/getConsultationData";

export const getBalanceOriginal = createSelector([
    getConsultationData,
], (consultationData) => assertDefined(consultationData.originalBalance,
    "There is no `balance` data in the consultation",
    {consultationData},
));
