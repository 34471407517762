import { SagaIterator } from "redux-saga";
import { HostRequestAction } from "src/Actions/HostCommandActions";

const emptyIterator = [][Symbol.iterator]();

type SagaDescription<TRequest extends string> = (action: HostRequestAction<TRequest>) => SagaIterator;
export function attachHostSaga<TRequest extends string>(
    requestType: TRequest,
    requestSaga: SagaDescription<TRequest>,
): SagaDescription<TRequest> {
    return function ({ type, request }: HostRequestAction<TRequest>): SagaIterator {
        if (request !== requestType) {
            return emptyIterator;
        }

        return requestSaga({ type, request });
    };
}
