import { assertDefined } from "src/Utils/assertionHelpers";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { BalanceCategoryLink } from "src/Services/Reference/BalanceCategoryLink";
import { DisplayClassLink } from "src/Services/Reference/DisplayClassLink";
import { BalanceDetailGroup, BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { OutputClassLink } from "src/Services/Reference/OutputClassLink";
import { DetailGroupLink } from "src/Services/Reference/DetailGroupLink";
import { BalanceAvailabilityCategory } from "src/State/Balance/BalanceAvailabilityCategory";

export interface IBalanceCategoryMapper {
    mapBalanceCategories(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        availabilityCategories: ReadonlyArray<BalanceAvailabilityCategory>,
    ): BalanceCategory[];
}

export class BalanceCategoryMapper implements IBalanceCategoryMapper {
    public static $inject: string[] = [
        "evjReferenceBalanceCategoryLinks",
        "evjReferenceDisplayClassLinks",
        "evjReferenceOutputClassLinks",
        "evjReferenceDetailGroupLinks",
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjCoreConfiguration",
    ];
    public constructor(
        private balanceCategoryLinks: ReadonlyArray<BalanceCategoryLink>,
        private displayClassLinks: ReadonlyArray<DisplayClassLink>,
        private outputClassLinks: ReadonlyArray<OutputClassLink>,
        private detailGroupLinks: ReadonlyArray<DetailGroupLink>,
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private coreConfiguration: CoreConfiguration,
    ) {
    }

    public mapBalanceCategories(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        availabilityCategories: ReadonlyArray<BalanceAvailabilityCategory>,
    ): BalanceCategory[] {
        return balance.parameterData.categories
            .map((category) => this.mapCategory(category, availabilityCategories, balance))
            .reduce((result, category) => {
                if (category.isRealEstate) {
                    const realEstateAssetsCategory: BalanceCategory = {
                        ...category,
                        id: `${BalanceCategoryType.ASSETS}/${category.balanceCategoryId}`,
                        type: BalanceCategoryType.ASSETS,
                    };
                    return result.concat(category, realEstateAssetsCategory);
                }
                return result.concat(category);
            }, [] as BalanceCategory[])
            .sort((a, b) => a.order - b.order)
            .map((category, index) => ({...category, order: index + 1}));
    }

    private mapCategory(
        balanceCategory: Readonly<Graviton.Balance.Category>,
        availabilityCategories: ReadonlyArray<BalanceAvailabilityCategory>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceCategory {
        const balanceCategoryLink = assertDefined(
            this.balanceCategoryLinks.find((it) => it.id === balanceCategory.type.id),
            `Could not find balance category link "${balanceCategory.type.id}"`,
            { balanceCategory, links: this.balanceCategoryLinks },
        );
        const categoryDefault = assertDefined(
            balanceCategory.default,
            `Balance "default" field is not defined "${balanceCategory.id}"`,
            { balanceCategory },
        );

        const { availabilityType } = balanceCategory;
        const availabilityCategory = availabilityType
            ? availabilityCategories.find(({ coreType }) => this.extRefComparator.compareRefs(
                this.extRefGenerator.createRef(EndpointName.ENTITY_CODE, coreType.id),
                availabilityType.$ref,
            ))
            : undefined;

        return {
            id: `${balanceCategoryLink.type}/${balanceCategory.id}`,
            balanceCategoryId: balanceCategory.id,
            title: balanceCategory.title,
            type: balanceCategoryLink.type,
            order: balanceCategory.order === undefined
                ? Number.MAX_SAFE_INTEGER
                : balanceCategory.order,
            coreTypes: balanceCategory.coreType
                ? balanceCategory.coreType
                : [],

            availabilityCategory: availabilityCategory,
            availabilityDetailGroups: categoryDefault.availabilityDetailGroup
                ? this.mapDetailGroups(categoryDefault.availabilityDetailGroup, balance)
                : [],

            displayClassType: this.getDisplayClass(categoryDefault.displayClass.$ref, balance),
            outputClassType: this.getOutputClass(categoryDefault.outputClass.$ref, balance),
            detailGroupTypes: categoryDefault.detailGroup
                ? this.mapDetailGroups(categoryDefault.detailGroup, balance)
                : [],
            isProvision: this.isSpecialCategory(balanceCategory, this.coreConfiguration.provisionCategoryId),
            isRealEstate: this.isSpecialCategory(balanceCategory, this.coreConfiguration.realEstateCategoryId),
        };
    }

    private isSpecialCategory(
        balanceCategory: Readonly<Graviton.Balance.Category>,
        categoryId: string,
    ): boolean {
        if (!balanceCategory.coreType) {
            return false;
        }

        const categoryRef = this.extRefGenerator.createRef(
            EndpointName.ENTITY_CODE,
            categoryId,
        );

        return balanceCategory.coreType.some((coreType) => this.extRefComparator.compareRefs(
            coreType.$ref,
            categoryRef,
        ));
    }

    private findCodeByRef(
        codes: ReadonlyArray<Graviton.Entity.Code>,
        $ref: Graviton.Common.ExtReference<Graviton.Entity.Code>,
    ): Graviton.Entity.Code | undefined {
        return codes.find((code) => this.extRefComparator.compareRefs(
            $ref,
            this.extRefGenerator.createRef(EndpointName.ENTITY_CODE, code.id),
        ));
    }

    private getDisplayClass(
        $ref: Graviton.Common.ExtReference<Graviton.Entity.Code>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceDisplayClassType {
        const { balanceDisplayClasses } = balance.parameterData;
        const displayClass = assertDefined(
            this.findCodeByRef(balanceDisplayClasses, $ref),
            `Could not find balanceDisplayClass "${$ref}"`,
            {balanceDisplayClasses},
        );

        const balanceDisplayClassLink = assertDefined(
            this.displayClassLinks.find((it) => it.number === displayClass.number),
            `Could not find balanceDisplayClass link "${displayClass.number}"`,
            {displayClass, links: this.displayClassLinks},
        );

        return balanceDisplayClassLink.type;
    }

    private getOutputClass(
        $ref: Graviton.Common.ExtReference<Graviton.Entity.Code>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceOutputClassType {
        const { balanceOutputClasses } = balance.parameterData;
        const outputClass = assertDefined(
            this.findCodeByRef(balanceOutputClasses, $ref),
            `Could not find balanceOutputClass "${$ref}"`,
            {balanceOutputClasses},
        );

        const balanceOutputClassLink = assertDefined(
            this.outputClassLinks.find((it) => it.number === outputClass.number),
            `Could not find balanceOutputClass link "${outputClass.number}"`,
            {outputClass, links: this.outputClassLinks},
        );

        return balanceOutputClassLink.type;
    }

    private mapDetailGroups(
        detailGroupRefObjects: ReadonlyArray<Graviton.Common.ExtRefObject<Graviton.Entity.Code>>,
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceDetailGroup[] {
        const { balanceDetailGroups } = balance.parameterData;

        return detailGroupRefObjects.map((detailGroup) => {
            const code = assertDefined(
                this.findCodeByRef(balanceDetailGroups, detailGroup.$ref),
                `Could not find balanceDetailGroup "${detailGroup.$ref}"`,
                {balanceDetailGroups},
            );
            return assertDefined(
                this.detailGroupLinks.find((it) => it.number === code.number),
                `Could not find balanceDetailGroup link "${code.number}"`,
                {code, links: this.detailGroupLinks},
            ).type;
        });
    }
}
