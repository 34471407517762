import * as React from "react";
import { Decimal } from "decimal.js-light";
import { BalancePortfolio } from "src/State/Balance/BalancePortfolio";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import { HomeCategory } from "src/Components/Home/HomeCategory";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";

type Props = {
    readonly portfolio: BalancePortfolio;
    readonly amount: Decimal;
};
export class HomePortfolio extends React.PureComponent<Props> {

    public render(): JSX.Element {
        const { amount, children } = this.props;
        return (
            <HomeCategory title={this.renderTitle()}
                          amount={<FormattedNumber value={amount.toNumber()}/>}>
                {children}
            </HomeCategory>
        );
    }

    private renderTitle(): JSX.Element {
        const { portfolio } = this.props;
        return (
            <div className="m03-home-portfolio">
                <div className="m03-home-portfolio__symbol">{portfolio.alphabetChar}</div>
                <div className="m03-home-portfolio__title">
                    <div className="m03-home-portfolio__title-text">
                        {portfolio.portfolioKind && <TranslatedMessage value={portfolio.portfolioKind.text}/>}
                    </div>
                    <div className="m03-home-portfolio__title-number">
                        {portfolio.numberFormatted}
                    </div>
                </div>
            </div>
        );
    }
}
