import { BalanceDetailGroup } from "src/State/Balance/BalanceType";

export type DetailGroupLink = {
    readonly number: string;
    readonly type: BalanceDetailGroup;
};
export const DETAIL_GROUP_LINKS: ReadonlyArray<DetailGroupLink> = [
    {
        number: "1",
        type: BalanceDetailGroup.PLEDGES,
    },
    {
        number: "2",
        type: BalanceDetailGroup.LIMITS,
    },
    {
        number: "3",
        type: BalanceDetailGroup.DEPOT_DETAILS,
    },
    {
        number: "4",
        type: BalanceDetailGroup.MORTGAGE,
    },
    {
        number: "5",
        type: BalanceDetailGroup.WITHDRAWAL_LIMITS,
    },
    {
        number: "6",
        type: BalanceDetailGroup.CANCELLATIONS,
    },
    {
        number: "7",
        type: BalanceDetailGroup.BALANCE_DETAILS,
    },
];
