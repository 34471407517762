import { SagaIterator } from "redux-saga";
import { put, call, select } from "redux-saga/effects";
import { NativeError } from "src/Errors/NativeError";
import { selectService } from "src/Modules/Bind/selectService";
import { CONSULTATION_ACTIONS } from "src/Actions/ConsultationActions";
import { IConsultationService } from "src/Services/ConsultationService";
import { ConsultationData } from "src/State/ConsultationState";

export function* consultationSaga(): SagaIterator {
    try {
        yield put(CONSULTATION_ACTIONS.pending());

        const consultationService: IConsultationService = yield select(selectService, "evjConsultationService");
        const consultation: ConsultationData = yield call(consultationService);

        yield put(CONSULTATION_ACTIONS.success(consultation));
    } catch (error) {
        yield put(CONSULTATION_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
