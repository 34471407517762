import { BalanceCreationClassLiabilityType } from "src/State/Balance/BalanceCreationClassLiability";

export type CreationClassLiabilityLink = {
    readonly number: string;
    readonly type: BalanceCreationClassLiabilityType;
};
export const CREATION_CLASS_LIABILITY_LINKS: ReadonlyArray<CreationClassLiabilityLink> = [
    {
        number: "1",
        type: BalanceCreationClassLiabilityType.PROPERTY,
    },
    {
        number: "2",
        type: BalanceCreationClassLiabilityType.OTHER,
    },
];
