import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Decimal } from "decimal.js-light";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import { TranslatedMessage } from "src/Components/Common/TranslatedMessage";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};
export function DetailGroupDataCurrency({ balanceObject }: Props): JSX.Element | null {
    const { account: { reportingCurrency, accountCurrency } } = balanceObject;
    if (reportingCurrency.currencyIso.id === accountCurrency.currencyIso.id) {
        return null;
    }

    return (
        <DetailGroupTable>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_title_accountCurrency"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    {accountCurrency.currencyIso.textShort
                        ? <TranslatedMessage value={accountCurrency.currencyIso.textShort}/>
                        : <TranslatedMessage value={accountCurrency.currencyIso.text}/>}
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_empty"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={new Decimal(accountCurrency.balance).toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>
        </DetailGroupTable>
    );
}
