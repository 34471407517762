import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import {
    AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS,
    AvailabilityToggleObjectAmountRequest,
} from "src/Actions/AvailabilityActions";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";

export function* availabilityToggleObjectAmountSaga(
    { data: { object, included } }: AvailabilityToggleObjectAmountRequest,
): SagaIterator {
    yield put(AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS.success({
        ...object,
        availableAmount: {
            ...object.availableAmount,
            included: included,
        },
    }));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
