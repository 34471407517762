import * as React from "react";
import * as moment from "moment";
import { EnvironmentContext } from "src/Context/EnvironmentContext";

type Props = {
    readonly value: Graviton.Common.DateTime | Date;
    readonly format?: string;
};

export function FormattedGravitonDate({ value, format = "DD.MM.YYYY" }: Props): JSX.Element {
    const { parseGravitonDate } = React.useContext(EnvironmentContext);
    const valueAsDate = value instanceof Date ? value : parseGravitonDate(value);

    return <>{moment(valueAsDate).format(format)}</>;
}
