import { ApplicationState } from "src/State/ApplicationState";
import { ILogger } from "src/Modules/Logger/Logger";
import { IPatchEndpoint } from "src/Services/ApiClient/JsonEndpoint";
import { LogicError } from "src/Errors/LogicError";
import { NativeError } from "src/Errors/NativeError";
import { RuntimeError } from "src/Errors/RuntimeError";
import { ISynchronizationMapper } from "src/Services/SynchronizationData/SynchronizationMapper";

export type ISynchronizationService = () => Promise<void>;

SynchronizationServiceFactory.$inject = [
    "$state",
    "$logger",
    "evjApiEndpointConsultation",
    "evjSynchronizationMapper",
];
export function SynchronizationServiceFactory(
    $state: ApplicationState,
    $logger: ILogger,
    apiEndpointConsultation: IPatchEndpoint<Graviton.Consultation.Consultation>,
    synchronizationMapper: ISynchronizationMapper,
): ISynchronizationService {
    return async () => {
        const consultation = $state.consultation.data;
        if (!consultation) {
            throw new LogicError("Consultation state has to be fetched first", {$state});
        }

        try {
            $logger.info(`Mapping consultation "${$state.parameters.consultationId}"...`);
            const request = await synchronizationMapper.createSyncRequest(consultation);
            $logger.info(`Mapping consultation "${$state.parameters.consultationId}"... done`, {request});

            $logger.info(`Saving consultation "${$state.parameters.consultationId}"...`, {request});
            const response = await apiEndpointConsultation.patch($state.parameters.consultationId, request);
            $logger.info(`Saving consultation "${$state.parameters.consultationId}"... done`, {request, response});
        } catch (error) {
            const wrappedError = NativeError.wrapError(error);
            $logger.error(`Error while saving consultation "${$state.parameters.consultationId}"`, wrappedError);
            throw new RuntimeError(`Could not synchronize consultation data`, {$state}, wrappedError);
        }
    };
}
