import { assertDefined } from "src/Utils/assertionHelpers";
import { CoreConfiguration } from "src/State/Configuration/CoreConfiguration";
import { AvailabilityCategoryLink } from "src/Services/Reference/AvailabilityCategoryLink";
import { BalanceAvailabilityCategory } from "src/State/Balance/BalanceAvailabilityCategory";

export interface IBalanceAvailabilityCategoryMapper {
    mapBalanceAvailabilityCategories(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceAvailabilityCategory[];
}

export class BalanceAvailabilityCategoryMapper implements IBalanceAvailabilityCategoryMapper {
    public static $inject: ReadonlyArray<string> = [
        "evjCoreConfiguration",
        "evjReferenceAvailabilityCategoryLinks",
    ];
    public constructor(
        private coreConfiguration: CoreConfiguration,
        private availabilityCategoryLinks: ReadonlyArray<AvailabilityCategoryLink>,
    ) {
    }

    public mapBalanceAvailabilityCategories(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
    ): BalanceAvailabilityCategory[] {
        return balance.parameterData.balanceAvailabilityTypes
            .map((categorySubType) => this.mapAvailabilityCategory(categorySubType))
            .sort((a, b) => a.order - b.order)
            .map((category, index) => ({ ...category, order: index + 1 }));
    }

    private mapAvailabilityCategory(
        categorySubType: Readonly<Graviton.Entity.Code>,
    ): BalanceAvailabilityCategory {
        const availabilityCategoryLink = assertDefined(
            this.availabilityCategoryLinks.find((link) => link.id === categorySubType.id),
            `Could not find availability category link "${categorySubType.id}"`,
            { categorySubType, links: this.availabilityCategoryLinks },
        );

        return {
            id: categorySubType.id,
            type: availabilityCategoryLink.type,
            order: categorySubType.order === undefined
                ? Number.MAX_SAFE_INTEGER
                : categorySubType.order,

            title: categorySubType.text,
            coreType: categorySubType,

            hasPositionBasedAvailability: this.coreConfiguration
                .positionBasedAvaliabilityCategoryIds
                .includes(categorySubType.id),
        };
    }
}
