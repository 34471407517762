import * as React from "react";
import { FormattedMessage } from "react-intl";
import Decimal from "decimal.js-light";
import { BalanceObjectAccount } from "src/State/Balance/BalanceObject";
import { FormattedNumber } from "src/Components/Common/FormattedNumber";
import {
    DetailGroupTable,
    DetailGroupTableCellAmount,
    DetailGroupTableCellValue,
    DetailGroupTableHead,
    DetailGroupTableRow,
} from "src/Components/DetailGroup/DetailGroupTable";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";

type Props = {
    readonly balanceObject: BalanceObjectAccount;
};
export function DetailGroupDataLimits({ balanceObject }: Props): JSX.Element | null {
    const { account } = balanceObject;
    if (account.creditLimit === undefined && account.reservedAmount === undefined) {
        return null;
    }

    const creditLimit = account.creditLimit === undefined ? ZERO_DECIMAL : new Decimal(account.creditLimit);
    const reservedAmount = account.reservedAmount === undefined ? ZERO_DECIMAL : new Decimal(account.reservedAmount);
    const balance = new Decimal(account.reportingCurrency.balance);

    return (
        <DetailGroupTable>
            <DetailGroupTableHead>
                <DetailGroupTableCellValue colSpan={2}>
                    <FormattedMessage id="detailGroupPopover_title_limits"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedMessage id="detailGroupPopover_title_currency"/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableHead>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_empty"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_limits_creditLimit"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={creditLimit.toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    {balance.lessThan(0)
                        ? <FormattedMessage id="detailGroupPopover_dotSlashDot"/>
                        : <FormattedMessage id="detailGroupPopover_plus"/>}
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_limits_balance"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={balance.abs().toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_dotSlashDot"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_limits_reserved"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={reservedAmount.toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>

            <DetailGroupTableRow>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_equals"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellValue>
                    <FormattedMessage id="detailGroupPopover_limits_available"/>
                </DetailGroupTableCellValue>
                <DetailGroupTableCellAmount>
                    <FormattedNumber value={creditLimit.sub(reservedAmount).add(balance).toNumber()}/>
                </DetailGroupTableCellAmount>
            </DetailGroupTableRow>
        </DetailGroupTable>
    );
}
