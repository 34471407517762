import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { ChecklistModule } from "src/State/Checklist/ChecklistModule";
import { ChecklistTrash } from "src/State/Checklist/ChecklistTrash";
import { ConsultationData } from "src/State/ConsultationState";
import { ChecklistUseCase } from "src/State/Checklist/ChecklistUseCase";
import { ChecklistSection } from "src/State/Checklist/ChecklistSection";
import { ChecklistItem } from "src/State/Checklist/ChecklistItem";

export interface IChecklistPatchMapper {
    mapChecklist(
        consultationData: ConsultationData,
    ): Graviton.Consultation.Checklist.Checklist;
}

export class ChecklistPatchMapper implements IChecklistPatchMapper {
    public static $inject: string[] = [
        "evjExtRefGenerator",
    ];
    public constructor(
        private extRefGenerator: IExtRefGenerator,
    ) {
    }

    public mapChecklist(
        consultationData: ConsultationData,
    ): Graviton.Consultation.Checklist.Checklist {
        return consultationData.checklist.modules.reduce((checklist, module, index) => ({
            ...checklist,
            [module.key]: this.mapModule(module, index),
        }), {
            trash: this.mapTrash(
                consultationData.checklist.trash,
                consultationData.checklist.modules.length,
            ),
        } as Graviton.Consultation.Checklist.Checklist);
    }

    private mapModule(module: ChecklistModule, order: number): Graviton.Consultation.Checklist.Module {
        return {
            $ref: this.extRefGenerator.createRef(EndpointName.CORE_MODULE, module.key),
            name: module.name,
            order: order,

            collapsed: module.collapsed,
            discussed: module.discussed,
            showsResultColumnItem: module.showsResultColumnItem,

            useCases: module.useCase
                ? [this.mapUseCase(module.useCase)]
                : module.useCases.map((useCase) => this.mapUseCase(useCase)),
        };
    }

    private mapUseCase(useCase: ChecklistUseCase): Graviton.Consultation.Checklist.UseCase {
        return {
            id: useCase.id,
            name: useCase.name,
            sections: useCase.sections.map((section) => this.mapSection(section)),
        };
    }

    private mapSection(section: ChecklistSection): Graviton.Consultation.Checklist.Section {
        return {
            id: section.id,

            collapsed: section.collapsed,
            imageKey: section.imageKey,

            name: section.name,
            items: section.items.map((item) => this.mapItem(item)),
        };
    }

    private mapItem(item: ChecklistItem): Graviton.Consultation.Checklist.Item {
        return {
            id: item.id,
            name: item.name,

            isChecked: item.isChecked,
            isOwnItem: item.isOwnItem,

            isHidden: item.isHidden,
            displayCondition: item.displayCondition!,

            syncStatus: item.syncStatus,
            processStatus: item.processStatus,
            processExtraData: item.processExtraData,
            externalReference: item.externalReference,
        };
    }

    private mapTrash(trash: ChecklistTrash, order: number): Graviton.Consultation.Checklist.Trash {
        return {
            $ref: this.extRefGenerator.createRef(EndpointName.CORE_MODULE, "trash"),
            order: order,

            collapsed: trash.collapsed,
            discussed: trash.discussed,
            showsResultColumnItem: trash.showsResultColumnItem,

            name: trash.name,
            items: trash.items.map((item) => this.mapItem(item)),
        };
    }
}
