import { Decimal } from "decimal.js-light";
import { assertDefined } from "src/Utils/assertionHelpers";
import { isDefined } from "src/Utils/isDefined";
import { BalanceCategory, BalanceCategoryType } from "src/State/Balance/BalanceCategory";
import { IBalanceObjectMapper } from "src/Services/BalanceData/BalanceObjectMapper";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { BalanceCreationClass } from "src/State/Balance/BalanceCreationClass";
import { BalanceObjectBalanceAccount } from "src/State/Balance/BalanceObjectBalanceAccount";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { IDateConverter } from "src/Services/Utils/DateConverter";
import { IExtRefGenerator } from "src/Services/ApiClient/ExtRefGenerator";
import { IExtRefComparator } from "src/Services/ApiClient/ExtRefComparator";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { DisplayClassLink } from "src/Services/Reference/DisplayClassLink";
import { OutputClassLink } from "src/Services/Reference/OutputClassLink";
import { LogicError } from "src/Errors/LogicError";

export class BalanceObjectCustomerBalanceMapper implements IBalanceObjectMapper {
    public static $inject: string[] = [
        "evjExtRefGenerator",
        "evjExtRefComparator",
        "evjDateConverter",
        "evjReferenceDisplayClassLinks",
        "evjReferenceOutputClassLinks",
    ];

    public constructor(
        private extRefGenerator: IExtRefGenerator,
        private extRefComparator: IExtRefComparator,
        private dateConverter: IDateConverter,
        private displayClassLinks: ReadonlyArray<DisplayClassLink>,
        private outputClassLinks: ReadonlyArray<OutputClassLink>,
    ) {
    }

    public mapBalanceObjects(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        categories: ReadonlyArray<BalanceCategory>,
        creationClasses: ReadonlyArray<BalanceCreationClass>,
    ): BalanceObjectCustomerBalance[] {
        const objects = balance.consultation.balanceAccounts.map((account): BalanceObjectCustomerBalance => {
            const category = assertDefined(
                categories.find((it) => this.extRefComparator.compareRefs(
                    this.extRefGenerator.createRef(EndpointName.BALANCE_CATEGORY, it.balanceCategoryId),
                    account.category.$ref,
                )),
                `Could not find category "${account.category.$ref}"`,
                { categories, account: account },
            );
            const creationClass = this.mapCreationClass(account, creationClasses);

            return {
                id: account.id,
                mainId: undefined,
                customerBalance: this.mapCustomerBalanceObject(account),
                category: category,
                amount: account.amount
                    ? new Decimal(account.amount)
                    : ZERO_DECIMAL,

                displayClassType: this.ensureDisplayClass(account),
                outputClassType: this.ensureOutputClass(account),
                creationClass: creationClass,
            };
        }).filter(isDefined);

        return objects.reduce((result, customerBalance) => {
            if (customerBalance.customerBalance.currentMarketPrice && customerBalance.category.isRealEstate) {
                const category = categories.find((it) => it.isRealEstate && it.type === BalanceCategoryType.ASSETS);
                if (category) {
                    const realEstateAsset: BalanceObjectCustomerBalance = {
                        ...customerBalance,
                        id: `${customerBalance.id}/duplicate`,
                        mainId: customerBalance.id,
                        category: category,
                        amount: new Decimal(customerBalance.customerBalance.currentMarketPrice),
                    };
                    return result.concat(customerBalance, realEstateAsset);
                }
            }

            return result.concat(customerBalance);
        }, [] as BalanceObjectCustomerBalance[]);
    }

    private ensureDisplayClass(
        account: Readonly<Graviton.Consultation.Balance.Consultation.CustomerBalance>,
    ): BalanceObjectCustomerBalance["displayClassType"] {
        const balanceDisplayClassLink = assertDefined(
            this.displayClassLinks.find((it) => it.number === account.displayClass.number),
            `Could not find balanceDisplayClass link "${account.displayClass.number}"`,
            { displayClass: account.displayClass, links: this.displayClassLinks },
        );
        switch (balanceDisplayClassLink.type) {
            case BalanceDisplayClassType.CUSTOMER_BALANCE:
            case BalanceDisplayClassType.NEW_CONTRACT:
            case BalanceDisplayClassType.NEW_ENGAGEMENT:
            case BalanceDisplayClassType.FINANCING_DEBT:
                return balanceDisplayClassLink.type;

            default:
                throw new LogicError(
                    `Unexpected display class type "${balanceDisplayClassLink.type}"`,
                    { account, balanceDisplayClassLink },
                );
        }
    }

    private ensureOutputClass(
        account: Readonly<Graviton.Consultation.Balance.Consultation.CustomerBalance>,
    ): BalanceObjectCustomerBalance["outputClassType"] {
        const balanceOutputClassLink = assertDefined(
            this.outputClassLinks.find((it) => it.number === account.outputClass.number),
            `Could not find balanceOutputClass link "${account.outputClass.number}"`,
            { outputClass: account.outputClass, links: this.outputClassLinks },
        );
        switch (balanceOutputClassLink.type) {
            case BalanceOutputClassType.CUSTOMER_BALANCE:
            case BalanceOutputClassType.NEW_CONTRACT:
            case BalanceOutputClassType.NEW_ENGAGEMENT:
            case BalanceOutputClassType.FINANCING_DEBT:
                return balanceOutputClassLink.type;

            default:
                throw new LogicError(
                    `Unexpected output class type "${balanceOutputClassLink.type}"`,
                    { account, balanceOutputClassLink },
                );
        }
    }

    private mapCreationClass(
        account: Readonly<Graviton.Consultation.Balance.Consultation.CustomerBalance>,
        creationClasses: ReadonlyArray<BalanceCreationClass>,
    ): BalanceCreationClass {
        return assertDefined(
            creationClasses.find((it) => it.code.id === account.creationClass.id),
            `Could not find creationClass "${account.creationClass.id}"`,
            { account, creationClasses },
        );
    }

    private mapCustomerBalanceObject(
        account: Readonly<Graviton.Consultation.Balance.Consultation.CustomerBalance>,
    ): BalanceObjectBalanceAccount {
        return {
            bank: account.bank,
            title: account.title || "",
            rubric: account.rubric || "",
            endDate: account.endDate
                ? this.dateConverter.convertToApp(account.endDate)
                : undefined,
            currentMarketPrice: account.currentMarketPrice
                ? new Decimal(account.currentMarketPrice)
                : undefined,
            isAtThirdParty: account.isAtThirdParty,
        };
    }
}
