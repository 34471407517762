import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { LiquidityPlanningProps } from "src/Pages/LiquidityPlanningPage";
import { LiquidityPlanningBar } from "src/Components/LiquidityPlanning/LiquidityPlanningBar";
import { LiquidityPlanningInput } from "src/Components/LiquidityPlanning/LiquidityPlanningInput";
import { classList } from "src/Utils/classList";

export class LiquidityPlanning extends React.Component<LiquidityPlanningProps> {
    public render(): JSX.Element {
        const {
            liquidityPlanning: {
                total,
                planned,
                wishReserve,
                provision,
                investment,
            },
            changeTotal,
            changePlanned,
            changeWishReserve,
            changeProvision,
            changeInvestment,
        } = this.props;
        const sum = ZERO_DECIMAL
            .add(planned)
            .add(wishReserve)
            .add(provision)
            .add(investment);

        return (
            <div className="m03-liquidity-planning">
                <div className="m03-liquidity-planning__header">
                    <FormattedMessage id="liquidityPlanning_title"/>
                </div>
                <div className="m03-liquidity-planning__content">
                    <div className={classList([
                        "m03-liquidity-planning__column",
                        "m03-liquidity-planning__column--total",
                    ])}>
                        <div className={classList([
                            "m03-liquidity-planning__column-bar",
                            "m03-liquidity-planning__column-bar--total",
                        ])}>
                            <LiquidityPlanningBar mode="total"
                                                  style="total"
                                                  value={total.sub(sum)}
                                                  total={total}/>
                        </div>
                        <div className="m03-liquidity-planning__column-title">
                            <FormattedMessage id="liquidityPlanning_column_total"/>
                        </div>
                        <div className="m03-liquidity-planning__column-input">
                            <LiquidityPlanningInput id="total"
                                                    value={total}
                                                    disabled={false}
                                                    onChange={changeTotal}/>
                        </div>
                    </div>

                    <div className={classList([
                        "m03-liquidity-planning__column",
                        "m03-liquidity-planning__column--planned",
                    ])}>
                        <div className={classList([
                            "m03-liquidity-planning__column-bar",
                            "m03-liquidity-planning__column-bar--planned",
                        ])}>
                            <LiquidityPlanningBar mode="value"
                                                  style="planned"
                                                  value={planned}
                                                  total={total}/>
                        </div>
                        <div className="m03-liquidity-planning__column-title">
                            <FormattedMessage id="liquidityPlanning_column_planned"/>
                        </div>
                        <div className="m03-liquidity-planning__column-input">
                            <LiquidityPlanningInput id="planned"
                                                    value={planned}
                                                    disabled={total.isZero()}
                                                    onChange={changePlanned}/>
                        </div>
                    </div>

                    <div className={classList([
                        "m03-liquidity-planning__column",
                        "m03-liquidity-planning__column--wishReserve",
                    ])}>
                        <div className={classList([
                            "m03-liquidity-planning__column-bar",
                            "m03-liquidity-planning__column-bar--wishReserve",
                        ])}>
                            <LiquidityPlanningBar mode="value"
                                                  style="wishReserve"
                                                  value={wishReserve}
                                                  total={total}/>
                        </div>
                        <div className="m03-liquidity-planning__column-title">
                            <FormattedMessage id="liquidityPlanning_column_wishReserve"/>
                        </div>
                        <div className="m03-liquidity-planning__column-input">
                            <LiquidityPlanningInput id="wishReserve"
                                                    value={wishReserve}
                                                    disabled={total.isZero()}
                                                    onChange={changeWishReserve}/>
                        </div>
                    </div>

                    <div className={classList([
                        "m03-liquidity-planning__column",
                        "m03-liquidity-planning__column--provision",
                    ])}>
                        <div className={classList([
                            "m03-liquidity-planning__column-bar",
                            "m03-liquidity-planning__column-bar--provision",
                        ])}>
                            <LiquidityPlanningBar mode="value"
                                                  style="provision"
                                                  value={provision}
                                                  total={total}/>
                        </div>
                        <div className="m03-liquidity-planning__column-title">
                            <FormattedMessage id="liquidityPlanning_column_provision"/>
                        </div>
                        <div className="m03-liquidity-planning__column-input">
                            <LiquidityPlanningInput id="provision"
                                                    value={provision}
                                                    disabled={total.isZero()}
                                                    onChange={changeProvision}/>
                        </div>
                    </div>

                    <div className={classList([
                        "m03-liquidity-planning__column",
                        "m03-liquidity-planning__column--investment",
                    ])}>
                        <div className={classList([
                            "m03-liquidity-planning__column-bar",
                            "m03-liquidity-planning__column-bar--investment",
                        ])}>
                            <LiquidityPlanningBar mode="value"
                                                  style="investment"
                                                  value={investment}
                                                  total={total}/>
                        </div>
                        <div className="m03-liquidity-planning__column-title">
                            <FormattedMessage id="liquidityPlanning_column_investment"/>
                        </div>
                        <div className="m03-liquidity-planning__column-input">
                            <LiquidityPlanningInput id="investment"
                                                    value={investment}
                                                    disabled={total.isZero()}
                                                    onChange={changeInvestment}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
