import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import { SYNCHRONIZATION_ACTIONS } from "src/Actions/SynchronizationActions";
import { CUSTOMER_BALANCE_DELETE_ACTIONS, CustomerBalanceDeleteRequest } from "src/Actions/CustomerBalanceActions";

export function* customerBalanceDeleteSaga(
    { data: { object } }: CustomerBalanceDeleteRequest,
): SagaIterator {
    yield put(CUSTOMER_BALANCE_DELETE_ACTIONS.success(object));
    yield put(SYNCHRONIZATION_ACTIONS.request(null));
}
