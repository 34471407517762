import * as URI from "urijs";
import { NativeError } from "src/Errors/NativeError";
import { AssertionError } from "src/Errors/AssertionError";
import { ParametersState } from "src/State/ParametersState";
import { assertOneOf, assertTypeIs } from "src/Utils/assertionHelpers";
import { MODULE_MINIMUM_WIDTH, MODULE_MINIMUM_HEIGHT } from "src/Constants/UiConstants";

ParametersDefaultState.$inject = [
    "$queryString",
];
export function ParametersDefaultState(
    $queryString: URLSearchParams,
): ParametersState {
    return {
        consultationId: getRequired($queryString, "consultationId"),
        grvURL: getRequired($queryString, "grvURL"),
        refURL: getValidRefUrl($queryString, "refURL"),
        theme: getOneOf($queryString, "theme", ["dark", "white"]),
        locale: getOneOf($queryString, "locale", ["de", "en", "fr"]),
        showDistribution: $queryString.has("showDistribution"),

        maxWidth: getLayoutSize($queryString, "maxWidth", MODULE_MINIMUM_WIDTH),
        maxHeight: getLayoutSize($queryString, "maxHeight", MODULE_MINIMUM_HEIGHT),
    };
}

function getRequired($queryString: URLSearchParams, parameter: string): string {
    return assertTypeIs(
        $queryString.get(parameter),
        (value): value is string => value !== null && value !== "",
        `URL parameter "${parameter}" should be defined`,
        { $queryString },
    );
}

function getOneOf<T extends string>($queryString: URLSearchParams, parameter: string, allowed: T[]): T {
    return assertOneOf(
        getRequired($queryString, parameter),
        allowed,
        `URL parameter "${parameter}" should be "${allowed.join(`" | "`)}"`,
        { $queryString },
    );
}

function getValidRefUrl($queryString: URLSearchParams, parameter: string): string {
    const raw = getRequired($queryString, parameter);

    try {
        const url = URI(raw);
        if (url.pathname() !== "/") {
            throw new AssertionError("URL should not contain path name");
        } else if (url.search() !== "") {
            throw new AssertionError("URL should not contain query string");
        }

        return raw;
    } catch (e) {
        throw new AssertionError(
            `URL parameter "${parameter}" should be valid URL`,
            { $queryString },
            NativeError.wrapError(e),
        );
    }
}

function getLayoutSize(
    $queryString: URLSearchParams,
    paramName: string,
    minimumValue: number,
): number | null {
    const value = $queryString.get(paramName);
    if (value === null) {
        return null;
    } else if (value === "none") {
        return null;
    }

    const parsed = parseInt(value, 10);
    if (!Number.isFinite(parsed)) {
        throw new AssertionError(
            `URL parameter "${paramName}" should be a valid number or "none"`,
            { $queryString },
        );
    }

    if (parsed < minimumValue) {
        throw new AssertionError(
            `URL parameter "${paramName}" should not be less than ${minimumValue}`,
            { $queryString },
        );
    }

    return parsed;
}
