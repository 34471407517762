import { assertDefined, assertNotNull } from "src/Utils/assertionHelpers";
import { BalanceObjectCustomerBalance } from "src/State/Balance/BalanceObject";
import { BalanceDisplayClassType, BalanceOutputClassType } from "src/State/Balance/BalanceType";
import { DisplayClassLink } from "src/Services/Reference/DisplayClassLink";
import { OutputClassLink } from "src/Services/Reference/OutputClassLink";
import { LogicError } from "src/Errors/LogicError";
import { CustomerBalanceForm } from "src/State/Form/CustomerBalanceForm";
import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";

export interface IBalanceObjectCreateService {
    create(
        balanceObjectId: string,
        balanceCategories: ReadonlyArray<BalanceCategory>,
        balanceCategoryMappings: ReadonlyArray<BalanceCategoryMapping>,
        formData: CustomerBalanceForm,
    ): BalanceObjectCustomerBalance;
}

export class BalanceObjectCreateService implements IBalanceObjectCreateService {
    public static $inject: string[] = [
        "evjReferenceDisplayClassLinks",
        "evjReferenceOutputClassLinks",
    ];

    public constructor(
        private displayClassLinks: ReadonlyArray<DisplayClassLink>,
        private outputClassLinks: ReadonlyArray<OutputClassLink>,
    ) {
    }

    public create(
        balanceObjectId: string,
        balanceCategories: ReadonlyArray<BalanceCategory>,
        balanceCategoryMappings: ReadonlyArray<BalanceCategoryMapping>,
        formData: CustomerBalanceForm,
    ): BalanceObjectCustomerBalance {
        const categoryMapping = assertDefined(
            balanceCategoryMappings.find((mapping) => mapping.creationClass.id === formData.creationClass.code.id),
            `Creation class "${formData.creationClass.code.id}" does not belong to any category mapping`,
            { balanceCategoryMappings, creationClass: formData.creationClass },
        );
        const balanceCategory = assertDefined(
            balanceCategories.find((category) => category.balanceCategoryId === categoryMapping.category.id),
            `Creation class "${formData.creationClass.code.id}" does not belong to any category`,
            { balanceCategories, categoryMapping, creationClass: formData.creationClass },
        );

        return {
            id: balanceObjectId,
            mainId: undefined,

            category: balanceCategory,
            creationClass: formData.creationClass,
            displayClassType: this.mapDisplayClass(categoryMapping.displayClass),
            outputClassType: this.mapOutputClass(categoryMapping.outputClass),

            amount: assertNotNull(
                formData.amount,
                `Invalid "CustomerBalanceForm.amount" value`,
                { formData },
            ),
            customerBalance: {
                isAtThirdParty: true,
                bank: assertNotNull(
                    formData.bank,
                    `Invalid "CustomerBalanceForm.bank" value`,
                    { formData },
                ),

                title: formData.title,
                rubric: formData.rubric,

                endDate: formData.endDate
                    ? formData.endDate
                    : undefined,
                currentMarketPrice: formData.currentMarketPrice
                    ? formData.currentMarketPrice
                    : undefined,
            },
        };
    }

    private mapDisplayClass(
        displayClass: Readonly<Graviton.Entity.Code>,
    ): BalanceObjectCustomerBalance["displayClassType"] {
        const balanceDisplayClassLink = assertDefined(
            this.displayClassLinks.find((it) => it.number === displayClass.number),
            `Could not find balanceDisplayClass link "${displayClass.number}"`,
            { displayClass: displayClass, links: this.displayClassLinks },
        );
        switch (balanceDisplayClassLink.type) {
            case BalanceDisplayClassType.CUSTOMER_BALANCE:
            case BalanceDisplayClassType.NEW_CONTRACT:
            case BalanceDisplayClassType.NEW_ENGAGEMENT:
            case BalanceDisplayClassType.FINANCING_DEBT:
                return balanceDisplayClassLink.type;

            default:
                throw new LogicError(
                    `Unexpected display class type "${balanceDisplayClassLink.type}"`,
                    { displayClass, balanceDisplayClassLink },
                );
        }
    }

    private mapOutputClass(
        outputClass: Readonly<Graviton.Entity.Code>,
    ): BalanceObjectCustomerBalance["outputClassType"] {
        const balanceOutputClassLink = assertDefined(
            this.outputClassLinks.find((it) => it.number === outputClass.number),
            `Could not find balanceOutputClass link "${outputClass.number}"`,
            { outputClass: outputClass, links: this.outputClassLinks },
        );
        switch (balanceOutputClassLink.type) {
            case BalanceOutputClassType.CUSTOMER_BALANCE:
            case BalanceOutputClassType.NEW_CONTRACT:
            case BalanceOutputClassType.NEW_ENGAGEMENT:
            case BalanceOutputClassType.FINANCING_DEBT:
                return balanceOutputClassLink.type;

            default:
                throw new LogicError(
                    `Unexpected output class type "${balanceOutputClassLink.type}"`,
                    { outputClass, balanceOutputClassLink },
                );
        }
    }
}
