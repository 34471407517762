import { createSelector } from "reselect";
import { getBalanceObjects } from "src/Selectors/Balance/getBalanceObjects";
import { assertDefined } from "src/Utils/assertionHelpers";
import { ApplicationState } from "src/State/ApplicationState";

// create a parametric selector
const getObjectId = (state: ApplicationState, id: string) => id;

export const getBalanceObjectById = createSelector([
    getBalanceObjects,
    getObjectId,
], (balanceObjects, objectId) => assertDefined(
    balanceObjects.find((it) => it.id === objectId),
    `Could not find balance object with id "${objectId}"`,
    { balanceObjects },
));
