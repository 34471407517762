export enum BalanceAvailabilityCategoryType {
    DIRECT = "direct",
    SELECTIVE = "selective",
    DEFINED = "defined",
    NONE = "none",
}

export type BalanceAvailabilityCategory = {
    readonly id: string;
    readonly type: BalanceAvailabilityCategoryType;
    readonly order: number;
    readonly title: Readonly<Graviton.Common.Translatable>;
    readonly coreType: Readonly<Graviton.Entity.Code>;
    readonly hasPositionBasedAvailability: boolean;
};
