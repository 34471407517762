import { createSelector } from "reselect";
import { getParameterData } from "src/Selectors/Balance/getParameterData";
import { getAssetCategories } from "src/Selectors/Balance/getAssetCategories";
import { EndpointName } from "src/Services/ApiClient/EndpointName";
import { EndpointEntity } from "src/Services/ApiClient/EndpointEntity";
import { assertDefined } from "src/Utils/assertionHelpers";
import { getRefComparator } from "src/Selectors/getRefComparator";
import { getRefGenerator } from "src/Selectors/getRefGenerator";
import { CategoriesByEntityCode } from "src/State/Balance/CategoriesByEntityCode";

export const getCategoriesByBalanceCategory = createSelector([
    getParameterData,
    getAssetCategories,
    getRefGenerator,
    getRefComparator,
], (
    { balanceCategories },
    categories,
    refGenerator,
    refComparator,
): CategoriesByEntityCode[] => {
    function findByRef<TEndpoint extends EndpointName>(
        endpoint: TEndpoint,
        entities: ReadonlyArray<EndpointEntity[TEndpoint] & { id: string }>,
        reference: Graviton.Common.ExtReference<EndpointEntity[TEndpoint]>,
    ): EndpointEntity[TEndpoint] {
        return assertDefined(
            entities.find((entity) => refComparator.compareRefs(
                reference,
                refGenerator(endpoint, entity.id),
            )),
            `Could not find entity "${endpoint}" with reference "${reference}"`,
            { endpoint, entities, reference },
        );
    }

    return categories
        .map((category) => {
            const balanceCategory = findByRef(EndpointName.ENTITY_CODE, balanceCategories, category.coreTypes[0].$ref);
            return {
                code: balanceCategory,
                categoryIds: [category.balanceCategoryId],
            };
        });
});
