import { BalanceObject } from "src/State/Balance/BalanceObject";
import { BalanceCategory } from "src/State/Balance/BalanceCategory";
import { BalanceCreationClass } from "src/State/Balance/BalanceCreationClass";
import { BalanceCategoryMapping } from "src/State/Balance/BalanceCategoryMapping";

export interface IBalanceObjectMapper {
    mapBalanceObjects(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        categories: ReadonlyArray<BalanceCategory>,
        creationClasses: ReadonlyArray<BalanceCreationClass>,
        categoryMappings: ReadonlyArray<BalanceCategoryMapping>,
    ): BalanceObject[];
}

export class BalanceObjectMapper implements IBalanceObjectMapper {
    private balanceObjectMappers: IBalanceObjectMapper[];

    public static $inject: string[] = [
        "evjBalanceObjectAccountMapper",
        "evjBalanceObjectCustomerBalanceMapper",
        "evjBalanceObjectFinancingMapper",
    ];
    public constructor(
        ...balanceObjectMappers: IBalanceObjectMapper[]
    ) {
        this.balanceObjectMappers = balanceObjectMappers;
    }

    public mapBalanceObjects(
        balance: Readonly<Graviton.Consultation.Balance.Balance>,
        categories: ReadonlyArray<BalanceCategory>,
        creationClasses: ReadonlyArray<BalanceCreationClass>,
        categoryMappings: ReadonlyArray<BalanceCategoryMapping>
    ): BalanceObject[] {
        return this.balanceObjectMappers.reduce((result, mapper) =>
            [...result, ...mapper.mapBalanceObjects(balance, categories, creationClasses, categoryMappings)],
            []
        );
    }
}
