import { Decimal } from "decimal.js-light";
import { ZERO_DECIMAL } from "src/Constants/ZeroDecimal";
import { BalanceObject } from "src/State/Balance/BalanceObject";
import { isBalanceObjectCustomerBalance } from "src/Services/Utils/balanceObjectGuard";
import { balanceObjectSum } from "src/Services/Utils/balanceObjectSum";

export function balanceObjectSumInBank(objects: ReadonlyArray<BalanceObject>): Decimal {
    const total = balanceObjectSum(objects);
    const inOtherBanks = objects
        .filter(isBalanceObjectCustomerBalance)
        .filter((item) => item.customerBalance.isAtThirdParty)
        .reduce((result, item) => result.add(item.amount), ZERO_DECIMAL);
    return total.minus(inOtherBanks);
}
