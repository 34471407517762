import { SagaIterator } from "redux-saga";
import { takeEvery } from "redux-saga/effects";
import {
    AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS,
    AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS,
    AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS,
    AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS,
} from "src/Actions/AvailabilityActions";
import { availabilityChangeNkkPercentSaga } from "src/Sagas/Availability/availabilityChangeNkkPercentSaga";
import { availabilityChangeWishedAmountSaga } from "src/Sagas/Availability/availabilityChangeWishedAmountSaga";
import { availabilityChangeObjectAmountSaga } from "src/Sagas/Availability/availabilityChangeObjectAmountSaga";
import { availabilityToggleObjectAmountSaga } from "src/Sagas/Availability/availabilityToggleObjectAmountSaga";

export function* availabilitySaga(): SagaIterator {
    yield takeEvery(AVAILABILITY_CHANGE_NKK_PERCENT_ACTIONS.REQUEST, availabilityChangeNkkPercentSaga);
    yield takeEvery(AVAILABILITY_CHANGE_WISHED_AMOUNT_ACTIONS.REQUEST, availabilityChangeWishedAmountSaga);
    yield takeEvery(AVAILABILITY_CHANGE_OBJECT_AMOUNT_ACTIONS.REQUEST, availabilityChangeObjectAmountSaga);
    yield takeEvery(AVAILABILITY_TOGGLE_OBJECT_AMOUNT_ACTIONS.REQUEST, availabilityToggleObjectAmountSaga);
}
